import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITabState } from '../../../../../../../../states/tabState.model';
import { IConditionsTabState, conditionsTabState } from '../../../../../conditions.state';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ContentCopy } from '@mui/icons-material';
import { IndividualPricesPopup } from '../popups/individualPrices/individualPrices.popup';
import { CopyIndividualPricesPopup } from '../popups/copyIndividualPrices/copyIndividualPrices.popup';
import { customersListState } from '../../../../../../../../states/customersList.state';
import { FieldsRow } from '../../../../../../../../../../shared/components/form';
import { Stack } from '@mui/material';
import { IconButton } from '../../../../../../../../../order/common/iconButton/iconButton.component';

export const ControlButtons: FC<IControlButtons> = (props) => {
  const { selectPos, specialPricesData, individualPricesPopup, copyIndividualPricesPopup } = props;
  const { t } = useTranslation();
  const [currentCustomerId, setCurrentCustomerId] = useState('');

  useEffect(() => {
    const customerListSub = customersListState.sub.state().subscribe(({ selectedRecord }) => {
      setCurrentCustomerId(selectedRecord?.id ?? '');
    });

    return () => {
      customerListSub.unsubscribe();
    };
  }, []);

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('customer.addNewRecord'),
        icon: <AddIcon />,
        onClick: () =>
          individualPricesPopup.stream.emit('open', {
            values: {
              row: {
                articleNo: null,
                comment: null,
                description: null,
                fromDate: null,
                fromQuantity: null,
                id: `new_${Date.now()}`,
                articleId: '',
                regularPrice: null,
                specialDiscount: null,
                specialIsNoDiscount: false,
                specialPrice: null,
                specialPriceType: null,
                toDate: null,
              },
              field: 'description',
            },
          }),
      },
      {
        title: t('customer.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => conditionsTabState.pub.deleteSpecialPricesPos(),
        disabled: !specialPricesData.length || !selectPos,
      },
      {
        title: t('customer.copy_article_prices'),
        icon: <ContentCopy />,
        onClick: () => copyIndividualPricesPopup.stream.emit('open', { currentCustomerId }),
      },
    ],
    [t, specialPricesData, selectPos, currentCustomerId],
  );

  return (
    <FieldsRow marginBottom={1} alignItems='center' spacing={0}>
      <Stack direction='row' spacing={2}>
        {buttonList.map(({ icon, ...props }, i) => (
          <IconButton key={i} color='primary' size='large' {...props}>
            {icon}
          </IconButton>
        ))}
      </Stack>
    </FieldsRow>
  );
};

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

interface IControlButtons {
  specialPricesData: ITabState['customerSpecialPrices'];
  selectPos: IConditionsTabState['selectedSpecialPricesPos'];
  individualPricesPopup: IndividualPricesPopup;
  copyIndividualPricesPopup: CopyIndividualPricesPopup;
}
