import { ChangeEvent, FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';

export const FormToggleButtonGroup: FC<IFormToggleButtonGroup> = ({
  onChange,
  name,
  controllerProps = {},
  defaultValue,
  children,
  ...props
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: onFieldChange, value, ...fieldProps } }) => {
        const isArrayValue = Array.isArray(value);
        const arrayValue = isArrayValue ? value : convertValueToArray(value);
        return (
          <ToggleButtonGroup
            {...fieldProps}
            value={arrayValue}
            onChange={(_, v) => {
              let newValue = v;
              if (!isArrayValue) {
                const allKeys = Object.keys(value);
                newValue = convertValueToObj(v, allKeys);
              }
              onFieldChange(newValue);
              onChange?.(newValue);
            }}
            {...props}
          >
            {children}
          </ToggleButtonGroup>
        );
      }}
    />
  );
};

function convertValueToArray(styles: IObjValue): string[] {
  if (!styles) {
    return [];
  }
  return Object.keys(styles).filter((key) => styles[key as keyof IObjValue]);
}

function convertValueToObj(styles: string[], allKeys: string[]): IObjValue {
  return allKeys.reduce((acc, key) => {
    acc[key] = styles.includes(key);
    return acc;
  }, {} as IObjValue);
}

interface IObjValue {
  [key: string]: boolean;
}

interface IFormToggleButtonGroup extends Omit<ToggleButtonGroupProps, 'value' | 'onChange'> {
  onChange?: (v: ChangeEvent<HTMLInputElement>['target']['value']) => void;
  controllerProps?: Omit<ControllerProps, 'render' | 'name'>;
  name: ControllerProps['name'];
}
