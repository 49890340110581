import { gql } from 'graphql-request';

export const getCompanyConfig = gql`
  query getConfigData {
    wawiAssist {
      getConfigData {
        id
        countryId
        companyLanguageId
        decimalPlacesCount
        isShippingFunctionActivated
        isBakery2bActivated
        isDefaultEmailTextForInvoice
        customEmailTextForInvoice
        isDefaultEmailTextForOrder
        isDefaultEmailTextForOffer
        customEmailTextForOffer
        customEmailTextForOrder
        isDefaultEmailTextForDebtor
        customEmailTextForDebtor
        orderPositionsSortingKindId
        kiloAmountRepresentationId
        defaultFontOfNoteInOrder
        isDoughInLitersInProductionReports
        isAllowSameEANForMultipleArticles
        isShowShippingPointsInArticle
        nextDeliveryDate
        companyNameInReports
        fibuClient
        fibuVatCodeNormal
        fibuVatCodeReduced
        fibuVatCodeWithoutVAT
        canForbidIncomingOrdersCAdirect
        canWorkWithVersionsInProduction
        globalTaxSystemId
        isUseProcurementLogicInProductionReports
        isAbacusXMLExport
        isDatevCommitment
        isAutoCustomerNo
        autoCustomerNoPrefix
        autoCustomerNoCounter
        actualInvoiceNo
        actualOrderNo
        roundingArticlePricesMethodId
        isShippingDocsFunctionActivated
        isShippingDocsForCardboardBoxes
        isUsePriceCategoryAhead
        isGotoNextDeliveryDay
        roundingTotalPricesMethodId
        orderTypesFilterInProductionReportsCheckedState {
          id
          checked
        }
        isUseOtherCurrencies
        standardBankId
        besrFormatId
        operationCostCenter
        euroRate
        usdRate
        currentUser {
          name
          company
          role
          avatarUrl
          userLanguageId
        }
        isCADBAvailable
        companySalutation
        companyName
        companyAddition
        companyStreet
        companyZipCode
        companyCity
        companyPhone
        companyFax
        companyEmail
        companyWebsite
        companyGLN
        companyVatNo
        companyTimeZone
        bankName
        bankBIN
        bankAccountNo
        bankIBAN
        creditorIdNumber
        bankBIC
        isDeliveryOnMon
        isDeliveryOnTue
        isDeliveryOnWed
        isDeliveryOnThu
        isDeliveryOnFri
        isDeliveryOnSat
        isDeliveryOnSun
        isShowSplittedAmountInLiters
        isShowRASemiproductsDetails
      }
    }
  }
`;

export const saveConfigData = gql`
  mutation saveConfigData($dataToSave: WA_SaveConfigInput!) {
    wawiAssist {
      saveConfigData(dataToSave: $dataToSave) {
        status
      }
    }
  }
`;
