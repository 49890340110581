import { FC, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { TDefaultValues } from './resellerPrices.popup.tsx';
import { generalTabState } from '../../../../../generalTab.state.ts';
import { useBeforeClosePopup } from '../../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import {
  FieldsRow,
  FormNumericField,
  FormSelect,
  FormTextField,
} from '../../../../../../../../../../shared/components/form/index.ts';
import { GeneralTabOptionsRes } from '../../../../../../../../services/article.service.ts';
import { IPopupWrapper } from '../../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { ISelectOption } from '../../../../../../../../../../shared/components/form/fields/select.component.tsx';

export const ResellerPricesForm: FC<IResellerPricesForm> = ({
  field,
  customersList,
  defaultValues,
  stream,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<TDefaultValues>({
    mode: 'onChange',
    defaultValues,
  });
  const { errors, dirtyFields } = formMethods.formState;

  const handleSubmit = (values: TDefaultValues) => {
    generalTabState.pub.updateResellerPricesPos(values);
    stream.emit('close');
  };

  const customersOptions = useMemo<Array<ExtendedSelectOption>>(() => {
    return customersList.reduce<Array<ExtendedSelectOption>>(
      (outArr, { id, customerNo, internalOrFullName }) => {
        if (id && (customerNo || internalOrFullName)) {
          outArr.push({ id, customerNo: customerNo || '', label: internalOrFullName || '' });
        }
        return outArr;
      },
      [],
    );
  }, [customersList]);

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.customerNo ? `${option?.customerNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
        <Stack spacing={2}>
          <FieldsRow>
            <FieldsRow width='75%'>
              <FormSelect
                disableClearable
                autoFocus={'customerName' === field}
                width='100%'
                name='customerId'
                label={t('customer.customer')}
                options={customersOptions as []}
                datasetattribute='customerOptions'
                onChange={(_, opt) => {
                  const option = opt as ExtendedSelectOption | null;
                  formMethods.setValue('customerName', option?.label);
                  formMethods.setValue('customerNo', option?.customerNo);
                  formMethods.setValue('customerId', option?.id as string);
                }}
                getOptionLabel={(params) => {
                  const { customerNo, label } = params as ExtendedSelectOption;
                  return `${customerNo} ${label}`;
                }}
                controllerProps={{
                  rules: {
                    validate: (v) => (v ? null : t('common.not_empty')),
                  },
                }}
                {...(errors.customerId && {
                  error: true,
                  helperText: errors.customerId.message,
                })}
                {...{ filterOptions }}
              />
            </FieldsRow>
            <FieldsRow width='25%'>
              <FormNumericField
                name='price'
                autoFocus={'price' === field}
                width='100%'
                precision={3}
                noTrailingZeros
                label={t('common.price')}
                controllerProps={{
                  rules: {
                    validate: (_, { price }) => {
                      return price ? null : t('common.not_empty');
                    },
                    deps: ['price'],
                  },
                }}
                {...(errors.price && {
                  error: true,
                  helperText: errors.price.message,
                })}
              />
            </FieldsRow>
          </FieldsRow>
          <FieldsRow>
            <FormTextField
              inputProps={{ maxLength: 128 }}
              width='100%'
              label={t('customer.comment')}
              name='comment'
              autoFocus={'comment' === field}
            />
          </FieldsRow>
        </Stack>
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button type='submit' variant='contained'>
            {t('common.save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

interface IResellerPricesForm {
  field: string;
  customersList: GeneralTabOptionsRes['customersList'];
  defaultValues: TDefaultValues;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  customerNo?: string;
}
