import { Box, Typography, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TCompanyConfigs,
  companyConfigService,
} from '../../../../../../services/companyConfig/companyConfig.service';
import DefaultAvatar from '../../../../../../icons/default_user_photo.png';
import { Subscription } from 'rxjs';

export const UserInfo: FC = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<TCompanyConfigs['currentUser']>({
    name: t('common.no_data'),
    company: '',
    role: '',
    avatarUrl: DefaultAvatar,
  });

  useEffect(() => {
    const currentUser = companyConfigService.getCachedConfigs()?.currentUser;
    let sub: Subscription;
    if (currentUser) {
      setUserInfo({ ...currentUser, avatarUrl: currentUser?.avatarUrl ?? DefaultAvatar });
    } else {
      sub = companyConfigService.getConfigs(true).subscribe(({ currentUser }) => {
        setUserInfo({ ...currentUser, avatarUrl: currentUser?.avatarUrl ?? DefaultAvatar });
      });
    }

    return () => {
      sub && sub?.unsubscribe();
    };
  }, []);

  return (
    <SWrapper>
      <SImg src={userInfo?.avatarUrl as string} alt={t('common.avatar')} />
      <SUserInfo>
        <SName variant='h6' noWrap>
          {userInfo?.name}
        </SName>
        <SCompany variant='body2' noWrap>
          {userInfo?.company}
        </SCompany>
      </SUserInfo>
    </SWrapper>
  );
};

const SWrapper = styled(Box)({
  maxWidth: '400px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const SImg = styled('img')(({ theme }) => ({
  width: '34px',
  height: '34px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.divider}`,
}));

const SUserInfo = styled(Box)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SName = styled(Typography)({
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '17px',
});

const SCompany = styled(Typography)({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: '2px',
});
