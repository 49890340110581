import { gql } from 'graphql-request';

export const getWAPdfHistoryReport = gql`
  query getWA_PdfHistoryReport($params: WA_GetReportInput!) {
    wawiAssist {
      getWA_PdfHistoryReport(params: $params) {
        documentNo
        reportId
        url
        s3Key
        bucket
      }
    }
  }
`;

export const listWAPdfHistoryReports = gql`
  query listWA_PdfHistoryReports($params: WA_ListReportsInput!) {
    wawiAssist {
      listWA_PdfHistoryReports(params: $params) {
        mergedFile {
          url
          s3Key
          bucket
        }
        reports {
          documentId
          documentNo
          reportId
          url
          s3Key
          bucket
        }
      }
    }
  }
`;

export const sendEmailWithDocument = gql`
  mutation sendEmailWithDocument(
    $messageParams: SendMultipleEmailsInput!
    $docType: C_EMAIL_MSG_DOCTYPE!
    $documentId: ID!
  ) {
    wawiAssist {
      sendEmailWithDocument(
        messageParams: $messageParams
        docType: $docType
        documentId: $documentId
      ) {
        sentMessages {
          messageId
          timestamp
        }
        unsentMessages {
          recipient
        }
      }
    }
  }
`;

export const extractXmlFromPdfOfInvoices = gql`
  mutation extractXmlFromPdfOfInvoices($invoices: [WA_PdfFileOfInvoiceInput!]!) {
    wawiAssist {
      extractXmlFromPdfOfInvoices(invoices: $invoices) {
        invoiceId
        xmlFile {
          s3Bucket
          s3Key
          url
        }
      }
    }
  }
`;

export const getDataForMultiEmails = gql`
  query ListWA_DataForMultiEmails($emailsDocType: C_EMAIL_MSG_DOCTYPE!, $documentsIds: [ID!]!) {
    wawiAssist {
      listWA_DataForMultiEmails(emailsDocType: $emailsDocType, documentsIds: $documentsIds) {
        documentId
        documentNo
        documentDate
        customerId
        customerNo
        internalOrFullName
        email
        eInvoiceFormat
      }
    }
  }
`;

export const getTasksStatus = gql`
  query getTasksStatus($tasksId: [Int!]!) {
    wawiAssist {
      getWinReportTasksStatuses(tasksId: $tasksId) {
        taskId
        status
      }
    }
  }
`;

export const r6_ArticleReportPreview = gql`
  mutation create_r6_ArticleListReport($params: R6_ArticleListInput!) {
    wawiAssist {
      create_r6_ArticleListReport(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r36_ArticleReportAllPriceCategoriesPreview = gql`
  mutation create_r36_ArticleListAllPriceCategoriesReport(
    $params: R36_ArticleListAllPriceCategoriesInput!
  ) {
    wawiAssist {
      create_r36_ArticleListAllPriceCategoriesReport(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r50_ArticleReportWithImagePreview = gql`
  mutation create_r50_ArticleListWithImageReport($params: R50_ArticleListWithImageInput!) {
    wawiAssist {
      create_r50_ArticleListWithImageReport(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r3_CustomerReportAddressesListPreview = gql`
  mutation create_r3_CustomerAddressesListReport($customersIds: [ID!]!) {
    wawiAssist {
      create_r3_CustomerAddressesListReport(customersIds: $customersIds) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r4_CustomerReportFactSheetPreview = gql`
  mutation create_r4_CustomerFactSheetReport($customersIds: [ID!]!) {
    wawiAssist {
      create_r4_CustomerFactSheetReport(customersIds: $customersIds) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r5_CustomerReportPhonesListPreview = gql`
  mutation create_r5_CustomerPhonesListReport($customersIds: [ID!]!) {
    wawiAssist {
      create_r5_CustomerPhonesListReport(customersIds: $customersIds) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r8_DeliveryNoteA4WithPricePreview = gql`
  mutation create_r8_DeliveryNoteA4WithPriceReport($orderId: ID!) {
    wawiAssist {
      create_r8_DeliveryNoteA4WithPriceReport(orderId: $orderId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r29_DeliveryNoteA5LandscapeWithPricePreview = gql`
  mutation create_r29_DeliveryNoteA5LandscapeWithPriceReport($orderId: ID!) {
    wawiAssist {
      create_r29_DeliveryNoteA5LandscapeWithPriceReport(orderId: $orderId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r9_InvoiceA4Preview = gql`
  mutation create_r9_InvoiceA4Report($invoiceId: ID!) {
    wawiAssist {
      create_r9_InvoiceA4Report(invoiceId: $invoiceId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r10_CustomersDebtNotifierPreview = gql`
  mutation create_r10_CustomersDebtNotifier($params: R10CustomersDebtNotifierInput!) {
    wawiAssist {
      create_r10_CustomersDebtNotifier(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r24_PaymentJournalA4Preview = gql`
  mutation create_r24_PaymentJournalA4($paymentsId: [ID!]!) {
    wawiAssist {
      create_r24_PaymentJournalA4(paymentsId: $paymentsId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r35_ExtendedPaymentJournalPreview = gql`
  mutation create_r35_ExtendedPaymentJournal($paymentsId: [ID!]!) {
    wawiAssist {
      create_r35_ExtendedPaymentJournal(paymentsId: $paymentsId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r22_BESRImportJournalPreview = gql`
  mutation create_r22_BESRImportA4($batchId: Int!) {
    wawiAssist {
      create_r22_BESRImportA4(batchId: $batchId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
