import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormCheckbox, FieldsSection } from '../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { configurationTabState } from '../../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  ICheckListData,
  OrderTypesAccountedInProductionReportsPopup,
} from './popups/orderTypesAccountedInProductionReports/orderTypesAccountedInProductionReports.popup.tsx';
import { DictionaryPopup } from '../../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { TextEditCellDict } from '../../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';

export const ProductionTab: FC<IProductionTab> = ({ params }) => {
  const { t } = useTranslation();
  const orderTypesAccountedInProductionReportsPopup = useMemo(
    () => new OrderTypesAccountedInProductionReportsPopup(),
    [],
  );
  const formMethods = useForm({ defaultValues: params, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;
  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        const recordData =
          fieldName === 'orderTypesFilterInProductionReportsCheckedState'
            ? (data[fieldName] as ICheckListData[]).map(({ label, ...rest }) => rest)
            : data[fieldName];
        configurationTabState.pub.recordData({ [fieldName]: recordData });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .pipe()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        if (name !== 'orderTypesFilterInProductionReportsCheckedState') {
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
          });
        }
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      orderTypesAccountedInProductionReportsPopup.stream.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  const cloudKitchenPrintersHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.production_printers',
        width: 700,
        dictionaryType: 'dictCloudPrinters',
        validateFields: ['name', (row) => /^N[A-Z0-9]{12}$/.test(row.serialNo || '')], // checks format as N411227F00614 (first letter must be capital N, and 12 characters must be capital letters or numbers)
        emptyRow: { name: null, serialNo: null },
        columns: [
          {
            field: 'name',
            headerName: t('common.name'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
          {
            field: 'serialNo',
            headerName: t('common.serial_no'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column'>
          <FormCheckbox
            name='isDoughInLitersInProductionReports'
            label={t('settings.show_liters_in_production_print_lists')}
          />
          <FormCheckbox
            name='canForbidIncomingOrdersCAdirect'
            label={t('settings.can_close_orders_from_cashAssist')}
          />
          <FormCheckbox
            name='canWorkWithVersionsInProduction'
            label={t('settings.working_with_versions')}
          />
          <FormCheckbox
            name='isUseProcurementLogicInProductionReports'
            label={t(
              'settings.take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports',
            )}
          />
          <Stack mt={1} alignItems='start' spacing={1} width='fit-content'>
            <Button
              size='medium'
              variant='outlined'
              color='secondary'
              fullWidth
              onClick={() => orderTypesAccountedInProductionReportsPopup.stream.emit('open')}
            >
              {t('settings.order_types_accounted_in_production_reports')}
            </Button>
          </Stack>
        </Stack>
        <orderTypesAccountedInProductionReportsPopup.Component />
      </form>
      <FieldsSection title={t('settings.related_directories')} nomt>
        <Stack alignItems='start' spacing={1} width='fit-content'>
          <Button
            onClick={cloudKitchenPrintersHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.production_printers_settings')}
          </Button>
        </Stack>
      </FieldsSection>
      <dictionaryPopup.Component />
    </FormProvider>
  );
};

interface IProductionTab {
  params: TCompanyConfigs;
}
