import {
  Observable,
  delay,
  filter,
  finalize,
  map,
  of,
  repeat,
  switchMap,
  take,
  takeWhile,
  zip,
} from 'rxjs';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import {
  C_Group_By_Type,
  C_Statistic_Calc_Status,
  C_Statistic_Period_Type,
  GetStatisticDataQuery,
  Wa_InitOrderStatisticCalcMutation,
  Wa_InitOrderStatisticCalcMutationVariables,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { getStatisticData, initOrderStatisticCalc } from './gql/orderStatistic.gql';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper';

type Actions = 'getOrderStatisticData' | undefined;

class PubImpl extends Pub<Actions> {
  getOrderStatisticData(params: Wa_InitOrderStatisticCalcMutationVariables): void {
    this.emit('getOrderStatisticData', params);
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  getOrderStatisticData(): Observable<ISatisticDataRes> {
    return this.actionListener('getOrderStatisticData').pipe(
      switchMap(({ params }) => {
        return zip(gqlClient(initOrderStatisticCalc, params), of(params)) as Observable<
          [Wa_InitOrderStatisticCalcMutation, Wa_InitOrderStatisticCalcMutationVariables]
        >;
      }),
      delay(1300),
      switchMap(([res, params]) => {
        const maxPollingAttempts = 50;
        const jobId = res?.wawiAssist?.WA_initOrderStatisticCalc?.jobId;
        return (gqlClient(getStatisticData, { jobId }) as Observable<GetStatisticDataQuery>).pipe(
          repeat({ delay: 1000 }),
          map((v) => {
            return {
              res: v.wawiAssist?.getWA_statisticData as ISatisticDataResWithStatus,
              params,
            };
          }),
          takeWhile((v) => {
            const isInProgress = v?.res?.status === C_Statistic_Calc_Status.SCS_LOADING;
            return isInProgress;
          }, true),
          filter((v, ind) => {
            const isInProgress = v?.res?.status === C_Statistic_Calc_Status.SCS_LOADING;
            const maxAttemptsReached = ind + 1 > maxPollingAttempts;
            return !isInProgress || maxAttemptsReached;
          }),
          take(1),
        );
      }),
      map(({ res, params }) => {
        const { items, summaryData, presentedOrderTypes, chartsDataset } =
          res?.data as ISatisticDataResNotFormatted;

        const updatedItems = this.transformStatisticData({ items, params, summaryData });

        return {
          items: updatedItems,
          summaryData,
          presentedOrderTypes,
          chartsDataset,
        };
      }),
      finalize(() => orderStatisticService.pub.clearStream()),
    );
  }

  private transformStatisticData(args: IArgsTransformStatisticData): ISatisticDataRes['items'] {
    const { items, params, summaryData } = args || {};
    const noData = !items?.length;
    if (noData) {
      return [];
    }
    const { totalQuantity, totalNetSales } = summaryData || {};

    const updatedItems = items?.map((itemRaw) => {
      let item = this.transformPeriodData({
        item: itemRaw,
        params,
        periodLabel: 'quantityByPeriods',
      });
      item = this.transformPeriodData({
        item: item as IArgsTransformPeriodData['item'],
        params,
        periodLabel: 'netSalesByPeriods',
      });
      const {
        articleNo,
        articleDescription,
        customerNo,
        customerName,
        totalQuantity: quantity = 0,
        netSales,
        quantityByOrderTypes,
      } = item || {};

      let percent = totalQuantity ? quantity / totalQuantity : 0;
      const isTurnover = item?.netSalesByPeriods?.length;
      if (isTurnover) {
        if (!totalNetSales) {
          percent = 0;
        } else {
          percent = (netSales ?? 0) / totalNetSales;
        }
      }
      percent = percent * 100;

      return {
        ...item,
        articleNoAndName: `${articleNo} ${articleDescription}`,
        customerNoAndName: `${customerNo} ${customerName}`,
        percent,
        ...(quantityByOrderTypes as TQuantityByOrderTypes)?.reduce((res, v) => {
          if (v?.orderType) {
            res[v?.orderType] = v?.quantity;
          }
          return res;
        }, {} as TOrderTypeQuantityMap),
      };
    });

    return updatedItems as ISatisticDataRes['items'];
  }

  private transformPeriodData(args: IArgsTransformPeriodData) {
    const { item, params, periodLabel } = args || {};
    const dateFrom = params?.orderStatisticProps?.dateFrom;
    const toDate = params?.orderStatisticProps?.dateTo;
    const groupBy = params?.orderStatisticProps?.groupBy;
    const periodData = item?.[periodLabel] as Array<number | null>;
    const periodPartsType = item?.periodPartsType;
    const noData =
      !periodPartsType ||
      !periodData ||
      !toDate ||
      !dateFrom ||
      (groupBy !== C_Group_By_Type.GBT7_ARTICLE_DATE_RANGE_DETAIL &&
        groupBy !== C_Group_By_Type.GBT8_ARTICLE_DATE_RANGE_DETAIL_TURNOVER);
    if (noData) {
      return item;
    }

    const result: { [key: string]: any } = { ...item };

    const date = localeFormatterHelper.localizedDate(dateFrom);
    const endDate = localeFormatterHelper.localizedDate(toDate);
    const newPeriodData: { [key: string]: number | null } = {};
    periodData.some((quantityOrSales) => {
      if (date > endDate) return true;

      let key: string;

      switch (periodPartsType) {
        case C_Statistic_Period_Type.SPT_DAY:
          key = localeFormatterHelper.formatDate(date, { day: '2-digit', month: '2-digit' }, true);
          date.setDate(date.getDate() + 1);
          break;

        case C_Statistic_Period_Type.SPT_MONTH:
          key = key = localeFormatterHelper.formatDate(
            date,
            { year: 'numeric', month: '2-digit' },
            true,
          );
          date.setMonth(date.getMonth() + 1);
          break;

        case C_Statistic_Period_Type.SPT_YEAR:
          key = date.getFullYear().toString();
          date.setFullYear(date.getFullYear() + 1);
          break;

        default:
          throw new Error(`Unknown periodPartsType: ${periodPartsType}`);
      }

      newPeriodData[key] = quantityOrSales;
      result[key] = quantityOrSales;
    });

    result[periodLabel] = newPeriodData;

    return result;
  }
}

class OrderStatisticService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const orderStatisticService = new OrderStatisticService();

export type ISatisticDataResWithStatus = NonNullable<
  NonNullable<GetStatisticDataQuery['wawiAssist']>['getWA_statisticData']
>;

export type ISatisticDataResNotFormatted = Extract<
  NonNullable<ISatisticDataResWithStatus['data']>,
  { __typename?: 'WA_OrderStatisticData' }
>;

interface IArgsTransformStatisticData {
  items: ISatisticDataResNotFormatted['items'];
  summaryData: ISatisticDataResNotFormatted['summaryData'];
  params: Wa_InitOrderStatisticCalcMutationVariables;
}

interface IArgsTransformPeriodData {
  item: ISatisticDataResNotFormatted['items'][number];
  params: Wa_InitOrderStatisticCalcMutationVariables;
  periodLabel: 'quantityByPeriods' | 'netSalesByPeriods';
}

type TQuantityByOrderTypes = NonNullable<
  ISatisticDataResNotFormatted['items'][number]['quantityByOrderTypes']
>;

type TOrderTypeQuantityMap = {
  [K in NonNullable<NonNullable<TQuantityByOrderTypes[number]>['orderType']>]:
    | number
    | undefined
    | null;
};

type TStatisticItemFromServer = NonNullable<
  NonNullable<ISatisticDataResNotFormatted['items']>[number]
>;

export interface IPeriodStatisticData {
  [key: string]: number | null;
}

type TStatisticItem = TStatisticItemFromServer &
  TOrderTypeQuantityMap & {
    articleNoAndName: string;
    customerNoAndName: string;
    percent: number;
    quantityByPeriods?: IPeriodStatisticData;
    netSalesByPeriods?: IPeriodStatisticData;
    [key: string]: any; // fields for periods (generated in transformPeriodData)
  };

export type ISatisticDataRes = Omit<ISatisticDataResNotFormatted, 'items'> & {
  items: TStatisticItem[];
};
