import { gql } from 'graphql-request';

export const procurementProductsList = gql`
  query ListProcurementProducts($filter: WA_ProcurementProductsGridFilter) {
    wawiAssist {
      listWA_ProcurementProducts(filter: $filter) {
        id
        productNo
        description
        storageMin
        storageMax
        storageActual
      }
    }
  }
`;

export const getProcurementProductData = gql`
  query GetProcurementProductData($id: ID!) {
    wawiAssist {
      getProcurementProductData(id: $id) {
        id
        productNo
        description
        storageMin
        storageMax
        storageActual
        storageLocationId
      }
    }
  }
`;

export const getProcurementProductStorageChanges = gql`
  query ListProcurementProductStorageChanges(
    $procurementProductId: ID!
    $dateFrom: Date!
    $dateTo: Date!
  ) {
    wawiAssist {
      listWA_ProcurementProductStorageChanges(
        procurementProductId: $procurementProductId
        dateFrom: $dateFrom
        dateTo: $dateTo
      ) {
        onDate
        quantityBeginOfTheDay
        historyUser
        operationDate
        numberOfSource
        quantityDiff
      }
    }
  }
`;

export const saveProcurementProductData = gql`
  mutation SaveProcurementProductData($dataToSave: WA_SaveProcurementProductInput!) {
    wawiAssist {
      saveProcurementProductData(dataToSave: $dataToSave) {
        status
        updatedGridItem {
          id
          productNo
          description
          storageMin
          storageMax
          storageActual
        }
      }
    }
  }
`;

export const getProcurementProductStorageQuantity = gql`
  query GetProcurementProductStorageQuantity($procurementProductId: ID!, $onDate: Date!) {
    wawiAssist {
      getWA_ProcurementProductStorageQuantity(
        procurementProductId: $procurementProductId
        onDate: $onDate
      )
    }
  }
`;

export const changeProcurementProductStorageQuantity = gql`
  mutation ChangeProcurementProductStorageQuantity(
    $procurementProductId: ID!
    $onDate: Date!
    $difference: Float!
  ) {
    wawiAssist {
      changeWA_ProcurementProductStorageQuantity(
        procurementProductId: $procurementProductId
        onDate: $onDate
        difference: $difference
      )
    }
  }
`;

export const sendStockListToCloudPrinter = gql`
  mutation SendStockListToCloudPrinter($params: WA_SendStockListToCloudPrinterInput!) {
    wawiAssist {
      sendStockListToCloudPrinter(params: $params)
    }
  }
`;
