import { LoaderFunction } from 'react-router-dom';
import { take } from 'rxjs';

import {
  companyConfigService,
  TCompanyConfigs,
} from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { ITabState, PrintFormsTabs } from '../states/tabState.model.ts';
import { printFormsTabLoadingService } from '../printFormsTabLoading.service.ts';
import {
  printFormsService,
  ReportsConfigurationRes,
  DataForReportsConfigurationPreviewRes,
} from '../services/printForms.service.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';

export const printFormsTabLoader: LoaderFunction = async ({ params }) => {
  const { tab } = params || {};
  if (!tab || !Object.values(PrintFormsTabs).includes(tab as PrintFormsTabs)) {
    location.href = '/404';
  }

  let tabState: Partial<ITabState> = {};

  switch (tab) {
    case PrintFormsTabs.general:
      const res = await getConfigData();
      const { companyNameInReports } = res as TCompanyConfigs;
      tabState = { companyNameInReports };
      break;
    case PrintFormsTabs.reportConfiguration:
      const [inputDataForReportsPreview, reportConfiguration] = await Promise.all([
        printFormsService.globHelpers.streamToPromise(
          printFormsService.sub.getDataForReportsConfigurationPreview().pipe(
            responseHandler<DataForReportsConfigurationPreviewRes>({
              errorReturnType: undefined,
            }),
            take(1),
          ),
          () => printFormsService.pub.getDataForReportsConfigurationPreview(),
        ),
        printFormsService.globHelpers.streamToPromise(
          printFormsService.sub
            .getReportsConfiguration()
            .pipe(
              responseHandler<ReportsConfigurationRes>({ errorReturnType: undefined }),
              take(1),
            ),
          () => printFormsService.pub.getReportsConfiguration(),
        ),
      ]);
      tabState = { inputDataForReportsPreview, reportConfiguration };
      break;
    default:
      tabState = {};
      break;
  }
  printFormsTabLoadingService.pub.loading(false);
  return tabState;
};

const getConfigData = async () => {
  return new Promise((res) => companyConfigService.getConfigs(true).pipe(take(1)).subscribe(res));
};
