import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { CustomerAvailabilityRes, PeriodConditions } from '../../../../services/customer.service';
import { FormCheckboxList } from '../../../../../../shared/components/form';
import { Button, Stack, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { availabilityTabState } from '../availability.state';
import { PeriodConditionsBox } from '../../../../../../shared/components/periodConditionsBox/periodConditionsBox.component';
import { DeliveryPeriods } from '../popups/deliveryPeriods/deliveryPeriods.popup';
import { Content } from '../../../../../../shared/components/content/content.component';
import { GotoSettingsIcon } from '../../../../../../svg/custom.svg.tsx';
import { useNavigate } from 'react-router-dom';
import { SGotoSettingsIcon } from '../../lists/components/listsForm.component.tsx';

export const AvailabilityForm: FC<IAvailabilityForm> = ({ formMethods }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getValues, setValue, handleSubmit } = formMethods || {};
  const deliveryPeriodsPopup = useMemo(() => new DeliveryPeriods(), []);
  const formValues = getValues() || {};
  const { periods, periodsWithCustomWeekDays, vacations, weekDays } = formValues;
  const isError = !('periods' in formValues);
  const initPeriodConditions = isError
    ? null
    : { periods, periodsWithCustomWeekDays, vacations, weekDays };
  const [periodConditions, setPeriodConditions] = useState(initPeriodConditions);

  const onSubmit = useCallback(() => {
    const {
      formState: { isValid, isDirty },
    } = formMethods;
    if (isValid && isDirty) {
      availabilityTabState.pub.save();
    }
  }, [formMethods]);

  useEffect(() => {
    deliveryPeriodsPopup.stream.state().subscribe((v) => {
      if (v?.action === 'save') {
        const dataForSave = v?.values?.periodConditions;
        const { periods, periodsWithCustomWeekDays, vacations, weekDays } = dataForSave || {};
        setValue('periods', periods, { shouldDirty: true });
        setValue('periodsWithCustomWeekDays', periodsWithCustomWeekDays, { shouldDirty: true });
        setValue('vacations', vacations, { shouldDirty: true });
        setValue('weekDays', weekDays, { shouldDirty: true });
        setPeriodConditions(dataForSave);
        availabilityTabState.pub.save();
      }
    });

    const unsubState = availabilityTabState.sub.state().subscribe(({ defaultValues }) => {
      setPeriodConditions(defaultValues);
    });
    return () => {
      deliveryPeriodsPopup.stream.unsubscribe();
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <>
      {Boolean(periodConditions) ? (
        <FormProvider {...formMethods}>
          <PeriodConditionsBox
            periodConditions={periodConditions as PeriodConditions}
            width='600px'
          />
          <form noValidate onBlur={handleSubmit(onSubmit)}>
            <Stack alignItems='start'>
              <Button
                onClick={() => {
                  deliveryPeriodsPopup.stream.emit('open', {
                    values: {
                      periodConditions: { periods, periodsWithCustomWeekDays, vacations, weekDays },
                    },
                  });
                }}
                sx={{ mt: 2, mb: 3 }}
                variant='contained'
                startIcon={<SettingsIcon />}
              >
                {t('customer.availabilitySetting')}
              </Button>
              <FormCheckboxList
                withDivider
                emptyListIndication={{
                  title: t('customer.assortment_groups_are_not_configured'),
                  text: (
                    <Trans
                      style={{ display: 'flex' }}
                      i18nKey='common.click_button_above_to_configure'
                      components={{ icon: <SGotoSettingsIcon /> }}
                    />
                  ),
                }}
                headerRightOptions={
                  <IconButton
                    sx={{ ml: 'auto', height: '40px' }}
                    onClick={() => navigate('/settings/configuration/article')}
                    title={t('common.settings')}
                  >
                    <GotoSettingsIcon />
                  </IconButton>
                }
                name='customerAssortimentGroupsCheckedState'
                controlLabel={'<' + t('customer.allProductGroups') + '>'}
                minHeight='150px'
              />
            </Stack>
          </form>
          <deliveryPeriodsPopup.Component />
        </FormProvider>
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};

interface IAvailabilityForm {
  formMethods: UseFormReturn<CustomerAvailabilityRes>;
}
