import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import styled from '@mui/material/styles/styled';

import { ICheckboxProps } from '../fields/checkbox.component.tsx';
import { Header } from './components/header.component.tsx';
import { ControlPanel } from './components/controlPanel.component.tsx';
import { List } from './components/list.component.tsx';
import { EmptyBox } from './components/emptyBox.component.tsx';

export const CheckboxList: FC<ICheckboxList> = ({
  data,
  controlLabel,
  onChange,
  onBlur,
  minWidth,
  width = 'fit-content',
  height = '100%',
  maxHeight,
  minHeight,
  listLabel,
  withDivider = false,
  headerRightOptions,
  emptyListIndication,
}) => {
  const [listData, setListData] = useState<ICheckboxList['data']>([]);

  useEffect(() => {
    setListData(data);
  }, [data]);

  return (
    <Stack
      height='100%'
      position='relative'
      direction='column'
      onBlur={() => onBlur?.(listData)}
      {...{ width }}
    >
      <Header {...{ listLabel }} />
      <SCheckboxList
        {...{ minWidth, height, maxHeight, minHeight }}
        {...(!withDivider && { py: 2, px: 3 })}
      >
        {data.length || Boolean(headerRightOptions) ? (
          <>
            <ControlPanel
              {...{
                data,
                listData,
                setListData,
                controlLabel,
                headerRightOptions,
                onChange,
                withDivider,
              }}
            />
            <List
              {...{
                data,
                listData,
                setListData,
                onChange,
                withDivider,
                emptyListIndication,
                minHeight,
              }}
            />
          </>
        ) : (
          <EmptyBox {...{ emptyListIndication, minHeight }} />
        )}
      </SCheckboxList>
    </Stack>
  );
};

const SCheckboxList = styled(Box)<{
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
}>(({ theme, minWidth, maxHeight, minHeight, height }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  minWidth: minWidth ? minWidth : 250,
  height: height ? height : 'auto',
  overflowY: 'scroll',
  ...(maxHeight && { maxHeight }),
  ...(minHeight && { minHeight }),
}));

export interface ICheckboxList {
  data: ICheckboxData[];
  controlLabel?: string;
  onChange?: (v: Partial<ICheckboxProps[]>) => void;
  onBlur?: (v: Partial<ICheckboxProps[]>) => void;
  width?: number;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  listLabel?: string;
  withDivider?: boolean;
  headerRightOptions?: ReactNode;
  emptyListIndication?: {
    icon?: ReactNode;
    title?: string;
    text?: string | ReactElement;
  };
}

export interface ICheckboxData extends Omit<ICheckboxProps, 'onChange' | 'id'> {
  id: ICheckboxProps['id'];
}
