/* eslint-disable react-hooks/rules-of-hooks */

import { TPopupDefaultActions } from '../../../../../../../../../../shared/components/popup/services/popup.service.ts';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { CopySpecialPrices } from './copySpecialPrices.form.tsx';

export class CopySpecialPricesPopup extends Popup<ICopySpecialPricesPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const { t } = useTranslation();
      const [currentArticleId, setCurrentArticleId] = useState('');
      const [copyPricesType, setCopyPricesType] = useState<TCopyPricesType>();

      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 498 },
        }));
      }, []);

      const formMethods = useForm({
        mode: 'onBlur',
      });

      useEffect(() => {
        stream.state().subscribe(({ action, currentArticleId, type }) => {
          if (action === 'open') {
            setCurrentArticleId(currentArticleId);
            setCopyPricesType(type);
          }
          if (action === 'close') formMethods.reset();
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('article.copy_special_prices')}>
          <FormProvider {...formMethods}>
            <CopySpecialPrices
              {...{ currentArticleId, stream }}
              type={copyPricesType || 'specialPrices'}
            />
          </FormProvider>
        </SWrapper>
      );
    };
  }
}

export type TCopyPricesType = 'specialPrices' | 'customerPrices' | 'resellerPrices';
interface ICopySpecialPricesPopup {
  action: TPopupDefaultActions;
  currentArticleId: string;
  type: TCopyPricesType;
}
