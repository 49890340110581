import { FC, useEffect, useMemo, useState } from 'react';
import { IGeneralTabState, generalTabState } from '../../../generalTab.state';
import { GeneralTabOptionsRes } from '../../../../../../services/article.service';
import { useFormContext } from 'react-hook-form';
import { STable } from '../common/styles/styles';
import { storageHelper } from '../../../../../../../../shared/helpers/storage';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { CopySpecialPricesPopup } from '../common/popups/copySpecialPrices/copySpecialPrices.popup';
import { ResellerPricesPopup } from './popups/resellerPrices/resellerPrices.popup';
import { ControlButtons } from './components/controlButtons.component';

export const ResellerPrices: FC<IResellerPrices> = (props) => {
  const { resellerPricesData, customersList, currentArticleId, isEditMode } = props;
  const { t } = useTranslation();
  const [selectedResellerPrices, setSelectedResellerPrices] = useState<
    IGeneralTabState['selectedResellerPricesPos']
  >(resellerPricesData?.[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const resellerPricesPopup = useMemo(() => new ResellerPricesPopup({ customersList }), []);
  const copySpecialPricesPopup = useMemo(() => new CopySpecialPricesPopup(), []);
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    // on create/copy mode here we provide data to form
    if (!isEditMode) {
      const articleResellerPricesForSave = resellerPricesData?.map(
        ({ id: _id, customerName: _, customerNo: __, ...rest }) => ({
          ...rest,
        }),
      );
      setValue('articleResellerPrices', articleResellerPricesForSave);
    }
  }, [resellerPricesData, isEditMode]);

  useEffect(() => {
    const unsubState = generalTabState.sub
      .state()
      .subscribe(({ action, selectedResellerPricesPos }) => {
        setSelectedResellerPrices(selectedResellerPricesPos);
        if (
          (action === 'updateResellerPricesPos' ||
            action === 'deleteResellerPricesPos' ||
            action === 'copyResellerPrices') &&
          isEditMode
        ) {
          generalTabState.pub.saveResellerPricesGrid(getValues('id'));
        }
      });
    const subLoadingGrid = generalTabState.sub.resellerPricesGridLoading().subscribe(setLoading);

    return () => {
      unsubState.unsubscribe();
      resellerPricesPopup.stream.unsubscribe();
      subLoadingGrid.unsubscribe();
    };
  }, []);

  return (
    <>
      <ControlButtons
        {...{
          currentArticleId,
          copySpecialPricesPopup,
          resellerPricesPopup,
          selectedResellerPrices,
        }}
      />
      <STable
        data={resellerPricesData}
        rowSelectionModel={selectedResellerPrices?.id}
        onRowClick={(v, e) => {
          generalTabState.pub.selectResellerPricesPos(v.row);
          resellerPricesPopup.stream.emit('open', {
            values: {
              row: v.row,
              field: (e.target as any).parentElement?.dataset?.field,
            },
          });
        }}
        initialState={{
          columns: storageHelper.local.getItem('article.resellerPricesColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('article.resellerPricesColumnModel', v)
        }
        height={400}
        {...{ loading }}
      >
        <Column
          field='customerName'
          headerName={t('customer.customer')}
          valueGetter={({ value, row }) => row.customerNo && `${row.customerNo} ${value}`}
          sortable={false}
          width={450}
        />
        <Column field='comment' headerName={t('customer.comment')} sortable={false} flex={1} />
        <Column
          field='price'
          headerName={t('common.price')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
      </STable>
      <resellerPricesPopup.Component />
      <copySpecialPricesPopup.Component />
    </>
  );
};

interface IResellerPrices {
  resellerPricesData: IGeneralTabState['articleResellerPrices'];
  customersList: GeneralTabOptionsRes['customersList'];
  isEditMode: boolean;
  currentArticleId: string;
}
