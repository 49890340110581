import { FC } from 'react';
import { IconButton } from '../../iconButton/iconButton.component.tsx';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { orderDetailsState as orderDetailsStateCreate } from '../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../edit/states/orderDetails/orderDetails.state.ts';
import { useTranslation } from 'react-i18next';

export const DeletionCell: FC<IDeletionCell> = ({ params, type, isEditable = true }) => {
  const { t } = useTranslation();
  const { row } = params;
  return (
    <Stack alignItems='center' justifyContent='center'>
      {!row.virtualPositionId && isEditable && (
        <IconButton
          tabIndex={-1}
          title={t('common.delete')}
          disabled={!row.isChangeable}
          onClick={(e) => {
            e.stopPropagation();
            (type === 'create'
              ? orderDetailsStateCreate
              : orderDetailsStateEdit
            ).pub.deletePosition({
              type: 'deletePosition',
              position: row,
            });
          }}
          size='small'
          color='default'
          className='deleteBtn'
        >
          <DeleteIcon fontSize='small' />
        </IconButton>
      )}
    </Stack>
  );
};

interface IDeletionCell {
  params: GridRenderCellParams;
  type: 'create' | 'edit';
  isEditable?: boolean;
}
