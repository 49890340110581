import { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { Checkbox } from '../../../../../../../../../shared/components/form/index.ts';
import { Stack, styled } from '@mui/material';
import { IArticleImportData } from '../../../import.tab.tsx';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const AnyBooleanFieldCell: FC<IAnyBooleanFieldCell> = ({
  params,
  data,
  setData,
  editable,
}) => {
  const { api, id, field, value } = params;
  const [checked, setChecked] = useState(value);
  const { setEditCellValue } = api;

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const updateCell = (v: boolean) => {
    setEditCellValue({ id, field, value });
    let newData = structuredClone(data);
    newData = newData.map((item) => {
      if (item.id == id) {
        (item as any)[field] = v;
      }
      return item;
    });
    setData(newData);
    setChecked(v);
  };

  return (
    <SStack>
      <Checkbox
        tabIndex={params.tabIndex}
        onKeyDown={(e) => {
          if (isEnterKey(e)) updateCell(checked);
        }}
        onChange={(_, v) => updateCell(v)}
        size='small'
        disabled={!editable}
        {...{ checked }}
      />
    </SStack>
  );
};

const SStack = styled(Stack)({
  '& label': {
    margin: 0,
  },
});

interface IAnyBooleanFieldCell {
  params: GridRenderEditCellParams;
  data: IArticleImportData[];
  setData: Dispatch<SetStateAction<IArticleImportData[]>>;
  editable: boolean;
}
