import React, { FC, SyntheticEvent, useMemo, Dispatch, SetStateAction } from 'react';
import { TableSelect } from '../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ISelectOption } from '../../../../../../../../invoice/create/states/customerList.state.ts';
import { IArticleImportData } from '../../../import.tab.tsx';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const AnyDropdownFieldEditCell: FC<IAnyDropdownFieldEditCell> = ({
  params,
  data,
  setData,
  options,
}) => {
  const { api, id, field } = params;
  const { setEditCellValue, stopCellEditMode } = api;
  const value = useMemo(() => {
    return options?.find((el) => el?.id === params.value);
  }, []);
  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    setEditCellValue({ id, field, value: v?.id });
    let newData = structuredClone(data);
    newData = newData.map((item) => {
      if (item.id == id) {
        (item as any)[field] = v?.id;
      }
      return item;
    });
    setData(newData);
    stopCellEditMode({ id, field });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && isEnterKey(e)) {
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableSelect
      autoFocus
      openOnFocus
      size='small'
      width='100%'
      fontSize='14px'
      autoHighlight={true}
      disableClearable
      disableTextInput
      options={options as []}
      value={value as ISelectOption}
      inputValue={value?.label || undefined}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

interface IAnyDropdownFieldEditCell {
  params: GridRenderCellParams;
  data: IArticleImportData[];
  setData: Dispatch<SetStateAction<IArticleImportData[]>>;
  options: Record<string, any>[];
}
