import { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import styled from '@mui/material/styles/styled';

export const AnyDropdownFieldCell: FC<IAnyDropdownFieldCell> = ({ params, options }) => {
  const value = options?.find((el) => el?.id === params.value);
  return <SValue>{value?.label}</SValue>;
};

export const SValue = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

interface IAnyDropdownFieldCell {
  params: GridRenderCellParams;
  options: Record<string, any>[];
}
