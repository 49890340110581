import { gql } from 'graphql-request';

export const getToolboxArticleListsFilter = gql`
  query getToolboxConfigData {
    wawiAssist {
      getConfigData {
        toolboxExportArticleListsFilterCheckedState {
          id
          name
          checked
        }
      }
    }
  }
`;

export const exportToolbox = gql`
  query prepareToolboxExportFiles($onDate: Date!) {
    wawiAssist {
      prepareToolboxExportFiles(onDate: $onDate) {
        url
        ordersCount
      }
    }
  }
`;
