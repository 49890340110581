import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITabState } from '../../../../../../../../states/tabState.model';
import { IConditionsTabState, conditionsTabState } from '../../../../../conditions.state';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ContentCopy } from '@mui/icons-material';
import { customersListState } from '../../../../../../../../states/customersList.state';
import { FieldsRow } from '../../../../../../../../../../shared/components/form';
import { Stack } from '@mui/material';
import { IconButton } from '../../../../../../../../../order/common/iconButton/iconButton.component';
import { ResellerPricesPopup } from '../popups/resellerPrices/resellerPrices.popup';
import { CopyResellerPricesPopup } from '../popups/copyResellerPrices/copyResellerPrices.popup';

export const ControlButtons: FC<IControlButtons> = (props) => {
  const { selectPos, resellerPricesData, copyResellerPricesPopup, resellerPricesPopup } = props;
  const { t } = useTranslation();
  const [currentCustomerId, setCurrentCustomerId] = useState('');

  useEffect(() => {
    const customerListSub = customersListState.sub.state().subscribe(({ selectedRecord }) => {
      setCurrentCustomerId(selectedRecord?.id ?? '');
    });

    return () => {
      customerListSub.unsubscribe();
    };
  }, []);

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('customer.addNewRecord'),
        icon: <AddIcon />,
        onClick: () =>
          resellerPricesPopup.stream.emit('open', {
            values: {
              row: {
                id: `new_${Date.now()}`,
                price: null,
                articleId: '',
                articleNo: null,
                description: null,
                comment: null,
              },
              field: 'description',
            },
          }),
      },
      {
        title: t('customer.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => conditionsTabState.pub.deleteResellerPricesPos(),
        disabled: !resellerPricesData.length || !selectPos,
      },
      {
        title: t('customer.copy_article_prices'),
        icon: <ContentCopy />,
        onClick: () => copyResellerPricesPopup.stream.emit('open', { currentCustomerId }),
      },
    ],
    [t, resellerPricesData, selectPos, currentCustomerId],
  );

  return (
    <FieldsRow marginBottom={1} alignItems='center' spacing={0}>
      <Stack direction='row' spacing={2}>
        {buttonList.map(({ icon, ...props }, i) => (
          <IconButton key={i} color='primary' size='large' {...props}>
            {icon}
          </IconButton>
        ))}
      </Stack>
    </FieldsRow>
  );
};

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

interface IControlButtons {
  resellerPricesData: ITabState['customerResellerPrices'];
  selectPos: IConditionsTabState['selectedResellerPricesPos'];
  resellerPricesPopup: ResellerPricesPopup;
  copyResellerPricesPopup: CopyResellerPricesPopup;
}
