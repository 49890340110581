import {
  FC,
  useCallback,
  useState,
  FocusEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { TableTextField } from '../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { ICustomerImportData } from '../../../import.tab.tsx';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const AnyTextFieldEditCell: FC<IAnyTextFieldEditCell> = ({
  params,
  data,
  setData,
  maxLength,
}) => {
  const { row, api, id, field, value: initVal } = params;

  const [value, setValue] = useState<string>(initVal !== null ? initVal : '');
  const { setEditCellValue, stopCellEditMode } = api;

  const updateCell = useCallback(
    (value: string) => {
      setEditCellValue({ id, field, value });
      let newData = structuredClone(data);
      newData = newData.map((item) => {
        if (item.id == id) {
          (item as any)[field] = value;
        }
        return item;
      });
      setData(newData);
      stopCellEditMode({ id, field });
    },
    [row],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    updateCell(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && isEnterKey(e)) {
      updateCell(e.target.value);
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <TableTextField
      inputProps={maxLength ? { maxLength: maxLength } : {}}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      autoFocus
      value={value}
    />
  );
};

interface IAnyTextFieldEditCell {
  params: GridRenderEditCellParams;
  data: ICustomerImportData[];
  setData: Dispatch<SetStateAction<ICustomerImportData[]>>;
  maxLength?: number | undefined;
}
