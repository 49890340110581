import { LoaderFunction } from 'react-router-dom';
import {
  companyConfigService,
  TCompanyConfigs,
} from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { ConfigurationTabs } from '../states/tabState.model.ts';
import { configurationTabLoadingService } from '../configurationTabLoading.service.ts';
import {
  configurationService,
  ListTaxesPeriodsAndRatesQueryRes,
} from '../services/configuration.service.ts';
import { take, zip } from 'rxjs';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import {
  configsData,
  DictBanksRes,
  DictDiscountGroupsRes,
  DictPriceCategoriesRes,
  DictQuantityDiscountsRes,
} from '../../../../shared/services/configsData/configsData.service.ts';

export const configurationTabLoader: LoaderFunction = async ({ params }) => {
  const { tab } = params || {};
  if (!tab || !Object.values(ConfigurationTabs).includes(tab as ConfigurationTabs)) {
    location.href = '/404';
  }
  let tabState;
  const res = await getConfigData();

  const {
    actualInvoiceNo,
    actualOrderNo,
    isGotoNextDeliveryDay,
    roundingArticlePricesMethodId,
    isShippingDocsFunctionActivated,
    isShippingDocsForCardboardBoxes,
    isUsePriceCategoryAhead,
    decimalPlacesCount,
    orderPositionsSortingKindId,
    kiloAmountRepresentationId,
    roundingTotalPricesMethodId,
    isUseOtherCurrencies,
    euroRate,
    usdRate,
    fibuClient,
    fibuVatCodeNormal,
    fibuVatCodeReduced,
    fibuVatCodeWithoutVAT,
    isAbacusXMLExport,
    isDatevCommitment,
    countryId,
    isAutoCustomerNo,
    standardBankId,
    besrFormatId,
    operationCostCenter,
    autoCustomerNoPrefix,
    autoCustomerNoCounter,
    isDoughInLitersInProductionReports,
    canForbidIncomingOrdersCAdirect,
    canWorkWithVersionsInProduction,
    isUseProcurementLogicInProductionReports,
    isAllowSameEANForMultipleArticles,
    isShowShippingPointsInArticle,
    isShippingFunctionActivated,
    globalTaxSystemId,
    isDeliveryOnMon,
    isDeliveryOnTue,
    isDeliveryOnWed,
    isDeliveryOnThu,
    isDeliveryOnFri,
    isDeliveryOnSat,
    isDeliveryOnSun,
    orderTypesFilterInProductionReportsCheckedState,
  } = res as TCompanyConfigs;
  switch (tab) {
    case ConfigurationTabs.invoice:
      tabState = {
        actualInvoiceNo,
        roundingTotalPricesMethodId,
        isUseOtherCurrencies,
        euroRate,
        usdRate,
      };
      break;
    case ConfigurationTabs.financialAccounting:
      tabState = {
        fibuClient,
        fibuVatCodeNormal,
        fibuVatCodeReduced,
        fibuVatCodeWithoutVAT,
        isAbacusXMLExport,
        isDatevCommitment,
      };
      break;
    case ConfigurationTabs.order:
      tabState = {
        actualOrderNo,
        roundingArticlePricesMethodId,
        decimalPlacesCount,
        orderPositionsSortingKindId,
        kiloAmountRepresentationId,
        isGotoNextDeliveryDay,
        isShippingDocsFunctionActivated,
        isShippingDocsForCardboardBoxes,
        isUsePriceCategoryAhead,
        isDeliveryOnMon,
        isDeliveryOnTue,
        isDeliveryOnWed,
        isDeliveryOnThu,
        isDeliveryOnFri,
        isDeliveryOnSat,
        isDeliveryOnSun,
      };
      break;
    case ConfigurationTabs.customer:
      // eslint-disable-next-line no-case-declarations
      const [
        banksDictOptionsForCutomer,
        dictPriceCategoriesForCustomer,
        discountGroupsForCustomer,
        quantityDiscountsForCustomer,
      ] = await configurationService.globHelpers.streamToPromise(
        zip(
          configsData.sub.dictBanks(),
          configsData.sub.dictPriceCategories(),
          configsData.sub.dictDiscountGroups(),
          configsData.sub.dictQuantityDiscounts(),
        ).pipe(
          responseHandler<
            [DictBanksRes, DictPriceCategoriesRes, DictDiscountGroupsRes, DictQuantityDiscountsRes]
          >({
            errorReturnType: [[], [], [], []],
          }),
          take(1),
        ),
        () =>
          configsData.pub.common([
            'dictBanks',
            'dictPriceCategories',
            'dictDiscountGroups',
            'dictQuantityDiscounts',
          ]),
      );
      tabState = {
        isAutoCustomerNo,
        autoCustomerNoPrefix,
        autoCustomerNoCounter,
        banksDictOptions: banksDictOptionsForCutomer,
        dictPriceCategories: dictPriceCategoriesForCustomer,
        discountGroups: discountGroupsForCustomer,
        quantityDiscounts: quantityDiscountsForCustomer,
      };
      break;
    case ConfigurationTabs.production:
      tabState = {
        isDoughInLitersInProductionReports,
        canForbidIncomingOrdersCAdirect,
        canWorkWithVersionsInProduction,
        isUseProcurementLogicInProductionReports,
        orderTypesFilterInProductionReportsCheckedState,
      };
      break;
    case ConfigurationTabs.article:
      tabState = {
        isAllowSameEANForMultipleArticles,
        isShowShippingPointsInArticle,
      };
      break;
    case ConfigurationTabs.bankAccounts:
      // eslint-disable-next-line no-case-declarations
      const banksDictOptions = await configurationService.globHelpers.streamToPromise(
        configsData.sub.dictBanks().pipe(take(1)),
        () => configsData.pub.common(['dictBanks']),
      );
      tabState = {
        standardBankId,
        besrFormatId,
        operationCostCenter,
        banksDictOptions,
      };
      break;
    case ConfigurationTabs.post:
      tabState = {
        isShippingFunctionActivated,
      };
      break;
    case ConfigurationTabs.vat:
      // eslint-disable-next-line no-case-declarations
      const listTaxesPeriodsAndRates = await configurationService.globHelpers.streamToPromise(
        configurationService.sub.listTaxesPeriodsAndRates().pipe(
          responseHandler<ListTaxesPeriodsAndRatesQueryRes>({
            errorReturnType: [],
          }),
          take(1),
        ),
        () => configurationService.pub.listTaxesPeriodsAndRates(),
      );
      tabState = { listTaxesPeriodsAndRates, globalTaxSystemId };
      break;
    case ConfigurationTabs.import:
      tabState = {};
      break;
    default:
      tabState = {};
      break;
  }
  configurationTabLoadingService.pub.loading(false);
  return { ...tabState, countryId };
};

const getConfigData = async () => {
  return new Promise((res) => companyConfigService.getConfigs(true).pipe(take(1)).subscribe(res));
};
