import { FC, useMemo, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  styled,
  IconButton,
} from '@mui/material';
import { InsertDriveFile as FileIcon, Error as ErrorIcon, Inbox } from '@mui/icons-material';
import { C_Report, C_Win_Report_Task_Status } from '../../../../../../../graphql/generatedModel';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LeftExpandArrow } from '../../../../../../../svg/custom.svg.tsx';
import { ConfigurablePrintFormTypes } from '../reportsConfiguration.tab';
import { DataForReportsConfigurationPreviewRes } from '../../../../services/printForms.service';
import { useReportPreview } from '../useReportPreview.ts';

export const ReportPreviewArea: FC<IReportPreviewArea> = ({
  inputDataForGeneratePreview,
  printFormType,
  disabledPreview,
  onExpandedState,
  expandedState,
}) => {
  const { t } = useTranslation();
  const {
    loading,
    generatedReport,
    resetGeneratedReport,
    generateArticlePricelist,
    generateDeliveryNote,
    generateInvoice,
    generateDebtReminder,
  } = useReportPreview();

  useEffect(() => {
    resetGeneratedReport();
  }, [printFormType]);

  const runPreview = useCallback(() => {
    if (isExistsDataForGenerateReport) {
      switch (printFormType) {
        case ConfigurablePrintFormTypes.ARTICLE_PRICELIST: {
          const { articlePricelistPreviewData } = inputDataForGeneratePreview;
          generateArticlePricelist({
            articlesIds: articlePricelistPreviewData.articlesIds,
            priceValidOn: new Date(),
            customerId: articlePricelistPreviewData.customerId ?? '0',
          });
          break;
        }
        case ConfigurablePrintFormTypes.DELIVERY_NOTE_A4: {
          const { deliveryNoteA4PreviewData } = inputDataForGeneratePreview;
          generateDeliveryNote({
            reportId: C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE,
            orderId: deliveryNoteA4PreviewData.orderId ?? '0',
          });
          break;
        }
        case ConfigurablePrintFormTypes.INVOICE: {
          const { invoicePreviewData } = inputDataForGeneratePreview;
          generateInvoice({
            invoiceId: invoicePreviewData.invoiceId ?? '0',
          });
          break;
        }
        case ConfigurablePrintFormTypes.DEBT_REMINDER: {
          const { debtReminderPreviewData } = inputDataForGeneratePreview;
          generateDebtReminder({
            invoiceId: debtReminderPreviewData.invoiceId ?? '0',
            invoiceDate: debtReminderPreviewData.invoiceDate ?? new Date(),
            customerId: debtReminderPreviewData.customerId ?? '0',
          });
          break;
        }
      }
    }
  }, [printFormType, inputDataForGeneratePreview]);

  const noDataTextAndLink = useMemo(() => {
    switch (printFormType) {
      case ConfigurablePrintFormTypes.ARTICLE_PRICELIST:
        return { i18key: 'settings.no_preview_article_not_exist', link: '/article/general' };

      case ConfigurablePrintFormTypes.DELIVERY_NOTE_A4:
        return { i18key: 'settings.no_preview_order_not_exist', link: '/order/create' };

      case ConfigurablePrintFormTypes.INVOICE:
        return { i18key: 'settings.no_preview_invoice_not_exist', link: '/invoice/create-invoice' };

      case ConfigurablePrintFormTypes.DEBT_REMINDER:
        return { i18key: 'settings.no_preview_invoice_not_exist', link: '/invoice/create-invoice' };

      default:
        return { i18key: '', link: '' };
    }
  }, [printFormType]);

  const isExistsDataForGenerateReport = useMemo(() => {
    switch (printFormType) {
      case ConfigurablePrintFormTypes.ARTICLE_PRICELIST: {
        const { articlePricelistPreviewData } = inputDataForGeneratePreview;
        return articlePricelistPreviewData.articlesIds.length > 0;
      }

      case ConfigurablePrintFormTypes.DELIVERY_NOTE_A4: {
        const { deliveryNoteA4PreviewData } = inputDataForGeneratePreview;
        return deliveryNoteA4PreviewData.orderId !== null;
      }

      case ConfigurablePrintFormTypes.INVOICE: {
        const { invoicePreviewData } = inputDataForGeneratePreview;
        return invoicePreviewData.invoiceId !== null;
      }

      case ConfigurablePrintFormTypes.DEBT_REMINDER: {
        const { debtReminderPreviewData } = inputDataForGeneratePreview;
        return debtReminderPreviewData.invoiceId !== null;
      }

      default:
        return false;
    }
  }, [printFormType]);

  const previewContent = useMemo(() => {
    if (generatedReport?.url) {
      return (
        <>
          <SNavContainer direction='row' p='10px' pl='64px'>
            <Button
              startIcon={<VisibilityIcon />}
              onClick={runPreview}
              variant='outlined'
              size='large'
              {...(loading && {
                startIcon: <CircularProgress size={16} sx={{ color: 'rgba(0,0,0,0.38)' }} />,
                disabled: true,
              })}
              {...(disabledPreview && {
                disabled: true,
              })}
            >
              {t('common.preview')}
            </Button>
          </SNavContainer>
          <SIFrame src={generatedReport.url} title='PDF' />
        </>
      );
    }
    if (generatedReport?.status === C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT) {
      return (
        <SCenterContainer>
          <PreviewError
            title={t('common.report_preview')}
            disabledPreview={disabledPreview}
            onClickPreview={runPreview}
            errorType={generatedReport.status}
          />
        </SCenterContainer>
      );
    }
    if (!generatedReport?.url) {
      return (
        <SCenterContainer>
          {isExistsDataForGenerateReport ? (
            <PreviewStub
              loading={loading}
              disabledPreview={disabledPreview}
              title={t('common.report_preview')}
              onClickPreview={runPreview}
            />
          ) : (
            <PreviewStubNoInputData
              i18KeyNoData={noDataTextAndLink.i18key}
              creationLink={noDataTextAndLink.link}
            />
          )}
        </SCenterContainer>
      );
    }
  }, [generatedReport, printFormType, t, loading, disabledPreview]);

  return (
    <Paper variant='outlined' sx={{ height: '100%', position: 'relative' }}>
      <SExpandedMenuBtn
        onClick={() => onExpandedState((prev) => !prev)}
        title={t('settings.expand_preview')}
        {...{ expandedState }}
      >
        <LeftExpandArrow />
      </SExpandedMenuBtn>
      <Stack direction='column' sx={{ height: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>{previewContent}</Box>
      </Stack>
    </Paper>
  );
};

const PreviewStubNoInputData: FC<IPreviewStubNoInputDataProps> = ({
  i18KeyNoData,
  creationLink,
}) => {
  return (
    <div>
      <Alert severity='warning'>
        <Trans
          style={{ display: 'flex' }}
          i18nKey={i18KeyNoData}
          components={{ a: <Link to={creationLink} /> }}
        />
      </Alert>
    </div>
  );
};

const PreviewStub: FC<IPreviewStubProps> = ({
  title,
  disabledPreview,
  loading,
  onClickPreview,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <>
        <FileIcon sx={{ width: '60px', height: '60px', fill: 'rgba(0,0,0,0.56)' }} />
        <Typography variant='body2' fontSize={24}>
          {title}
        </Typography>
        <Button
          onClick={onClickPreview}
          variant='outlined'
          size='large'
          sx={{ mt: 3 }}
          startIcon={<VisibilityIcon />}
          {...(loading && {
            startIcon: <CircularProgress size={16} sx={{ color: 'rgba(0,0,0,0.38)' }} />,
            disabled: true,
          })}
          {...(disabledPreview && {
            disabled: true,
          })}
        >
          {t('common.preview')}
        </Button>
      </>
    </div>
  );
};

const PreviewError: FC<IPreviewErrorProps> = ({
  title,
  disabledPreview,
  onClickPreview,
  errorType,
}) => {
  const { t } = useTranslation();
  const errMsg = useMemo(() => {
    switch (errorType) {
      case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
        return t('production.no_data_no_preview');
      default:
        return t('production.request_error_message');
    }
  }, [errorType, t]);
  const errorIcon = useMemo(() => {
    switch (errorType) {
      case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
        return <Inbox sx={{ width: '60px', height: '60px', fill: 'rgba(0,0,0,0.56)' }} />;
      default:
        return <ErrorIcon sx={{ width: '60px', height: '60px', fill: 'rgba(211, 47, 47, 1)' }} />;
    }
  }, [errorType]);
  return (
    <div>
      {errorIcon}
      <Typography variant='body2' fontSize={24}>
        {title}
      </Typography>
      <Typography variant='body2' fontSize={14} color='rgba(0,0,0,0.6)'>
        {errMsg}
        <br />
        {t('production.try_preview_again')}
      </Typography>
      <Button
        onClick={onClickPreview}
        variant='outlined'
        size='large'
        sx={{ mt: 3 }}
        {...(disabledPreview && {
          disabled: true,
        })}
      >
        {t('production.try_again')}
      </Button>
    </div>
  );
};

const SCenterContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));
const SIFrame = styled('iframe')(() => ({
  width: '100%',
  height: 'calc(100% - 50px)',
  display: 'block',
}));
const SNavContainer = styled(Stack)(() => ({}));
const SExpandedMenuBtn = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'expandedState',
})<{ expandedState: boolean }>(({ expandedState }) => ({
  position: 'absolute',
  top: 12,
  left: 12,
  ...(!expandedState && { transform: 'rotate(180deg)' }),
}));

interface IPreviewStubProps {
  loading: boolean;
  onClickPreview: () => void;
  disabledPreview: boolean;
  title: string;
}
interface IPreviewStubNoInputDataProps {
  i18KeyNoData: string;
  creationLink: string;
}
interface IPreviewErrorProps {
  onClickPreview: () => void;
  disabledPreview: boolean;
  errorType: IGeneratedReportData['status'];
  title: string;
}
interface IReportPreviewArea {
  inputDataForGeneratePreview: DataForReportsConfigurationPreviewRes;
  printFormType: ConfigurablePrintFormTypes;
  disabledPreview: boolean;
  onExpandedState: (v: boolean | ((v: boolean) => boolean)) => void;
  expandedState: boolean;
}
export interface IGeneratedReportData {
  taskId?: number;
  url?: string;
  s3Key?: string;
  status?: C_Win_Report_Task_Status;
}
