import { styled } from '@mui/material';
import { Table } from '../../../../../../../../../shared/components/table/table.component';

export const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});
