import { gql } from 'graphql-request';

export const getReportsConfiguration = gql`
  query getReportsConfiguration {
    wawiAssist {
      getReportsConfiguration {
        articlePricelist {
          pageMargins {
            left
            top
            bottom
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          address {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          logo1Page {
            isVisible
            left
            top
            height
            width
            image
          }
          logoFrom2ndPage {
            isVisible
            left
            top
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
        }
        articlePricelistDefaultValues {
          pageMargins {
            left
            top
            bottom
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          address {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          logo1Page {
            isVisible
            left
            top
            height
            width
            image
          }
          logoFrom2ndPage {
            isVisible
            left
            top
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
        }
        deliveryNoteA4 {
          pageMargins {
            left
            top
            bottom
          }
          pageBgImage {
            isVisible
            image
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          deliveryAddress {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          invoiceAddress {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          phoneNumber {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          logo {
            isVisible
            left
            top
            height
            width
            image
          }
          companyVatNo {
            isVisible
          }
          sellerPerson {
            isVisible
            left
            top
            height
            width
          }
          receiversSignature {
            isVisible
            left
            top
            height
            width
          }
          footerImage {
            isVisible
            left
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
          createdBy {
            isVisible
            left
            height
            width
          }
        }
        deliveryNoteA4DefaultValues {
          pageMargins {
            left
            top
            bottom
          }
          pageBgImage {
            isVisible
            image
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          deliveryAddress {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          invoiceAddress {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          phoneNumber {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          logo {
            isVisible
            left
            top
            height
            width
            image
          }
          companyVatNo {
            isVisible
          }
          sellerPerson {
            isVisible
            left
            top
            height
            width
          }
          receiversSignature {
            isVisible
            left
            top
            height
            width
          }
          footerImage {
            isVisible
            left
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
          createdBy {
            isVisible
            left
            height
            width
          }
        }
        invoice {
          pageMargins {
            left
            top
            bottom
          }
          pageBgImage {
            isVisible
            image
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          logo1Page {
            isVisible
            left
            top
            height
            width
            image
          }
          logoFrom2ndPage {
            isVisible
            left
            top
            height
            width
            image
          }
          invoiceAddress {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          deliveryAddress {
            isVisible
            left
            top
            height
            width
          }
          invoiceAddressFrom2ndPage {
            isVisible
            left
            top
            height
            width
          }
          reasonForDeletion {
            isVisible
            left
            top
            height
            width
          }
          bankInformation {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          companyVatNo {
            isVisible
          }
          footerImage {
            isVisible
            left
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
          createdBy {
            isVisible
            left
            height
            width
          }
        }
        invoiceDefaultValues {
          pageMargins {
            left
            top
            bottom
          }
          pageBgImage {
            isVisible
            image
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          logo1Page {
            isVisible
            left
            top
            height
            width
            image
          }
          logoFrom2ndPage {
            isVisible
            left
            top
            height
            width
            image
          }
          invoiceAddress {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          deliveryAddress {
            isVisible
            left
            top
            height
            width
          }
          invoiceAddressFrom2ndPage {
            isVisible
            left
            top
            height
            width
          }
          reasonForDeletion {
            isVisible
            left
            top
            height
            width
          }
          bankInformation {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          companyVatNo {
            isVisible
          }
          footerImage {
            isVisible
            left
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
          createdBy {
            isVisible
            left
            height
            width
          }
        }
        debtReminder {
          pageMargins {
            left
            top
            bottom
          }
          pageBgImage {
            isVisible
            image
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          logo {
            isVisible
            left
            top
            height
            width
            image
          }
          address {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          bankInformation {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          footerImage {
            isVisible
            left
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
          createdBy {
            isVisible
            left
            height
            width
          }
        }
        debtReminderDefaultValues {
          pageMargins {
            left
            top
            bottom
          }
          pageBgImage {
            isVisible
            image
          }
          letterhead {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          logo {
            isVisible
            left
            top
            height
            width
            image
          }
          address {
            isVisible
            left
            top
            height
            width
            fontOverride {
              family
              size
              style {
                isBold
                isItalic
                isUnderline
              }
            }
          }
          bankInformation {
            isVisible
            left
            top
            height
            width
            formattedText
          }
          footerImage {
            isVisible
            left
            height
            width
            image
          }
          footerText {
            isVisible
            left
            height
            width
            formattedText
          }
          printedAt {
            isVisible
            left
            height
            width
          }
          pageNumbering {
            isVisible
            left
            height
            width
          }
          createdBy {
            isVisible
            left
            height
            width
          }
        }
      }
    }
  }
`;

export const saveReportsConfiguration = gql`
  mutation SaveReportsConfiguration($dataToSave: WA_ReportsConfigurationInput!) {
    wawiAssist {
      saveReportsConfiguration(dataToSave: $dataToSave) {
        status
      }
    }
  }
`;

export const getDataForReportsConfigurationPreview = gql`
  query GetWA_DataForReportsConfigurationPreview {
    wawiAssist {
      getWA_DataForReportsConfigurationPreview {
        articlePricelistPreviewData {
          articlesIds
          customerId
        }
        offerPreviewData {
          offerId
        }
        deliveryNoteA4PreviewData {
          orderId
        }
        deliveryNoteA5PreviewData {
          orderId
        }
        invoicePreviewData {
          invoiceId
        }
        paymentSlipPreviewData {
          invoiceId
        }
        debtReminderPreviewData {
          invoiceId
          invoiceDate
          customerId
        }
      }
    }
  }
`;
