/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { ResellerPricesForm } from './resellerPrices.form.tsx';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { GeneralTabOptionsRes } from '../../../../../../../../services/article.service.ts';
import { TPopupDefaultActions } from '../../../../../../../../../../shared/components/popup/services/popup.service.ts';
import { ITabState } from '../../../../../../../../states/tabState.model.ts';

export class ResellerPricesPopup extends Popup<IResellerPricesPopup> {
  Component: FC<TPopupComponent>;
  customersList: GeneralTabOptionsRes['customersList'];
  constructor(params: { customersList: GeneralTabOptionsRes['customersList'] }) {
    super();
    const stream = this.innerStream;
    this.customersList = params.customersList;
    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 700 },
        }));
      }, []);
      const [defaultValues, setDefaultValues] = useState<TDefaultValues>({
        id: '',
        customerId: '',
        price: null,
        comment: null,
      });
      const [field, setField] = useState('');
      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values?.row) {
            setDefaultValues(values.row);
          }
          if (values?.field) setField(values.field);
        });
        stream.actionListener('close').subscribe(() => {
          setDefaultValues({
            id: '',
            customerId: '',
            price: null,
            comment: null,
          });
          setField('');
          stream.emit('clear');
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.resellerPrices')}>
          <ResellerPricesForm
            customersList={this.customersList}
            {...{ field, stream, defaultValues }}
          />
        </SWrapper>
      );
    };
  }
}

export interface IResellerPricesPopup {
  action: 'save' | TPopupDefaultActions;
  values?: { row: GridRowParams['row']; field?: string };
}

export type TDefaultValues = ITabState['articleResellerPrices'][number];
