import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FileUpload,
  IUploadFileToS3Return,
} from '../../../../../../../shared/components/fileUpload/fileUpload.component';
import { CustomerTable } from './customerTable.component.tsx';
import { ArticleTable } from './articleTable.component.tsx';
import { IImportData } from '../import.tab.tsx';

export const ImportDetailsInfo: FC<IImportDetailsInfo> = ({
  data,
  onFileUploaded,
  onLoading,
  validationData,
  optionsData,
  currentFielName,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' pb={'20px'}>
        <FileUpload.Field
          onLoading={onLoading}
          sx={{ minWidth: '500px' }}
          label={t('debitor.paymentsDetailsInfo.upload')}
          place='payments'
          fileName={currentFielName}
          onUploaded={onFileUploaded}
          accept={'.xls,.xlsx'}
        />
      </Box>
      <Stack gap='24px'>
        <CustomerTable
          dataFromFile={data?.customerImportData ?? []}
          heigthWhenCollapsed={(data?.articleImportData ?? []).length ? 250 : 610}
          shownRecordsCountWhenCollapsed={(data?.articleImportData ?? []).length ? 5 : 16}
          existingNumbers={validationData.existingCustomerNumbers}
          options={optionsData}
        />
        <ArticleTable
          dataFromFile={data?.articleImportData ?? []}
          heigthWhenCollapsed={(data?.customerImportData ?? []).length ? 250 : 610}
          shownRecordsCountWhenCollapsed={(data?.customerImportData ?? []).length ? 5 : 16}
          existingNumbers={validationData.existingArticleNumbers}
          options={optionsData}
        />
      </Stack>
    </Box>
  );
};

export interface IValidationData {
  existingArticleNumbers: string[];
  existingCustomerNumbers: string[];
}

export interface IOptionsData {
  customerGroups: Record<string, any>[];
  postSectors: Record<string, any>[];
  quantityDiscounts: Record<string, any>[];
  deliveryNoteGroups: Record<string, any>[];
  priceCategories: Record<string, any>[];
  discountGroups: Record<string, any>[];
  marketingGroups: Record<string, any>[];
  productionGroups: Record<string, any>[];
  shippingPoints: Record<string, any>[];
  tourplans: Record<string, any>[];
  banks: Record<string, any>[];
  storageLocations: Record<string, any>[];
  besrFormats: Record<string, any>[];
  bookingTypes: Record<string, any>[];
  visibilityStatuses: Record<string, any>[];
  correspondenceLanguages: Record<string, any>[];
  customerAddressKinds: Record<string, any>[];
  customerDefOrderSources: Record<string, any>[];
  debitorStatuses: Record<string, any>[];
  directDebitBanks: Record<string, any>[];
  discountsAndPricesKinds: Record<string, any>[];
  discountKinds: Record<string, any>[];
  eInvoiceFormats: Record<string, any>[];
  freezerConditions: Record<string, any>[];
  invoiceIntervals: Record<string, any>[];
  kgRepresentations: Record<string, any>[];
  procurementProductKinds: Record<string, any>[];
  reports: Record<string, any>[];
  saleUnits: Record<string, any>[];
  taxSystems: Record<string, any>[];
  vatCodes: Record<string, any>[];
  weigthFractionTypes: Record<string, any>[];
}

export const defValidationData = {
  existingArticleNumbers: [],
  existingCustomerNumbers: [],
};

export const defOptionsData = {
  customers: [],
  customerGroups: [],
  postSectors: [],
  quantityDiscounts: [],
  deliveryNoteGroups: [],
  priceCategories: [],
  cashAssistProductGroups: [],
  cashAssistTemplates: [],
  discountGroups: [],
  marketingGroups: [],
  productionGroups: [],
  shippingPoints: [],
  tourplans: [],
  articles: [],
  banks: [],
  storageLocations: [],
  besrFormats: [],
  bookingTypes: [],
  visibilityStatuses: [],
  correspondenceLanguages: [],
  customerAddressKinds: [],
  customerDefOrderSources: [],
  debitorStatuses: [],
  directDebitBanks: [],
  discountsAndPricesKinds: [],
  discountKinds: [],
  eInvoiceFormats: [],
  freezerConditions: [],
  invoiceIntervals: [],
  kgRepresentations: [],
  procurementProductKinds: [],
  reports: [],
  saleUnits: [],
  taxSystems: [],
  vatCodes: [],
  weigthFractionTypes: [],
};

interface IImportDetailsInfo {
  data: IImportData | undefined;
  validationData: IValidationData;
  optionsData: IOptionsData;
  currentFielName: string;
  onFileUploaded: (result: IUploadFileToS3Return) => void;
  onLoading: () => void;
}
