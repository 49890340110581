import React, { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, styled, TextField, TextFieldProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { localeFormatterHelper } from '../../../helpers/formatter/localeFormatter.helper.ts';
import { companyConfigService } from '../../../services/companyConfig/companyConfig.service.ts';

export const NumericField = forwardRef<HTMLInputElement, TNumericFieldProps>(
  (
    {
      shrink,
      controls = false,
      step = 1,
      min = 0,
      max = 10000000,
      onChange,
      onBlur,
      defaultValue,
      precision,
      noTrailingZeros = false,
      value,
      width,
      onKeyDown,
      returnZero = false,
      InputProps,
      disableDecrease = false,
      keyChange = true,
      ...props
    },
    ref,
  ) => {
    const initVal =
      (typeof value === 'number' ? (returnZero ? String(value) : value || null) : null) ||
      (typeof defaultValue === 'number'
        ? returnZero
          ? defaultValue
          : defaultValue || null
        : null);

    const [focused, setFocused] = useState(false);
    const cachedPrecision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
    const effectivePrecision = precision !== undefined ? precision : cachedPrecision;

    const format = useCallback(
      (numV: string | number | null) => {
        if (returnZero && numV === null) return '';
        return localeFormatterHelper.formatNumber(Number(numV!), {
          precision: effectivePrecision,
          returnZero,
          noTrailingZeros,
        });
      },
      [effectivePrecision, noTrailingZeros, returnZero],
    );

    const fixToPrecision = useCallback(
      (numV: number): number => {
        return Number(numV.toFixed(effectivePrecision));
      },
      [effectivePrecision],
    );

    const [isChange, setIsChange] = useState(false);
    const [displayVal, setDisplayVal] = useState<string>(format(initVal));
    const [numberVal, setNumberVal] = useState<number | null>(initVal ? Number(initVal) : null);

    useEffect(() => {
      if (!isChange && (typeof value === 'number' || value === null)) {
        if (value === null) {
          setDisplayVal('');
          setNumberVal(null);
          return;
        }
        if (returnZero && typeof value === 'number') {
          setDisplayVal(format(checkString(value, !!effectivePrecision, returnZero)));
          setNumberVal(Number(value));
          return;
        }
        if (!returnZero && typeof value === 'number') {
          setDisplayVal(format(checkString(value, !!effectivePrecision, returnZero)));
          setNumberVal(Number(value) || null);
        }
      }
    }, [isChange, value, format, returnZero, defaultValue]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setIsChange(true);
      let value = e.target.value;
      const isValid = /^-?(?!([.,]))[0-9]*[.,]?[0-9]*$/.test(value);
      if (isValid) {
        if (value) {
          const replacedToDot = value.replace(/,/g, '.');
          const numberValue = Number(replacedToDot);

          if (value === '-' || isNaN(numberValue)) {
            // when customer try write negative number. He start with '-' and without this code
            // he will have scary error. Something like "NaN (cast from the value `NaN`)"
            setDisplayVal(value);
            setNumberVal(null);
            onChange?.(null);
            return;
          }

          if (numberValue > max) {
            value = String(max);
          }
          if (numberValue < min) {
            value = String(min);
          }
        }
        setDisplayVal(value);
        let numVal;
        if (returnZero && !value) {
          numVal = null;
        } else numVal = Number(value.replace(/,/g, '.'));
        setNumberVal(numVal);
        onChange?.(numVal);
      }
    };

    const handleBlur = () => {
      setFocused(false);
      setIsChange(false);
      let res;
      if (returnZero && numberVal === null) res = null;
      else res = fixToPrecision(Number(numberVal));
      setDisplayVal(format(numberVal));
      onChange?.(res);
      onBlur?.(res);
    };

    const handleClick = (stepType: 'increase' | 'decrease'): void => {
      let newValue: number =
        stepType === 'increase' ? Number(numberVal) + step : Number(numberVal) - step;
      let canChange: boolean;
      if (stepType === 'increase') {
        if (!isNaN(min) && newValue < min) {
          newValue = min;
        }
        canChange = isNaN(max) ? true : newValue <= max;
      } else {
        if (!isNaN(max) && newValue > max) {
          newValue = max;
        }
        canChange = isNaN(min) ? true : newValue >= min;
      }
      if (canChange) {
        setDisplayVal(format(newValue));
        const numVal = fixToPrecision(newValue);
        setNumberVal(numVal);
        onChange?.(numVal);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      const code = e.code;
      if (keyChange) {
        if (code === 'ArrowUp') {
          e.preventDefault();
          handleClick('increase');
        }
        if (code === 'ArrowDown') {
          e.preventDefault();
          handleClick('decrease');
        }
      }
      onKeyDown?.(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      // this timer corrects the order of events when the autofocus prop is enabled
      const timer = setTimeout(() => {
        setDisplayVal(checkString(e.target.value, !!effectivePrecision, returnZero));
        setFocused(true);
        clearTimeout(timer);
      }, 0);
    };

    const handleInputFocusByArrow = (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.currentTarget as HTMLDivElement;
      if (target !== null) {
        const prevEl = target.previousElementSibling;
        const timer = setTimeout(() => {
          (prevEl as HTMLElement)?.focus();
          clearTimeout(timer);
        }, 0);
      }
    };

    return (
      <SBox
        controls={controls}
        sx={{
          width: '100%',
          maxWidth: (theme) =>
            width || (shrink ? theme.form.fieldShrinkWidth : theme.form.fieldWidth),
        }}
      >
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          onChange={handleChange}
          onFocus={handleFocus}
          InputLabelProps={{ shrink: true }}
          type='text'
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          inputProps={{
            inputMode: 'numeric',
          }}
          ref={ref}
          {...props}
          focused={focused}
          value={displayVal}
          InputProps={{
            ...(controls && {
              endAdornment: (
                <SBtnWrapper
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  spacing={0}
                  width='fit-content'
                  onBlur={() => {
                    onBlur?.(numberVal);
                  }}
                  onMouseDown={(e) => {
                    handleInputFocusByArrow(e);
                    focused && e.preventDefault();
                  }}
                >
                  <SIconButton
                    disabled={props.disabled}
                    tabIndex={-1}
                    onClick={() => handleClick('increase')}
                  >
                    <ExpandLessIcon />
                  </SIconButton>
                  <SIconButton
                    disabled={props.disabled || disableDecrease}
                    tabIndex={-1}
                    onClick={() => handleClick('decrease')}
                  >
                    <ExpandMoreIcon />
                  </SIconButton>
                </SBtnWrapper>
              ),
            }),
            ...InputProps,
          }}
        />
      </SBox>
    );
  },
);

const checkString = (v: string | number, precision: boolean, returnZero: boolean): string => {
  if (returnZero && !v) return '';
  if (returnZero && Number(v) === 0) return '0';
  const checkedVal = typeof v === 'number' ? String(v) : v;
  const valArr = checkedVal.split('');
  if (valArr.length) {
    const lastFoundInx = precision ? valArr.findLastIndex((el) => el === '.' || el === ',') : -1;
    return valArr.reduce((acc, item, idx) => {
      if (!isNaN(Number(item)) || idx === lastFoundInx || idx === 0) {
        acc += item;
      }
      return acc;
    }, '');
  }
  return '';
};

const SIconButton = styled(IconButton)(() => ({
  padding: 0,
  width: 15,
  height: 15,
  '& svg': {
    fontSize: 17,
  },
}));

const SBtnWrapper = styled(Stack)(() => ({
  display: 'inline-flex',
}));

const SBox = styled(Box)<{ controls: boolean; shrink?: boolean }>(({ controls }) => ({
  position: 'relative',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  '& input': {
    appearance: 'textfield',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
    marginRight: 0,
  },
  ...(controls && {
    '& input': {
      paddingRight: 23,
    },
  }),
}));

export type TNumericFieldProps = Omit<
  TextFieldProps,
  'onChange' | 'onBlur' | 'onKeyDown' | 'type'
> & {
  shrink?: boolean;
  defaultValue?: number | null;
  onChange?: (v: number | null) => void;
  onBlur?: (v: number | null) => void;
  onKeyDown?: (v: React.KeyboardEvent) => void;
  returnZero?: boolean;
  controls?: boolean;
  step?: number;
  min?: number;
  max?: number;
  precision?: number;
  noTrailingZeros?: boolean;
  width?: string;
  disableDecrease?: boolean;
  keyChange?: boolean;
};
