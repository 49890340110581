import { FC, useEffect, useState, useRef } from 'react';
import { Stack } from '@mui/material';
import {
  ImportDetailsInfo,
  IValidationData,
  IOptionsData,
  defOptionsData,
  defValidationData,
} from './components/importDetailsInfo.component.tsx';
import { AddImportInfo } from './components/addImportInfo.component.tsx';
import { configurationTabLoadingService } from '../../../configurationTabLoading.service.ts';
import { ExtendedListImportDataFromFileQueryRes } from '../../../services/configuration.service.ts';
import { IUploadFileToS3Return } from '../../../../../../shared/components/fileUpload/fileUpload.component.tsx';
import {
  configurationService,
  ImportTabOptionsRes,
} from '../../../services/configuration.service.ts';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler.ts';
import { useTranslation } from 'react-i18next';
import {
  C_Besr_Format,
  C_Booking_Type,
  C_Ca_Visibility_Status,
  C_Correspondence_Language,
  C_Customer_Address_Kind,
  C_Customer_Def_Order_Source,
  C_Debitor_Status,
  C_Direct_Debit_Banks,
  C_Discounts_And_Prices_Kind,
  C_Discount_Kind,
  C_E_Invoice_Format,
  C_Freezer_Condition,
  C_Invoice_Interval,
  C_Kg_Representation,
  C_Procurement_Product_Kind,
  C_Report,
  C_Sale_Unit,
  C_Tax_System,
  C_Vat_Code,
  C_Weigth_Fraction_Type,
} from '../../../../../../graphql/generatedModel.ts';
import { TFunction } from 'i18next';
import { Maybe, Scalars } from '../../../../../../graphql/generatedModel.ts';
import {
  ExtendedArticleImportDataFromFile,
  ExtendedCustomerImportDataFromFile,
} from '../../../services/configuration.service.ts';
import { snackbarService } from '../../../../../../shared/components/snackbar/service/snackbar.service.ts';

export const ImportTab: FC = () => {
  const { t } = useTranslation();
  const [importData, setImportData] = useState<IImportData>();
  const [validationData, setValidationData] = useState<IValidationData>(defValidationData);
  const [optionsData, setOptionsData] = useState<IOptionsData>(defOptionsData);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const optionsDataRef = useRef<IOptionsData>(defOptionsData);

  useEffect(() => {
    optionsDataRef.current = optionsData;
  }, [optionsData]);

  useEffect(() => {
    const optionsSub = configurationService.sub
      .importTabOptions()
      .pipe(responseHandler<ImportTabOptionsRes>({ errorReturnType: defOptionsData }))
      .subscribe((data) => setOptionsData(initOptionsArray(data, t)));

    configurationService.pub.importTabOptions();

    const validationDataSub = configurationService.sub
      .listValidationDataForImport()
      .pipe(responseHandler<IValidationData>({ errorReturnType: defValidationData }))
      .subscribe((data) => {
        setValidationData(data);
      });

    configurationService.pub.listValidationDataForImport();

    const importDataSub = configurationService.sub
      .getImportDataFromFile()
      .pipe(
        responseHandler<ExtendedListImportDataFromFileQueryRes>({
          errorReturnType: {
            articleImportData: [],
            customerImportData: [],
          },
        }),
      )
      .subscribe((data) => {
        setImportData(preprocessImportData(data, optionsDataRef.current, t));
        snackbarService.pub.hide('aiProcessingFile');
        configurationTabLoadingService.pub.loading(false);
      });

    return () => {
      importDataSub.unsubscribe();
      validationDataSub.unsubscribe();
      optionsSub.unsubscribe();
    };
  }, []);

  const handleOnFileUploaded: (result: IUploadFileToS3Return) => void = ({ files: [file] }) => {
    const { key } = file;
    configurationService.pub.getImportDataFromFile({ key });
    setCurrentFileName(key.split(/(\\|\/)/g).pop() || '');
  };

  const loadingHandler = () => {
    configurationTabLoadingService.pub.loading(true);
    snackbarService.pub.show({
      id: 'aiProcessingFile',
      type: 'loading',
      content: t('settings.ai_processing_your_file'),
      noAutoHide: true,
    });
  };

  return (
    <Stack height='100%'>
      {importData ? (
        <ImportDetailsInfo
          data={importData}
          validationData={validationData}
          optionsData={optionsData}
          onFileUploaded={handleOnFileUploaded}
          onLoading={loadingHandler}
          currentFielName={currentFileName}
        />
      ) : (
        <AddImportInfo onFileUploaded={handleOnFileUploaded} onLoading={loadingHandler} />
      )}
    </Stack>
  );
};

const initOptionsArray = (data: ImportTabOptionsRes, t: TFunction<'translation', undefined>) => {
  return {
    customerGroups: prepareOptionsAnyDict(data.customerGroups),
    postSectors: prepareOptionsAnyDict(data.postSectors),
    quantityDiscounts: prepareOptionsAnyDict(data.quantityDiscounts),
    deliveryNoteGroups: prepareOptionsAnyDict(data.deliveryNoteGroups),
    priceCategories: prepareOptionsAnyDict(data.priceCategories),
    discountGroups: prepareOptionsAnyDict(data.discountGroups),
    marketingGroups: prepareOptionsAnyDict(data.marketingGroups),
    productionGroups: prepareOptionsAnyDict(data.productionGroups),
    shippingPoints: prepareOptionsAnyDict(data.shippingPoints),
    tourplans: prepareOptionsAnyDict(data.tourplans),
    banks: prepareOptionsAnyDict(data.banks),
    storageLocations: prepareOptionsAnyDict(data.storageLocations),
    besrFormats: prepareOptionsAnyEnum(C_Besr_Format, t),
    bookingTypes: prepareOptionsAnyEnum(C_Booking_Type, t),
    visibilityStatuses: prepareOptionsAnyEnum(C_Ca_Visibility_Status, t),
    correspondenceLanguages: prepareOptionsAnyEnum(C_Correspondence_Language, t),
    customerAddressKinds: prepareOptionsAnyEnum(C_Customer_Address_Kind, t),
    customerDefOrderSources: prepareOptionsAnyEnum(C_Customer_Def_Order_Source, t),
    debitorStatuses: prepareOptionsAnyEnum(C_Debitor_Status, t),
    directDebitBanks: prepareOptionsAnyEnum(C_Direct_Debit_Banks, t),
    discountsAndPricesKinds: prepareOptionsAnyEnum(C_Discounts_And_Prices_Kind, t),
    discountKinds: prepareOptionsAnyEnum(C_Discount_Kind, t),
    eInvoiceFormats: prepareOptionsAnyEnum(C_E_Invoice_Format, t),
    freezerConditions: prepareOptionsAnyEnum(C_Freezer_Condition, t),
    invoiceIntervals: prepareOptionsAnyEnum(C_Invoice_Interval, t),
    kgRepresentations: prepareOptionsAnyEnum(C_Kg_Representation, t),
    procurementProductKinds: prepareOptionsAnyEnum(C_Procurement_Product_Kind, t),
    reports: prepareOptionsAnyEnum(C_Report, t),
    saleUnits: prepareOptionsAnyEnum(C_Sale_Unit, t),
    taxSystems: prepareOptionsAnyEnum(C_Tax_System, t),
    vatCodes: prepareOptionsAnyEnum(C_Vat_Code, t),
    weigthFractionTypes: prepareOptionsAnyEnum(C_Weigth_Fraction_Type, t),
  };
};

const prepareOptionsAnyDict = (array: { id: any; label?: any; [key: string]: any }[]) => {
  return Object.values(array).map((key) => ({
    id: key.id,
    label: key.label,
  }));
};

const prepareOptionsAnyEnum = (
  inEnum:
    | typeof C_Besr_Format
    | typeof C_Booking_Type
    | typeof C_Ca_Visibility_Status
    | typeof C_Correspondence_Language
    | typeof C_Customer_Address_Kind
    | typeof C_Customer_Def_Order_Source
    | typeof C_Debitor_Status
    | typeof C_Direct_Debit_Banks
    | typeof C_Discounts_And_Prices_Kind
    | typeof C_Discount_Kind
    | typeof C_E_Invoice_Format
    | typeof C_Freezer_Condition
    | typeof C_Invoice_Interval
    | typeof C_Kg_Representation
    | typeof C_Procurement_Product_Kind
    | typeof C_Report
    | typeof C_Sale_Unit
    | typeof C_Tax_System
    | typeof C_Vat_Code
    | typeof C_Weigth_Fraction_Type,
  t: TFunction<'translation', undefined>,
) => {
  return Object.values(inEnum).map((key) => ({
    id: key,
    label: t(`enums.${key}`),
  }));
};

// Anyway need to do it here because of translations which are stored on frontend...
// Also here we will truncete too long strings for showing
// to the customer real value which will be saved in db
const preprocessImportData = (
  data: ExtendedListImportDataFromFileQueryRes,
  optionsData: IOptionsData,
  t: TFunction<'translation', undefined>,
): IImportData => {
  let result: IImportData = {
    customerImportData: [],
    articleImportData: [],
  };

  data.customerImportData.forEach((value) =>
    result.customerImportData.push({
      ...value,
      debtorAccount: value.debtorAccount?.slice(0, 11),
      slipReferenceNo: value.slipReferenceNo?.slice(0, 32),
      bank1Name: value.bank1Name?.slice(0, 32),
      bank1BIN: value.bank1BIN?.slice(0, 16),
      bank1AccountNo: value.bank1AccountNo?.slice(0, 32),
      bank1IBAN: value.bank1IBAN?.slice(0, 32),
      bank1BIC: value.bank1BIC?.slice(0, 32),
      bank2Name: value.bank2Name?.slice(0, 32),
      bank2BIN: value.bank2BIN?.slice(0, 16),
      bank2AccountNo: value.bank2AccountNo?.slice(0, 32),
      bank2IBAN: value.bank2IBAN?.slice(0, 32),
      bank2BIC: value.bank2BIC?.slice(0, 32),
      eBillAccount: value.eBillAccount?.slice(0, 40),
      salutation: value.salutation?.slice(0, 40),
      name: value.name?.slice(0, 40),
      surname: value.surname?.slice(0, 40),
      addition: value.addition?.slice(0, 40),
      street: value.street?.slice(0, 40),
      postBox: value.postBox?.slice(0, 40),
      zipCode: value.zipCode?.slice(0, 12),
      city: value.city?.slice(0, 40),
      internalName: value.internalName?.slice(0, 60),
      deliveryAddressSalutation: value.deliveryAddressSalutation?.slice(0, 40),
      deliveryAddressName: value.deliveryAddressName?.slice(0, 40),
      deliveryAddressAddition: value.deliveryAddressName?.slice(0, 40),
      deliveryAddressStreet: value.deliveryAddressStreet?.slice(0, 40),
      deliveryAddressPostBox: value.deliveryAddressPostBox?.slice(0, 40),
      deliveryAddressZipCode: value.deliveryAddressZipCode?.slice(0, 12),
      deliveryAddressCity: value.deliveryAddressCity?.slice(0, 40),
      phoneBusiness: value.phoneBusiness?.slice(0, 40),
      phoneBusiness2: value.phoneBusiness2?.slice(0, 40),
      phonePrivate: value.phonePrivate?.slice(0, 40),
      fax: value.fax?.slice(0, 40),
      phoneMobile: value.phoneMobile?.slice(0, 40),
      website: value.website?.slice(0, 40),
      contactPerson: value.contactPerson?.slice(0, 40),
      tourPreparationArea: value.tourPreparationArea?.slice(0, 16),
      tourSatPreparationArea: value.tourSatPreparationArea?.slice(0, 16),
      tourSunPreparationArea: value.tourSunPreparationArea?.slice(0, 16),
      besrBankId: decodeDictValue(value.besrBankId, optionsData.banks),
      caPriceAndDiscountSourceId: decodeEnumDiscountsAndPricesKindValue(
        value.caPriceAndDiscountSourceId,
        t,
      ),
      customerAddressKindId: decodeEnumCustomerAddressKindValue(value.customerAddressKindId, t),
      customerGroupId: decodeDictValue(value.customerGroupId, optionsData.customerGroups),
      debitorStatus: decodeEnumDebitorStatusValue(value.debitorStatus, t),
      defaultOrderSourceId: decodeEnumCustomerDefOrderSourceValue(value.defaultOrderSourceId, t),
      deliveryNoteGroupId: decodeDictValue(
        value.deliveryNoteGroupId,
        optionsData.deliveryNoteGroups,
      ),
      directDebitBankId: decodeEnumDirectDebitBanksValue(value.directDebitBankId, t),
      discountKindId: decodeEnumDiscountsKindValue(value.discountKindId, t),
      eInvoiceFormat: decodeEnumEInvoiceFormatValue(value.eInvoiceFormat, t),
      invoicePaymentSlipReportId: decodeEnumReportValue(value.invoicePaymentSlipReportId, t),
      invoiceReportId: decodeEnumReportValue(value.invoiceReportId, t),
      invoiceSummaryReportId: decodeEnumReportValue(value.invoiceSummaryReportId, t),
      invoicingIntervalId: decodeEnumInvoiceIntervalValue(value.invoicingIntervalId, t),
      offerReportId: decodeEnumReportValue(value.offerReportId, t),
      orderReportId: decodeEnumReportValue(value.orderReportId, t),
      priceCategoryId: decodeDictValue(value.priceCategoryId, optionsData.priceCategories),
      quantityDiscountSettingId: decodeDictValue(
        value.quantityDiscountSettingId,
        optionsData.quantityDiscounts,
      ),
      taxSystemId: decodeEnumTaxSystemValue(value.taxSystemId, t),
      tourplanId: decodeDictValue(value.tourplanId, optionsData.tourplans),
      tourplanSatId: decodeDictValue(value.tourplanSatId, optionsData.tourplans),
      tourplanSunId: decodeDictValue(value.tourplanSunId, optionsData.tourplans),
      transportSectorId: decodeDictValue(value.transportSectorId, optionsData.postSectors),
    }),
  );

  data.articleImportData.forEach((value) =>
    result.articleImportData.push({
      ...value,
      description: value.description?.slice(0, 64),
      availabilityInProcurementKindId: decodeEnumProcurementProductKindValue(
        value.availabilityInProcurementKindId,
        t,
      ),
      caVisibilityStatusId: decodeEnumCaVisibilityStatusValue(value.caVisibilityStatusId, t),
      discountGroupId: decodeDictValue(value.discountGroupId, optionsData.discountGroups),
      marketingGroupId: decodeDictValue(value.marketingGroupId, optionsData.marketingGroups),
      productionGroupId: decodeDictValue(value.productionGroupId, optionsData.productionGroups),
      saleUnitId: decodeEnumSaleUnitValue(value.saleUnitId, t),
      shippingPointId: decodeDictValue(value.shippingPointId, optionsData.shippingPoints),
      vatCode: decodeEnumVatCodeValue(value.vatCode, t),
    }),
  );

  return result;
};

const decodeDictValue = (
  value: string | null | undefined,
  array: Record<string, any>[],
): Scalars['ID'] | null => {
  return Object.values(array).find(
    (item) => item.label.toUpperCase() == value?.toUpperCase().trim(),
  )?.id;
};

const decodeEnumCaVisibilityStatusValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Ca_Visibility_Status | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Ca_Visibility_Status).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumCustomerAddressKindValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Customer_Address_Kind | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Customer_Address_Kind).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumCustomerDefOrderSourceValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Customer_Def_Order_Source | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Customer_Def_Order_Source).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumDebitorStatusValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Debitor_Status | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Debitor_Status).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumDirectDebitBanksValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Direct_Debit_Banks | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Direct_Debit_Banks).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumDiscountsAndPricesKindValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Discounts_And_Prices_Kind | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Discounts_And_Prices_Kind).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumDiscountsKindValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Discount_Kind | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Discount_Kind).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumEInvoiceFormatValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_E_Invoice_Format | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_E_Invoice_Format).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumInvoiceIntervalValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Invoice_Interval | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Invoice_Interval).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumProcurementProductKindValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Procurement_Product_Kind | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Procurement_Product_Kind).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumReportValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Report | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Report).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumSaleUnitValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Sale_Unit | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Sale_Unit).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumTaxSystemValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Tax_System | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Tax_System).forEach((item) => {
      if (value.toUpperCase().trim() === t(`enums.${item}`).toUpperCase()) {
        result = item;
      }
    });
  }

  return result;
};

const decodeEnumVatCodeValue = (
  value: string | null | undefined,
  t: TFunction<'translation', undefined>,
): C_Vat_Code | undefined => {
  let result = undefined;

  if (value) {
    Object.values(C_Vat_Code).forEach((item) => {
      if (t(`enums.${item}`).toUpperCase().includes(value.toUpperCase().trim())) {
        result = item;
      }
    });
  }

  return result;
};

export interface IArticleImportData
  extends Omit<
    ExtendedArticleImportDataFromFile,
    | 'availabilityInProcurementKindId'
    | 'caVisibilityStatusId'
    | 'discountGroupId'
    | 'marketingGroupId'
    | 'productionGroupId'
    | 'saleUnitId'
    | 'shippingPointId'
    | 'vatCode'
  > {
  availabilityInProcurementKindId?: Maybe<C_Procurement_Product_Kind> | null;
  caVisibilityStatusId?: Maybe<C_Ca_Visibility_Status> | null;
  discountGroupId?: Maybe<Scalars['ID']> | null;
  marketingGroupId?: Maybe<Scalars['ID']> | null;
  productionGroupId?: Maybe<Scalars['ID']> | null;
  saleUnitId?: Maybe<C_Sale_Unit> | null;
  shippingPointId?: Maybe<Scalars['ID']> | null;
  vatCode?: Maybe<C_Vat_Code> | null;
}
export interface ICustomerImportData
  extends Omit<
    ExtendedCustomerImportDataFromFile,
    | 'besrBankId'
    | 'caPriceAndDiscountSourceId'
    | 'customerAddressKindId'
    | 'customerGroupId'
    | 'debitorStatus'
    | 'defaultOrderSourceId'
    | 'deliveryNoteGroupId'
    | 'directDebitBankId'
    | 'discountKindId'
    | 'eInvoiceFormat'
    | 'invoicePaymentSlipReportId'
    | 'invoiceReportId'
    | 'invoiceSummaryReportId'
    | 'invoicingIntervalId'
    | 'offerReportId'
    | 'orderReportId'
    | 'priceCategoryId'
    | 'quantityDiscountSettingId'
    | 'taxSystemId'
    | 'tourplanId'
    | 'tourplanSatId'
    | 'tourplanSunId'
    | 'transportSectorId'
  > {
  besrBankId?: Scalars['ID'] | null;
  caPriceAndDiscountSourceId?: C_Discounts_And_Prices_Kind | null;
  customerAddressKindId?: C_Customer_Address_Kind | null;
  customerGroupId?: Scalars['ID'] | null;
  debitorStatus?: C_Debitor_Status | null;
  defaultOrderSourceId?: C_Customer_Def_Order_Source | null;
  deliveryNoteGroupId?: Scalars['ID'] | null;
  directDebitBankId?: C_Direct_Debit_Banks | null;
  discountKindId?: C_Discount_Kind | null;
  eInvoiceFormat?: C_E_Invoice_Format | null;
  invoicePaymentSlipReportId?: C_Report | null;
  invoiceReportId?: C_Report | null;
  invoiceSummaryReportId?: C_Report | null;
  invoicingIntervalId?: C_Invoice_Interval | null;
  offerReportId?: C_Report | null;
  orderReportId?: C_Report | null;
  priceCategoryId?: Scalars['ID'] | null;
  quantityDiscountSettingId?: Scalars['ID'] | null;
  taxSystemId?: C_Tax_System | null;
  tourplanId?: Scalars['ID'] | null;
  tourplanSatId?: Scalars['ID'] | null;
  tourplanSunId?: Scalars['ID'] | null;
  transportSectorId?: Scalars['ID'] | null;
}

export interface IImportData
  extends Omit<ExtendedListImportDataFromFileQueryRes, 'articleImportData' | 'customerImportData'> {
  articleImportData: IArticleImportData[];
  customerImportData: ICustomerImportData[];
}
