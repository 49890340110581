import {
  Box,
  Fade,
  IconButton,
  Popover as PopoverMUI,
  PopoverProps,
  SxProps,
  Theme as ThemeMUI,
} from '@mui/material';
import { FC, MouseEvent, ReactNode, useState } from 'react';

export const Popover: FC<IPopover> = ({ icon, content, popoverProps, mainSx, iconSx }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={mainSx}>
      <IconButton
        sx={{
          height: '34px',
          width: '34px',
          backgroundColor: open ? 'rgba(0, 0, 0, 0.12)' : 'inherit',
          ...iconSx,
        }}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <PopoverMUI
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: 1,
        }}
        {...popoverProps}
      >
        <Box>{content}</Box>
      </PopoverMUI>
    </Box>
  );
};

interface IPopover {
  icon: ReactNode;
  content: ReactNode;
  minWidth?: number;
  mainSx?: SxProps<ThemeMUI>;
  iconSx?: SxProps<ThemeMUI>;
  popoverProps?: Partial<PopoverProps>;
}
