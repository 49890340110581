import React, { FC, SyntheticEvent, useMemo } from 'react';
import { TableSelect } from '../../table/styles/styleFields.ts';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { dictionaryState } from '../states/dictionary.state.ts';
import { ISelectOption } from '../../form/fields/select.component.tsx';
import { isEnterKey } from '../../../helpers/utils/utils.helper.ts';

export const SelectEditCellDict: FC<GridRenderEditCellParams & { options: ISelectOption[] }> = (
  props,
) => {
  const { api, id, field, options } = props;
  const { setEditCellValue, stopCellEditMode } = api;
  const value = useMemo(() => {
    return options?.find((el) => el?.id === props.value);
  }, []);

  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    const updatingCell = { id, field, value: v?.id };
    setEditCellValue(updatingCell);
    dictionaryState.pub.updateCell(updatingCell);
    stopCellEditMode({ id, field });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && isEnterKey(e)) {
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableSelect
      autoFocus
      openOnFocus
      size='small'
      width='100%'
      fontSize='14px'
      autoHighlight={true}
      disableClearable
      disableTextInput
      value={value as ISelectOption}
      inputValue={value?.label || undefined}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      {...{ options }}
    />
  );
};
