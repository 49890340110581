import { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import {
  ArticleProductionTabDataRes,
  ProductionTabOptionsRes,
} from '../../../../services/article.service';
import { GeneralSection } from './sections/general.component';
import { ArticleLinkSection } from './sections/articleLink.component';
import { SheetSection } from './sections/sheet.component';
import { FractionSection } from './sections/fraction.component';
import { LitersSection } from './sections/liters.component';
import { CloudPrintersSection } from './sections/cloudPrinters.component.tsx';
import { CalculationSection } from './sections/calculation/calculation.component.tsx';
import { productionTabState } from '../production.state';

export const ProductionForm: FC<IProductionForm> = ({
  formMethods,
  productionTabOptions,
  productionRecipeValidation,
}) => {
  const { dictArticles, dictRecipesWithConnectedArticles } = productionTabOptions;

  const onSubmit = () => {
    const {
      formState: { isValid, dirtyFields },
    } = formMethods;
    if (isValid && !!Object.keys(dirtyFields).length) {
      productionTabState.pub.save();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <GeneralSection
          dictRecipesWithConnectedArticles={dictRecipesWithConnectedArticles}
          productionRecipeValidation={productionRecipeValidation}
        />
        {formMethods.watch('isDough') ? (
          <LitersSection />
        ) : (
          <>
            <ArticleLinkSection dictArticles={dictArticles} />
            <SheetSection />
            <FractionSection dictArticles={dictArticles} />
            <CloudPrintersSection />
            <CalculationSection dictArticles={dictArticles} />
          </>
        )}
      </form>
    </FormProvider>
  );
};

interface IProductionForm {
  formMethods: UseFormReturn<ArticleProductionTabDataRes>;
  productionTabOptions: ProductionTabOptionsRes;
  productionRecipeValidation: boolean;
}
