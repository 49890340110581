import { useState, useCallback, ChangeEventHandler, FC } from 'react';
import { IDefaultOrderState } from '../../../../../../defaultOrder.state.ts';
import { clearRowFromVirtualKeys } from '../../../../gridItems/cells/additionalFunctions.ts';
import { deliverySplittingState } from '../../deliverySplitting.state.ts';
import { TableTextField } from '../../../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { isEnterKey } from '../../../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const SplittingEditCell: FC<ISplittingEditCell> = ({ params }) => {
  const { field, value: initVal, row, api, id } = params;
  const { getSortedRows } = api;
  const [value, setValue] = useState(initVal);
  const updateCell = useCallback(
    (v: string) => {
      if (initVal !== v) {
        const [clearPath, indexVal] = field.split('_');
        const [group, name] = clearPath.split('.');
        let values: (string | null)[] | null = [...(row?.[group][name] || [])];
        values[Number(indexVal)] = v;
        const emptyValues = values.every((el) => !el);
        values = emptyValues
          ? null
          : Array.from(values, (e) => (typeof e === 'undefined' || e === null ? '' : e));
        const updatedValues = getSortedRows().map((el) => {
          if (el.id === id) {
            el[group][name] = values;
          }
          return clearRowFromVirtualKeys(el as IDefaultOrderState['defaultOrderGridData'][number]);
        });
        deliverySplittingState.pub.updateGridData(updatedValues);
      }
    },
    [params],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currV = e.target.value;
    const checked = /[^0-9%]/.test(currV);
    if (currV.includes('%') && currV.length === 1) return;
    if (currV.includes('r') && currV.length === 1) setValue(currV);
    const perCount = currV.match(/%/gi) || [];
    if (!checked) {
      if (perCount.length > 1) return;
      if (currV.slice(-1) === '%') setValue(currV);
      if (perCount.length === 1 && typeof Number(currV.slice(-1)) === 'number') return;
      setValue(currV);
    }
  };
  const handleBlur = (v: React.FocusEvent<HTMLInputElement>) => {
    const newValue = v.target.value;
    updateCell(newValue);
  };

  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && isEnterKey(v)) {
      updateCell(v.target.value);
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <TableTextField
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      inputProps={{ sx: { textAlign: 'right' } }}
      autoFocus
      value={value}
    />
  );
};
export interface ISplittingEditCell {
  params: GridRenderEditCellParams;
}
