import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITabState } from '../../../../../../../states/tabState.model';
import {
  IGeneralTabState,
  generalTabState,
  initSpecialPricePosition,
} from '../../../../generalTab.state';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SpecialPricesPopup } from '../popups/specialPrices/specialPrices.popup';
import { CopySpecialPricesPopup } from '../../common/popups/copySpecialPrices/copySpecialPrices.popup';
import { Stack } from '@mui/material';
import { IconButton } from '../../../../../../../../order/common/iconButton/iconButton.component';

export const ControlButtons: FC<IControlButtons> = (props) => {
  const {
    categoriesPricesData,
    selectPos,
    currentArticleId,
    specialPricesPopup,
    copySpecialPricesPopup,
  } = props;
  const { t } = useTranslation();

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('article.addNewRecord'),
        icon: <AddIcon />,
        onClick: () =>
          specialPricesPopup.stream.emit('open', {
            values: {
              row: {
                ...initSpecialPricePosition,
                id: `new_${Date.now()}`,
              },
              field: 'fromDate',
              allPricesData: categoriesPricesData,
            },
          }),
      },
      {
        title: t('article.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => generalTabState.pub.deleteSpecialPricesPos(),
        disabled: !categoriesPricesData.length || !selectPos,
      },
      {
        title: t('article.copy_special_prices'),
        icon: <ContentCopyIcon />,
        onClick: () =>
          copySpecialPricesPopup.stream.emit('open', { currentArticleId, type: 'specialPrices' }),
      },
    ],
    [t, categoriesPricesData, selectPos, currentArticleId],
  );

  return (
    <Stack direction='row' spacing={2} mb={1}>
      {buttonList.map(({ icon, ...props }, i) => (
        <IconButton key={i} color='primary' size='large' {...props}>
          {icon}
        </IconButton>
      ))}
    </Stack>
  );
};

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

interface IControlButtons {
  categoriesPricesData: ITabState['articleSpecialPrices'];
  selectPos: IGeneralTabState['selectedSpecialPricesPos'];
  currentArticleId: string;
  specialPricesPopup: SpecialPricesPopup;
  copySpecialPricesPopup: CopySpecialPricesPopup;
}
