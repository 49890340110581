import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldsSection,
  FormSelect,
} from '../../../../../../../../../../../../shared/components/form';
import { useOptionsForSecectors } from '../../../../hooks/useOptionsForSecectors';

export const DeliveryNote: FC = () => {
  const { t } = useTranslation();
  const optionsFromEnums = useOptionsForSecectors();

  return (
    <FieldsSection titleBoxSx={{ pt: 0 }} nomt title={t('common.delivery_note')}>
      <FormSelect
        width='400px'
        name='orderReportId'
        label={t('common.print_form')}
        options={(optionsFromEnums?.orderReportOptions as []) || []}
        disableClearable
      />
    </FieldsSection>
  );
};
