import { TypeTable } from '../../../../../../../../../shared/components/table/table.component';
import { Theme as theme } from '../../../../../../../../../shared/styles/theme/theme';

export const viewRows: TypeTable['viewRows'] = {
  action: ({ row }) => {
    if (row.isActual) {
      return 'actual';
    }
    return '';
  },
  styles: [
    {
      className: 'actual',
      bgColor: theme.palette.teal.light,
      bgColorHover: theme.palette.teal.main,
    },
  ],
};
