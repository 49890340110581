import AttachFileIcon from '@mui/icons-material/AttachFile';
import { BaseTextFieldProps, IconButton, InputAdornment, styled, TextField } from '@mui/material';
import { ChangeEvent, EventHandler, FC } from 'react';
import { take } from 'rxjs';
import { IUploadFileCommon, uploadFilesToS3 } from '../fileUpload.component';

export const UploadField: FC<IField> = ({
  accept = '*',
  place,
  fileName = '',
  value,
  onLoading,
  onUploaded,
  ...rest
}) => {
  const handleFileUpload: EventHandler<ChangeEvent<HTMLInputElement>> = (evt) => {
    evt.stopPropagation();

    const files = evt.currentTarget.files;
    if (typeof onLoading === 'function' && files?.length !== 0) {
      onLoading();
    }
    uploadFilesToS3({ files, place })
      .pipe(take(1))
      .subscribe((result) => {
        if (typeof onUploaded === 'function') {
          onUploaded(result);
        }
      });
  };

  return (
    <SLabel htmlFor='file-upload'>
      <TextField
        title={fileName}
        size='small'
        {...rest}
        value={fileName}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton component='span'>
                <AttachFileIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <input id='file-upload' type='file' hidden accept={accept} onChange={handleFileUpload} />
      <div title={fileName} style={{ position: 'absolute', width: '100%', height: '100%' }} />
    </SLabel>
  );
};
interface IField extends IUploadFileCommon, BaseTextFieldProps {
  fileName?: string;
}
const SLabel = styled('label')({
  position: 'relative',
  display: 'inline-flex',
});
