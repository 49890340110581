import { C_Global_Error_Code } from '../../graphql/generatedModel';
import { LocalizationHelper } from '../helpers/localization/localization.helper';

type Keys = C_Global_Error_Code | 'unknown' | 'unknownWithReload' | 'reload' | '404' | 'toHome';

const t = LocalizationHelper<Keys>('errors');

export const errorsDe = t({
  unknown: 'Etwas ist schief gelaufen!',
  unknownWithReload: 'Etwas ist schiefgelaufen! Bitte versuchen Sie, die Seite neu zu laden.',
  reload: 'Neu laden',
  404: '404 Nicht gefunden',
  toHome: 'Gehen Sie zur Startseite',
  [C_Global_Error_Code.GEC10_INVALID_GQL_DATA]: 'Falsche Datenbankdaten',
  [C_Global_Error_Code.GEC11_INVALID_DATABASE_DATA]: 'Falsche Datenbankdaten',
  [C_Global_Error_Code.GEC20_NO_DATA]: 'Für Ihre Anfrage liegen keine Daten vor',
  [C_Global_Error_Code.GEC30_BAD_USER_INPUT]: 'Ungültige Daten an Server gesendet',
  [C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE]: 'Der gelöschte Datensatz wird verwendet',
  [C_Global_Error_Code.GEC41_DELETE_PROHIBITED]: 'Löschen verboten',
  [C_Global_Error_Code.GEC42_DEACTIVATING_BY_EMPTY_NAME_RECORD_IN_USE]:
    'Dieser Datensatz wird verwendet',
  [C_Global_Error_Code.GEC43_DEACTIVATING_BY_EMPTY_NAME_PROHIBITED]: 'Deaktivieren ist verboten',
  [C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES]: 'Die Datenbank wird gerade aktualisiert',
  [C_Global_Error_Code.GEC51_EMPTY_HISTORY_USER_ID]: 'HistoryUserID Fehler',
  [C_Global_Error_Code.GEC52_ERROR_IN_STORED_PROCEDURE]: 'Server fehler',
});

export const errorsFr = t({
  unknown: 'Une erreur est survenue !',
  unknownWithReload: 'Une erreur est survenue ! Veuillez recharger la page.',
  reload: 'Recharger',
  404: '404 Page non trouvée',
  toHome: "Aller à la page d'accueil",
  [C_Global_Error_Code.GEC10_INVALID_GQL_DATA]: 'Données de la base incorrectes',
  [C_Global_Error_Code.GEC11_INVALID_DATABASE_DATA]: 'Données de la base incorrectes',
  [C_Global_Error_Code.GEC20_NO_DATA]: 'Aucune donnée pour votre requête',
  [C_Global_Error_Code.GEC30_BAD_USER_INPUT]: 'Données invalides envoyées au serveur',
  [C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE]: "L'enregistrement supprimé est utilisé",
  [C_Global_Error_Code.GEC41_DELETE_PROHIBITED]: 'Suppression interdite',
  [C_Global_Error_Code.GEC42_DEACTIVATING_BY_EMPTY_NAME_RECORD_IN_USE]:
    'Cet enregistrement est utilisé',
  [C_Global_Error_Code.GEC43_DEACTIVATING_BY_EMPTY_NAME_PROHIBITED]:
    'La désactivation est interdite',
  [C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES]:
    'La base de données est en cours de mise à jour',
  [C_Global_Error_Code.GEC51_EMPTY_HISTORY_USER_ID]: 'Erreur HistoryUserID',
  [C_Global_Error_Code.GEC52_ERROR_IN_STORED_PROCEDURE]: 'Erreur du serveur',
});

export const errorsIt = t({
  unknown: 'Si è verificato un errore!',
  unknownWithReload: 'Si è verificato un errore! Per favore, prova a ricaricare la pagina.',
  reload: 'Ricarica',
  404: '404 Non trovato',
  toHome: 'Vai alla homepage',
  [C_Global_Error_Code.GEC10_INVALID_GQL_DATA]: 'Dati del database non corretti',
  [C_Global_Error_Code.GEC11_INVALID_DATABASE_DATA]: 'Dati del database non corretti',
  [C_Global_Error_Code.GEC20_NO_DATA]: 'Non ci sono dati per la tua richiesta',
  [C_Global_Error_Code.GEC30_BAD_USER_INPUT]: 'Dati non validi inviati al server',
  [C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE]: 'Il record eliminato è in uso',
  [C_Global_Error_Code.GEC41_DELETE_PROHIBITED]: 'Eliminazione proibita',
  [C_Global_Error_Code.GEC42_DEACTIVATING_BY_EMPTY_NAME_RECORD_IN_USE]: 'Questo record è in uso',
  [C_Global_Error_Code.GEC43_DEACTIVATING_BY_EMPTY_NAME_PROHIBITED]: 'Disattivazione proibita',
  [C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES]: 'Il database è in fase di aggiornamento',
  [C_Global_Error_Code.GEC51_EMPTY_HISTORY_USER_ID]: 'Errore HistoryUserID',
  [C_Global_Error_Code.GEC52_ERROR_IN_STORED_PROCEDURE]: 'Errore del server',
});

export const errorsEn = t({
  unknown: 'Something went wrong!',
  unknownWithReload: 'Something went wrong! Please try reloading the page.',
  reload: 'Reload',
  404: '404 Not found',
  toHome: 'Go to homepage',
  [C_Global_Error_Code.GEC10_INVALID_GQL_DATA]: 'Incorrect database data',
  [C_Global_Error_Code.GEC11_INVALID_DATABASE_DATA]: 'Incorrect database data',
  [C_Global_Error_Code.GEC20_NO_DATA]: 'There is no data for your request',
  [C_Global_Error_Code.GEC30_BAD_USER_INPUT]: 'Invalid data sent to server',
  [C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE]: 'Deleted record is used',
  [C_Global_Error_Code.GEC41_DELETE_PROHIBITED]: 'Delete prohibited',
  [C_Global_Error_Code.GEC42_DEACTIVATING_BY_EMPTY_NAME_RECORD_IN_USE]: 'This record is in use',
  [C_Global_Error_Code.GEC43_DEACTIVATING_BY_EMPTY_NAME_PROHIBITED]:
    'Deactivating is prohibitedDeaktivieren ist verboten',
  [C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES]: 'Database is in updating process',
  [C_Global_Error_Code.GEC51_EMPTY_HISTORY_USER_ID]: 'HistoryUserID error',
  [C_Global_Error_Code.GEC52_ERROR_IN_STORED_PROCEDURE]: 'Server error',
});
