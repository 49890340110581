export enum ConfigurationTabs {
  invoice = 'invoice',
  customer = 'customer',
  financialAccounting = 'financial-accounting',
  production = 'production',
  article = 'article',
  post = 'post',
  vat = 'vat',
  order = 'order',
  bankAccounts = 'bank-accounts',
  import = 'import',
}
