/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton, Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service';
import { FormCheckboxList } from '../../../../../../../shared/components/form';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { C_Order_Type } from '../../../../../../../graphql/generatedModel.ts';
import { EditPrintListGeneralTabRes } from '../../../../../services/editPrintList.service.ts';
import { GotoSettingsIcon } from '../../../../../../../svg/custom.svg.tsx';
import { useNavigate } from 'react-router-dom';

export class FilterPopup extends Popup<IFilterPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const navigate = useNavigate();
      const { t } = useTranslation();
      const {
        resetField,
        getValues,
        formState: { dirtyFields },
      } = useFormContext();
      const handleApply = useRef(() => {});
      const onApply = () => {
        handleApply.current();
        stream.emit('close');
      };
      const onClose = () => {
        Object.keys(dirtyFields).forEach((key) => resetField(key, { keepDirty: false }));
        stream.emit('close');
      };

      useBeforeClosePopup({
        stream,
        isDirty: !!Object.keys(dirtyFields).length,
        handlers: () => {
          onClose();
        },
      });

      useEffect(() => {
        stream.actionListener('open').subscribe(({ onApply }) => {
          handleApply.current = onApply;
          const orderTypesFilter = getValues(
            'orderTypesFilter',
          ) as EditPrintListGeneralTabRes['orderTypesFilter'];
          resetField('orderTypesFilter', {
            defaultValue: orderTypesFilter.map((item) => ({
              ...item,
              label: t(`enums.${C_Order_Type[item.id as keyof typeof C_Order_Type]}`),
            })),
          });
        });
      }, []);

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('common.filter')}
          PaperProps={{ sx: { maxWidth: '1200px', maxHeight: '700px' } }}
          fullWidth
          keepMounted
        >
          <Stack direction='row' justifyContent='space-between'>
            <FormCheckboxList
              name='articleListsFilter'
              height='450px'
              minWidth='350px'
              listLabel={t('production.article_list_filter')}
              controlLabel={'<' + t('common.all_lists') + '>'}
              emptyListIndication={{
                title: t('common.list_are_not_configured'),
                text: (
                  <Trans
                    style={{ display: 'flex' }}
                    i18nKey='common.click_button_above_to_configure'
                    components={{ icon: <SSettingsIcon /> }}
                  />
                ),
              }}
              headerRightOptions={
                <IconButton
                  sx={{ ml: 'auto', height: '40px' }}
                  onClick={() => navigate('/settings/configuration/article')}
                  title={t('common.settings')}
                >
                  <GotoSettingsIcon />
                </IconButton>
              }
            />
            <FormCheckboxList
              name='customerListsFilter'
              height='450px'
              minWidth='350px'
              listLabel={t('production.customer_list_filter')}
              controlLabel={'<' + t('common.all_lists') + '>'}
              emptyListIndication={{
                title: t('common.list_are_not_configured'),
                text: (
                  <Trans
                    style={{ display: 'flex' }}
                    i18nKey='common.click_button_above_to_configure'
                    components={{ icon: <SSettingsIcon /> }}
                  />
                ),
              }}
              headerRightOptions={
                <IconButton
                  sx={{ ml: 'auto', height: '40px' }}
                  onClick={() => navigate('/settings/configuration/customer')}
                  title={t('common.settings')}
                >
                  <GotoSettingsIcon />
                </IconButton>
              }
            />
            <FormCheckboxList
              name='orderTypesFilter'
              height='450px'
              minWidth='350px'
              listLabel={t('production.order_type')}
              controlLabel={'<' + t('common.all_types') + '>'}
            />
          </Stack>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button
              onClick={onApply}
              variant='contained'
              disabled={Object.keys(dirtyFields).length === 0}
              sx={{ width: 200 }}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface IFilterPopup {
  action: TPopupDefaultActions;
  onApply: () => void;
}

export const SSettingsIcon = styled(GotoSettingsIcon)(({ theme }) => ({
  marginRight: 2,
  marginLeft: 2,
  height: '24px !important',
  width: '24px !important',
  verticalAlign: 'bottom',
  color: `${theme.palette.text.secondary}!important`,
}));
