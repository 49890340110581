import { Box, CircularProgress, InputAdornment, Stack, styled } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormSelect,
  FormTextField,
  SelectMenuWithFooter,
} from '../../../../../../shared/components/form';
import { NoteButton } from '../../../../../../shared/components/form/buttons/noteButton.component';
import {
  IServerValidation,
  ServerValidation,
} from '../../../../../../shared/components/serverValidation/serverValidation.component';
import { valueHelper } from '../../../../../../shared/helpers/valueProcessor/value.helper';
import { TMode } from '../../../../../../shared/services/mode/mode.service';
import {
  ArticleGeneralTabDataRes,
  GeneralTabOptionsRes,
} from '../../../../services/article.service';
import { generalTabState } from '../generalTab.state';
import { getSaleUnitOptions } from '../options/saleUnit.options';
import { getTaxesOptions } from '../options/tax.options';
import { NotePopup } from '../popups/note.popup';
import { SpecialPrices } from './specialPrices/specialPrices.component';
import { resetArticleDeps } from '../../../../states/articleList.state.ts';
import { ITabState } from '../../../../states/tabState.model.ts';

export const GeneralForm: FC<IGeneralForm> = ({
  articleSpecialPrices = [],
  articlePricesByCustomers = [],
  articleResellerPrices = [],
  mode,
  isDough = false,
  handleSubmitValue,
  loading,
}) => {
  const {
    generalPriceCategories,
    generalTabOptions,
    specialPriceCategoriesColumns,
    createSpecialPriceCategoriesInputs,
  } = useLoaderData() as ITabState & { generalTabOptions: GeneralTabOptionsRes };
  const notePopup = useMemo(() => new NotePopup(), []);
  const { t } = useTranslation();
  const saleUnitOptions = getSaleUnitOptions(t);
  const taxOptions = getTaxesOptions(t);
  const {
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof ArticleGeneralTabDataRes;
    if (!errors[fieldName] && !!dirtyFields[fieldName] && fieldName !== 'articleNo') {
      if (mode === 'edit') {
        generalTabState.pub.save();
      }
    }
  };

  useEffect(() => {
    notePopup.stream.state().subscribe(({ action }) => {
      if (action === 'save') {
        generalTabState.pub.save();
      }
    });
    return () => notePopup.stream.unsubscribe();
  }, []);

  return (
    <>
      <form {...(mode === 'edit' && { onBlur: onSubmit })} noValidate>
        <SGrid>
          <FieldsSection
            className='base'
            titleBoxSx={{ pb: 2, pt: 0 }}
            nomt
            title={t('article.basis')}
          >
            <FieldsRow alignItems='flex-start'>
              <ServerValidation
                fieldName='articleNo'
                allowEmpty={false}
                handleSubmitValue={handleSubmitValue!}
                renderItem={({ fieldName, errors, setIsFocused }) => (
                  <FormTextField
                    width='295px'
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    name={fieldName}
                    label={t('common.article_no')}
                    required
                    error={Boolean(errors[fieldName])}
                    helperText={errors[fieldName] ? t(String(errors[fieldName]!.message)) : ''}
                    InputProps={{
                      endAdornment: loading && (
                        <InputAdornment position='end'>
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <FormCheckbox
                label={t('article.article_is_active')}
                name='isActive'
                onChange={() => resetArticleDeps()}
              />
            </FieldsRow>
            <FieldsRow sx={{ alignItems: 'flex-start' }}>
              <FormTextField
                inputProps={{ maxLength: 64 }}
                width='100%'
                name='description'
                label={t('common.description')}
                required
                error={Boolean(errors['description'])}
                helperText={errors['description'] ? t(String(errors['description'].message)) : ''}
              />
              <NoteButton
                view='small'
                badged={valueHelper.checkValueInHtml(getValues().note)}
                onClick={() =>
                  notePopup.stream.emit('open', {
                    values: {
                      note: getValues().note,
                      isNotifyOnOrderStage: getValues().isNotifyOnOrderStage,
                    },
                  })
                }
              />
            </FieldsRow>
            <FieldsRow alignItems='center'>
              <FormSelect
                name='saleUnitId'
                popperWidth='210px'
                label={t('article.sale_unit')}
                options={saleUnitOptions}
                disableClearable
                shrink
              />
              <FormNumericField
                name='quantityPerLot'
                label={t('article.piece_or_unit')}
                noTrailingZeros
                shrink
                precision={0}
              />
              <FormSelect
                name='vatCode'
                label={t('common.vat')}
                options={taxOptions}
                disableClearable
              />
            </FieldsRow>

            <FieldsRow sx={{ mt: generalTabOptions.isShowShippingPointsInArticle ? 3 : 1 }}>
              {generalTabOptions.isShowShippingPointsInArticle && (
                <FormSelect
                  sx={{ minWidth: 298, width: 298 }}
                  name='shippingPointId'
                  label={t('article.shipping_point')}
                  options={
                    (generalTabOptions.shippingPoints as { id: string; label: string }[]) || []
                  }
                  PaperComponent={(props) => (
                    <SelectMenuWithFooter {...props} href='/settings/configuration/article' />
                  )}
                />
              )}
              <FormCheckbox
                labelProps={{ sx: { whiteSpace: 'nowrap', ...(isDough && { display: 'none' }) } }}
                label={t('article.net_article')}
                name='isNoDiscount'
              />
            </FieldsRow>
          </FieldsSection>

          <FieldsSection
            className='groups'
            titleBoxSx={{ pb: 2, pt: 0 }}
            nomt
            title={t('article.groups')}
          >
            <Stack spacing={3}>
              <FormSelect
                name='discountGroupId'
                label={t('article.product_group')}
                options={
                  (generalTabOptions?.discountGroups as { id: string; label: string }[]) || []
                }
                PaperComponent={(props) => (
                  <SelectMenuWithFooter {...props} href='/settings/configuration/article' />
                )}
              />
              <FormSelect
                name='productionGroupId'
                label={t('article.production_group')}
                options={
                  (generalTabOptions?.productionGroups as { id: string; label: string }[]) || []
                }
                PaperComponent={(props) => (
                  <SelectMenuWithFooter {...props} href='/settings/configuration/article' />
                )}
              />
              <FormSelect
                name='marketingGroupId'
                label={t('article.marketing_group')}
                options={
                  (generalTabOptions?.marketingGroups as { id: string; label: string }[]) || []
                }
                PaperComponent={(props) => (
                  <SelectMenuWithFooter {...props} href='/settings/configuration/article' />
                )}
              />
            </Stack>
          </FieldsSection>

          <FieldsSection
            sx={{ ...(isDough && { display: 'none' }) }}
            className='regular_price'
            titleBoxSx={{ pb: 2, pt: 0 }}
            nomt
            title={t('article.regular_price')}
          >
            <FieldsRow flexWrap='wrap' columnGap={2} rowGap={3} useFlexGap>
              {generalPriceCategories}
            </FieldsRow>
          </FieldsSection>
        </SGrid>

        {!isDough && (
          <FieldsSection
            className='special_price'
            titleBoxSx={{ pb: 2, pt: 0 }}
            title={t('article.special_price')}
          >
            <SpecialPrices
              specialPriceCategoriesColumns={
                specialPriceCategoriesColumns as ITabState['specialPriceCategoriesColumns']
              }
              customersList={generalTabOptions.customersList}
              {...{
                articleSpecialPrices,
                createSpecialPriceCategoriesInputs,
                articlePricesByCustomers,
                mode,
                articleResellerPrices,
              }}
            />
          </FieldsSection>
        )}
      </form>
      <notePopup.Component />
    </>
  );
};

interface IGeneralForm extends Partial<Pick<IServerValidation, 'handleSubmitValue'>> {
  mode: TMode;
  loading: boolean;
  isDough?: boolean;
  articleSpecialPrices?: ITabState['articleSpecialPrices'];
  articlePricesByCustomers?: ITabState['articlePricesByCustomers'];
  articleResellerPrices?: ITabState['articleResellerPrices'];
}

const SGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'minmax(300px, 1fr) minmax(200px, 1fr) 300px 1fr',
  gridTemplateRows: 'repeat(2, auto)',
  gap: '16px 54px',
  '& .base': {
    gridArea: 'base',
  },
  '& .groups': {
    gridArea: 'groups',
  },
  '& .regular_price': {
    gridArea: 'regular_price',
  },

  '@media(min-width: 1400px)': {
    gridTemplateAreas: `"base base groups"
  "regular_price regular_price regular_price"`,
  },
  '@media(max-width: 1400px)': {
    gridTemplateAreas: `"base base ..."
  "groups regular_price regular_price"`,
  },
});
