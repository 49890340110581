import { FC, useEffect, useMemo, useState } from 'react';
import { IGeneralTabState, generalTabState } from '../../../generalTab.state';
import { CustomerPricesPopup } from './popups/customerPrices/customerPrices.popup';
import { GeneralTabOptionsRes } from '../../../../../../services/article.service';
import { CopySpecialPricesPopup } from '../common/popups/copySpecialPrices/copySpecialPrices.popup';
import { useFormContext } from 'react-hook-form';
import { ControlButtons } from './components/controlButtons.component';
import { STable } from '../common/styles/styles';
import { storageHelper } from '../../../../../../../../shared/helpers/storage';
import { Column } from '../../../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { useTranslation } from 'react-i18next';
import { SCellCheckboxContainer } from '../../../../../../../customer/components/tabs/conditions/components/sections/specialPrices/individualPrices/individualPrices.component';
import { Checkbox } from '../../../../../../../../shared/components/form';

export const CustomersPrices: FC<ICustomersPrices> = (props) => {
  const { pricesByCustomerData, customersList, isEditMode, currentArticleId } = props;
  const { t } = useTranslation();
  const [selectedPriceByCustomer, setSelectedPriceByCustomer] = useState<
    IGeneralTabState['selectedPricesByCustomerPos']
  >(pricesByCustomerData?.[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const customerPricesPopup = useMemo(() => new CustomerPricesPopup({ customersList }), []);
  const copySpecialPricesPopup = useMemo(() => new CopySpecialPricesPopup(), []);
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    // on create/copy mode here we provide data to form
    if (!isEditMode) {
      const articlePricesByCustomersForSave = pricesByCustomerData?.map(
        ({ id: _id, customerName: _, customerNo: __, ...rest }) => ({
          ...rest,
        }),
      );
      setValue('articlePricesByCustomers', articlePricesByCustomersForSave);
    }
  }, [pricesByCustomerData, isEditMode]);

  useEffect(() => {
    const unsubState = generalTabState.sub
      .state()
      .subscribe(({ action, selectedPricesByCustomerPos }) => {
        setSelectedPriceByCustomer(selectedPricesByCustomerPos);
        if (
          (action === 'updatePricesByCustomerPos' ||
            action === 'deletePricesByCustomerPos' ||
            action === 'copyPricesByCustomer') &&
          isEditMode
        ) {
          generalTabState.pub.savePricesByCustomerGrid(getValues('id'));
        }
      });
    const subLoadingGrid = generalTabState.sub.customersPricesGridLoading().subscribe(setLoading);

    return () => {
      unsubState.unsubscribe();
      customerPricesPopup.stream.unsubscribe();
      subLoadingGrid.unsubscribe();
    };
  }, []);

  return (
    <>
      <ControlButtons
        {...{
          currentArticleId,
          copySpecialPricesPopup,
          customerPricesPopup,
          selectedPriceByCustomer,
        }}
      />
      <STable
        data={pricesByCustomerData}
        rowSelectionModel={selectedPriceByCustomer?.id}
        onRowClick={(v, e) => {
          generalTabState.pub.selectPricesByCustomerPos(v.row);
          customerPricesPopup.stream.emit('open', {
            values: {
              row: v.row,
              field: (e.target as any).parentElement?.dataset?.field,
            },
          });
        }}
        initialState={{
          columns: storageHelper.local.getItem('article.pricesByCustomerColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('article.pricesByCustomerColumnModel', v)
        }
        height={400}
        {...{ loading }}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={100}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={100}
        />
        <Column
          field='customerName'
          headerName={t('common.customer')}
          valueGetter={({ value, row }) => row.customerNo && `${row.customerNo} ${value}`}
          width={200}
        />
        <Column
          field='specialPrice'
          headerName={t('common.price')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
        <Column
          field='specialDiscount'
          headerName={t('common.discount')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={90}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? `${localeFormatterHelper.formatNumber(value, {
                  precision: 2,
                  noTrailingZeros: true,
                })}%`
              : value
          }
        />
        <Column
          field='specialIsNoDiscount'
          headerName={t('customer.noDiscount')}
          sortable={false}
          align='center'
          headerAlign='center'
          renderCell={({ value }) => (
            <SCellCheckboxContainer data-field='specialIsNoDiscount'>
              <Checkbox disabled size='small' checked={value} />
            </SCellCheckboxContainer>
          )}
          width={110}
        />
        <Column
          field='fromQuantity'
          headerName={t('customer.fromQuantity')}
          align='right'
          headerAlign='right'
          sortable={false}
          valueGetter={({ value }) =>
            value
              ? localeFormatterHelper.formatNumber(value, {
                  precision: 3,
                  noTrailingZeros: true,
                })
              : ''
          }
          width={100}
        />
        <Column
          field='specialPriceType'
          headerName={t('customer.kind')}
          sortable={false}
          valueGetter={({ value }) => value && t(`enums.${value}`)}
          width={200}
        />
        <Column field='comment' headerName={t('customer.comment')} sortable={false} width={150} />
      </STable>
      <customerPricesPopup.Component />
      <copySpecialPricesPopup.Component />
    </>
  );
};

interface ICustomersPrices {
  pricesByCustomerData: IGeneralTabState['articlePricesByCustomers'];
  customersList: GeneralTabOptionsRes['customersList'];
  isEditMode: boolean;
  currentArticleId: string;
}
