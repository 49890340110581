import { FC, useEffect, useState } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FieldsRow,
  FormCheckbox,
  FormDatePicker,
  FormNumericField,
  FormSelect,
  FormTextField,
} from '../../../../../../../../../../../shared/components/form/index.ts';
import { ITabState } from '../../../../../../../../../states/tabState.model.ts';
import {
  ArticleStdPriceQueryRes,
  customerService,
} from '../../../../../../../../../services/customer.service.ts';
import { responseHandler } from '../../../../../../../../../../../shared/responseHandler/responseHandler.ts';
import { take } from 'rxjs';
import { FormProvider, useForm } from 'react-hook-form';
import { IPopupWrapper } from '../../../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { conditionsTabState } from '../../../../../../conditions.state.ts';
import { DictArticlesOption } from '../../../../../../../../../loaders/conditionsTab.resolver.ts';
import { getSpecialPriceType, TDefaultValues } from './individualPrices.popup.tsx';
import { format } from 'date-fns';
import { ISelectOption } from '../../../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { useBeforeClosePopup } from '../../../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const IndividualPricesForm: FC<IIndividualPricesForm> = ({
  field,
  productListOptions,
  priceCategory,
  defaultValues,
  stream,
}) => {
  const { t } = useTranslation();
  const [productLoading, setProductLoading] = useState(false);
  const formMethods = useForm<TDefaultValues>({
    mode: 'onChange',
    defaultValues,
  });
  const { errors, dirtyFields } = formMethods.formState;

  useEffect(() => {
    const formStateSub = formMethods.watch((data, { name, type }) => {
      if (
        type === 'change' &&
        (name === 'fromQuantity' ||
          name === 'specialPrice' ||
          name === 'specialDiscount' ||
          name === 'specialIsNoDiscount')
      ) {
        const specialPriceType = getSpecialPriceType(data);
        formMethods.setValue(
          'specialPriceTypeText',
          specialPriceType && t(`enums.${specialPriceType}`),
        );
        formMethods.setValue('specialPriceType', specialPriceType);
      }
    });

    return () => {
      formStateSub.unsubscribe();
    };
  }, []);

  const handleSubmit = (values: TDefaultValues) => {
    const { specialPriceTypeText: _, ...data } = values as TDefaultValues;
    if (data.fromDate) data.fromDate = format(data.fromDate, 'yyyy-MM-dd');
    if (data.toDate) data.toDate = format(data.toDate, 'yyyy-MM-dd');

    conditionsTabState.pub.updateSpecialPricesPos(data);
    stream.emit('close');
  };

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.articleNo ? `${option?.articleNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
        <Stack spacing={2}>
          <FieldsRow>
            <FormSelect
              disableClearable
              autoFocus={'description' === field}
              width='100%'
              name='articleId'
              label={t('customer.article')}
              options={productListOptions as []}
              loading={productLoading}
              datasetattribute='articleOptions'
              onChange={(v) => {
                customerService.sub
                  .articleStdPrice()
                  .pipe(
                    responseHandler<ArticleStdPriceQueryRes | null>({ errorReturnType: null }),
                    take(1),
                  )
                  .subscribe((v) => {
                    const product = productListOptions?.find(
                      (item) => item.id === formMethods.watch('articleId'),
                    ) as DictArticlesOption;
                    if (v) {
                      formMethods.setValue('regularPrice', v);
                    }
                    formMethods.setValue('description', product?.label);
                    formMethods.setValue('articleNo', product?.articleNo);
                    formMethods.setValue('articleId', product?.id);
                    setProductLoading(false);
                  });
                customerService.pub.articleStdPrice({
                  stdPriceInput: {
                    priceCategory,
                    articleId: String(v),
                  },
                });
                setProductLoading(true);
              }}
              getOptionLabel={(params) => {
                const { articleNo, label } = params as ExtendedSelectOption;
                return `${articleNo} ${label}`;
              }}
              controllerProps={{
                rules: {
                  validate: (v) => (v ? null : t('common.not_empty')),
                },
              }}
              {...(errors.articleId && {
                error: true,
                helperText: errors.articleId.message,
              })}
              {...{ filterOptions }}
            />
            <SFormNumericField
              name='regularPrice'
              width='142px'
              autoFocus={'regularPrice' === field}
              label={t('customer.stdPrice')}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </FieldsRow>
          <FieldsRow>
            <FieldsRow width='50%'>
              <FormNumericField
                name='fromQuantity'
                autoFocus={'fromQuantity' === field}
                precision={3}
                noTrailingZeros
                label={t('customer.fromQuantity')}
              />
              <FormNumericField
                autoFocus={'specialPrice' === field}
                name='specialPrice'
                label={t('common.price')}
                controllerProps={{
                  rules: {
                    validate: (_, { specialPriceType }) => {
                      return specialPriceType ? null : t('common.not_empty');
                    },
                    deps: ['specialDiscount'],
                  },
                }}
                {...(errors.specialPrice && {
                  error: true,
                  helperText: errors.specialPrice.message,
                })}
              />
            </FieldsRow>
            <FieldsRow width='50%'>
              <FormNumericField
                name='specialDiscount'
                autoFocus={'specialDiscount' === field}
                precision={2}
                noTrailingZeros
                returnZero
                InputProps={{
                  endAdornment: '%',
                }}
                controllerProps={{
                  rules: {
                    validate: (_, { specialPriceType }) => {
                      return specialPriceType ? null : t('common.not_empty');
                    },
                    deps: ['specialPrice'],
                  },
                }}
                {...(errors.specialDiscount && {
                  error: true,
                  helperText: errors.specialDiscount.message,
                })}
                max={100}
                label={t('common.discount')}
              />
              <FieldsRow width={142} minWidth={142}>
                <FormCheckbox
                  name='specialIsNoDiscount'
                  inputProps={{
                    autoFocus: 'specialIsNoDiscount' === field,
                  }}
                  controllerProps={{
                    rules: {
                      deps: ['specialPrice', 'specialDiscount'],
                    },
                  }}
                  label={t('customer.noDiscount')}
                />
              </FieldsRow>
            </FieldsRow>
          </FieldsRow>
          <FieldsRow>
            <FieldsRow width='50%'>
              <FormDatePicker
                sx={{ flexGrow: 1 }}
                returnEmpty
                fieldProps={{ label: t('common.from_date'), autoFocus: 'fromDate' === field }}
                navigation={false}
                name='fromDate'
              />
              <FormDatePicker
                sx={{ flexGrow: 1 }}
                returnEmpty
                fieldProps={{ label: t('common.to_date'), autoFocus: 'toDate' === field }}
                navigation={false}
                name='toDate'
              />
            </FieldsRow>
            <FieldsRow width='50%'>
              <FormTextField
                inputProps={{ maxLength: 128 }}
                width='100%'
                label={t('customer.comment')}
                name='comment'
                autoFocus={'comment' === field}
              />
            </FieldsRow>
          </FieldsRow>
          <FieldsRow>
            <SFormTextField
              width='100%'
              label={t('customer.kind')}
              name='specialPriceType'
              autoFocus={'specialPriceType' === field}
              {...(errors.specialPriceType && {
                error: true,
                helperText: t(errors['specialPriceType'].message as string),
              })}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </FieldsRow>
        </Stack>
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button type='submit' variant='contained'>
            {t('common.save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

const SFormTextField = styled(FormTextField)({
  '& .MuiInputBase-root, & input': {
    cursor: 'not-allowed',
  },
});

const SFormNumericField = styled(FormNumericField)({
  '& .MuiInputBase-root, & input': {
    cursor: 'not-allowed',
  },
});

interface IIndividualPricesForm {
  field: string;
  productListOptions: ITabState['conditionsTabOptions']['productList'];
  priceCategory: number;
  customerId: string;
  defaultValues: TDefaultValues;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
