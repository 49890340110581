import { LoaderFunction } from 'react-router-dom';
import { ToolboxArticleListsFilterRes, toolboxService } from '../../services/toolbox.service';
import { take } from 'rxjs';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';

export const toolboxLoader: LoaderFunction = async (): Promise<IToolboxLoaderData> => {
  const articleListsFilter = await toolboxService.globHelpers.streamToPromise(
    toolboxService.sub.getToolboxArticleListsFilter().pipe(
      take(1),
      responseHandler<ToolboxArticleListsFilterRes>({
        errorReturnType: [],
      }),
    ),
    () => toolboxService.pub.getToolboxArticleListsFilter(),
  );
  return { articleListsFilter };
};

export interface IToolboxLoaderData {
  articleListsFilter: ToolboxArticleListsFilterRes;
}
