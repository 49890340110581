import React, { FC, useCallback } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { TableNumericField } from '../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { defaultOrderState } from '../../../../defaultOrder.state.ts';
import { clearRowFromVirtualKeys } from './additionalFunctions.ts';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const QuantityEditCell: FC<INumberEditCell> = ({ params }) => {
  const { value, field, id, api, row } = params;
  const { setEditCellValue, stopCellEditMode } = api;

  const updateCell = useCallback(
    (v: number | null) => {
      if (typeof Number(v) === 'number' && value !== v) {
        setEditCellValue({ id, field, value: v });
        const path = field.split('.');
        row[path[0]][path[1]] = v;
        defaultOrderState.pub.updateCell(clearRowFromVirtualKeys(row), 'simpleCell');
      }
    },
    [params],
  );
  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && isEnterKey(v)) {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(value ? Number(value) : null);
      stopCellEditMode({ id, field });
    }
  };
  return (
    <TableNumericField
      returnZero
      onKeyDown={handleKeyDown}
      onBlur={updateCell}
      min={-10000000}
      autoFocus
      precision={3}
      noTrailingZeros
      defaultValue={value}
    />
  );
};

interface INumberEditCell {
  params: GridRenderCellParams;
}
