import {
  Scalars,
  C_Save_Operation_Status,
  Wa_DefaultOrderPositionWeekDaySettingsInput as DefaultOrderPositionWeekDaySettings,
  Wa_DefaultOrderPositionByArticleInput as DefaultOrderPositionByArticle,
} from '../../../../../graphql/generatedModel.ts';
import {
  ExtendedDefaultOrderGridDataPos,
  articleService,
  GetWeeklyDefaultOrderByArticleFromDateResExtended,
  SaveArticleDataRes,
  GetDataForNewDefaultOrderPositionByArticleRes,
  GetDefaultOrderTabDataResExtended,
} from '../../../services/article.service.ts';
import { getFirstDayOfWeek } from '../../../../../shared/components/datePicker/weekPicker.component.tsx';
import { configsData } from '../../../../../shared/services/configsData/configsData.service.ts';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract.ts';
import {
  BehaviorSubject,
  Observable,
  share,
  merge,
  tap,
  filter,
  map,
  switchMap,
  of,
  take,
  delay,
  finalize,
} from 'rxjs';
import { articleListState } from '../../../states/articleList.state.ts';
import { articleTabLoadingService } from '../articleTababLoading.service.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import { defaultOrderDataByArticle } from '../../../loaders/defaultOrderTab.resolver.ts';
import { format } from 'date-fns';
import i18n from 'i18next';
import equal from 'fast-deep-equal/react';

export const initDefaultOrderState: IDefaultOrderState = {
  action: undefined,
  selectedPos: null,
  defaultOrderGridData: [],
  defaultOrderGridDataBackup: [],
  articleId: null,
  fromDate: getFirstDayOfWeek(),
};

class PubImpl extends Pub<IDefaultOrderState> {
  init(params: IDefaultOrderState) {
    this.emit('init', params);
  }
  selectPosition(selectedPos: IDefaultOrderState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  updateCell(selectedPos: IDefaultOrderState['selectedPos'], type: TypeCell) {
    this.emit(type, { selectedPos });
  }
  fromDate(fromDate: IDefaultOrderState['fromDate']) {
    this.emit('fromDate', { fromDate });
  }
  updateGridData(defaultOrderGridData: IDefaultOrderState['defaultOrderGridData']) {
    this.emit('updateGridData', { defaultOrderGridData });
  }
  addPosition() {
    this.emit('addPosition', {});
  }
  deletePosition() {
    this.emit('deletePosition', {});
  }
  saveDefaultOrderGrid() {
    this.emit('saveDefaultOrderGrid', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<IDefaultOrderState> {
  private loadingMainGrid$ = new BehaviorSubject<boolean>(false);
  private cellsLoading$ = new BehaviorSubject<string[]>([]); // Loading by cells
  private shareLoadingMainGrid$: Observable<boolean> = this.loadingMainGrid$.pipe(share());
  private shareCellsLoading$ = this.cellsLoading$.pipe(share());
  protected actionHandlers(): Observable<IDefaultOrderState> {
    return merge(
      this.simpleCell(),
      this.nameCell(),
      this.selectedArticle(),
      this.formDate(),
      this.addPosition(),
      this.deletePosition(),
      this.saveDefaultOrderGrid(),
      this.updateState(),
    ).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  loadingMainGrid(): Observable<boolean> {
    return this.shareLoadingMainGrid$;
  }
  cellsLoading(): Observable<string[]> {
    return this.shareCellsLoading$;
  }

  private selectedArticle(): Observable<IDefaultOrderState> {
    return articleListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'selectArticle' || action === 'articleList' || action === 'filter',
      ),
      switchMap(({ selectedArticle }) => {
        const state = structuredClone(this.stream$.getValue());
        state.action = 'list.selectedArticle';
        if (!selectedArticle || !selectedArticle?.id) {
          state.articleId = null;
          return of(state);
        }
        articleTabLoadingService.pub.loading(true);
        return this.details(state, selectedArticle.id);
      }),
    );
  }

  private simpleCell(): Observable<IDefaultOrderState> {
    return this.actionListener('simpleCell').pipe(
      map((state) => {
        const { selectedPos } = state;
        const updatedPos = structuredClone(selectedPos!);
        state.defaultOrderGridData = state.defaultOrderGridData?.map((el) =>
          el?.id === updatedPos?.id ? updatedPos : el,
        );
        state.selectedPos = updatedPos;
        return { ...state };
      }),
    );
  }

  private nameCell(): Observable<IDefaultOrderState> {
    return this.actionListener('nameCell').pipe(
      tap((state) => {
        const { selectedPos, fromDate, articleId } = state;
        const { id, customerId } = selectedPos!;
        this.preUpdateCell(selectedPos);
        articleService.pub.getDataForNewDefaultOrderPositionByArticle({
          customerId,
          date: format(fromDate!, 'yyyy-MM-dd'),
          articleId: articleId!,
        });
        this.cellsLoading$.next([...this.cellsLoading$.value, `${id}_name`]);
      }),
      switchMap((state) => {
        const articleId = state.articleId;
        const { id, ...prevSelectedPos } = state.selectedPos!;
        const selectedPosId = id;
        return articleService.sub.getDataForNewDefaultOrderPositionByArticle().pipe(
          responseHandler<GetDataForNewDefaultOrderPositionByArticleRes>({
            errorReturnType: prevSelectedPos,
          }),
          filter(() => {
            const state = this.stream$.getValue();
            this.cellsLoading$.next(
              this.cellsLoading$.value.filter((item) => item !== `${selectedPosId}_name`),
            );
            return (
              !!state.defaultOrderGridData?.some((el) => el.id === selectedPosId) ||
              articleId === state.articleId
            );
          }), // if position is deleted or article is changed
          map((data) => {
            const updatedState = structuredClone(this.stream$.getValue()!);
            updatedState.defaultOrderGridData = updatedState.defaultOrderGridData.map((item) => {
              if (selectedPosId === item.id) {
                const updatedPosition = {
                  ...item,
                  ...data,
                  mondaySettings: { ...data.mondaySettings, ...item.mondaySettings },
                  tuesdaySettings: { ...data.tuesdaySettings, ...item.tuesdaySettings },
                  wednesdaySettings: { ...data.wednesdaySettings, ...item.wednesdaySettings },
                  thursdaySettings: { ...data.thursdaySettings, ...item.thursdaySettings },
                  fridaySettings: { ...data.fridaySettings, ...item.fridaySettings },
                  saturdaySettings: { ...data.saturdaySettings, ...item.saturdaySettings },
                  sundaySettings: { ...data.sundaySettings, ...item.sundaySettings },
                  __reorder__: `${item?.customerNr ? '(' + item?.customerNr + ')' : ''} ${
                    item?.internalOrFullName || ''
                  }`,
                };
                updatedState.selectedPos = updatedPosition;
                return updatedPosition;
              } else return item;
            });
            return updatedState;
          }),
        );
      }),
    );
  }

  private formDate(): Observable<IDefaultOrderState> {
    return this.actionListener('fromDate').pipe(
      tap(({ fromDate, articleId }) => {
        if (fromDate && articleId) {
          this.loadingMainGrid$.next(true);
          articleService.pub.getWeeklyDefaultOrderByArticleFromDate({
            id: articleId,
            date: format(fromDate, 'yyyy-MM-d'),
          });
        }
      }),
      switchMap(() => {
        return articleService.sub.getWeeklyDefaultOrderByArticleFromDate().pipe(
          responseHandler<GetWeeklyDefaultOrderByArticleFromDateResExtended>({
            errorReturnType: {
              id: '',
              weeklyDefaultOrderByArticle: [],
            },
          }),
          take(1),
        );
      }),
      map((data) => {
        this.loadingMainGrid$.next(false);
        const { weeklyDefaultOrderByArticle: defaultOrderGridData, id: articleId } = data;
        let state = structuredClone(this.stream$.getValue());
        if (articleId === state.articleId) {
          state = {
            ...state,
            defaultOrderGridData,
            articleId,
            defaultOrderGridDataBackup: structuredClone(defaultOrderGridData),
          };
        }
        return state;
      }),
    );
  }

  private addPosition(): Observable<IDefaultOrderState> {
    return this.actionListener('addPosition').pipe(
      switchMap((state) => {
        const updatedState = structuredClone(state);
        const newPosition = {
          isActive: true,
          customerId: '',
          internalOrFullName: null,
          customerNr: null,
          itemSortPos: updatedState.defaultOrderGridData.length + 1,
          isMatchAssortmentGroup: true,
          canChangeInOrders: true,
          isDeliverySplitting: false,
          mondaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          tuesdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          wednesdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          thursdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          fridaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          saturdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          sundaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          id: Date.now(),
          __reorder__: i18n.t('order.new_position'),
        };
        updatedState.defaultOrderGridData.push(newPosition);
        updatedState.selectedPos = newPosition;
        return of(updatedState);
      }),
    );
  }

  private deletePosition(): Observable<IDefaultOrderState> {
    return this.actionListener('deletePosition').pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IDefaultOrderState['selectedPos'] = null;
        updatedState.defaultOrderGridData = updatedState.defaultOrderGridData?.filter(
          (pos, i, arr) => {
            if (pos?.id === state.selectedPos?.id) {
              const nextPos = arr?.[i + 1];
              const prevPos = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                selectedPos = nextPos;
              }
              if (i !== 0) {
                selectedPos = nextPos || prevPos || null;
              }
              return false;
            } else return true;
          },
        );
        updatedState.selectedPos = selectedPos;
        return updatedState;
      }),
    );
  }

  private saveDefaultOrderGrid(): Observable<IDefaultOrderState> {
    return this.actionListener('saveDefaultOrderGrid').pipe(
      tap((state) => {
        const updatedState = structuredClone(state);
        const { defaultOrderGridData, defaultOrderGridDataBackup } = updatedState;
        const isDirtyGrid = !equal(defaultOrderGridData, defaultOrderGridDataBackup);
        if (isDirtyGrid) {
          let weeklyDefaultOrderByArticle;
          if (isDirtyGrid) {
            weeklyDefaultOrderByArticle = updatedState.defaultOrderGridData.reduce(
              (acc: DefaultOrderPositionByArticle[], item, i) => {
                if (item.customerId) {
                  acc?.push({
                    customerId: item.customerId,
                    canChangeInOrders: item.canChangeInOrders,
                    mondaySettings: this.prepareDaySettings(item.mondaySettings),
                    tuesdaySettings: this.prepareDaySettings(item.tuesdaySettings),
                    wednesdaySettings: this.prepareDaySettings(item.wednesdaySettings),
                    thursdaySettings: this.prepareDaySettings(item.thursdaySettings),
                    fridaySettings: this.prepareDaySettings(item.fridaySettings),
                    saturdaySettings: this.prepareDaySettings(item.saturdaySettings),
                    sundaySettings: this.prepareDaySettings(item.sundaySettings),
                    itemSortPos: ++i,
                  });
                }
                return acc;
              },
              [],
            );
          }

          articleService.sub
            .editArticle()
            .pipe(
              responseHandler<SaveArticleDataRes | undefined>({
                success: () => 'customer.defaultOrder_saved',
                customErrorHandler: () => 'common.error_chnages_not_saved',
              }),
              filter((v) => v !== undefined),
              take(1),
            )
            .subscribe((res) => {
              if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
                const { updatedGridItem } = res;
                articleListState.pub.updateArticle(updatedGridItem!);
              }
            });
          articleService.pub.editArticle({
            dataToSave: {
              id: updatedState.articleId!,
              weeklyDefaultOrderByArticle,
            },
          });
          this.stream$.next({
            ...updatedState,
            defaultOrderGridDataBackup: structuredClone(updatedState.defaultOrderGridData),
            action: 'internalUpdate',
          });
        }
      }),
      finalize(() => defaultOrderState.pub.clearStream()),
      filter(() => false), // state updating is not needed
    );
  }

  private details(state: IDefaultOrderState, id: string): Observable<IDefaultOrderState> {
    const details = articleService.sub.defaultOrderTabData().pipe(
      responseHandler<GetDefaultOrderTabDataResExtended>({
        errorReturnType: defaultOrderDataByArticle,
      }),
      map(({ weeklyDefaultOrderByArticle: defaultOrderGridData = [], id: articleId }) => {
        state.defaultOrderGridData = defaultOrderGridData;
        state.defaultOrderGridDataBackup = structuredClone(defaultOrderGridData);
        state.articleId = articleId;
        articleTabLoadingService.pub.loading(false);
        return state;
      }),
    );
    articleService.pub.defaultOrderTabData({ id });
    configsData.pub.common(['dictCustomers']);

    return details;
  }

  private updateState(): Observable<IDefaultOrderState> {
    return this.actionListener(['selectPosition', 'updateGridData', 'updateState']);
  }

  /* UTILITY start */
  private preUpdateCell(position: IDefaultOrderState['selectedPos']) {
    const state = this.stream$.getValue();
    if (position && state.defaultOrderGridData) {
      state.defaultOrderGridData = state.defaultOrderGridData?.map((el) =>
        el.id === position.id ? position : el,
      );
      this.stream$.next({ ...state, action: 'updateState' });
    }
  }
  private prepareDaySettings(
    daySettings: ExtendedDefaultOrderGridDataPos['mondaySettings'],
  ): DefaultOrderPositionWeekDaySettings {
    const copyDaySettings = structuredClone(daySettings);
    const { date: _, isArticleAvailable: __, ...rest } = copyDaySettings;
    return rest;
  }
  /* UTILITY end */
}

class DefaultOrderState extends State<IDefaultOrderState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const defaultOrderState = new DefaultOrderState(initDefaultOrderState);

export interface IDefaultOrderState {
  action:
    | 'init'
    | 'selectPosition'
    | 'list.selectedArticle'
    | 'fromDate'
    | 'updateGridData'
    | 'addPosition'
    | 'deletePosition'
    | 'updateState'
    | 'saveDefaultOrderGrid'
    | 'internalUpdate'
    | TypeCell
    | undefined;
  selectedPos: ExtendedDefaultOrderGridDataPos | null;
  defaultOrderGridData: IDefaultOrderGridData;
  defaultOrderGridDataBackup: IDefaultOrderGridData;
  articleId: Scalars['ID'] | null;
  fromDate: Date;
}

type TypeCell = 'nameCell' | 'simpleCell';
export type IDefaultOrderGridData = ExtendedDefaultOrderGridDataPos[];
