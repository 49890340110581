import { FC, useEffect, useMemo, useState } from 'react';
import { ITabState } from '../../../../../../states/tabState.model';
import { IGeneralTabState, generalTabState } from '../../../generalTab.state';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SpecialPricesPopup } from './popups/specialPrices/specialPrices.popup';
import { CopySpecialPricesPopup } from '../common/popups/copySpecialPrices/copySpecialPrices.popup';
import { ArticleGeneralTabDataRes } from '../../../../../../services/article.service';
import { ControlButtons } from './components/controlButtons.component';
import { STable } from '../common/styles/styles';
import { storageHelper } from '../../../../../../../../shared/helpers/storage';
import { isEnterKey } from '../../../../../../../../shared/helpers/utils/utils.helper';
import { viewRows } from './views/viewRows';
import { Column } from '../../../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../../../shared/helpers/formatter/localeFormatter.helper';

export const CategoriesPrices: FC<ICategoriesPrices> = (props) => {
  const {
    categoriesPricesData,
    isEditMode,
    createSpecialPriceCategoriesInputs,
    currentArticleId,
    specialPriceCategoriesColumns,
  } = props;
  const { t } = useTranslation();
  const [selectPos, setSelectPos] = useState<IGeneralTabState['selectedSpecialPricesPos']>(
    categoriesPricesData?.[0],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { getValues, setValue } = useFormContext();
  const specialPricesPopup = useMemo(
    () =>
      new SpecialPricesPopup({
        createSpecialPriceCategoriesInputs:
          createSpecialPriceCategoriesInputs as ITabState['createSpecialPriceCategoriesInputs'],
      }),
    [],
  );
  const copySpecialPricesPopup = useMemo(() => new CopySpecialPricesPopup(), []);

  useEffect(() => {
    // on create/copy mode here we provide data to form
    if (!isEditMode) {
      const specialPricesForSave = categoriesPricesData
        ?.filter(({ fromDate, toDate }) => fromDate || toDate)
        ?.map(({ id: _id, isActual: _, ...rest }) => ({
          ...rest,
        })) as ArticleGeneralTabDataRes['articleSpecialPrices'];
      setValue('articleSpecialPrices', specialPricesForSave);
    }
  }, [categoriesPricesData, isEditMode]);

  useEffect(() => {
    const unsubState = generalTabState.sub
      .state()
      .subscribe(({ action, selectedSpecialPricesPos }) => {
        setSelectPos(selectedSpecialPricesPos);
        if (
          (action === 'updateSpecialPricesPos' ||
            action === 'deleteSpecialPricesPos' ||
            action === 'copySpecialPrices') &&
          isEditMode
        ) {
          generalTabState.pub.saveSpecialPricesGrid(getValues('id'));
        }
      });
    const subLoadingGrid = generalTabState.sub.specialPricesGridLoading().subscribe(setLoading);

    return () => {
      unsubState.unsubscribe();
      specialPricesPopup.stream.unsubscribe();
      subLoadingGrid.unsubscribe();
    };
  }, []);

  const priceArray = generatePriceArray();

  return (
    <>
      <ControlButtons
        {...{
          categoriesPricesData,
          selectPos,
          currentArticleId,
          copySpecialPricesPopup,
          specialPricesPopup,
        }}
      />
      <STable
        data={categoriesPricesData}
        initialState={{
          columns: storageHelper.local.getItem('article.specialPricesColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('article.specialPricesColumnModel', v)
        }
        rowSelectionModel={selectPos?.id || undefined}
        onRowClick={(v, e) => {
          generalTabState.pub.selectSpecialPricesPos(v.row);
          specialPricesPopup.stream.emit('open', {
            values: {
              row: v.row,
              field: (e.target as any).parentElement?.dataset?.field,
              allPricesData: categoriesPricesData,
            },
          });
        }}
        focusTabProps={{
          enabled: true,
          enabledFields: ['description', 'fromDate', 'isActual', 'toDate', ...priceArray],
        }}
        onCellKeyDown={({ row, field }, event) => {
          if (isEnterKey(event)) {
            generalTabState.pub.selectSpecialPricesPos(row);
            specialPricesPopup.stream.emit('open', {
              values: { row, field, allPricesData: categoriesPricesData },
            });
          }
        }}
        height={400}
        {...{ viewRows, loading }}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        {specialPriceCategoriesColumns}
        <Column field='description' headerName={t('common.note')} sortable={false} width={200} />
      </STable>
      <specialPricesPopup.Component />
      <copySpecialPricesPopup.Component />
    </>
  );
};

function generatePriceArray() {
  const prices = [];
  for (let i = 1; i <= 10; i++) {
    prices.push(`price${i}`);
  }
  return prices;
}

interface ICategoriesPrices {
  categoriesPricesData: ITabState['articleSpecialPrices'];
  createSpecialPriceCategoriesInputs: ITabState['createSpecialPriceCategoriesInputs'];
  isEditMode: boolean;
  currentArticleId: string;
  specialPriceCategoriesColumns: ITabState['specialPriceCategoriesColumns'];
}
