import { map, Observable, of, switchMap, take, tap, zip, filter } from 'rxjs';

import { IStream, Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  CopyWa_ProductionPrintListMutation,
  CopyWa_ProductionPrintListMutationVariables,
  CreateWa_ProductionPrintListMutation,
  CreateWa_ProductionPrintListMutationVariables,
  DeleteWa_ProductionPrintListMutation as DeleteProductionPrintListMutation,
  DeleteWa_ProductionPrintListMutationVariables as DeleteProductionPrintListMutationVariables,
  GetProductionPrintListDataQuery as ProductionPrintListDataQuery,
  GetProductionPrintListDataQueryVariables as ProductionPrintListDataQueryVariable,
  GetCrosstableConfigurationDataQuery as CrosstableConfigurationDataQuery,
  GetCrosstableConfigurationDataQueryVariables as CrosstableConfigurationDataQueryVariables,
  ListWa_ProductionPrintListsQuery as ProductionPrintListsQuery,
  ListWa_ProductionPrintListsQueryVariables as ProductionPrintListsQueryVariables,
  SaveProductionEditPrintListDataMutation,
  SaveProductionEditPrintListDataMutationVariables,
} from '../../../graphql/generatedModel.ts';
import {
  copyProductionPrintList,
  createProductionPrintList,
  deleteProductionPrintList,
  getProductionEditList,
  getProductionPrintListData,
  getCrosstableConfigurationData,
  saveProductionEditPrintListData,
} from './gql/editPrintList.gql.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import { extractGeneralTabData } from '../editPrintList/components/tabs/general/utils/generalTab.utils.ts';
import { modeService } from '../../../shared/services/mode/mode.service.ts';
import { editPrintListState } from '../editPrintList/states/editPrintList.state.ts';

type Actions =
  | 'editPrintList'
  | 'deleteEditPrintList'
  | 'editPrintListGeneralData'
  | 'crosstableConfigurationData'
  | 'saveEditPrintListData'
  | 'updateInitialGeneralTabData'
  | 'createPrintList'
  | 'copyPrintList';

class PubImpl extends Pub<Actions> {
  editPrintList(params: ProductionPrintListsQueryVariables & { cache: boolean }): void {
    this.emit('editPrintList', params);
  }
  deleteEditPrintList(params: DeleteProductionPrintListMutationVariables) {
    this.emit('deleteEditPrintList', params);
  }
  editPrintListGeneralData(params: ProductionPrintListDataQueryVariable): void {
    this.emit('editPrintListGeneralData', params);
  }
  saveEditPrintListData(params: SaveProductionEditPrintListDataMutationVariables): void {
    this.emit('saveEditPrintListData', this.transformCrosstableConfigurationData(params));
  }
  updateInitialGeneralTabData(params: Partial<EditPrintListGeneralTabRes>): void {
    this.emit('updateInitialGeneralTabData', params);
  }
  crosstableConfigurationData(params: CrosstableConfigurationDataQueryVariables): void {
    this.emit('crosstableConfigurationData', params);
  }
  createPrintList(params: CreateWa_ProductionPrintListMutationVariables): void {
    this.emit('createPrintList', this.transformCrosstableConfigurationData(params));
  }
  copyPrintList(params: CopyWa_ProductionPrintListMutationVariables) {
    this.emit('copyPrintList', this.transformCrosstableConfigurationData(params));
  }

  private transformCrosstableConfigurationData(
    variables:
      | SaveProductionEditPrintListDataMutationVariables
      | CreateWa_ProductionPrintListMutationVariables
      | CopyWa_ProductionPrintListMutationVariables,
  ) {
    if ('dataToSave' in variables) {
      if (variables.dataToSave?.crosstableEasyModeSplitNoFilter === '0') {
        variables.dataToSave.crosstableEasyModeSplitNoFilter = null;
      }
    } else if ('data' in variables) {
      if (variables.data?.crosstableEasyModeSplitNoFilter === '0') {
        variables.data.crosstableEasyModeSplitNoFilter = null;
      }
    }
    return variables;
  }
}

class SubImpl extends Sub<Actions> {
  public initialGeneralTabPrintListData: EditPrintListGeneralTabRes | undefined;

  editPrintList(): Observable<EditPrintListRes> {
    return this.actionListener('editPrintList').pipe(
      switchMap(({ params: { filter, cache } }) => {
        if (cache) {
          const list = storageHelper.memory.getItem('editPrintList.list');
          if (list) {
            return of(list);
          }
        }

        return gqlClient(getProductionEditList, { filter }).pipe(
          map((data) => {
            const list = data.wawiAssist?.listWA_ProductionPrintLists;
            storageHelper.memory.setItem('editPrintList.list', list);
            return list;
          }),
        ) as Observable<ProductionPrintListsQuery>;
      }),
    );
  }
  deleteEditPrintList(): Observable<DeleteEditPrintListRes> {
    return this.actionListener('deleteEditPrintList').pipe(
      take(1),
      switchMap(({ params }) => {
        return zip(
          gqlClient(
            deleteProductionPrintList,
            params,
          ) as Observable<DeleteProductionPrintListMutation>,
          of(params),
        );
      }),
      map(([data, { id }]) => {
        return {
          status: data?.wawiAssist?.deleteWA_ProductionPrintList,
          id,
        } as DeleteEditPrintListRes;
      }),
    );
  }
  editPrintListGeneralData(): Observable<EditPrintListGeneralTabRes> {
    return this.actionListener('editPrintListGeneralData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getProductionPrintListData,
          params,
        ) as Observable<ProductionPrintListDataQuery>;
      }),
      map((data: ProductionPrintListDataQuery) => {
        const queryData = data?.wawiAssist?.getProductionPrintListData;
        const printListData: EditPrintListGeneralTabResDefault =
          this.transformCrosstableConfigurationData(queryData as EditPrintListGeneralTabRes);
        this.initialGeneralTabPrintListData = printListData;
        return extractGeneralTabData(printListData) as EditPrintListGeneralTabRes;
      }),
    );
  }
  crosstableConfigurationData(): Observable<CrosstableConfigurationDataRes> {
    return this.actionListener('crosstableConfigurationData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getCrosstableConfigurationData,
          params,
        ) as Observable<CrosstableConfigurationDataQuery>;
      }),
      map((data: CrosstableConfigurationDataQuery) => {
        return data?.wawiAssist?.getProductionPrintListData as CrosstableConfigurationDataRes;
      }),
    );
  }
  createPrintList(): Observable<CreateOrCopyPrintListRes> {
    return this.actionListener('createPrintList').pipe(
      switchMap(({ params }) => {
        const { data } = params || {};
        return gqlClient(createProductionPrintList, {
          data,
        }) as Observable<CreateWa_ProductionPrintListMutation>;
      }),
      map((res: CreateWa_ProductionPrintListMutation) => {
        const data = res.wawiAssist?.createWA_ProductionPrintList;
        this.switchToEditModeAndAddPrintList(data);
        return data as CreateOrCopyPrintListRes;
      }),
    );
  }
  copyPrintList(): Observable<CreateOrCopyPrintListRes> {
    return this.actionListener('copyPrintList').pipe(
      switchMap(({ params }) => {
        const { originalId, data } = params as CopyWa_ProductionPrintListMutationVariables;

        return gqlClient(copyProductionPrintList, {
          originalId,
          data,
        }) as Observable<CopyWa_ProductionPrintListMutation>;
      }),
      map((res: CopyWa_ProductionPrintListMutation) => {
        const data = res.wawiAssist?.copyWA_ProductionPrintList;
        this.switchToEditModeAndAddPrintList(data);
        return data as CreateOrCopyPrintListRes;
      }),
    );
  }
  saveEditPrintListData(): Observable<SaveProductionEditPrintListDataRes> {
    return this.actionListener('saveEditPrintListData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveProductionEditPrintListData,
          params,
        ) as Observable<SaveProductionEditPrintListDataMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveProductionPrintListData as SaveProductionEditPrintListDataRes;
      }),
    );
  }
  updateInitialGeneralTabData(): Observable<IStream<Actions>> {
    return this.actionListener('updateInitialGeneralTabData').pipe(
      tap(({ params }) => {
        this.initialGeneralTabPrintListData = {
          ...this.initialGeneralTabPrintListData,
          ...params,
        };
      }),
    );
  }

  private switchToEditModeAndAddPrintList(data: EditPrintListRes[number] | undefined) {
    modeService.pub.mode('edit');
    modeService.sub
      .mode()
      .pipe(
        filter((v) => v === 'edit'),
        take(1),
      )
      .subscribe((v) => {
        if (v === 'edit') editPrintListState.pub.addEditPrintList(data as EditPrintListRes[number]);
      });
  }

  public transformCrosstableConfigurationData(queryData: EditPrintListGeneralTabRes) {
    return {
      ...queryData,
      crosstableEasyModeSplitNoFilter: queryData?.crosstableEasyModeSplitNoFilter || '0',
      crosstableConfigurationData:
        queryData?.crosstableConfigurationData?.map((setting) => ({
          ...setting,
          customerIndicator: setting.categoryCustomerId
            ? '0'
            : setting.categoryCustomerListId
            ? '1'
            : '0',
        })) || [],
    };
  }
}

class EditPrintListService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const editPrintListService = new EditPrintListService();

export type EditPrintListRes = NonNullable<
  NonNullable<ProductionPrintListsQuery['wawiAssist']>['listWA_ProductionPrintLists']
>;
export interface DeleteEditPrintListRes {
  status: NonNullable<
    NonNullable<DeleteProductionPrintListMutation['wawiAssist']>['deleteWA_ProductionPrintList']
  >;
  id: string;
}
export type EditPrintListGeneralTabResDefault = NonNullable<
  NonNullable<ProductionPrintListDataQuery['wawiAssist']>['getProductionPrintListData']
>;
export type EditPrintListGeneralTabRes = NonNullable<
  Omit<
    NonNullable<ProductionPrintListDataQuery['wawiAssist']>['getProductionPrintListData'],
    'crosstableConfigurationData'
  > & {
    crosstableConfigurationData: Array<
      NonNullable<
        ProductionPrintListDataQuery['wawiAssist']
      >['getProductionPrintListData']['crosstableConfigurationData'][number] & {
        customerIndicator?: '0' | '1' | null;
        id?: string;
      }
    >;
  }
>;
export type CrosstableConfigurationDataRes = NonNullable<
  NonNullable<CrosstableConfigurationDataQuery['wawiAssist']>['getProductionPrintListData']
>;
export type SaveProductionEditPrintListDataRes = NonNullable<
  NonNullable<
    NonNullable<
      SaveProductionEditPrintListDataMutation['wawiAssist']
    >['saveProductionPrintListData']
  >
>;
export type CreateOrCopyPrintListRes = NonNullable<
  NonNullable<CreateWa_ProductionPrintListMutation['wawiAssist']>['createWA_ProductionPrintList']
>;
