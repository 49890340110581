import { TypeTable } from '../../../../../../../../shared/components/table/table.component.tsx';
import { Theme as theme } from '../../../../../../../../shared/styles/theme/theme.ts';

export const viewRows: TypeTable['viewRows'] = {
  action: ({ row }) => {
    if (row.isDuplicate) {
      return 'isDuplicate';
    }
    if (row.isRecordWhichOverwrites) {
      return 'isRecordWhichOverwrites';
    }
    return '';
  },
  styles: [
    {
      className: 'isRecordWhichOverwrites',
      bgColor: theme.palette.blue.light,
      bgColorHover: theme.palette.blue.main,
    },
    {
      className: 'isDuplicate',
      bgColor: theme.palette.orange.light,
      bgColorHover: theme.palette.orange.main,
    },
  ],
};
