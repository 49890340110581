import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  SvgIcon,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Children, FC, MouseEventHandler, ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BehaviorSubject, withLatestFrom } from 'rxjs';
import { navBlocker } from '../blocker/nav.blocker.ts';
import { NavContext } from '../nav.context';
import { navService } from '../nav.service';
import { Theme } from '../../../styles/theme/theme.ts';

const isParentNavActive = (pathname: string, to: string): boolean => {
  const getBasePath = (path: string): string | null => {
    const segments = path.split('/').filter(Boolean);
    return segments.length > 0 ? segments[0] : null;
  };
  return getBasePath(to) === getBasePath(pathname);
};

export const NavLink: FC<INavLink> = ({ icon: Icon, label, to, children }) => {
  const { pathname } = useLocation();
  const [navActive, setNavActive] = useState<boolean>(isParentNavActive(pathname, to));
  const [expandNav, setExpandNav] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const childNav = Boolean(Children.count(children));
  const nav$ = useMemo(
    () => new BehaviorSubject<{ navActive: boolean; expandNav: boolean }>({ navActive, expandNav }),
    [],
  );

  const navLinkClickHandle: MouseEventHandler<HTMLElement> = (evt) => {
    evt.stopPropagation();
    if (childNav) {
      if (!open) {
        navService.pub.openMenu();
      }
      setExpandNav(!expandNav);
    } else if (!navActive) {
      navBlocker.controlledNavigate(() => {
        navService.pub.navigateTo(to);
        setNavActive(true);
      });
      navService.pub.closeMenu();
    }
  };
  useEffect(() => {
    if (isParentNavActive(pathname, to)) {
      navBlocker.controlledNavigate(() => {
        setNavActive(true);
      });
    } else {
      setNavActive(false);
    }
  }, [pathname]);

  useEffect(() => {
    nav$.next({ navActive, expandNav });
  }, [navActive, expandNav]);

  useEffect(() => {
    const navigateToSub = navService.sub.navigateTo().subscribe((userActionTo) => {
      if (!isParentNavActive(userActionTo, to)) {
        setNavActive(false);
      }
    });
    const closeMenuSub = navService.sub
      .closeMenu()
      .pipe(withLatestFrom(nav$))
      .subscribe(([menu, props]) => {
        setOpen(menu);
        if (childNav && props.expandNav) {
          setExpandNav(false);
        }
      });
    const openMenuSub = navService.sub
      .openMenu()
      .pipe(withLatestFrom(nav$))
      .subscribe(([menu, props]) => {
        const { state, isBurger } = menu;
        setOpen(state);
        setShowTooltip(false);
        if (isBurger && childNav && props.navActive) {
          setExpandNav(true);
        }
      });
    return () => {
      navigateToSub.unsubscribe();
      closeMenuSub.unsubscribe();
      openMenuSub.unsubscribe();
      nav$.unsubscribe();
    };
  }, []);

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <STooltip
        title={label}
        open={showTooltip}
        disableHoverListener
        disableFocusListener
        disableTouchListener
        placement='right'
      >
        <SListItemButton
          onMouseEnter={() => {
            if (open || navActive) {
              setShowTooltip(false);
            } else {
              setShowTooltip(true);
            }
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
          hover={open ? 'true' : 'false'}
          disableRipple
          onClick={navLinkClickHandle}
          selected={open ? navActive : false}
        >
          <SListItemIcon
            opened={open ? 'true' : 'false'}
            active={open === false && navActive ? 'true' : 'false'}
            sx={{
              minWidth: '34px',
              height: '34px',
              justifyContent: 'center',
              alignItems: 'center',
              mr: '15px',
              ml: '-1px',
            }}
          >
            <Icon />
          </SListItemIcon>

          <ListItemText
            primary={
              <Typography fontSize={'1rem'} fontWeight={400}>
                {label}
              </Typography>
            }
          />
          {childNav ? (
            expandNav ? (
              <ExpandLess sx={{ color: Theme.palette.action.active }} />
            ) : (
              <ExpandMore sx={{ color: Theme.palette.action.active }} />
            )
          ) : null}
        </SListItemButton>
      </STooltip>
      {childNav && (
        <Collapse in={expandNav}>
          <List sx={{ padding: 0 }}>
            <NavContext.Provider value={{ setParentNavActive: setNavActive }}>
              {children}
            </NavContext.Provider>
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};

interface INavLink {
  icon: typeof SvgIcon;
  to: string;
  label: string;
  children?: ReactElement | ReactElement[];
}

const SListItemIcon = styled(ListItemIcon)<{ active: string; opened: string }>(
  ({ opened = 'false', active = 'false', theme }) => ({
    borderRadius: '50%',
    backgroundColor: active === 'true' ? theme.palette.action.focus : 'transparent',
    '&:hover': {
      backgroundColor:
        opened === 'true'
          ? 'transparent'
          : active === 'false'
          ? theme.palette.action.hover
          : theme.palette.action.focus,
    },
  }),
);
const SListItemButton = styled(ListItemButton)<{ hover: string }>(({ hover = 'false' }) => ({
  padding: '5px 11px',
  '&:hover': {
    ...(hover === 'false' && { backgroundColor: 'transparent' }),
  },
}));
const STooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.action.active,
    fontSize: 12,
  },
}));
