import FullSizeNavIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { LogoIcon as Logo } from '../../../../svg/custom.svg.tsx';

export const NavHeader: FC<INavHeader> = ({ open, handleNavOpen, handleNavClose }) => {
  return (
    <Stack direction='row' alignItems='center' sx={{ margin: '5px 0 8px' }}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ minWidth: (theme) => theme.nav.width, height: '46px' }}
      >
        <IconButton
          sx={{ width: '34px', height: '34px', ml: '-2px' }}
          onClick={(evt) => {
            if (open === true) {
              handleNavClose(evt);
            }
            if (open === false) {
              handleNavOpen(evt);
            }
          }}
          size='large'
        >
          <FullSizeNavIcon />
        </IconButton>
      </Box>
      <Stack direction='row' sx={{ width: '100%' }}>
        <SLogo />
        <Typography fontSize={18} variant='body2' sx={{ mx: 1, pt: 0.5 }}>
          <Typography fontWeight={700} component='strong'>
            WaWi
          </Typography>
          Assist
        </Typography>
      </Stack>
    </Stack>
  );
};
const SLogo = styled(Logo)(() => ({
  minWidth: '34px',
  height: '34px',
}));

interface INavHeader {
  open: boolean;
  handleNavOpen: (evt: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleNavClose: (
    evt: MouseEvent | TouchEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}
