import React, { FC, forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  MultiSectionDigitalClock,
  MultiSectionDigitalClockProps,
  TimeField,
  TimeFieldProps,
} from '@mui/x-date-pickers-pro';
import styled from '@mui/material/styles/styled';
import { Box, Fade, IconButton, Popover } from '@mui/material';
import { Subject } from 'rxjs';
import TimeIcon from '@mui/icons-material/AccessTime';
import { ControllerProps } from 'react-hook-form';
import equal from 'fast-deep-equal/react';
import { localeFormatterHelper } from '../../helpers/formatter/localeFormatter.helper.ts';

export const TimePicker: FC<ITimePicker> = forwardRef(
  (
    {
      width,
      onChange,
      onBlur,
      defaultValue,
      fieldProps = {},
      clockProps = {},
      label,
      value,
      errorText,
      disabled,
    },
    ref,
  ) => {
    const timeStream$ = useMemo(() => new Subject<Date | null>(), []);
    const [valueDate, setValueDate] = useState(defaultValue || null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const pickerRef = useRef(null);
    const isChanged = useRef(false);
    const disabledBlur = useRef<boolean>(false);
    const inputEl = useRef<HTMLInputElement | null>(null);
    const [focused, setFocused] = useState(false);

    const open = Boolean(anchorEl);

    useLayoutEffect(() => {
      const dateSub = timeStream$.subscribe((date) => {
        onChange?.(date);
      });
      return () => {
        dateSub.unsubscribe();
      };
    }, []);

    useLayoutEffect(() => {
      if (value !== undefined) setValueDate(value || null);
    }, [value]);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(pickerRef.current);
      const el = (e.target as HTMLInputElement).closest('button');
      const input = el?.previousElementSibling as HTMLInputElement;
      inputEl.current = input;
      const timer = setTimeout(() => {
        setFocused(true);
        disabledBlur.current = true;
        input.focus();
        clearTimeout(timer);
      }, 0);
    };

    const handleClose = () => {
      setAnchorEl(null);
      disabledBlur.current = true;
      const timer = setTimeout(() => {
        inputEl.current?.focus();
        disabledBlur.current = false;
        clearTimeout(timer);
      }, 0);
    };

    return (
      <Box ref={ref}>
        <SDatePicker ref={pickerRef} {...{ width, errorText }}>
          <TimeField
            InputLabelProps={{ shrink: true }}
            inputProps={{ p: 0 }}
            size='small'
            fullWidth
            onMouseEnter={() => {
              disabledBlur.current = true;
            }}
            onMouseLeave={() => {
              disabledBlur.current = false;
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClick} {...{ disabled }}>
                  <TimeIcon />
                </IconButton>
              ),
            }}
            value={valueDate && localeFormatterHelper.localizedDate(valueDate)}
            timezone='UTC'
            onBlur={() => {
              if (isChanged.current && !disabledBlur.current) {
                timeStream$.next(valueDate);
                onBlur?.(valueDate);
                isChanged.current = false;
              }
              if (!open && !disabledBlur.current) {
                setFocused(false);
              }
            }}
            onChange={(date, context) => {
              const notEqual = !equal(date, valueDate);
              if (!context.validationError && notEqual) {
                setValueDate(date ? localeFormatterHelper.localizedDate(date) : null);
                timeStream$.next(date);
                isChanged.current = true;
              }
            }}
            onClick={() => {
              setFocused(true);
            }}
            helperText={errorText}
            {...{ label, disabled, focused }}
            {...fieldProps}
          />
        </SDatePicker>
        <Popover
          disableAutoFocus
          disableEnforceFocus
          anchorEl={anchorEl}
          open={open}
          keepMounted
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            marginTop: 1,
          }}
        >
          <MultiSectionDigitalClock
            value={valueDate}
            onChange={(date, selectionState) => {
              date && setValueDate(date);
              if (!equal(date, valueDate)) {
                timeStream$.next(date);
                isChanged.current = true;
              }
              if (selectionState === 'finish' && date) {
                handleClose();
              }
            }}
            {...clockProps}
          />
        </Popover>
      </Box>
    );
  },
);

const SDatePicker = styled('div')<TTimePickerWrap>(({ width, errorText, theme }) => ({
  width: width || '100%',
  display: 'flex',
  '& .MuiInputBase-root': {
    paddingRight: 2,
  },
  ...(errorText && {
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
      color: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main}!important`,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
  }),
}));

export interface ITimePicker {
  width?: number | string;
  onChange?: (v: Date | null) => void;
  onBlur?: (v: Date | null) => void;
  fieldProps?: Omit<TimeFieldProps<Date>, 'defaultValue' | 'onChange' | 'label' | 'value'>;
  clockProps?: Omit<MultiSectionDigitalClockProps<Date>, 'defaultValue' | 'onChange'>;
  defaultValue?: Date | null;
  value?: Date | null;
  label?: string;
  errorText?: string;
  disabled?: boolean;
  name?: ControllerProps['name'];
}

type TTimePickerWrap = Pick<ITimePicker, 'width' | 'errorText'>;
