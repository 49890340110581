import { Grid, styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormNumericField,
  FormCheckbox,
  FormTextEditor,
  FormSelect,
} from '../../../../../../../../shared/components/form';
import { useFormContext } from 'react-hook-form';
import { getJSONValueByPath } from '../../../../../../../../shared/helpers/utils/utils.helper';
import { IReportAnyObjectTunerProps } from '../../reportsConfiguration.tab';
import { fontHelper } from '../../../../../../../../shared/helpers/font/font.helper';
import { useAlternativeSave } from './hooks/useAlternativeSave';
import { FontStyleToggle } from './components/fontStyleToggle.component';
import { environment } from '../../../../../../../../environment';

export const ReportObjectVisibilityTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  //reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <SFirstGridItem item xs={12}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </SFirstGridItem>
    </Grid>
  );
};

export const ReportObjectTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(reportDefaultValuesPath);

  return (
    <Grid container spacing={2}>
      <SFirstGridItem item xs={12}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </SFirstGridItem>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.left`}
          placeholder={defaultValues?.[reportObjectName]?.['left']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.top')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.top`}
          placeholder={defaultValues?.[reportObjectName]?.['top']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.height`}
          placeholder={defaultValues?.[reportObjectName]?.['height']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.height`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.width`}
          placeholder={defaultValues?.[reportObjectName]?.['width']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.width`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
    </Grid>
  );
};

export const ReportFooterObjectTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(reportDefaultValuesPath);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.left`}
          placeholder={defaultValues?.[reportObjectName]?.['left']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.height`}
          placeholder={defaultValues?.[reportObjectName]?.['height']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.height`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.width`}
          placeholder={defaultValues?.[reportObjectName]?.['width']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.width`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
    </Grid>
  );
};

export const ReportObjectWithFormattedTextTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(`${reportDefaultValuesPath}.${reportObjectName}`);
  const path = `${reportActualValuesPath}.${reportObjectName}`;
  const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
  const alternativeSave = useAlternativeSave();
  const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;

  return (
    <Grid container spacing={2}>
      <SFirstGridItem item xs={12}>
        <FormCheckbox label={t('settings.visible')} name={`${path}.isVisible`} />
      </SFirstGridItem>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${path}.left`}
          placeholder={defaultValues?.['left']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.left`))}
          helperText={
            getJSONValueByPath(errors, `${path}.left`)
              ? t(String(getJSONValueByPath(errors, `${path}.left`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.top')} ${t('settings.cm_brackets')}`}
          name={`${path}.top`}
          placeholder={defaultValues?.['top']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.top`))}
          helperText={
            getJSONValueByPath(errors, `${path}.top`)
              ? t(String(getJSONValueByPath(errors, `${path}.top`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${path}.height`}
          placeholder={defaultValues?.['height']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.height`))}
          helperText={
            getJSONValueByPath(errors, `${path}.height`)
              ? t(String(getJSONValueByPath(errors, `${path}.height`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${path}.width`}
          placeholder={defaultValues?.['width']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.width`))}
          helperText={
            getJSONValueByPath(errors, `${path}.width`)
              ? t(String(getJSONValueByPath(errors, `${path}.width`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      {appEnv === 'development' && (
        <Grid item xs={12}>
          <FormTextEditor
            name={`${path}.formattedText`}
            onBlur={() => alternativeSave(`${path}.formattedText`)}
            defaultFormat={defaultFontSize}
            toolbarItems={['bold', 'italic', 'underline', 'size', 'color', 'alignment']}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const ReportFooterObjectWithFormattedTextTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(reportDefaultValuesPath);
  const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
  const alternativeSave = useAlternativeSave();
  const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.left`}
          placeholder={defaultValues?.[reportObjectName]?.['left']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.height`}
          placeholder={defaultValues?.[reportObjectName]?.['height']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.height`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.width`}
          placeholder={defaultValues?.[reportObjectName]?.['width']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.width`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      {appEnv === 'development' && (
        <Grid item xs={12}>
          <FormTextEditor
            name={`${reportActualValuesPath}.${reportObjectName}.formattedText`}
            onBlur={() =>
              alternativeSave(`${reportActualValuesPath}.${reportObjectName}.formattedText`)
            }
            defaultFormat={defaultFontSize}
            toolbarItems={['bold', 'italic', 'underline', 'size', 'color', 'alignment']}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const ReportObjectWithCustomFontTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(reportDefaultValuesPath);
  const options = useMemo(
    () => [
      {
        id: 'Arial',
        label: 'Arial',
      },
      {
        id: 'Calibri',
        label: 'Calibri',
      },
      {
        id: 'Courier New',
        label: 'Courier New',
      },
      {
        id: 'Georgia',
        label: 'Georgia',
      },
      {
        id: 'Tahoma',
        label: 'Tahoma',
      },
      {
        id: 'Times New Roman',
        label: 'Times New Roman',
      },
      {
        id: 'Verdana',
        label: 'Verdana',
      },
    ],
    [],
  );

  return (
    <Grid container spacing={2}>
      <SFirstGridItem item xs={12}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </SFirstGridItem>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.left`}
          placeholder={defaultValues?.[reportObjectName]?.['left']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.top')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.top`}
          placeholder={defaultValues?.[reportObjectName]?.['top']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.height`}
          placeholder={defaultValues?.[reportObjectName]?.['height']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.height`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.width`}
          placeholder={defaultValues?.[reportObjectName]?.['width']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.width`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={4.79}>
        <FormSelect
          name={`${reportActualValuesPath}.${reportObjectName}.fontOverride.family`}
          {...{ options }}
          label={t('common.font')}
          placeholder={defaultValues?.[reportObjectName]?.['fontOverride']?.['family']?.toString()}
          error={Boolean(
            getJSONValueByPath(
              errors,
              `${reportActualValuesPath}.${reportObjectName}.fontOverride.family`,
            ),
          )}
          helperText={
            getJSONValueByPath(
              errors,
              `${reportActualValuesPath}.${reportObjectName}.fontOverride.family`,
            )
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.fontOverride.family`,
                    ).message,
                  ),
                )
              : ''
          }
          disableClearable
          popperWidth='190px'
        />
      </Grid>
      <Grid item xs={3}>
        <FormNumericField
          label={t('settings.size')}
          name={`${reportActualValuesPath}.${reportObjectName}.fontOverride.size`}
          placeholder={defaultValues?.[reportObjectName]?.['fontOverride']?.['size']?.toString()}
          error={Boolean(
            getJSONValueByPath(
              errors,
              `${reportActualValuesPath}.${reportObjectName}.fontOverride.size`,
            ),
          )}
          helperText={
            getJSONValueByPath(
              errors,
              `${reportActualValuesPath}.${reportObjectName}.fontOverride.size`,
            )
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.fontOverride.size`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={1}
          min={1}
          max={50}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={3.65}>
        <FontStyleToggle
          prefix={`${reportActualValuesPath}.${reportObjectName}.fontOverride.style`}
        />
      </Grid>
    </Grid>
  );
};

export const SFirstGridItem = styled(Grid)({
  paddingTop: '0 !important',
});
