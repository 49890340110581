import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  PaperProps,
  styled,
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const SelectMenuWithFooter: FC<ISelectMenuWithFooterProps> = ({
  children,
  href,
  additionalClickFn,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper {...props}>
      {children}
      <Divider />
      <Link
        onMouseDown={(e) => {
          e.stopPropagation();
          href && (href.startsWith('http') ? (window.location.href = href) : navigate(href));
          additionalClickFn?.();
        }}
        {...(href && { href })}
        underline='hover'
        color='inherit'
        width='100%'
      >
        <List disablePadding>
          <SMenutItem>
            <ListItemIcon>
              <Settings fill='red' />
            </ListItemIcon>
            <ListItemText>{t('common.configure')}</ListItemText>
          </SMenutItem>
        </List>
      </Link>
    </Paper>
  );
};

const SMenutItem = styled(MenuItem)(({ theme }) => ({
  background: theme.palette.grey[50],
  flexWrap: 'wrap',
}));

interface ISelectMenuWithFooterProps extends PaperProps {
  href?: string; // react-router-dom path
  additionalClickFn?: () => void;
}
