import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from '@mui/material';
import { MouseEventHandler } from 'react';

export const TableHeader: FC<ITableHeader> = ({
  icon,
  title,
  isAllDataShown,
  dataLength,
  isRecordsWhichOverwrites,
  isDuplicates,
  shownDataLength,
  importButtonAvailable,
  onImport,
}) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection='row' justifyContent='space-between'>
      <Stack flexDirection='row' mb='8px'>
        {icon}
        <Typography
          marginTop='8px'
          marginLeft='8px'
          variant='h4'
          fontSize='18px'
          fontWeight='500'
          lineHeight='21.94px'
        >
          {title}
        </Typography>
        {!isAllDataShown && (
          <Typography
            marginTop='10px'
            marginLeft='8px'
            variant='h4'
            fontSize='12px'
            fontWeight='400'
            lineHeight='21.94px'
          >
            {`(${t('common.shown')}: ${shownDataLength}/${dataLength})`}
          </Typography>
        )}
        {isRecordsWhichOverwrites && (
          <Alert
            severity='info'
            sx={{
              marginLeft: '8px',
              height: 36,
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            {t('settings.some_existing_records_will_be_overwrited')}
          </Alert>
        )}
        {isDuplicates && (
          <Alert
            severity='warning'
            sx={{
              marginLeft: '8px',
              height: 36,
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            {t('settings.there_are_some_duplicates_in_import_data')}
          </Alert>
        )}
      </Stack>
      <Button
        sx={{ minWidth: '100px', marginTop: '8px', marginBottom: '8px' }}
        variant='contained'
        color='primary'
        size='small'
        disabled={!importButtonAvailable}
        onClick={onImport}
      >
        {t('debitor.paymentsDetailsInfo.import')}
      </Button>
    </Stack>
  );
};

interface ITableHeader {
  icon: JSX.Element;
  title: string;
  dataLength?: number;
  shownDataLength?: number;
  isAllDataShown: boolean;
  isRecordsWhichOverwrites: boolean;
  isDuplicates: boolean;
  onImport: MouseEventHandler<HTMLButtonElement> | undefined;
  importButtonAvailable: boolean;
}
