import { TCompanyConfigs } from '../../../../shared/services/companyConfig/companyConfig.service';
import {
  ReportsConfigurationRes,
  DataForReportsConfigurationPreviewRes,
} from '../services/printForms.service';

export enum PrintFormsTabs {
  general = 'general',
  reportConfiguration = 'report-configuration',
}

export interface ITabState {
  companyNameInReports: TCompanyConfigs['companyNameInReports'];
  inputDataForReportsPreview: DataForReportsConfigurationPreviewRes;
  reportConfiguration: ReportsConfigurationRes;
}
