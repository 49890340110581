/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { TPopupDefaultActions } from '../../../../../../../../../../../shared/components/popup/services/popup.service.ts';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useFormContext } from 'react-hook-form';
import { ITabState } from '../../../../../../../../../states/tabState.model.ts';
import { IResellerPrices } from '../../resellerPrices.component.tsx';
import { ResellerPricesForm } from './resellerPrices.form.tsx';

export class ResellerPricesPopup extends Popup<IResellerPricesPopup> {
  Component: FC<TPopupComponent>;
  productListOptions: ITabState['conditionsTabOptions']['productList'];
  constructor(params: { productListOptions: IResellerPrices['productListOptions'] }) {
    super();
    const stream = this.innerStream;
    this.productListOptions = params.productListOptions;
    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 700 },
        }));
      }, []);
      const { getValues } = useFormContext();
      const [defaultValues, setDefaultValues] = useState<TDefaultValues>({
        id: '',
        articleId: getValues().id,
        price: null,
        comment: null,
      });
      const [field, setField] = useState('');
      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values?.row) {
            setDefaultValues(values.row);
          }
          if (values?.field) setField(values.field);
        });
        stream.actionListener('close').subscribe(() => {
          setDefaultValues({
            id: '',
            articleId: getValues().id,
            price: null,
            comment: null,
          });
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.resellerPrices')}>
          <ResellerPricesForm
            productListOptions={this.productListOptions}
            customerId={getValues().id}
            defaultValues={defaultValues}
            {...{ field, stream }}
          />
        </SWrapper>
      );
    };
  }
}

export interface IResellerPricesPopup {
  action: 'save' | TPopupDefaultActions;
  values?: { row: GridRowParams['row']; field?: string };
}

export type TDefaultValues = ITabState['customerResellerPrices'][number];
