import { FC, useEffect, useMemo, useState } from 'react';
import { ITabState } from '../../../../../../../states/tabState.model';
import { useTranslation } from 'react-i18next';
import { IConditionsTabState, conditionsTabState } from '../../../../conditions.state';
import { useFormContext } from 'react-hook-form';
import { Table } from '../../../../../../../../../shared/components/table/table.component';
import { styled } from '@mui/material';
import { Column } from '../../../../../../../../../shared/components/table/components/common.components';
import { storageHelper } from '../../../../../../../../../shared/helpers/storage';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper';
import { localeFormatterHelper } from '../../../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { ResellerPricesPopup } from './popups/resellerPrices/resellerPrices.popup';
import { CopyResellerPricesPopup } from './popups/copyResellerPrices/copyResellerPrices.popup';
import { ControlButtons } from './components/controlButtons.components';

export const ResellerPrices: FC<IResellerPrices> = ({ productListOptions, resellerPricesData }) => {
  const { t } = useTranslation();
  const [selectPos, setSelectPos] =
    useState<IConditionsTabState['selectedResellerPricesPos']>(null);
  const [loading, setLoading] = useState(false);
  const { getValues } = useFormContext();
  const resellerPricesPopup = useMemo(
    () =>
      new ResellerPricesPopup({
        productListOptions,
      }),
    [],
  );
  const copyResellerPricesPopup = useMemo(() => new CopyResellerPricesPopup(), []);

  useEffect(() => {
    const unsubState = conditionsTabState.sub
      .state()
      .subscribe(({ action, selectedResellerPricesPos }) => {
        setSelectPos(selectedResellerPricesPos);
        if (
          action === 'updateResellerPricesPos' ||
          action === 'deleteResellerPricesPos' ||
          action === 'copyResellerPrices'
        ) {
          conditionsTabState.pub.saveResellerPricesGrid(getValues('id'));
        }
      });
    const subLoadingGrid = conditionsTabState.sub.resellerPricesGridLoading().subscribe(setLoading);

    return () => {
      unsubState.unsubscribe();
      resellerPricesPopup.stream.unsubscribe();
      subLoadingGrid.unsubscribe();
    };
  }, []);

  return (
    <>
      <ControlButtons
        {...{ selectPos, resellerPricesData, copyResellerPricesPopup, resellerPricesPopup }}
      />
      <STable
        data={resellerPricesData}
        initialState={{
          columns: storageHelper.local.getItem('customer.resellerPricesColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('customer.resellerPricesColumnModel', v)
        }
        rowSelectionModel={selectPos?.id || undefined}
        onRowClick={(v, e) => {
          conditionsTabState.pub.selectResellerPricesPos(v.row);
          resellerPricesPopup.stream.emit('open', {
            values: { row: v.row, field: (e.target as any).parentElement?.dataset?.field },
          });
        }}
        focusTabProps={{
          enabled: true,
          enabledFields: ['description', 'price', 'comment'],
        }}
        onCellKeyDown={({ row, field }, event) => {
          if (isEnterKey(event)) {
            conditionsTabState.pub.selectResellerPricesPos(row);
            resellerPricesPopup.stream.emit('open', {
              values: { row, field },
            });
          }
        }}
        height={400}
        {...{ loading }}
      >
        <Column
          field='description'
          headerName={t('customer.article')}
          valueGetter={({ value, row }) => row.articleNo && `${row.articleNo} ${value}`}
          sortable={false}
          width={450}
        />
        <Column field='comment' headerName={t('customer.comment')} sortable={false} flex={1} />
        <Column
          field='price'
          headerName={t('common.price')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
      </STable>
      <resellerPricesPopup.Component />
      <copyResellerPricesPopup.Component />
    </>
  );
};

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

export interface IResellerPrices {
  resellerPricesData: ITabState['customerResellerPrices'];
  productListOptions: ITabState['conditionsTabOptions']['productList'];
}
