import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
/* Icons */
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';
import { Button, FormLabel, RadioGroup, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumericField, Radio } from '../../../../../../../shared/components/form';
import Grid from '@mui/material/Grid/Grid';
import styled from '@mui/material/styles/styled';
import { IRadio } from '../../../../../../../shared/components/form/fields/radio.component.tsx';
import { DatePicker } from '../../../../../../../shared/components/datePicker/datePicker.component.tsx';
import { format, sub } from 'date-fns';
import { orderDetailsState } from '../../../../states/orderDetails/orderDetails.state.ts';
import {
  C_New_Order_Selection_Mode,
  Scalars,
} from '../../../../../../../graphql/generatedModel.ts';
import { customerListState, ISharedValues } from '../../../../states/customerList.state.ts';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';

export const Content: FC<IContent> = ({ stream }) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string>();
  const [customerValues, setCustomerValues] = useState<ISharedValues>();

  useLayoutEffect(() => {
    const customerValueSub = customerListState.sub.sharedValues().subscribe((v) => {
      const { draftOrderId, offerId, ...customer } = v.customer!;
      setCustomerValues({ ...v, customer });
    });
    return () => customerValueSub.unsubscribe();
  }, []);
  const [outputArgs, setOutputArgs] = useState<IOutputArgs | null>(null);

  const daysOfTheWeek: IRadio[] = useMemo(
    () => [
      { label: t('common.monday'), value: 'daysOfTheWeek_1' },
      { label: t('common.tuesday'), value: 'daysOfTheWeek_2' },
      { label: t('common.wednesday'), value: 'daysOfTheWeek_3' },
      { label: t('common.thursday'), value: 'daysOfTheWeek_4' },
      { label: t('common.friday'), value: 'daysOfTheWeek_5' },
      { label: t('common.saturday'), value: 'daysOfTheWeek_6' },
      { label: t('common.sunday'), value: 'daysOfTheWeek_0' },
    ],
    [],
  );

  const dateVariant: IRadio[] = useMemo(
    () => [
      { label: t('common.weekAgo'), value: 'dateVariant_1' },
      { label: t('common.twoWeeksAgo'), value: 'dateVariant_2' },
      { label: t('common.threeWeeksAgo'), value: 'dateVariant_3' },
      { label: t('order.fromDate'), value: 'dateVariant_date' },
    ],
    [],
  );

  const deliveryVariant: IRadio[] = useMemo(
    () => [
      { label: t('order.last_delivery_note'), value: 'deliveryVariant_lastDeliveryNote' },
      { label: t('common.delivery_no'), value: 'deliveryVariant_deliveryNo' },
    ],
    [],
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = e.target.value;
    const currDate = localeFormatterHelper.localizedDate(customerValues?.date);
    setCurrentValue(radioValue);
    if (radioValue === 'deliveryVariant_deliveryNo') {
      setOutputArgs(null);
      return;
    }
    const [radioType, value] = radioValue.split('_');
    if (radioType === 'daysOfTheWeek') {
      const curWeekDay = currDate.getDay();
      const delta = Number(value) - curWeekDay;
      setOutputArgs({
        date: format(currDate.setDate(currDate.getDate() + delta), 'yyyy-MM-dd'),
        mode: C_New_Order_Selection_Mode.DEFAULT_ORDER_OF_CUSTOMER,
      });
    }
    if (radioType === 'dateVariant') {
      const updatedDate =
        radioValue === 'dateVariant_date' ? currDate : sub(currDate, { weeks: Number(value) });
      setOutputArgs({
        sourceOrderDate: format(updatedDate, 'yyyy-MM-dd'),
        mode: C_New_Order_Selection_Mode.FROM_ORDER_ON_CHOSEN_DATE,
      });
    }
    if (radioType === 'deliveryVariant' && value === 'lastDeliveryNote') {
      setOutputArgs({ mode: C_New_Order_Selection_Mode.FROM_LAST_ORDER });
    }
  };

  return (
    <>
      <RadioGroup onChange={handleChange}>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={4}>
            <FormLabel>{t('order.default_order')}</FormLabel>
            <SStack>
              {daysOfTheWeek.map((props, i) => (
                <Radio key={i} {...props} />
              ))}
            </SStack>
          </Grid>
          <Grid item xs={4}>
            <FormLabel>{t('common.date')}</FormLabel>
            <SStack>
              {dateVariant.map((props, i) => (
                <Radio key={i} {...props} />
              ))}
            </SStack>
            {currentValue === 'dateVariant_date' && (
              <DatePicker
                onChange={(v) => {
                  setOutputArgs({
                    sourceOrderDate: format(v!, 'yyyy-MM-dd'),
                    mode: C_New_Order_Selection_Mode.FROM_ORDER_ON_CHOSEN_DATE,
                  });
                }}
                defaultValue={localeFormatterHelper.localizedDate(customerValues?.date)}
                navigation={false}
                width='90%'
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <FormLabel>{t('common.delivery_note')}</FormLabel>
            <SStack>
              {deliveryVariant.map((props, i) => (
                <Radio key={i} {...props} />
              ))}
              {currentValue === 'deliveryVariant_deliveryNo' && (
                <NumericField
                  noTrailingZeros
                  width='80%'
                  onChange={(num) => {
                    setOutputArgs(
                      num
                        ? {
                            sourceOrderNo: num,
                            mode: C_New_Order_Selection_Mode.FROM_ORDER_BY_NUMBER,
                          }
                        : null,
                    );
                  }}
                />
              )}
            </SStack>
          </Grid>
        </Grid>
      </RadioGroup>
      <Stack mt={5} direction='row' justifyContent='space-between' spacing={2}>
        <Button
          onClick={() => {
            orderDetailsState.pub.updatePositions([]);
            stream.emit('close');
          }}
          startIcon={<CleaningServicesIcon />}
        >
          {t('order.clear_positions')}
        </Button>
        <Stack direction='row' spacing={2}>
          <Button
            disabled={!outputArgs}
            onClick={() => {
              orderDetailsState.pub.replaceOrderData({
                customer: customerValues!.customer!,
                orderType: customerValues!.orderType,
                date: outputArgs!.date ?? customerValues!.date,
                mode: outputArgs!.mode,
                sourceOrderDate: outputArgs!.sourceOrderDate,
                sourceOrderNo: outputArgs!.sourceOrderNo,
              });
              stream.emit('close');
            }}
            variant='contained'
          >
            {t('common.replace')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

const SStack = styled(Stack)({
  paddingLeft: 10,
});

export interface IContent {
  stream: IPopupWrapper['stream'];
}

interface IOutputArgs {
  mode?: C_New_Order_Selection_Mode;
  date?: Scalars['Date'];
  sourceOrderNo?: number;
  sourceOrderDate?: Scalars['Date'];
}
