import { ITabState } from '../states/tabState.model.ts';
import { CustomerConditionsRes, customerService } from '../services/customer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { map, take, zipWith } from 'rxjs';
import {
  configsData,
  DictArticlesRes,
  DictBanksRes,
  DictCustomersRes,
  DictDiscountGroupsRes,
  DictPriceCategoriesRes,
  DictQuantityDiscountsRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { formatDate } from '../../../shared/helpers/utils/utils.helper.ts';
import {
  TCompanyConfigs,
  companyConfigService,
} from '../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  C_Debitor_Status,
  C_Discount_Kind,
  C_Invoice_Interval,
  C_Tax_System,
  C_E_Invoice_Format,
} from '../../../graphql/generatedModel.ts';

export const defaultCustomerConditionsData: CustomerConditionsRes = {
  id: '',
  customerNo: null,
  priceCategoryId: '',
  taxSystemId: C_Tax_System.TAX3_NO_VAT,
  discountKindId: C_Discount_Kind.DK1_NO_DISCOUNT,
  quantityDiscountSettingId: null,
  discountOnGroup1: null,
  discountOnGroup2: null,
  discountOnGroup3: null,
  discountOnGroup4: null,
  discountOnGroup5: null,
  discountOnGroup6: null,
  discountOnGroup7: null,
  discountOnGroup8: null,
  discountOnGroup9: null,
  discountOnGroup10: null,
  discountOnGroup11: null,
  discountOnGroup12: null,
  discountOnGroup13: null,
  discountOnGroup14: null,
  discountOnGroup15: null,
  discountOnGroup16: null,
  discountOnGroup17: null,
  discountOnGroup18: null,
  discountOnGroup19: null,
  discountOnGroup20: null,
  invoicingIntervalId: C_Invoice_Interval.II7_MANUAL,
  debitorStatus: C_Debitor_Status.DS1_DEBITOR,
  collectiveInvoicingToCustomerId: null,
  returnLimit: null,
  eInvoiceFormat: C_E_Invoice_Format.EIF1_PDF_XML_XRECHNUNG_PROFILE,
  paymentPeriod: null,
  individualPaymentTerms: null,
  skonto: null,
  skontoPeriod: null,
  besrBankId: null,
  debtorAccount: null,
  slipReferenceNo: null,
  directDebitBankId: null,
  bank1Name: null,
  bank1BIN: null,
  bank1AccountNo: null,
  bank1IBAN: null,
  bank1BIC: null,
  bank1MandatNo: null,
  bank1MandatSignatureDate: null,
  bank2Name: null,
  bank2BIN: null,
  bank2AccountNo: null,
  bank2IBAN: null,
  bank2BIC: null,
  bank2MandatNo: null,
  bank2MandatSignatureDate: null,
  customerSpecialPrices: [],
  customerResellerPrices: [],
};

export async function resolveDataForConditionsTab(data: ITabState): Promise<ITabState> {
  const configData = companyConfigService.getCachedConfigs() || {};
  const { globalTaxSystemId, countryId } = configData as TCompanyConfigs;
  data.configData = { globalTaxSystemId, countryId };
  const resData = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub.getCustomerConditions().pipe(
          responseHandler<CustomerConditionsRes>({
            errorReturnType: defaultCustomerConditionsData,
          }),
          take(1),
        ),
        () => {
          customerService.pub.getCustomerConditions({ id: data.customerId! });
        },
      )
    : {};

  if (Object.keys(resData).length) {
    const {
      bank1MandatSignatureDate,
      bank2MandatSignatureDate,
      customerSpecialPrices,
      customerResellerPrices,
      ...rest
    } = resData as CustomerConditionsRes;
    const formattedDates = {
      bank1MandatSignatureDate: formatDate(bank1MandatSignatureDate),
      bank2MandatSignatureDate: formatDate(bank2MandatSignatureDate),
    };

    data.defaultValues = { ...rest, ...formattedDates };
    data.customerSpecialPrices = customerSpecialPrices;
    data.customerResellerPrices = customerResellerPrices;

    return data;
  }
  data.defaultValues = {};
  data.customerSpecialPrices = [];
  data.customerResellerPrices = [];
  return data;
}

export async function resolveConditionsTabOptions(data: ITabState): Promise<ITabState> {
  data.conditionsTabOptions = await customerService.globHelpers.streamToPromise(
    configsData.sub.dictPriceCategories().pipe(
      zipWith(
        configsData.sub.dictCustomers(),
        configsData.sub.dictBanks(),
        configsData.sub.dictDiscountGroups(),
        configsData.sub.dictQuantityDiscounts(),
        configsData.sub.dictArticles(),
      ),
      responseHandler<ConditionsTabResponseData>({ errorReturnType: [[], [], [], [], [], []] }),
      map(([priceCategories, customers, banks, discountGroups, quantityDiscounts, articles]) => {
        const productList = articles.reduce(
          (acc: DictArticlesOption[], item: DictArticlesRes[number]) => {
            if (!item.isDough) {
              const { description, ...rest } = item;
              acc.push({ ...rest, label: description || '' });
            }
            return acc;
          },
          [],
        );
        return {
          priceCategories,
          customers,
          banks,
          discountGroups,
          quantityDiscounts,
          productList,
        };
      }),
    ),
    () =>
      configsData.pub.common([
        'dictPriceCategories',
        'dictCustomers',
        'dictBanks',
        'dictDiscountGroups',
        'dictQuantityDiscounts',
        'dictArticles',
      ]),
  );
  return data;
}

export type DictArticlesOption = Omit<DictArticlesRes[number], 'description'> & { label: string };

type ConditionsTabResponseData = [
  DictPriceCategoriesRes,
  DictCustomersRes,
  DictBanksRes,
  DictDiscountGroupsRes,
  DictQuantityDiscountsRes,
  DictArticlesRes,
];
