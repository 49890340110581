import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FieldsRow,
  FormNumericField,
  FormSelect,
  FormTextField,
} from '../../../../../../../../../../../shared/components/form/index.ts';
import { ITabState } from '../../../../../../../../../states/tabState.model.ts';
import { FormProvider, useForm } from 'react-hook-form';
import { IPopupWrapper } from '../../../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { conditionsTabState } from '../../../../../../conditions.state.ts';
import { TDefaultValues } from './resellerPrices.popup.tsx';
import { ISelectOption } from '../../../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { useBeforeClosePopup } from '../../../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { DictArticlesOption } from '../../../../../../../../../loaders/conditionsTab.resolver.ts';

export const ResellerPricesForm: FC<IResellerPricesForm> = ({
  field,
  productListOptions,
  defaultValues,
  stream,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<TDefaultValues>({
    mode: 'onChange',
    defaultValues,
  });
  const { errors, dirtyFields } = formMethods.formState;

  const handleSubmit = (values: TDefaultValues) => {
    conditionsTabState.pub.updateResellerPricesPos(values);
    stream.emit('close');
  };

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.articleNo ? `${option?.articleNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
        <Stack spacing={2}>
          <FieldsRow>
            <FieldsRow width='75%'>
              <FormSelect
                disableClearable
                autoFocus={'description' === field}
                width='100%'
                name='articleId'
                label={t('customer.article')}
                options={productListOptions as []}
                datasetattribute='articleOptions'
                onChange={(v) => {
                  const product = productListOptions?.find(
                    (item) => item.id === v,
                  ) as DictArticlesOption;
                  formMethods.setValue('description', product?.label);
                  formMethods.setValue('articleNo', product?.articleNo);
                  formMethods.setValue('articleId', product?.id);
                }}
                getOptionLabel={(params) => {
                  const { articleNo, label } = params as ExtendedSelectOption;
                  return `${articleNo} ${label}`;
                }}
                controllerProps={{
                  rules: {
                    validate: (v) => (v ? null : t('common.not_empty')),
                  },
                }}
                {...(errors.articleId && {
                  error: true,
                  helperText: errors.articleId.message,
                })}
                {...{ filterOptions }}
              />
            </FieldsRow>
            <FieldsRow width='25%'>
              <FormNumericField
                name='price'
                autoFocus={'price' === field}
                width='100%'
                precision={3}
                noTrailingZeros
                label={t('common.price')}
                controllerProps={{
                  rules: {
                    validate: (_, { price }) => {
                      return price ? null : t('common.not_empty');
                    },
                    deps: ['price'],
                  },
                }}
                {...(errors.price && {
                  error: true,
                  helperText: errors.price.message,
                })}
              />
            </FieldsRow>
          </FieldsRow>
          <FieldsRow>
            <FormTextField
              inputProps={{ maxLength: 128 }}
              width='100%'
              label={t('customer.comment')}
              name='comment'
              autoFocus={'comment' === field}
            />
          </FieldsRow>
        </Stack>
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button type='submit' variant='contained'>
            {t('common.save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

interface IResellerPricesForm {
  field: string;
  productListOptions: ITabState['conditionsTabOptions']['productList'];
  customerId: string;
  defaultValues: TDefaultValues;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
