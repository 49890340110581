import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper.ts';

type Keys =
  | 'order'
  | 'orders'
  | 'create_order'
  | 'edit_order'
  | 'overview'
  | 'customer'
  | 'customers'
  | 'save_order'
  | 'confirm'
  | 'daily_customers'
  | 'customers_without_order'
  | 'accepted_offers'
  | 'active_customers'
  | 'inactive_customers'
  | 'delivery_note_group'
  | 'order_type'
  | 'one_time_customer_order'
  | 'auto_order'
  | 'draft_order'
  | 'tab_not_loaded'
  | 'accepted_offer'
  | 'no_order'
  | 'orders_overview'
  | 'articles_overview'
  | 'reference'
  | 'delivery_time'
  | 'text'
  | 'customer_group'
  | 'remove_article'
  | 'reset_to_default'
  | 'order_settings'
  | 'choose_default'
  | 'delivery_splitting'
  | 'add_shipping_cost'
  | 'barcode_scan'
  | 'merge_positions'
  | 'should_similar_items_be_merged'
  | 'nothing_to_merge'
  /* don't delete this translation list because it is used to create a dynamic translation list like "order.[key]" start */
  | 'phone_business'
  | 'phone_business2'
  | 'phone_private'
  | 'phone_mobile'
  | 'fax'
  | 'email'
  /* end */
  | 'invalid_value'
  | 'select_order'
  | 'select_order_title'
  | 'additional_comments'
  | 'article'
  | 'article_other'
  | 'value_between'
  | 'lot_quantity'
  | 'add_article'
  | 'more_settings'
  | 'edit_amount'
  | 'empty_order_grid'
  | 'new_position'
  | 'article_description'
  | 'text_on_production_list'
  | 'text_on_delivery_note_invoice'
  | 'individual_description'
  | 'legend_deleted_order'
  | 'legend_invoiced_order'
  | 'delete_order'
  | 'clear_positions'
  | 'default_order'
  | 'last_delivery_note'
  | 'fromDate'
  | 'shipping_cost'
  | 'shipping_cost_fixed'
  | 'delete_order_confirmation'
  | 'preview_automatically'
  | 'create_with_invoice'
  | 'pay_by_cash'
  | 'email_message'
  // errors messages -->
  | 'error_failed_to_load_regular_customer_list'
  | 'error_article_with_this_barcode_not_found'
  // <-- errors messages
  | 'empty_positions_create_warning'
  | 'existing_orders_warning'
  | 'positions_lot_quantity_warning'
  | 'positions_min_max_quantity_warning'
  | 'delivery_splitting_calculations_warning'
  | 'restore'
  | 'no_orders_for_this_date'
  | 'have_orders_for_this_date'
  | 'create_auto_orders'
  | 'create_auto_orders_confirmation'
  | 'no_customers_for_auto_order'
  | 'orders_range'
  | 'number_of_created_orders'
  | 'creating_standard_orders'
  | 'deleting_order'
  | 'order_successfully_deleted'
  | 'error_calculating_price'
  | 'enter_customer_info'
  | 'name'
  | 'delivery_note_form'
  | 'order_will_be_charged'
  | 'convert_to_regular_customer'
  | 'title'
  | 'one_time_order_message'
  | 'paymentPeriod'
  | 'creatingCustomer'
  | 'customerCreated'
  | 'emails_with_attached_reports_message'
  | 'not_enough_days_to_produce'
  | 'datetime_of_last_email_message'
  | 'import_orders_from_xml'
  | 'if_the_customer_already_has_orders'
  | 'import_successful'
  | 'morgengold_xml_orders_import'
  | 'navigation_with_Enter_info'
  | 'navigate_with_keyboard_Enter';

const t = LocalizationHelper<Keys>('order');

export const orderDe = t({
  order: 'Bestellung',
  orders: 'Bestellungen',
  create_order: 'Bestellung erstellen',
  edit_order: 'Bestellung bearbeiten',
  overview: 'Übersicht',
  customer: 'Kunde',
  customers: 'Kunden',
  confirm: 'Bestätigen',
  customer_group: 'Kunde Gruppe',
  articles_overview: 'Artikelübersicht',
  orders_overview: 'Lieferscheinübersicht',
  tab_not_loaded: 'Daten für Tab nicht geladen!',
  daily_customers: 'Tageskunden',
  customers_without_order: 'Kunden ohne Bestellungen',
  accepted_offers: 'Akzeptierte Angebote',
  active_customers: 'Aktive Kunden',
  inactive_customers: 'Inaktive Kunden',
  delivery_note_group: 'Lieferscheingruppe',
  order_type: 'Bestelltyp',
  one_time_customer_order: 'Bestellung für Einmalkunde',
  auto_order: 'Automatische Bestellung',
  draft_order: 'Bestellungsentwurf',
  accepted_offer: 'Akzeptiertes Angebot',
  no_order: 'Keine Bestellung',
  order_settings: 'Auftragseinstellung',
  reference: 'Referenz',
  delivery_time: 'Lieferzeit',
  text: 'Text',
  save_order: 'Bestellung speichern',
  add_article: 'Artikel hinzufügen',
  remove_article: 'Artikel löschen',
  reset_to_default: 'Auf Standard zurücksetzen',
  choose_default: 'Vorgabe wählen',
  delivery_splitting: 'Teillieferungen anzeigen',
  add_shipping_cost: 'Versandkosten hinzufügen',
  phone_business: 'Telefon G',
  phone_business2: 'Telefon G II',
  phone_private: 'Telefon P',
  phone_mobile: 'Natel',
  fax: 'Fax',
  email: 'Email',
  invalid_value: 'Ungültiger Wert',
  select_order: 'Bestellung wählen',
  select_order_title: 'Bitte wählen Sie die gewünschte Bestellung aus der Liste unten:',
  additional_comments: 'Zusätzliche Bemerkung',
  merge_positions: 'Positionen zusammenführen',
  should_similar_items_be_merged: 'Sollen gleiche Artikel zusammengeführt werden?',
  nothing_to_merge: 'Nichts zum Zusammenführen',
  barcode_scan: 'Barcode-Scan',
  error_article_with_this_barcode_not_found: 'Artikel mit diesem Barcode wurde nicht gefunden',
  article: 'Artikel',
  article_other: 'Artikel',
  error_failed_to_load_regular_customer_list: 'Die Stammkundenliste konnte nicht geladen werden!',
  more_settings: 'Mehr Einstellungen',
  value_between: 'Der Wert muss zwischen liegen {{minQuantity}} und {{maxQuantity}}',
  lot_quantity: 'Der Wert muss ein Vielfaches der Anzahl der Lots sein ({{quantityPerLot}})',
  edit_amount: 'Menge bearbeiten',
  empty_order_grid: 'Klicken Sie auf “+”, um den ersten Artikel hinzuzufügen',
  new_position: 'Neue Position',
  article_description: 'Artikel-Text',
  text_on_production_list: 'Text auf Produktionsliste',
  text_on_delivery_note_invoice: 'Text auf Lieferschein / Rechnung',
  delete_order: 'Bestellung löschen',
  legend_deleted_order: 'Gelöschte Bestellung',
  legend_invoiced_order: 'Fakturierte Bestellung',
  individual_description: 'Individuelle Bezeichnung',
  clear_positions: 'Klar Positionen',
  default_order: 'Vorgabebestellung',
  last_delivery_note: 'Letzter Lieferschein',
  fromDate: 'Von Datum',
  shipping_cost: 'Versandkosten für {{weight}} kg',
  shipping_cost_fixed: 'Versandkosten',
  delete_order_confirmation: 'Sind Sie sicher, dass Sie die Bestellung löschen möchten?',
  preview_automatically: 'Automatische Vorschau',
  create_with_invoice: 'Erstellen mit Rechnung',
  pay_by_cash: 'Rechnung bar',
  email_message:
    'Sehr geehrte Damen und Herren\n \nIn der Beilage erhalten Sie den Lieferschein [LieferscheinNr] zur Archivierung. Wir danken Ihnen für die Bestellung.\n \nMit freundlichen Grüssen',
  empty_positions_create_warning:
    'Es kann kein Bestellung erstellt werden, da keine Mengen im Bestellung vorhanden sind.',
  existing_orders_warning:
    'Für diesen Kunden liegen am ausgewählten Datum Bestellungen vor. Möchten Sie, dass die Bestellung erstellt wird?',
  positions_lot_quantity_warning: 'Problem mit Menge bei einigen Artikeln. Trotzdem fortfahren?',
  positions_min_max_quantity_warning:
    'Eingegebene Menge von Artikel ist weniger/mehr als der zulässige Menge. Trotzdem fortfahren?',
  delivery_splitting_calculations_warning:
    'Problem der Splitting Kalkulation dieser Lieferung. Trotzdem fortfahren?',
  restore: 'Wiederherstellung',
  no_orders_for_this_date: 'Dieser Kunde wird für dieses Datum keine Bestellungen',
  have_orders_for_this_date: 'Dieser Kunde hat möglicherweise Bestellungen für dieses Datum',
  create_auto_orders: 'Standardbestellungen automatisch erstellen',
  create_auto_orders_confirmation:
    'Sie möchten Bestellungen automatisch erstellen für {{count}} Kunden?',
  no_customers_for_auto_order:
    'Es gibt keine berechtigten Kunden, eine automatische Bestellung zu erstellen.',
  orders_range: 'Bestellungen Bereich',
  number_of_created_orders: 'Anzahl der erstellten Bestellungen',
  creating_standard_orders: 'Erstellen von Standardaufträgen',
  deleting_order: 'Bestellung stornieren...',
  order_successfully_deleted: 'Bestellung erfolgreich gelöscht',
  error_calculating_price: 'Beim Berechnen des Preises ist ein Fehler aufgetreten.',
  enter_customer_info: 'Kundeninformationen eingeben',
  name: 'Name',
  delivery_note_form: 'Lieferschein Formular',
  order_will_be_charged: 'Bestellung wird verrechnet',
  convert_to_regular_customer: 'In Stammkunde umwandeln',
  title: 'Anrede',
  one_time_order_message:
    'Kunde mit diesem Namen wurde bereits für eine frühere Lieferung benutzt. Mochten Sie ihn verwenden?',
  paymentPeriod: 'Zahlungsziel',
  creatingCustomer: 'Erstellen Sie einen Kunden',
  customerCreated: 'Kunde wird angelegt',
  emails_with_attached_reports_message:
    'Emails mit angehängten Reports werden automatisch erstellt und an folgenden Kunden gesendet',
  not_enough_days_to_produce:
    'Für die Produktion einiger Artikel sind nicht genügend Tage vorhanden. Trotzdem fortfahren?',
  datetime_of_last_email_message: 'Email wurde verschickt am {{date}} um {{time}}',
  import_orders_from_xml: 'Bestellungen aus XML importieren',
  navigation_with_Enter_info:
    'Wählen Sie Spalten für die schnelle Navigation mit „Eingabe“ aus. Ohne Auswahl werden alle Zellen einbezogen.',
  navigate_with_keyboard_Enter: 'Einstellungen für die Navigation mit der "Enter"-Taste',
  if_the_customer_already_has_orders:
    'Es gibt an diesem Tag schon eine Bestellung für diesen Kunden. Trotzdem eine neue Bestellung erstellen?',
  import_successful:
    'Import erfolgreich durchgeführt.\nEingefügte Datensätze: {{recordsInserted}}.\nÜbersprungene Datensätze: {{recordsSkipped}}.',
  morgengold_xml_orders_import: 'Morgengold XML Bestellungen Import',
});

export const orderFr = t({
  order: 'Commande',
  orders: 'Commandes',
  create_order: 'Créer une commande',
  edit_order: 'Modifier la commande',
  overview: 'Aperçu',
  customer: 'Client',
  customers: 'Clients',
  confirm: 'Confirmer',
  customer_group: 'Groupe de clients',
  articles_overview: 'Aperçu des articles',
  orders_overview: 'Aperçu des commandes',
  daily_customers: 'Clients du jour',
  customers_without_order: 'Clients sans commandes',
  accepted_offers: 'Offres acceptées',
  active_customers: 'Clients actifs',
  inactive_customers: 'Clients inactifs',
  delivery_note_group: 'Groupe de bons de livraison',
  order_type: 'Type de commande',
  one_time_customer_order: 'Commande pour client ponctuel',
  auto_order: 'Création automatique des commandes',
  draft_order: 'Commande brouillon',
  accepted_offer: 'Offre acceptée',
  no_order: 'Pas de commande',
  reference: 'Référence',
  delivery_time: 'Heure de livraison',
  text: 'Texte',
  save_order: 'Enregistrer la commande',
  order_settings: 'Paramètres de commande',
  add_article: 'Ajouter un article',
  remove_article: 'Supprimer l’article',
  reset_to_default: 'Réinitialiser par défaut',
  choose_default: 'Choisir par défaut',
  delivery_splitting: 'Afficher les parties de livraison',
  add_shipping_cost: 'Ajouter des frais de livraison',
  phone_business: 'Téléphone professionnel',
  phone_business2: 'Téléphone professionnel II',
  phone_private: 'Téléphone privé',
  phone_mobile: 'Portable',
  email: 'Email',
  fax: 'Fax',
  merge_positions: 'Fusionner les positions',
  should_similar_items_be_merged: 'Les articles similaires doivent-ils être fusionnés ?',
  nothing_to_merge: 'Rien à fusionner',
  barcode_scan: 'Scanner le code-barres',
  error_article_with_this_barcode_not_found: 'Article avec ce code-barres non trouvé',
  invalid_value: 'Valeur invalide',
  select_order: 'Sélectionner une commande',
  select_order_title: 'Veuillez sélectionner la commande souhaitée dans la liste ci-dessous :',
  additional_comments: 'Commentaires supplémentaires',
  more_settings: 'Plus de paramètres',
  error_failed_to_load_regular_customer_list:
    'Impossible de charger la liste des clients réguliers !',
  tab_not_loaded: 'Données pour l’onglet non chargées !',
  article: 'Article',
  article_other: 'Articles',
  value_between: 'La valeur doit être comprise entre {{minQuantity}} et {{maxQuantity}}',
  lot_quantity: 'La valeur doit être un multiple du nombre de lots ({{quantityPerLot}})',
  edit_amount: 'Modifier la quantité',
  empty_order_grid: 'Cliquez sur “+” pour ajouter le premier article',
  new_position: 'Nouvelle position',
  article_description: 'Description de l’article',
  text_on_production_list: 'Texte sur la liste de production',
  text_on_delivery_note_invoice: 'Texte sur le bon de livraison / facture',
  delete_order: 'Supprimer la commande',
  legend_deleted_order: 'Commande supprimée',
  legend_invoiced_order: 'Commande facturée',
  individual_description: 'Description individuelle',
  clear_positions: 'Effacer les positions',
  default_order: 'Commande par défaut',
  last_delivery_note: 'Dernier bon de livraison',
  fromDate: 'À partir de la date',
  shipping_cost: 'Frais de livraison pour {{weight}} kg',
  shipping_cost_fixed: 'Frais de livraison',
  delete_order_confirmation: 'Êtes-vous sûr de vouloir supprimer la commande ?',
  preview_automatically: 'Aperçu automatique',
  create_with_invoice: 'Créer avec facture',
  pay_by_cash: 'Payer en espèces',
  email_message:
    'Madame, Monsieur\n \nVeuillez trouver ci-joint le bon de livraison [LieferscheinNr] pour archivage. Merci pour votre commande.\n \nCordialement',
  empty_positions_create_warning:
    'La commande ne peut pas être créée car le champ de quantité est vide pour toutes les positions.',
  existing_orders_warning:
    'Il existe des commandes pour ce client à la date sélectionnée. Voulez-vous créer la commande ?',
  positions_lot_quantity_warning:
    'Problème avec la quantité pour certains articles. Continuer quand même ?',
  positions_min_max_quantity_warning:
    'La quantité saisie pour l’article est inférieure ou supérieure à la quantité autorisée. Continuer quand même ?',
  delivery_splitting_calculations_warning:
    'Problème de calcul de la répartition des livraisons. Continuer quand même ?',
  restore: 'Restaurer',
  no_orders_for_this_date: 'Ce client n’aura pas de commande pour cette date',
  have_orders_for_this_date: 'Ce client pourrait avoir des commandes pour cette date',
  create_auto_orders: 'Générer automatiquement les commandes standards',
  create_auto_orders_confirmation:
    'Voulez-vous créer automatiquement des commandes pour {{count}} clients ?',
  no_customers_for_auto_order: 'Aucun client éligible pour créer une commande automatique.',
  orders_range: 'Plage de commandes',
  number_of_created_orders: 'Nombre de commandes créées',
  creating_standard_orders: 'Création des commandes standards',
  deleting_order: 'Suppression de la commande...',
  order_successfully_deleted: 'Commande supprimée avec succès',
  error_calculating_price: 'Une erreur s’est produite lors du calcul du prix.',
  enter_customer_info: 'Entrer les informations du client',
  name: 'Nom',
  delivery_note_form: 'Formulaire de bon de livraison',
  order_will_be_charged: 'La commande sera facturée',
  convert_to_regular_customer: 'Convertir en client régulier',
  title: 'Titre',
  one_time_order_message:
    'Un client portant ce nom a déjà été utilisé pour créer une livraison. Voulez-vous l’utiliser ?',
  paymentPeriod: 'Période de paiement',
  creatingCustomer: 'Création du client',
  customerCreated: 'Client créé',
  emails_with_attached_reports_message:
    'Des emails avec des rapports en pièce jointe seront automatiquement créés et envoyés aux clients suivants',
  not_enough_days_to_produce:
    'Pas assez de jours pour produire certains articles. Continuer quand même ?',
  datetime_of_last_email_message: 'Email envoyé le {{date}} à {{time}}',
  import_orders_from_xml: 'Importer des commandes depuis XML',
  navigation_with_Enter_info:
    'Sélectionnez les colonnes pour une navigation rapide avec « Entrée ». Sans sélection, toutes les cellules sont incluses.',
  navigate_with_keyboard_Enter: 'Naviguez avec la touche « Entrée » du clavier',
  if_the_customer_already_has_orders:
    'Si le client a déjà des commandes à cette date, souhaitez-vous tout de même en créer une nouvelle?',
  import_successful:
    'Importation réussie.\nEnregistrements insérés : {{recordsInserted}}.\nEnregistrements ignorés : {{recordsSkipped}}.',
  morgengold_xml_orders_import: 'Import des commandes XML Morgengold',
});

export const orderIt = t({
  order: 'Ordine',
  orders: 'Ordini',
  create_order: 'Crea ordine',
  edit_order: 'Modifica ordine',
  overview: 'Panoramica',
  customer: 'Cliente',
  customers: 'Clienti',
  confirm: 'Conferma',
  customer_group: 'Gruppo clienti',
  articles_overview: 'Panoramica articoli',
  orders_overview: 'Panoramica ordini',
  daily_customers: 'Clienti giornalieri',
  customers_without_order: 'Clienti senza ordini',
  accepted_offers: 'Offerte accettate',
  active_customers: 'Clienti attivi',
  inactive_customers: 'Clienti inattivi',
  delivery_note_group: 'Gruppo note di consegna',
  order_type: 'Tipo di ordine',
  one_time_customer_order: 'Ordine per cliente occasionale',
  auto_order: 'Crea ordini automaticamente',
  draft_order: 'Ordine in bozza',
  accepted_offer: 'Offerta accettata',
  no_order: 'Nessun ordine',
  reference: 'Riferimento',
  delivery_time: 'Orario di consegna',
  text: 'Testo',
  save_order: 'Salva ordine',
  order_settings: 'Impostazioni ordine',
  add_article: 'Aggiungi articolo',
  remove_article: 'Elimina articolo',
  reset_to_default: 'Ripristina impostazioni predefinite',
  choose_default: 'Scegli predefinito',
  delivery_splitting: 'Mostra suddivisione consegna',
  add_shipping_cost: 'Aggiungi costi di spedizione',
  phone_business: 'Telefono aziendale',
  phone_business2: 'Telefono aziendale II',
  phone_private: 'Telefono privato',
  phone_mobile: 'Cellulare',
  email: 'Email',
  fax: 'Fax',
  merge_positions: 'Unisci posizioni',
  should_similar_items_be_merged: 'Unire gli articoli simili?',
  nothing_to_merge: 'Niente da unire',
  barcode_scan: 'Scansione codice a barre',
  error_article_with_this_barcode_not_found: 'Articolo con questo codice a barre non trovato',
  invalid_value: 'Valore non valido',
  select_order: 'Seleziona ordine',
  select_order_title: 'Seleziona l’ordine desiderato dall’elenco sottostante:',
  additional_comments: 'Commenti aggiuntivi',
  more_settings: 'Altre impostazioni',
  error_failed_to_load_regular_customer_list: 'Impossibile caricare elenco clienti abituali!',
  tab_not_loaded: 'Dati per la scheda non caricati!',
  article: 'Articolo',
  article_other: 'Articoli',
  value_between: 'Il valore deve essere compreso tra {{minQuantity}} e {{maxQuantity}}',
  lot_quantity: 'Il valore deve essere un multiplo del numero di lotti ({{quantityPerLot}})',
  edit_amount: 'Modifica quantità',
  empty_order_grid: 'Clicca “+” per aggiungere il primo articolo',
  new_position: 'Nuova posizione',
  article_description: 'Descrizione articolo',
  text_on_production_list: 'Testo sull’elenco di produzione',
  text_on_delivery_note_invoice: 'Testo su nota di consegna / fattura',
  delete_order: 'Elimina ordine',
  legend_deleted_order: 'Ordine eliminato',
  legend_invoiced_order: 'Ordine fatturato',
  individual_description: 'Descrizione individuale',
  clear_positions: 'Cancella posizioni',
  default_order: 'Ordine predefinito',
  last_delivery_note: 'Ultima nota di consegna',
  fromDate: 'Dalla data',
  shipping_cost: 'Costi di spedizione per {{weight}} kg',
  shipping_cost_fixed: 'Costi di spedizione',
  delete_order_confirmation: 'Sei sicuro di voler eliminare l’ordine?',
  preview_automatically: 'Anteprima automatica',
  create_with_invoice: 'Crea con fattura',
  pay_by_cash: 'Paga in contanti',
  email_message:
    'Gentili Signore e Signori,\n\nIn allegato ricevete la nota di consegna [LieferscheinNr] per l’archiviazione. Grazie per il vostro ordine.\n\nCordiali saluti',
  empty_positions_create_warning:
    'L’ordine non può essere creato perché il campo quantità è vuoto in tutte le posizioni dell’ordine.',
  existing_orders_warning:
    'Esistono già ordini per questo cliente nella data selezionata. Vuoi creare l’ordine?',
  positions_lot_quantity_warning:
    'Problema con la quantità per alcuni articoli. Continuare comunque?',
  positions_min_max_quantity_warning:
    'La quantità inserita per l’articolo è inferiore/superiore a quella consentita. Continuare comunque?',
  delivery_splitting_calculations_warning:
    'Problema con il calcolo della suddivisione della consegna. Continuare comunque?',
  restore: 'Ripristina',
  no_orders_for_this_date: 'Questo cliente non avrà ordini per questa data',
  have_orders_for_this_date: 'Questo cliente potrebbe avere ordini per questa data',
  create_auto_orders: 'Genera automaticamente ordini standard',
  create_auto_orders_confirmation: 'Vuoi creare automaticamente ordini per {{count}} clienti?',
  no_customers_for_auto_order: 'Non ci sono clienti idonei per creare un ordine automatico.',
  orders_range: 'Intervallo di ordini',
  number_of_created_orders: 'Numero di ordini creati',
  creating_standard_orders: 'Creazione di ordini standard',
  deleting_order: 'Eliminazione ordine...',
  order_successfully_deleted: 'Ordine eliminato con successo',
  error_calculating_price: 'Si è verificato un errore nel calcolo del prezzo.',
  enter_customer_info: 'Inserisci informazioni cliente',
  name: 'Nome',
  delivery_note_form: 'Modulo nota di consegna',
  order_will_be_charged: 'L’ordine sarà fatturato',
  convert_to_regular_customer: 'Converti in cliente abituale',
  title: 'Titolo',
  one_time_order_message:
    'Un cliente con questo nome è già stato usato per una consegna. Vuoi riutilizzarlo?',
  paymentPeriod: 'Periodo di pagamento',
  creatingCustomer: 'Creazione cliente',
  customerCreated: 'Cliente creato',
  emails_with_attached_reports_message:
    'Le email con report allegati saranno automaticamente create e inviate ai seguenti clienti',
  not_enough_days_to_produce:
    'Non ci sono abbastanza giorni per produrre alcuni articoli. Continuare comunque?',
  datetime_of_last_email_message: 'L’email è stata inviata il {{date}} alle {{time}}',
  import_orders_from_xml: 'Importa ordini da XML',
  navigation_with_Enter_info:
    'Seleziona le colonne per la navigazione rapida con “Invio”. Senza selezione, tutte le celle sono incluse.',
  navigate_with_keyboard_Enter: 'Naviga con il tasto “Invio” della tastiera',
  if_the_customer_already_has_orders:
    'Se il cliente ha già degli ordini per questa data, si desidera comunque crearne uno nuovo?',
  import_successful:
    'Importazione riuscita.\nRecord inseriti: {{recordsInserted}}.\nRecord saltati: {{recordsSkipped}}.',
  morgengold_xml_orders_import: 'Importazione ordini XML Morgengold',
});

export const orderEn = t({
  order: 'Order',
  orders: 'Orders',
  create_order: 'Create order',
  edit_order: 'Edit order',
  overview: 'Overview',
  customer: 'Customer',
  customers: 'Customers',
  confirm: 'Confirm',
  customer_group: 'Customer Group',
  articles_overview: 'Articles overview',
  orders_overview: 'Orders overview',
  daily_customers: 'Daily customers',
  customers_without_order: 'Customers without orders',
  accepted_offers: 'Accepted offers',
  active_customers: 'Active customers',
  inactive_customers: 'Inactive customers',
  delivery_note_group: 'Delivery note group',
  order_type: 'Order type',
  one_time_customer_order: 'Order for one-time customer',
  auto_order: 'Auto-create orders',
  draft_order: 'Draft order',
  accepted_offer: 'Accepted offer ',
  no_order: 'No order',
  reference: 'Reference',
  delivery_time: 'Delivery time',
  text: 'Text',
  save_order: 'Save order',
  order_settings: 'Order settings',
  add_article: 'Add article',
  remove_article: 'Delete article',
  reset_to_default: 'Reset to default',
  choose_default: 'Choose default',
  delivery_splitting: 'Show delivery parts',
  add_shipping_cost: 'Add shipping costs',
  phone_business: 'Phone business',
  phone_business2: 'Phone business II',
  phone_private: 'Phone private',
  phone_mobile: 'Mobile',
  email: 'Email',
  fax: 'Fax',
  merge_positions: 'Merge positions',
  should_similar_items_be_merged: 'Should similar items be merged?',
  nothing_to_merge: 'Nothing to merge',
  barcode_scan: 'Barcode scan',
  error_article_with_this_barcode_not_found: 'Article with this barcode was not found',
  invalid_value: 'Invalid value',
  select_order: 'Select order',
  select_order_title: 'Please select desired order from the list below:',
  additional_comments: 'Additional comments',
  more_settings: 'More settings',
  error_failed_to_load_regular_customer_list: 'Failed to load regular customer list!',
  tab_not_loaded: 'Data for tab not loaded!',
  article: 'Article',
  article_other: 'Articles',
  value_between: 'The value must be between {{minQuantity}} and {{maxQuantity}}',
  lot_quantity: 'The value must be a multiple of the number of lots ({{quantityPerLot}})',
  edit_amount: 'Edit Amount',
  empty_order_grid: 'Click “+” to add the first item',
  new_position: 'New position',
  article_description: 'Article description',
  text_on_production_list: 'Text on production list',
  text_on_delivery_note_invoice: 'Text on delivery note / invoice',
  delete_order: 'Delete order',
  legend_deleted_order: 'Deleted order',
  legend_invoiced_order: 'Invoiced order',
  individual_description: 'Individual description',
  clear_positions: 'Clear positions',
  default_order: 'Default order',
  last_delivery_note: 'Last delivery note',
  fromDate: 'From date',
  shipping_cost: 'Shipping costs for {{weight}} kg',
  shipping_cost_fixed: 'Shipping costs',
  delete_order_confirmation: 'Are you sure you want to delete the order?',
  preview_automatically: 'Automatic preview',
  create_with_invoice: 'Create with invoice',
  pay_by_cash: 'Pay by cash',
  email_message:
    'Dear Ladies and Gentlemen\n \nEnclosed you receive the delivery note [LieferscheinNr] for archiving. Thank you for ordering.\n \nBest regards',
  empty_positions_create_warning:
    'Order cannot be created because amount field is empty in all positions of the order.',
  existing_orders_warning:
    'There are orders for this customer on the selected date. Do you want the order to be created?',
  positions_lot_quantity_warning: 'Problem with quantity for some articles. Continue anyway?',
  positions_min_max_quantity_warning:
    'Quantity of article entered is less/greater than allowed quantity. Continue anyway?',
  delivery_splitting_calculations_warning:
    'Problem of delivery splitting calculation. Continue anyway?',
  restore: 'Restore',
  no_orders_for_this_date: 'This customer will have no orders for this date',
  have_orders_for_this_date: 'This customer may have orders for this date',
  create_auto_orders: 'Generate standard orders automatically',
  create_auto_orders_confirmation:
    'Do you want to automatically create orders for {{count}} customers?',
  no_customers_for_auto_order: 'There are no eligible customers to create an auto-order.',
  orders_range: 'Orders range',
  number_of_created_orders: 'Number of created orders',
  creating_standard_orders: 'Creating standard orders',
  deleting_order: 'Deleting order...',
  order_successfully_deleted: 'Order successfully deleted',
  error_calculating_price: 'An error occurred while calculating the price.',
  enter_customer_info: 'Enter customer information',
  name: 'Name',
  delivery_note_form: 'Delivery note form',
  order_will_be_charged: 'Order will be charged',
  convert_to_regular_customer: 'Convert to regular customer',
  title: 'Title',
  one_time_order_message:
    'Customer with this name already been used when creating delivery. Do you want to use it?',
  paymentPeriod: 'Payment period',
  creatingCustomer: 'Creating a customer',
  customerCreated: 'Customer is created',
  emails_with_attached_reports_message:
    'Emails with attached reports wil be automatically created and sent to the following customers',
  not_enough_days_to_produce: 'Not enough days for some articles be produced. Continue anyway?',
  datetime_of_last_email_message: 'Email was sent on {{date}} at {{time}}',
  import_orders_from_xml: 'Import orders from XML',
  navigation_with_Enter_info:
    'Select columns for quick navigation with ‘Enter’. Without selection, all cells are included.',
  navigate_with_keyboard_Enter: 'Navigate with keyboard ‘Enter’',
  if_the_customer_already_has_orders:
    'If the customer already has orders on this date, should we still create a new one?',
  import_successful: `Import successful. \nInserted records: {{recordsInserted}}. \nSkipped records: {{recordsSkipped}}.`,
  morgengold_xml_orders_import: 'Morgengold XML orders import',
});
