import { Stack } from '@mui/material';
import { FC } from 'react';
import { IconButton } from '../../../../../../../../order/common/iconButton/iconButton.component';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { IGeneralTabState, generalTabState } from '../../../../generalTab.state';
import { CopySpecialPricesPopup } from '../../common/popups/copySpecialPrices/copySpecialPrices.popup';
import { ResellerPricesPopup } from '../popups/resellerPrices/resellerPrices.popup';

export const ControlButtons: FC<IControlButtons> = (props) => {
  const { resellerPricesPopup, selectedResellerPrices, copySpecialPricesPopup, currentArticleId } =
    props;
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={2} mb={1}>
      <IconButton
        onClick={() =>
          resellerPricesPopup.stream.emit('open', {
            values: {
              row: {
                id: `new_${Date.now()}`,
                price: null,
                customerId: '',
                customerNo: null,
                customerName: null,
                comment: null,
              },
              field: 'customerName',
            },
          })
        }
        color='primary'
        size='large'
        title={t('article.addNewRecord')}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        onClick={() => generalTabState.pub.deleteResellerPricesPos()}
        color='primary'
        size='large'
        title={t('article.deleteRecord')}
        disabled={!selectedResellerPrices}
      >
        <RemoveIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          copySpecialPricesPopup.stream.emit('open', {
            currentArticleId,
            type: 'resellerPrices',
          });
        }}
        color='primary'
        size='large'
        title={t('article.copy_reseller_prices')}
      >
        <ContentCopyIcon />
      </IconButton>
    </Stack>
  );
};

interface IControlButtons {
  resellerPricesPopup: ResellerPricesPopup;
  selectedResellerPrices: IGeneralTabState['selectedResellerPricesPos'];
  copySpecialPricesPopup: CopySpecialPricesPopup;
  currentArticleId: string;
}
