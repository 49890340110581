import { BehaviorSubject, delay, filter, map, merge, Observable, share, take, tap } from 'rxjs';
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';
import { Pub, State, Sub } from '../../../state/state.abstract.ts';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { GridEditCellValueParams } from '@mui/x-data-grid/models/params/gridEditCellParams';
import { TypeDictionaries } from '../dictionary.popup.tsx';
import { storageHelper } from '../../../helpers/storage';
import {
  CreateActions,
  DeleteActions,
  dictionaryActionsService,
  SaveActions,
} from '../services/dictionaryActions.service.ts';
import {
  handler as defaultErrorHandler,
  responseHandler,
} from '../../../responseHandler/responseHandler.ts';
import { C_Global_Error_Code } from '../../../../graphql/generatedModel.ts';
import { deletionConfirmationPopup } from '../content.popup.tsx';
import { GridValidRowModel } from '@mui/x-data-grid-premium';

const initState: IDictionaryState = {
  action: undefined,
  selectedRow: undefined,
  updatingCell: undefined,
  data: [],
  limit: false,
  validateFields: undefined,
  dictionaryType: undefined,
  forceUnbindAndDelete: false,
};
class PubImpl extends Pub<IDictionaryState> {
  initData(params: TInitData) {
    this.emit('initData', { ...params });
  }
  selectRow(selectedRow: IDictionaryState['selectedRow']) {
    this.emit('selectRow', { selectedRow });
  }
  clearState() {
    this.emit('clearState', initState);
  }
  addRow(selectedRow: IDictionaryState['selectedRow']) {
    this.emit('addRow', { selectedRow });
  }
  deleteRow(flag?: IDictionaryState['forceUnbindAndDelete']) {
    this.emit('deleteRow', { forceUnbindAndDelete: !!flag });
  }
  updateCell(updatingCell: IDictionaryState['updatingCell'], parseFields?: boolean) {
    this.emit('updateCell', { updatingCell, parseFields });
  }
}
class SubImpl extends Sub<IDictionaryState> {
  private serviceMethodName?: string;
  private deletingRecordInUse = false;
  private disableDelete$ = new BehaviorSubject<boolean>(false);
  private disableEditing$ = new BehaviorSubject<string[]>([]);
  private shareDisableDelete$: Observable<boolean> = this.disableDelete$.pipe(share());
  private shareDisableEditing$: Observable<string[]> = this.disableEditing$.pipe(share());
  protected actionHandlers(): Observable<IDictionaryState> {
    return merge(this.addRow(), this.deleteRow(), this.updateCell(), this.updateState()).pipe(
      tap((state) => {
        if (state.action !== 'updateCell')
          this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }

  disableDelete() {
    return this.shareDisableDelete$;
  }
  disableEditing() {
    return this.shareDisableEditing$;
  }
  private addRow(): Observable<IDictionaryState> {
    return this.actionListener('addRow').pipe(
      map((state) => {
        const updatedState = this.customStructuredClone(state);
        const id = updatedState.limit
          ? `new_${this.findMissingId(updatedState.data)}`
          : `new_${Date.now()}`;
        updatedState.selectedRow = { ...updatedState.selectedRow, id };
        updatedState.data = [...updatedState.data, updatedState.selectedRow];
        return updatedState;
      }),
    );
  }

  private deleteRow(): Observable<IDictionaryState> {
    return this.actionListener('deleteRow').pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      tap(({ forceUnbindAndDelete }) => {
        const { selectedRow, dictionaryType } = this.stream$.getValue();
        if (dictionaryType && (!selectedRow.invalid || !selectedRow.id.includes('new'))) {
          const deletionConfirmationPopupSub = deletionConfirmationPopup?.stream
            .state()
            .pipe(filter(({ action }) => action === 'confirm' || action === 'cancel'))
            .subscribe(({ action }) => {
              if (action === 'confirm') {
                dictionaryState.pub.deleteRow(true);
              }
              if (action === 'cancel') this.disableDelete$.next(false);
              this.deletingRecordInUse = false;
              deletionConfirmationPopupSub?.unsubscribe();
            });
          this.disableDelete$.next(true);
          (
            dictionaryActionsService.sub[
              `delete${this.serviceMethodName}` as DeleteActions
            ]() as Observable<any>
          )
            .pipe(
              responseHandler({
                quite: true,
                customErrorHandler: (data) => {
                  if (data.code === C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE) {
                    const linkedRecords = (data.linkedRecords || {}) as Record<string, any>;
                    deletionConfirmationPopup?.stream.emit('open', {
                      linkedRecords,
                      customMessage: '',
                      forbiddenToDelete: false,
                    });
                    this.deletingRecordInUse = true;
                  } else if (data.code === C_Global_Error_Code.GEC41_DELETE_PROHIBITED) {
                    deletionConfirmationPopup?.stream.emit('open', {
                      customMessage: 'settings.cannot_delete_this_bank',
                      forbiddenToDelete: true,
                    });
                    this.deletingRecordInUse = true;
                  } else defaultErrorHandler({ data });
                  return '';
                },
                success: () => 'common.successfully_deleted',
              }),
              take(1),
            )
            .subscribe((data) => {
              if (!this.deletingRecordInUse) {
                if (data) {
                  this._deleteRow();
                }
                this.disableDelete$.next(false);
                deletionConfirmationPopupSub?.unsubscribe();
              }
            });
          dictionaryActionsService.pub[`delete${this.serviceMethodName}` as DeleteActions]({
            id: selectedRow.id,
            forceUnbindAndDelete,
          });
        } else if (dictionaryType && selectedRow.invalid) this._deleteRow();
      }),
      filter(() => false),
    );
  }

  private updateCell(): Observable<IDictionaryState> {
    return this.actionListener('updateCell').pipe(
      map((state) => this.customStructuredClone(state)),
      map((state) => {
        const { id, field, value } = state.updatingCell!;
        const foundIndex = state.data.findIndex((el) => el.id === id);
        if (foundIndex !== -1) {
          if (state.parseFields) {
            state.data[foundIndex] = { ...state.selectedRow, ...value };
          } else {
            state.data[foundIndex][field] = value;

            if (state.validateFields) {
              let isInvalidRow: Boolean = false;
              state.validateFields.forEach((validatedField) => {
                if (!isInvalidRow) {
                  if (typeof validatedField === 'function') {
                    isInvalidRow = !validatedField(state.data[foundIndex]);
                  } else {
                    const validatedValue = state.data[foundIndex][validatedField];
                    const isEmpty = !validatedValue;
                    const isNonUnique =
                      state.data.filter((row) => row[validatedField] === validatedValue).length > 1;
                    if (isEmpty || isNonUnique) {
                      isInvalidRow = true;
                    }
                  }
                }
              });
              if (isInvalidRow) {
                state.data[foundIndex].invalid = true;
              } else {
                delete state.data[foundIndex].invalid;
              }
            }
          }
          state.selectedRow = state.data[foundIndex];
        }
        this.stream$.next({ ...state, action: 'internalUpdate' });
        return state;
      }),
      tap(({ limit, selectedRow }) => {
        const newRow = selectedRow.id.includes('new');
        if (!newRow) this._updateCell();
        else this._createRow(limit);
      }),
    );
  }

  private _updateCell() {
    const { dictionaryType, selectedRow } = this.stream$.getValue();
    const { id, ...fields } = selectedRow;
    if (dictionaryType && !selectedRow.invalid) {
      (
        dictionaryActionsService.sub[
          `save${this.serviceMethodName}` as SaveActions
        ]() as Observable<any>
      )
        .pipe(
          responseHandler({
            success: () => 'common.successfully_saved',
          }),
          take(1),
        )
        .subscribe();
      dictionaryActionsService.pub[`save${this.serviceMethodName}` as SaveActions]({
        id: String(id),
        fields,
      });

      storageHelper.memory.removeItem(`configsData.${dictionaryType}`);
    }
  }

  private _createRow(limit: IDictionaryState['limit']) {
    const { dictionaryType, selectedRow } = this.stream$.getValue();
    const { id, ...fields } = selectedRow;
    const limitedId = id.split('_')[1];
    if (dictionaryType && !selectedRow.invalid) {
      (limit
        ? (dictionaryActionsService.sub[
            `save${this.serviceMethodName}` as SaveActions
          ]() as Observable<any>)
        : (dictionaryActionsService.sub[
            `create${this.serviceMethodName}` as CreateActions
          ]() as Observable<any>)
      )
        .pipe(
          responseHandler({
            success: () => 'common.successfully_saved',
          }),
          take(1),
        )
        .subscribe((data) => {
          const updatedState = this.customStructuredClone(this.stream$.getValue());
          this.disableEditing$.next(
            this.disableEditing$.value.filter((item) => item !== selectedRow.id),
          );
          updatedState.data = updatedState.data.map((el) => {
            if (el.id === selectedRow.id && (data?.id || limit)) {
              const updatedRow = { ...el, id: limit ? limitedId : data.id };
              updatedState.selectedRow = updatedRow;
              return updatedRow;
            }
            return el;
          });
          this.stream$.next({ ...updatedState, action: 'updateState' });
        });
      this.disableEditing$.next([...this.disableEditing$.value, id]);
      if (limit) {
        dictionaryActionsService.pub[`save${this.serviceMethodName}` as SaveActions]({
          id: limitedId,
          fields,
        });
      } else {
        dictionaryActionsService.pub[`create${this.serviceMethodName}` as CreateActions]({
          fields,
        });
      }
      storageHelper.memory.removeItem(`configsData.${dictionaryType}`);
    }
  }

  _deleteRow() {
    const updatedState = this.customStructuredClone(this.stream$.getValue());
    let selectedRow: IDictionaryState['selectedRow'];
    updatedState.data = updatedState.data.filter((pos, i, arr) => {
      if (pos?.id === updatedState.selectedRow?.id) {
        const nextRow = arr?.[i + 1];
        const prevRow = arr?.[i - 1];
        if (i === 0 && arr!.length > 1) {
          selectedRow = nextRow;
        }
        if (i !== 0) {
          selectedRow = prevRow;
        }
        return false;
      } else return true;
    });
    updatedState.selectedRow = selectedRow;
    this.stream$.next({ ...updatedState, action: 'updateState' });
    storageHelper.memory.removeItem(`configsData.${updatedState.dictionaryType!}`);
  }

  private updateState() {
    return this.actionListener(['initData', 'selectRow', 'updateState', 'clearState']).pipe(
      tap(({ dictionaryType, action }) => {
        if (dictionaryType && action === 'initData') {
          this.serviceMethodName = dictionaryType[0].toUpperCase() + dictionaryType.slice(1);
        }
        if (action === 'clearState') {
          this.disableDelete$.next(false);
        }
      }),
    );
  }

  /* UTILITY start */
  private findMissingId(arr: IDictionaryState['data']) {
    const existingIds = arr.map((item) => {
      if (item.id.includes('new_')) {
        const clearId = item.id.split('_')[1];
        return Number(clearId);
      }
      return Number(item.id);
    });
    for (let i = 1; i <= existingIds.length + 1; i++) {
      if (!existingIds.includes(i)) {
        return String(i);
      }
    }
    return String(existingIds.length + 1);
  }
  private customStructuredClone(state: IDictionaryState): IDictionaryState {
    const { validateFields, ...restState } = state;
    const copyState = structuredClone(restState);
    return { validateFields, ...copyState };
  }
  /* UTILITY end */
}

class DictionaryState extends State<IDictionaryState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const dictionaryState = new DictionaryState(initState);

export interface IDictionaryState {
  action:
    | undefined
    | 'initData'
    | 'selectRow'
    | 'addRow'
    | 'deleteRow'
    | 'updateCell'
    | 'clearState'
    | 'internalUpdate'
    | 'updateState';
  data: GridRowModel[];
  selectedRow: GridRowParams['row'];
  updatingCell: GridEditCellValueParams | undefined;
  parseFields?: boolean; // we use this argument {{parseFields}} to save all the fields passed in instead of a specific field name //
  limit: boolean;
  validateFields?: Array<string | ((row: GridValidRowModel) => boolean)>;
  dictionaryType?: TypeDictionaries;
  forceUnbindAndDelete?: boolean;
}

type TInitData = Pick<IDictionaryState, 'data' | 'limit' | 'validateFields' | 'dictionaryType'>;
