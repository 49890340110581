import { FC, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { EmailForm } from '../../../../../../../shared/components/emailPopup/components/emailForm.component.tsx';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';
import { ISendEmailPopup } from './sendEmail.popup.tsx';
import { useTranslation } from 'react-i18next';
import { take, zip, switchMap, map, of } from 'rxjs';
import {
  CustomerEmailsDataRes,
  customerService,
} from '../../../../../../customer/services/customer.service.ts';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler.ts';
import {
  IGetR10NotifierFileRes,
  IGetR64NotifierFilesRes,
} from '../../../../../../../shared/services/reports/reports.service';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service.ts';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { notificationCenter } from '../../../../../../../shared/notificationCenter/notificationCenter.ts';
import {
  openDebtsService,
  SendReminderOfInvoiceEmailRes,
} from '../../../../../services/openDebts.service.ts';
import { openDebtsState } from '../../../../states/openDebts.state.ts';
import {
  C_E_Invoice_Format,
  C_Email_Msg_Doctype,
  EmailAttachmentInput,
  Wa_PdfFileOfInvoiceInput,
} from '../../../../../../../graphql/generatedModel';
import {
  reportsService,
  getSubjectForEmailWithDocument,
  getTextForEmailWithDocument,
  getFilesDataToExtractXmlFromPdfOfInvoices,
  ExtractXmlFromPdfOfInvoicesRes,
  getFileNameForInvoiceXml,
} from '../../../../../../../shared/services/reports/reports.service';
import { getFileNameForEmailWithDocument } from '../../../../../../../shared/services/reports/reports.service';

export const EmailContentPopup: FC<IEmailContentPopup> = ({ stream, params }) => {
  const { getNotifierReportFilesPub, getNotifierReportFilesSub, customerId, invoicesList } = params;
  const [loading, setLoading] = useState<boolean>(true);
  const attachments = useRef<EmailAttachmentInput[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const subscr = zip(customerService.sub.customerEmailsData(), getNotifierReportFilesSub())
      .pipe(
        responseHandler<[CustomerEmailsDataRes, IGetR10NotifierFileRes | IGetR64NotifierFilesRes]>({
          customErrorHandler: () => {
            setLoading(false);
            stream.emit('close');
            return undefined;
          },
          errorReturnType: [
            {
              id: 'errorId',
              email: null,
              emailForInvoice: null,
              eInvoiceFormat: C_E_Invoice_Format.EIF1_PDF_XML_XRECHNUNG_PROFILE,
            },
            {
              url: null,
              s3Key: null,
              taskId: 0,
            },
          ],
        }),
        switchMap(
          (v: [CustomerEmailsDataRes, IGetR10NotifierFileRes | IGetR64NotifierFilesRes]) => {
            // this function extracts XML file from the invoice if needed and passes to next function
            const [emailData, reportData] = v;

            const maybeR64NotifierFiles = (reportData as IGetR64NotifierFilesRes).invoicesPdfs
              ? (reportData as IGetR64NotifierFilesRes)
              : undefined;

            let invoicePdfsToExtractXml: Array<Wa_PdfFileOfInvoiceInput> = [];
            if (
              maybeR64NotifierFiles &&
              maybeR64NotifierFiles.invoicesPdfs.length > 0 &&
              emailData.eInvoiceFormat === C_E_Invoice_Format.EIF2_XML_XRECHNUNG_PROFILE
            ) {
              invoicePdfsToExtractXml = getFilesDataToExtractXmlFromPdfOfInvoices(
                maybeR64NotifierFiles.invoicesPdfs,
              );
            }

            if (invoicePdfsToExtractXml.length > 0) {
              reportsService.pub.extractXmlFromPdfOfInvoices({
                invoices: invoicePdfsToExtractXml,
              });
              return reportsService.sub
                .extractXmlFromPdfOfInvoices()
                .pipe(
                  map(
                    (extractedXmlWithEInvoice) =>
                      [emailData, reportData, extractedXmlWithEInvoice] as [
                        CustomerEmailsDataRes,
                        IGetR10NotifierFileRes | IGetR64NotifierFilesRes,
                        ExtractXmlFromPdfOfInvoicesRes,
                      ],
                  ),
                );
            } else {
              return of([emailData, reportData, []] as [
                CustomerEmailsDataRes,
                IGetR10NotifierFileRes | IGetR64NotifierFilesRes,
                ExtractXmlFromPdfOfInvoicesRes,
              ]);
            }
          },
        ),
      )
      .subscribe(
        (
          v: [
            CustomerEmailsDataRes,
            IGetR10NotifierFileRes | IGetR64NotifierFilesRes,
            ExtractXmlFromPdfOfInvoicesRes,
          ],
        ) => {
          // prepare attachments. if e-invoice XML is provided, ignore invoice's PDF files
          const [emailData, reportData, extractedXmlWithEInvoice] = v;

          const maybeR10NotifierFile = (reportData as IGetR64NotifierFilesRes).invoicesPdfs
            ? undefined
            : (reportData as IGetR10NotifierFileRes);
          const maybeR64NotifierFiles = (reportData as IGetR64NotifierFilesRes).invoicesPdfs
            ? (reportData as IGetR64NotifierFilesRes)
            : undefined;

          setValue<'recipient'>('recipient', emailData.emailForInvoice || emailData.email || '');

          if (maybeR10NotifierFile && maybeR10NotifierFile.url) {
            attachments.current.push({
              url: maybeR10NotifierFile.url,
              fileName: `${t('debitor.reminder')}.pdf`,
            });
          } else if (
            maybeR64NotifierFiles &&
            maybeR64NotifierFiles.notifierPdfUrl &&
            maybeR64NotifierFiles.invoicesPdfs.length
          ) {
            attachments.current.push({
              url: maybeR64NotifierFiles.notifierPdfUrl,
              fileName: `${t('debitor.reminder')}.pdf`,
            });

            let invoiceAttachments: EmailAttachmentInput[] = [];
            if (extractedXmlWithEInvoice.length > 0) {
              invoiceAttachments = extractedXmlWithEInvoice.map((xmlFileData) => {
                const correspondingPdfFile = maybeR64NotifierFiles.invoicesPdfs.find(
                  ({ documentId }) => documentId === xmlFileData.invoiceId,
                );
                return {
                  url: xmlFileData.xmlFile.url,
                  fileName: getFileNameForInvoiceXml(correspondingPdfFile?.documentNo ?? 0),
                };
              });
            } else {
              invoiceAttachments = maybeR64NotifierFiles.invoicesPdfs.map((invoiceReport) => {
                return {
                  url: invoiceReport.url ?? '',
                  fileName: getFileNameForEmailWithDocument(
                    t,
                    C_Email_Msg_Doctype.EML_INVOICE,
                    invoiceReport.documentNo,
                    invoiceReport.reportId,
                  ),
                };
              });
            }

            attachments.current = attachments.current.concat(invoiceAttachments);
          } else {
            snackbarService.pub.show({
              type: 'warning',
              title: t('common.no_data'),
              content: t('common.report_is_generating_now'),
            });
            stream.emit('close');
          }

          setLoading(false);
        },
      );

    customerService.pub.customerEmailsData({ id: customerId.toString() });
    getNotifierReportFilesPub();

    return () => {
      subscr.unsubscribe();
    };
  }, []);

  const defaultValues = {
    recipient: '',
    subject: getSubjectForEmailWithDocument(t, C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE),
    text: getTextForEmailWithDocument(t, C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE),
  };
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const {
    setValue,
    formState: { isDirty },
  } = formMethods;

  useBeforeClosePopup({
    stream,
    isDirty,
  });

  const submitHandler: SubmitHandler<typeof defaultValues> = (v) => {
    if (attachments.current.length === 0) {
      snackbarService.pub.show({
        type: 'error',
        content: t('common.problem_while_sending_email'),
      });
      stream.emit('close');
      return;
    }

    const { recipient, subject, text } = v;

    notificationCenter.notify<SendReminderOfInvoiceEmailRes>(
      openDebtsService.sub.sendReminderOfInvoiceEmail().pipe(
        responseHandler<SendReminderOfInvoiceEmailRes>({
          errorReturnType: {
            sentMessages: [],
            unsentMessages: [],
          },
        }),
      ),
      {
        type: 'snackbar',
        handler(src, target) {
          src.pipe(take(1)).subscribe({
            next(v) {
              const data = v as SendReminderOfInvoiceEmailRes;
              snackbarService.pub.hide('snackbarLoading');
              if (data.sentMessages?.length) {
                openDebtsState.pub.updateReminderCount(invoicesList);
              }
              if (data.sentMessages?.length && !data.unsentMessages?.length) {
                snackbarService.pub.show({
                  type: 'success',
                  content: t('common.email_sent_successfully', { count: data.sentMessages.length }),
                });
              }
              if (data.sentMessages?.length && data.unsentMessages?.length) {
                snackbarService.pub.show({
                  content: `${t('common.emails_sent_successfully', {
                    successCount: data.sentMessages?.length,
                  })}\n${t('common.emails_failed_to_send', {
                    failureCount: data.unsentMessages?.length,
                  })}`,
                  type: 'warning',
                });
              }
              if (!data.sentMessages?.length && data.unsentMessages?.length) {
                snackbarService.pub.show({
                  type: 'error',
                  content: t('common.problem_while_sending_email', {
                    count: data.unsentMessages.length,
                  }),
                });
              }

              target.next(v);
            },
            error(err) {
              snackbarService.pub.hide('snackbarLoading');
              snackbarService.pub.show({
                type: 'error',
                content: t('common.problem_while_sending_email'),
              });
              target.error(err);
            },
          });
        },
      },
    );

    const emailParams = {
      emailParams: {
        to: recipient.replace(/\s/g, '').split(/,|;/),
        body: text,
        subject,
        attachments: attachments.current,
      },
      invoiceIds: invoicesList,
    };
    openDebtsService.pub.sendReminderOfInvoiceEmail(emailParams);

    stream.emit('close');
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(submitHandler)}>
            <EmailForm attachments={attachments.current} />
          </form>
        </FormProvider>
      )}
    </>
  );
};

interface IEmailContentPopup {
  stream: IPopupWrapper['stream'];
  params: ISendEmailPopup['params'];
}
