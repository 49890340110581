import UploadFileIcon from '@mui/icons-material/UploadFileOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  FileUpload,
  IUploadFileToS3Return,
} from '../../../../../../../shared/components/fileUpload/fileUpload.component';
import { Theme } from '../../../../../../../shared/styles/theme/theme';
import { useTranslation } from 'react-i18next';

export const AddImportInfo: React.FC<IAddFile> = ({ onFileUploaded, onLoading }) => {
  const { t } = useTranslation();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='100%'
    >
      <UploadFileIcon sx={{ fontSize: 60, color: Theme.palette.primary.main, mb: 2 }} />
      <Typography variant='h6' sx={{ mb: 1 }}>
        {t('debitor.addPaymentsInfo.noData')}
      </Typography>
      <Typography variant='body2' color='textSecondary' sx={{ mb: 3 }}>
        {t('debitor.addPaymentsInfo.hint')}
      </Typography>
      <FileUpload.Button
        onLoading={onLoading}
        place='imports'
        text={t('debitor.addPaymentsInfo.upload')}
        onUploaded={onFileUploaded}
        accept={'.xls,.xlsx'}
      />
    </Box>
  );
};
interface IAddFile {
  onFileUploaded: (result: IUploadFileToS3Return) => void;
  onLoading: () => void;
}
