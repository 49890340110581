import { useFormContext } from 'react-hook-form';
import { getJSONValueByPath } from '../../../../../../../../../shared/helpers/utils/utils.helper';
import { reportsConfigurationTabState } from '../../../../../../states/reportsConfiguration.state';

export const useAlternativeSave = () => {
  const {
    formState: { errors, dirtyFields },
  } = useFormContext();

  return (fieldName: string) => {
    const noErrors = !getJSONValueByPath(errors, fieldName);
    const isDirty = !!getJSONValueByPath(dirtyFields, fieldName);

    if (noErrors && isDirty) {
      reportsConfigurationTabState.pub.saveData();
    }
  };
};
