/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useMemo, useState } from 'react';
import { Stack, Paper, CircularProgress, styled } from '@mui/material';
import { Observable, take, zip } from 'rxjs';
import { useTranslation } from 'react-i18next';
import PreviewIconMUI from '@mui/icons-material/RemoveRedEye';
import CheckIconMUI from '@mui/icons-material/Check';

import {
  IVersionsWorkerState,
  ReportVersionStatus,
  TProducedArticles,
  versionsWorkerState,
} from '../../../states/versionsWorker/versionsWorker.state';
import {
  printJobsService,
  ProducedArticlesRes,
  ProductionListRes,
  ProductionReportsVersionsRes,
  SaveProducedArticlesQuantityRes,
} from '../../../../services/printJobs.service';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';
import { FieldsRow, FieldsSection, Select } from '../../../../../../shared/components/form';
import { CustomIconButton } from '../../../../../../shared/components/form/buttons/addButton.component';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component';
import { C_Report } from '../../../../../../graphql/generatedModel';
import { Table } from '../../../../../../shared/components/table/table.component';
import { Column } from '../../../../../../shared/components/table/components/common.components';
import { SButtonWrapper } from '../productionList/productionList.component';
import { NoteButton } from '../../../../../../shared/components/form/buttons/noteButton.component';
import { format } from 'date-fns';
import { storageHelper } from '../../../../../../shared/helpers/storage';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { ProducedQuantityEditCell } from '../cells/producedQuantity.cell';
import { snackbarService } from '../../../../../../shared/components/snackbar/service/snackbar.service';
import {
  getProductionReportFromVersion,
  getProductionReportFromVersionDifference,
} from '../../../states/statusWorker/utils/getDiffServiceByReportId';
import { PreviewPopup } from '../../../../../../shared/components/previewPopup/preview.popup';
import {
  CommonWinReportTaskRes,
  productionReportFromVersionService,
} from '../../../../services/productionReportFromVersion.service';
import { ReportRes } from '../../../../../../shared/components/previewPopup/content.popup';

export const ProductionVersionDetails: FC<ProductionVersionDetailsProps> = ({
  versions,
  date,
  selectedRecord,
}) => {
  const { t } = useTranslation();
  const [versionsOptions, setVersionsOptions] = useState<ISelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isProducedArticlesAvailableToSave, setIsProducedArticlesAvailableToSave] =
    useState<boolean>(true);
  const [isQuantityFilled, setQuantityFilled] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Partial<SelectedItemsState>>({});
  const [selectedRow, setSelectedRow] = useState<TProducedArticles[number]>({ recordId: '' });

  const isPreviewButtonEnabled = !!selectedItems.version;
  const isCompareButtonEnabled =
    (selectedItems.compare1 &&
      selectedItems.compare2 &&
      selectedItems.compare1.id !== selectedItems.compare2.id) ||
    (selectedItems.form?.id === C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE &&
      !selectedItems.compare1 &&
      !selectedItems.compare2 &&
      isQuantityFilled);
  const notifierPreviewPopup = useMemo(() => new PreviewPopup(), []);

  const printFormOptions = useMemo(
    () => [
      {
        id: C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE,
        label: t(`enums.${C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE}`),
      },
      {
        id: C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE,
        label: t(`enums.${C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE}`),
      },
      {
        id: C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE,
        label: t(`enums.${C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE}`),
      },
    ],
    [t],
  );

  const handleSelectChange = (field: keyof SelectedItemsState, value: ISelectOption | null) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSaveProducedArticlesQuantity = () => {
    printJobsService.pub.saveProducedArticlesQuantity({
      records: versions.recordsToSave,
    });
    setIsProducedArticlesAvailableToSave(true);
    printJobsService.sub
      .saveProducedArticlesQuantity()
      .pipe(
        responseHandler<SaveProducedArticlesQuantityRes>({
          errorReturnType: false,
        }),
        take(1),
      )
      .subscribe((response) => {
        if (response) {
          const hasFilledQuantities = versions.recordsToSave.some(
            (article) => article.producedQuantity !== null,
          );
          setQuantityFilled(hasFilledQuantities);
          snackbarService.pub.show({
            type: 'success',
            content: t('common.successfully_saved'),
          });
        }
      });
  };

  useEffect(() => {
    const isViewedStatusItem = versions.versionStatuses.some(
      (status) => status.statusItem === ReportVersionStatus.viewed,
    );
    if (!isViewedStatusItem) {
      const versionsSub = zip(
        printJobsService.sub.getProductionReportsVersions(),
        printJobsService.sub.producedArticlesList(),
      )
        .pipe(
          responseHandler<[ProductionReportsVersionsRes, ProducedArticlesRes]>({
            errorReturnType: [[], []],
          }),
          take(1),
        )
        .subscribe(([reportsVersions, producedArticlesList]) => {
          const options = reportsVersions.map(({ id, versionNo, createdDateTime }) => ({
            id,
            label: `Version ${versionNo} (${format(
              localeFormatterHelper.localizedDate(createdDateTime),
              'dd.MM, HH:mm:ss',
            )})`,
            versionNo,
            createdDateTime,
          }));
          const producedArticles = producedArticlesList.map((producedArticle) => ({
            ...producedArticle,
            id: producedArticle.recordId,
          }));
          const recordsToSave = producedArticlesList.map(({ articleNo, description, ...rest }) => ({
            ...rest,
          }));
          const hasFilledQuantities = producedArticlesList.some(
            (article) => article.producedQuantity !== null,
          );
          setQuantityFilled(hasFilledQuantities);

          setVersionsOptions(options);
          setSelectedItems({
            version: options.length > 0 ? options[0] : null,
            compare1: options.length > 1 ? options[options.length - 2] : options[0],
            compare2: options.length > 0 ? options[options.length - 1] : null,
            form: printFormOptions[0] || null,
          });
          versionsWorkerState.pub.updateVersionWorkerState({
            versionStatuses: versions.versionStatuses.map((status) =>
              status.isSelected ? { ...status, statusItem: ReportVersionStatus.viewed } : status,
            ),
            producedArticles,
            recordsToSave,
          });
          setLoading(false);
        });

      setLoading(true);
      printJobsService.pub.getProductionReportsVersions({ onDate: date });
      printJobsService.pub.producedArticlesList({
        onDate: date,
        productionPrintListId: selectedRecord?.id || '',
      });

      return () => {
        versionsSub.unsubscribe();
      };
    }
  }, [selectedRecord]);

  const handleClickVersionDifference = () => {
    const isBakingListVersionDifferenceReport =
      selectedItems.form?.id === C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE;
    const hasSelectedRecordId = !!selectedRecord?.id;
    const hasCompareIds = !!selectedItems.compare1?.id && !!selectedItems.compare2?.id;

    if (isBakingListVersionDifferenceReport && hasSelectedRecordId) {
      notifierPreviewPopup.stream.emit('open', {
        pub: () => {
          if (hasCompareIds) {
            return productionReportFromVersionService.pub.r56VersionsDifference({
              type: 'difference',
              variables: {
                params: {
                  productionPrintListId: selectedRecord.id,
                  versionOfDataId1: String(selectedItems.compare1?.id),
                  versionOfDataId2: String(selectedItems.compare2?.id),
                },
              },
            });
          }
          return productionReportFromVersionService.pub.r56VersionsDifferenceFromLiveData({
            type: 'liveData',
            variables: {
              params: {
                productionPrintListId: selectedRecord.id,
                dateOfLiveData: date,
              },
            },
          });
        },
        sub: () =>
          productionReportFromVersionService.sub.mergedAll().pipe(
            responseHandler<CommonWinReportTaskRes>({
              errorReturnType: { taskId: 0, url: '', s3Key: '' },
            }),
          ) as Observable<ReportRes>,
      });
    } else if (hasSelectedRecordId && hasCompareIds) {
      notifierPreviewPopup.stream.emit('open', {
        pub: () =>
          getProductionReportFromVersionDifference(selectedItems.form?.id as C_Report, {
            variables: {
              params: {
                productionPrintListId: selectedRecord.id,
                versionOfDataId1: String(selectedItems.compare1?.id),
                versionOfDataId2: String(selectedItems.compare2?.id),
              },
            },
          }),
        sub: () =>
          productionReportFromVersionService.sub.mergedAll().pipe(
            responseHandler<CommonWinReportTaskRes>({
              errorReturnType: { taskId: 0, url: '', s3Key: '' },
            }),
          ) as Observable<ReportRes>,
      });
    }
  };

  return (
    <Paper variant='outlined' sx={{ height: '100%', padding: '1rem' }}>
      {loading ? (
        <Stack
          direction='column'
          sx={{ height: '100%' }}
          alignItems='center'
          justifyContent='center'
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack direction='column' sx={{ height: '100%' }}>
          <FieldsSection
            nomt
            titleBoxSx={{ paddingTop: '0' }}
            title={t('production.display_selected_version')}
          >
            <FieldsRow alignItems='center' spacing={1}>
              <Select
                onChange={(_, v) => handleSelectChange('version', v)}
                value={selectedItems.version}
                options={versionsOptions}
                width='300px'
                clearIcon={false}
              />
              <CustomIconButton
                size='large'
                disabled={!isPreviewButtonEnabled}
                onClick={() => {
                  if (selectedRecord?.id && selectedItems.version?.id) {
                    notifierPreviewPopup.stream.emit('open', {
                      pub: () => {
                        return getProductionReportFromVersion(
                          selectedRecord?.reportId as C_Report,
                          {
                            variables: {
                              params: {
                                productionPrintListId: selectedRecord.id,
                                versionOfDataId: String(selectedItems.version?.id),
                              },
                            },
                          },
                        );
                      },
                      sub: () =>
                        productionReportFromVersionService.sub.mergedAll().pipe(
                          responseHandler<CommonWinReportTaskRes>({
                            errorReturnType: { taskId: 0, url: '', s3Key: '' },
                          }),
                        ) as Observable<ReportRes>,
                    });
                  }
                }}
                title={t('common.preview')}
              >
                <PreviewIconMUI sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
              </CustomIconButton>
            </FieldsRow>
          </FieldsSection>
          <FieldsSection title={t('production.compare_selected_versions')}>
            <FieldsRow alignItems='center' spacing={2}>
              <Select
                onChange={(_, v) => handleSelectChange('compare1', v)}
                value={selectedItems.compare1}
                options={versionsOptions}
                width='300px'
              />
              <Select
                onChange={(_, v) => handleSelectChange('compare2', v)}
                value={selectedItems.compare2}
                options={versionsOptions}
                width='300px'
              />
            </FieldsRow>
            <FieldsRow alignItems='center' spacing={1}>
              <Select
                label={t('common.print_form')}
                onChange={(_, v) => handleSelectChange('form', v)}
                value={selectedItems.form}
                options={printFormOptions}
                width='500px'
                clearIcon={false}
              />
              <CustomIconButton
                size='large'
                disabled={!isCompareButtonEnabled}
                onClick={handleClickVersionDifference}
                title={t('common.preview')}
              >
                <PreviewIconMUI sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
              </CustomIconButton>
            </FieldsRow>
          </FieldsSection>
          <FieldsSection
            title={t('production.manage_quantity_of_produced_articles')}
            sx={{ width: '38.625rem' }}
          >
            <Table
              data={versions.producedArticles || []}
              initialState={{
                columns: storageHelper.local.getItem('printJobs.producedArticlesColumnModel'),
              }}
              onChangeColumnModel={(v) =>
                storageHelper.local.setItem('printJobs.producedArticlesColumnModel', v)
              }
              rowSelectionModel={selectedRow?.id || undefined}
              onRowClick={(v) => setSelectedRow(v.row)}
              height={298}
              onSearch={(search) => versionsWorkerState.pub.search(search)}
              onSort={(sortModel) => versionsWorkerState.pub.sort(sortModel)}
              childrenButtons={() => (
                <SButtonWrapper>
                  <SaveButton
                    buttonIcon={CheckIconMUI}
                    disabled={isProducedArticlesAvailableToSave}
                    sx={{
                      width: 200,
                    }}
                    onClick={handleSaveProducedArticlesQuantity}
                  >
                    {t('common.save')}
                  </SaveButton>
                </SButtonWrapper>
              )}
            >
              <Column
                field='articleNo'
                headerName={t('common.article_no')}
                sortable
                editable={false}
                width={120}
                disableReorder
              />
              <Column
                field='description'
                headerName={t('common.description')}
                sortable={false}
                editable={false}
                flex={1}
                disableReorder
              />
              <Column
                field='producedQuantity'
                headerName={t('common.quantity')}
                headerAlign='right'
                align='right'
                valueFormatter={({ value }) =>
                  value
                    ? localeFormatterHelper.formatNumber(value, {
                        precision: 3,
                        noTrailingZeros: true,
                      })
                    : ''
                }
                renderEditCell={(params) => (
                  <ProducedQuantityEditCell
                    {...{
                      params,
                      versions,
                      onChangeAvailability: (isAvailable: boolean): void =>
                        setIsProducedArticlesAvailableToSave(isAvailable),
                    }}
                  />
                )}
                sortable={false}
                editable
                disableReorder
              />
            </Table>
          </FieldsSection>
          <notifierPreviewPopup.Component />
        </Stack>
      )}
    </Paper>
  );
};

const SaveButton = styled(NoteButton)<{
  disabled?: boolean;
}>(({ disabled }) => ({
  ...(disabled && {
    background: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))',
    color: 'var(--action-disabled, rgba(0, 0, 0, 0.38))',
  }),
}));

interface ProductionVersionDetailsProps {
  versions: IVersionsWorkerState;
  selectedRecord: ProductionListRes[number] | undefined;
  date: string;
}

type SelectedItemsState = Record<
  'version' | 'compare1' | 'compare2' | 'form',
  ISelectOption | null
>;
