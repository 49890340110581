import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import { TableSelect } from '../../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ISelectOption } from '../../../../../../../../../invoice/create/states/customerList.state.ts';
import { DictCustomersRes } from '../../../../../../../../../../shared/services/configsData/configsData.service.ts';
import { clearRowFromVirtualKeys } from '../additionalFunctions.ts';
import { defaultOrderState } from '../../../../../defaultOrder.state.ts';
import { isEnterKey } from '../../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const CustomerEditCell: FC<ICustomerEditCell> = ({ params, customerList }) => {
  const { api, id, field, row } = params;
  const filteredCustomerList = useMemo(() => {
    return customerList.reduce((acc: IFullOptions[], item) => {
      if (
        item?.id === row.customerId ||
        !api.getSortedRows().some((el) => el.customerId === item.id)
      ) {
        const { internalOrFullName, ...rest } = item;
        acc.push({ label: internalOrFullName, ...rest } as IFullOptions);
      }
      return acc;
    }, []);
  }, [customerList, row]);
  const { setEditCellValue, stopCellEditMode } = api;
  const defaultValue = useMemo(() => {
    return filteredCustomerList?.find((el) => el?.id === row.customerId);
  }, []);
  const [inputValue, setInputValue] = useState(defaultValue?.label || undefined);
  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    setEditCellValue({ id, field, value: v?.label });
    const { label, customerNo: customerNr, id: customerId } = v as IFullOptions;
    defaultOrderState.pub.updateCell(
      {
        ...clearRowFromVirtualKeys(row),
        customerNr,
        customerId,
        [field]: label,
      },
      'nameCell',
    );
    stopCellEditMode({ id, field });
  };

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        (option.customerNo?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          option.label?.toLowerCase().includes(inputValue?.toLowerCase())) as boolean,
    );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && isEnterKey(e)) {
      stopCellEditMode({ id, field });
    }
  };
  return (
    <TableSelect
      autoFocus
      datasetattribute='customerOptions'
      openOnFocus
      size='small'
      width='100%'
      fontSize='14px'
      disableClearable
      options={filteredCustomerList as []}
      filterOptions={filterOptions}
      defaultValue={defaultValue as ISelectOption}
      inputValue={inputValue}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'input') setInputValue(newInputValue);
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

interface ICustomerEditCell {
  params: GridRenderCellParams;
  customerList: Record<string, any>[];
}

interface ExtendedSelectOption extends ISelectOption {
  customerNo?: string;
}
interface IFullOptions extends Omit<DictCustomersRes[number], 'internalOrFullName'> {
  label: DictCustomersRes[number]['internalOrFullName'];
}
