import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import { BehaviorSubject, map, merge, Observable, share, switchMap, tap } from 'rxjs';
import {
  C_Global_Error_Code,
  C_Payments_Filter_Mode,
  ExportAbacusWa_PaymentsMutationVariables,
  ListPaymentsQueryVariables,
} from '../../../../graphql/generatedModel.ts';
import { getFirstAndLastDateOfMonth } from '../../../../shared/helpers/utils/utils.helper.ts';
import {
  AbacusExportRes,
  ListPaymentsRes,
  paymentsService,
} from '../../services/payments.service.ts';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { localeFormatterHelper } from '../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service.ts';
import i18n from '../../../../i18n/init.ts';

export const initPaymentsData: IPayments = {
  action: undefined,
  paymentsData: [],
  filter: {
    filterBy: C_Payments_Filter_Mode.CURRENT_MONTH,
    ...getFirstAndLastDateOfMonth(
      localeFormatterHelper.localizedDate(),
      C_Payments_Filter_Mode.CURRENT_MONTH,
    ),
  },
  sort: [
    {
      field: 'date',
      sort: null,
    },
  ],
  search: '',
};

class PubImpl extends Pub<IPayments> {
  init(data: IPayments) {
    this.emit('init', data);
  }
  sort(sort: IPayments['sort']) {
    this.emit('sort', { sort });
  }
  search(search: IPayments['search']) {
    this.emit('search', { search });
  }
  filter(filter: IPayments['filter']) {
    this.emit('filter', { filter });
  }
  abacusExport(params: ExportAbacusWa_PaymentsMutationVariables) {
    paymentsService.pub.abacusExport(params);
    this.emit('abacusExport', {});
  }
}

class SubImpl extends Sub<IPayments> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<IPayments> {
    return merge(this.filter(), this.abacusExport(), this.updateState()).pipe(
      map(({ paymentsData, sort, search, ...rest }) => {
        let sortedPaymentsData = [...paymentsData];
        if (sort || search) {
          paymentsData = dataHelper
            .data(paymentsData)
            .sort({
              sortModel: sort,
              callback: (data) => {
                sortedPaymentsData = data as [];
              },
            })
            .search({
              search,
              fields: [
                ['date'],
                'invoiceNo',
                'customerNo',
                'internalOrFullName',
                'amount',
                'accountNo',
                'receiptNo',
                'description',
              ],
            })
            .result() as IPayments['paymentsData'];
        }
        const updatedState = {
          ...rest,
          paymentsData,
          sort,
          search,
        };
        this.stream$.next({
          ...updatedState,
          paymentsData: sortedPaymentsData,
          action: 'internalUpdate',
        });
        return updatedState;
      }),
      tap(({ action }) => {
        if (action !== 'sort' && action !== 'search') {
          this.loading$.next(false);
        }
      }),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  /* PRIVATE METHODS start */
  private filter(): Observable<IPayments> {
    return this.actionListener('filter').pipe(
      tap(() => this.loading$.next(true)),
      tap(({ filter }) => paymentsService.pub.listPayments({ filter })),
      switchMap((state) => {
        return paymentsService.sub.listPayments().pipe(
          responseHandler<ListPaymentsRes>({ errorReturnType: [] }),
          map((paymentsData) => {
            return { ...state, paymentsData, selectedRecord: null };
          }),
        );
      }),
    );
  }
  private abacusExport(): Observable<IPayments> {
    return this.actionListener('abacusExport').pipe(
      tap(() => {
        snackbarService.pub.show({
          type: 'loading',
          content: i18n.t('common.data_processing'),
          noAutoHide: true,
        });
      }),
      switchMap((stateV) => {
        return paymentsService.sub.abacusExport().pipe(
          responseHandler<AbacusExportRes>({
            errorReturnType: { url: '' },
            customErrorHandler: (err) => {
              snackbarService.pub.hide();
              return err.code === C_Global_Error_Code.GEC20_NO_DATA ? 'invoice.no_data' : undefined;
            },
          }),
          map((exportRes) => {
            if (exportRes.url) {
              snackbarService.pub.hide();
              stateV.abacusExportUrl = exportRes.url;
            } else {
              stateV.action = undefined;
            }
            return stateV;
          }),
        );
      }),
    );
  }
  private updateState(): Observable<IPayments> {
    return this.actionListener(['sort', 'search']);
  }
  /* PRIVATE METHODS end */
}

class PaymentsState extends State<IPayments> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const paymentsState = new PaymentsState(initPaymentsData);

export interface IPayments {
  paymentsData: ListPaymentsRes;
  filter: ListPaymentsQueryVariables['filter'];
  sort: GridSortModel;
  search: string;
  abacusExportUrl?: string;
  action:
    | 'loader'
    | 'init'
    | 'sort'
    | 'search'
    | 'filter'
    | 'abacusExport'
    | 'internalUpdate'
    | undefined;
}
