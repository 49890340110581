import { Tab, Tabs } from '@mui/material';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';

import { TCompanyConfigs } from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { PrintFormsTabs } from '../states/tabState.model';
import { transitionService } from '../../../../shared/components/loading/service/transition.service.ts';
import { printFormsTabLoadingService } from '../printFormsTabLoading.service.ts';
import { navigateService } from '../../../../shared/services/navigate/navigate.service.ts';
import { TabContentContainer } from '../../../../shared/components/tabs/tabsContainer.component.tsx';
import { ContentLoading } from '../../../../shared/components/loading/loading.component.tsx';
import { TabPanel } from '../../../../shared/components/tabs';
import { GeneralTab } from './tabs/general/general.tab.tsx';
import { ReportsConfigurationTab } from './tabs/reportsConfiguration/reportsConfiguration.tab.tsx';

const PrintFormsDetails: FC = () => {
  const { tab } = useParams() as { tab: PrintFormsTabs };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const initTabData = useLoaderData() as TCompanyConfigs;

  useEffect(() => {
    const unsubLoading = printFormsTabLoadingService.sub.loading().subscribe(setLoading);
    return () => {
      unsubLoading.unsubscribe();
    };
  }, []);

  const handleActiveTab: THandleActiveTab = (_, tab) => {
    transitionService.pub.off();
    printFormsTabLoadingService.pub.loading(true);
    navigateService.pub.navigateTo(`/settings/print-forms/${tab}`);
  };

  return (
    <>
      <Tabs
        scrollButtons={false}
        sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
        variant='scrollable'
        value={tab}
        onChange={handleActiveTab}
      >
        <Tab label={t('settings.general_tab')} value={PrintFormsTabs.general} />
        <Tab
          label={t('settings.reportConfigurationTab')}
          value={PrintFormsTabs.reportConfiguration}
        />
      </Tabs>
      <TabContentContainer
        sx={{ ...(tab === PrintFormsTabs.reportConfiguration && { overflowX: 'hidden' }) }}
      >
        <ContentLoading {...{ loading }}>
          <TabPanel value={PrintFormsTabs.general} activeValue={tab}>
            <GeneralTab params={initTabData} />
          </TabPanel>
          <TabPanel value={PrintFormsTabs.reportConfiguration} activeValue={tab}>
            <ReportsConfigurationTab />
          </TabPanel>
        </ContentLoading>
      </TabContentContainer>
    </>
  );
};

export default PrintFormsDetails;

type THandleActiveTab = (evt: SyntheticEvent<Element, Event>, tab: string) => void;
