import { FC } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { FormToggleButtonGroup } from '../../../../../../../../../shared/components/form/formConnected/formToggleButtonGroup';
import { styled } from '@mui/material';

export const FontStyleToggle: FC<IFontStyleToggle> = ({ prefix }) => {
  return (
    <SFormToggleButtonGroup name={prefix} size='small'>
      <ToggleButton value='isBold'>
        <FormatBoldIcon />
      </ToggleButton>
      <ToggleButton value='isItalic'>
        <FormatItalicIcon />
      </ToggleButton>
      <ToggleButton value='isUnderline'>
        <FormatUnderlinedIcon />
      </ToggleButton>
    </SFormToggleButtonGroup>
  );
};

const SFormToggleButtonGroup = styled(FormToggleButtonGroup)(({ theme }) => ({
  border: 'none',
  '& .MuiToggleButton-root': {
    borderColor: theme.palette.grey.A400,
  },
  '&.MuiToggleButtonGroup-root .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

interface IFontStyleToggle {
  prefix: string;
}
