import { map, Observable } from 'rxjs';
import { Pub, Service, Sub } from '../../../../shared/services/service.abstract';
import { switchMap } from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import {
  getReportsConfiguration,
  saveReportsConfiguration,
  getDataForReportsConfigurationPreview,
} from './gql/printForms.gql.ts';
import {
  GetReportsConfigurationQuery,
  SaveReportsConfigurationMutation,
  SaveReportsConfigurationMutationVariables,
  GetWa_DataForReportsConfigurationPreviewQuery,
} from '../../../../graphql/generatedModel.ts';

type Actions =
  | 'getReportsConfiguration'
  | 'saveReportsConfiguration'
  | 'getDataForReportsConfigurationPreview';

class PubImpl extends Pub<Actions> {
  getReportsConfiguration() {
    this.emit('getReportsConfiguration');
  }
  saveReportsConfiguration(params: SaveReportsConfigurationMutationVariables): void {
    this.emit('saveReportsConfiguration', params);
  }
  getDataForReportsConfigurationPreview() {
    this.emit('getDataForReportsConfigurationPreview');
  }
}

class SubImpl extends Sub<Actions> {
  getReportsConfiguration(): Observable<ReportsConfigurationRes> {
    return this.actionListener('getReportsConfiguration').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getReportsConfiguration,
          params,
        ) as Observable<GetReportsConfigurationQuery>;
      }),
      map((data: GetReportsConfigurationQuery) => {
        return data.wawiAssist?.getReportsConfiguration as ReportsConfigurationRes;
      }),
    );
  }
  saveReportsConfiguration(): Observable<SaveReportsConfigurationRes> {
    return this.actionListener('saveReportsConfiguration').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveReportsConfiguration,
          params,
        ) as Observable<SaveReportsConfigurationMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveReportsConfiguration as SaveReportsConfigurationRes;
      }),
    );
  }
  getDataForReportsConfigurationPreview(): Observable<DataForReportsConfigurationPreviewRes> {
    return this.actionListener('getDataForReportsConfigurationPreview').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getDataForReportsConfigurationPreview,
          params,
        ) as Observable<GetWa_DataForReportsConfigurationPreviewQuery>;
      }),
      map((data: GetWa_DataForReportsConfigurationPreviewQuery) => {
        return data.wawiAssist
          ?.getWA_DataForReportsConfigurationPreview as DataForReportsConfigurationPreviewRes;
      }),
    );
  }
}

class PrintFormsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const printFormsService = new PrintFormsService();

export type ReportsConfigurationRes = NonNullable<
  NonNullable<GetReportsConfigurationQuery['wawiAssist']>['getReportsConfiguration']
>;

export type SaveReportsConfigurationRes = NonNullable<
  NonNullable<
    NonNullable<SaveReportsConfigurationMutation['wawiAssist']>['saveReportsConfiguration']
  >
>;

export type DataForReportsConfigurationPreviewRes = NonNullable<
  NonNullable<
    GetWa_DataForReportsConfigurationPreviewQuery['wawiAssist']
  >['getWA_DataForReportsConfigurationPreview']
>;
