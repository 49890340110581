import { useEffect, useState } from 'react';
import { tap } from 'rxjs';
import { format } from 'date-fns';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper';
import {
  WindowsGeneratedReportPreviewRes,
  IArticlesReportParams,
  IOrderReportParams,
  IInvoiceReportParams,
  IDebtReminderReportParams,
  IGetR10NotifierFileRes,
  reportsService,
} from '../../../../../../shared/services/reports/reports.service';
import { C_Report, C_Win_Report_Task_Status } from '../../../../../../graphql/generatedModel';
import { IGeneratedReportData } from './components/reportPreviewArea';

export const useReportPreview = (): IUseReportPreview => {
  const [loading, setLoading] = useState<boolean>(false);
  const [generatedReport, setGeneratedReport] = useState<IUseReportPreview['generatedReport']>({});

  useEffect(() => {
    const unsubArticlePricelistReport = reportsService.sub
      .createArticlesReport()
      .pipe(
        responseHandler<WindowsGeneratedReportPreviewRes>({ errorReturnType: {} as any }),
        tap(() => setLoading(false)),
      )
      .subscribe(setGeneratedReport);

    const unsubOrderReport = reportsService.sub
      .createOrderReport()
      .pipe(
        responseHandler<WindowsGeneratedReportPreviewRes>({ errorReturnType: {} as any }),
        tap(() => setLoading(false)),
      )
      .subscribe(setGeneratedReport);

    const unsubInvoiceReport = reportsService.sub
      .createInvoiceReport()
      .pipe(
        responseHandler<WindowsGeneratedReportPreviewRes>({ errorReturnType: {} as any }),
        tap(() => setLoading(false)),
      )
      .subscribe(setGeneratedReport);

    const unsubDebtReminderReport = reportsService.sub
      .createDebtReminderReport()
      .pipe(
        responseHandler<IGetR10NotifierFileRes>({ errorReturnType: {} as any }),
        tap(() => setLoading(false)),
      )
      .subscribe((v) => {
        // workaround for now until there isn't status in result
        setGeneratedReport({
          taskId: v.taskId,
          s3Key: v.s3Key ?? '',
          url: v.url ?? '',
          status: C_Win_Report_Task_Status.WRTS2_READY,
        });
      });

    return () => {
      unsubArticlePricelistReport.unsubscribe();
      unsubOrderReport.unsubscribe();
      unsubInvoiceReport.unsubscribe();
      unsubDebtReminderReport.unsubscribe();
    };
  }, []);

  const resetGeneratedReport = () => {
    setGeneratedReport({});
  };

  const generateArticlePricelist: IUseReportPreview['generateArticlePricelist'] = ({
    articlesIds,
    priceValidOn,
    customerId,
  }) => {
    const values: IArticlesReportParams = {
      articlesIds,
      priceValidOn,
      reportId: C_Report.R6_ARTICLE_LIST,
      priceMode: 'byCustomer',
      customerId,
    };
    setLoading(true);
    reportsService.pub.createArticlesReport(values);
  };

  const generateDeliveryNote: IUseReportPreview['generateDeliveryNote'] = ({
    reportId,
    orderId,
  }) => {
    const values: IOrderReportParams = {
      reportId,
      orderId,
    };
    setLoading(true);
    reportsService.pub.createOrderReport(values);
  };

  const generateInvoice: IUseReportPreview['generateInvoice'] = ({ invoiceId }) => {
    const values: IInvoiceReportParams = {
      reportId: C_Report.R9_INVOICE_A4,
      invoiceId,
    };
    setLoading(true);
    reportsService.pub.createInvoiceReport(values);
  };

  const generateDebtReminder: IUseReportPreview['generateDebtReminder'] = ({
    invoiceId,
    invoiceDate,
    customerId,
  }) => {
    const dateForReport = new Date(invoiceDate);
    const considerPaymentsTill = dateForReport.setDate(dateForReport.getDate() - 1);
    const values = {
      invoicesList: [invoiceId],
      customerId,
      periodStart: dateForReport,
      periodEnd: dateForReport,
      paymentsTill: format(localeFormatterHelper.localizedDate(considerPaymentsTill), 'yyyy-MM-dd'),
    };
    setLoading(true);
    reportsService.pub.createDebtReminderReport({ params: values });
  };

  return {
    loading,
    generatedReport,
    resetGeneratedReport,
    generateArticlePricelist,
    generateDeliveryNote,
    generateInvoice,
    generateDebtReminder,
  };
};

interface IUseReportPreview {
  loading: boolean;
  generatedReport: IGeneratedReportData;
  resetGeneratedReport: () => void;
  generateArticlePricelist: (params: IArticlePricelistParams) => void;
  generateDeliveryNote: (params: IOrderReportParams) => void;
  generateInvoice: (params: IInvoiceA4Params) => void;
  generateDebtReminder: (params: IDebtReminderParams) => void;
}

type IArticlePricelistParams = Omit<
  IArticlesReportParams,
  'reportId' | 'priceMode' | 'priceCategory'
>;

type IInvoiceA4Params = Omit<IInvoiceReportParams, 'reportId'>;

type IDebtReminderParams = Omit<IDebtReminderReportParams, 'reportId'>;
