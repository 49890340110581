import { FC } from 'react';
import { ICheckboxList } from '../checkboxList.component';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InboxIcon from '@mui/icons-material/Inbox';
import { grey } from '@mui/material/colors';

export const EmptyBox: FC<IEmptyBox> = ({ emptyListIndication, minHeight }) => {
  const { t } = useTranslation();

  return (
    <SEmptyBox {...{ minHeight }}>
      {emptyListIndication?.icon || <InboxIcon />}
      <Typography variant='h5'>{emptyListIndication?.title || t('common.empty')}</Typography>
      <Typography textAlign='center' variant='subtitle2' color={grey['600']}>
        {emptyListIndication?.text || t('common.no_data')}
      </Typography>
    </SEmptyBox>
  );
};

const SEmptyBox = styled(Box)<{ minHeight?: string }>(({ theme, minHeight }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  ...(minHeight && { minHeight }),
  '& svg': {
    height: 60,
    width: 'auto',
    color: theme.palette.info.main,
  },
}));

interface IEmptyBox {
  emptyListIndication: ICheckboxList['emptyListIndication'];
  minHeight?: string;
}
