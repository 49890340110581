import { isDate, addMilliseconds } from 'date-fns';
import { C_Country } from '../../../graphql/generatedModel';
import { TCompanyConfigs } from '../../services/companyConfig/companyConfig.service';
import { utcToZonedTime, toDate, getTimezoneOffset } from 'date-fns-tz';

class LocaleFormatterHelper {
  private locale!: string;
  private decimalPlacesCount!: number;
  private companyTimeZone!: string;
  private localTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  setCompanyConfig(values: TCompanyConfigs) {
    const { countryId, decimalPlacesCount, companyTimeZone } = values || {};
    this.decimalPlacesCount = decimalPlacesCount || 2;
    this.companyTimeZone = companyTimeZone || this.localTimeZone;
    switch (countryId as C_Country) {
      case C_Country.CO1_SWITZERLAND:
        this.locale = 'de-CH';
        break;
      case C_Country.CO2_GERMANY:
        this.locale = 'de';
        break;
      case C_Country.CO3_AUSTRIA:
        this.locale = 'de-at';
        break;
      case C_Country.CO5_LUXEMBOURG:
        this.locale = 'de-lu';
        break;
      default:
        this.locale = 'en';
    }
  }
  formatNumber(v: number, options: IFormatNumberOptions = {}): string {
    const {
      precision = this.decimalPlacesCount || 2,
      minimalPrecision,
      noTrailingZeros,
      returnZero = true,
    } = options;
    if (isNaN(v) || (!returnZero && !v)) return '';
    const formatOptions: Intl.NumberFormatOptions = {};
    formatOptions.maximumFractionDigits = precision;
    !noTrailingZeros && (formatOptions.minimumFractionDigits = minimalPrecision || precision);
    return Number(v).toLocaleString(this.locale, formatOptions);
  }
  formatDate(
    date: Date | string | number,
    options?: Intl.DateTimeFormatOptions,
    fullOptionsReplace?: boolean,
  ): string {
    const { ...restOptions } = options || {};
    if (!date) return '';
    try {
      const internalOptions = fullOptionsReplace
        ? restOptions
        : { day: '2-digit', month: '2-digit', year: 'numeric', ...restOptions };
      return new Intl.DateTimeFormat(this.locale, {
        ...internalOptions,
      } as Intl.DateTimeFormatOptions).format(this.localizedDate(date));
    } catch {
      return '';
    }
  }
  parseDateWithoutLocalTimezone(isoString: string, options: IParseDateOpt = {}): Date | null {
    const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-]\d{2}:\d{2})?$/;
    // Regular expression to validate ISO 8601 format
    if (!isoRegex.test(isoString)) {
      return null;
    }
    const [datePart, timePart] = isoString.split('T');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.replace('Z', '').split(':');
    const { resetTime, resetSecond } = options;
    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(resetTime ? 0 : hours),
      Number(resetTime ? 0 : minutes),
      Number(resetTime || resetSecond ? 0 : seconds),
    );
  }

  localizedDate(value?: number | string | null | Date): Date {
    const v = typeof value === 'string' ? value.replace(/\//g, '-') : value;
    if (v) {
      const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
      const checkedString = typeof v === 'string' && (!v.includes(':') || regex.test(v));
      if (isDate(v) || checkedString) {
        return toDate(v);
      }
      const localHoursOffset = getTimezoneOffset(this.localTimeZone, toDate(v));
      return addMilliseconds(toDate(v), localHoursOffset * -1);
    }
    return utcToZonedTime(new Date(), this.companyTimeZone);
  }
}
export const localeFormatterHelper = new LocaleFormatterHelper();

export interface IFormatNumberOptions {
  precision?: number; // Number of decimal signs. Default value is taken from DB. Must be provided for integers (as 0) or to use constant precision independent of DB value
  minimalPrecision?: number; // Use it in cases when noTrailingZeros should remove not all trailing zeros. F.e. getLocalNum(1.1, { precision: 3, minimalPrecision: 2, noTrailingZeros: true }) returns '1.10' (without minimalPrecision parameter it returns '1.1')
  noTrailingZeros?: boolean; // Don't show decimal zeros in integers if precision is used. F.e. getLocalNum(1, { precision: 2 }) returns '1'
  returnZero?: boolean; // true by default. Returns '' for 0 if false.
}

interface IParseDateOpt {
  resetTime?: boolean;
  resetSecond?: boolean;
}
