import { FC } from 'react';
import { IconButton } from '../../../../../../../order/common/iconButton/iconButton.component';
import { useTranslation } from 'react-i18next';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { IReportAnyObjectTunerProps } from '../../reportsConfiguration.tab';
import { useFormContext } from 'react-hook-form';
import { reportsConfigurationTabState } from '../../../../../states/reportsConfiguration.state';
import { createNestedObject } from '../../../../../../../../shared/helpers/utils/utils.helper';
import equal from 'fast-deep-equal/react';

export const ResetButton: FC<IResetButton> = ({
  reportDefaultValuesPath,
  reportObjectName,
  reportActualValuesPath,
  ignoredFields,
}) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const path = `${reportActualValuesPath}.${reportObjectName}`;
  const defaultPath = `${reportDefaultValuesPath}.${reportObjectName}`;
  const defaultValuesRaw = getValues(defaultPath);
  const valuesRaw = watch(path);
  const defaultValues = removeIgnoredFields(defaultValuesRaw, ignoredFields);
  const values = removeIgnoredFields(valuesRaw, ignoredFields);
  const isDefault = equal(values, defaultValues);

  return (
    <IconButton
      color='primary'
      size='large'
      title={t('settings.reset_to_default')}
      disabled={isDefault}
      onClick={(e) => {
        e.stopPropagation();
        const dataToSave = createNestedObject(`${path}`, defaultValues);
        reportsConfigurationTabState.pub.saveData(dataToSave);
      }}
    >
      <SettingsBackupRestoreIcon />
    </IconButton>
  );
};

function removeIgnoredFields<T extends Record<string, any>>(obj: T, ignoredFields?: string[]): T {
  if (!ignoredFields) {
    return obj;
  }
  const result = structuredClone(obj);
  ignoredFields.forEach((field) => {
    if (field in result) {
      delete result[field];
    }
  });
  return result;
}

interface IResetButton
  extends Pick<
    IReportAnyObjectTunerProps,
    'reportDefaultValuesPath' | 'reportObjectName' | 'reportActualValuesPath'
  > {
  ignoredFields?: string[];
}
