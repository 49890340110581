import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormNumericField } from '../../../../../../../../shared/components/form';
import { useFormContext } from 'react-hook-form';
import { getJSONValueByPath } from '../../../../../../../../shared/helpers/utils/utils.helper';
import { IReportAnyObjectTunerProps } from '../../reportsConfiguration.tab';

export const PageMarginsTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(`${reportDefaultValuesPath}.${reportObjectName}`);
  const path = `${reportActualValuesPath}.${reportObjectName}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormNumericField
          label={`${t('settings.left_margin')} ${t('settings.cm_brackets')}`}
          name={`${path}.left`}
          placeholder={defaultValues?.['left']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.left`))}
          helperText={
            getJSONValueByPath(errors, `${path}.left`)
              ? t(String(getJSONValueByPath(errors, `${path}.left`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={4}>
        <FormNumericField
          label={`${t('settings.top_margin')} ${t('settings.cm_brackets')}`}
          name={`${path}.top`}
          placeholder={defaultValues?.['top']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.top`))}
          helperText={
            getJSONValueByPath(errors, `${path}.top`)
              ? t(String(getJSONValueByPath(errors, `${path}.top`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={4}>
        <FormNumericField
          label={`${t('settings.bottom_margin')} ${t('settings.cm_brackets')}`}
          name={`${path}.bottom`}
          placeholder={defaultValues?.['bottom']?.toString()}
          error={Boolean(getJSONValueByPath(errors, `${path}.bottom`))}
          helperText={
            getJSONValueByPath(errors, `${path}.bottom`)
              ? t(String(getJSONValueByPath(errors, `${path}.bottom`).message))
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
    </Grid>
  );
};
