import { gql } from 'graphql-request';

export const listTaxesPeriodsAndRates = gql`
  query ListWA_TaxesPeriodsAndRates {
    wawiAssist {
      listWA_TaxesPeriodsAndRates {
        fromDate
        toDate
        vatNormal
        vatReduced
        vatSpecial1
        vatSpecial2
        vatZero
      }
    }
  }
`;

export const executeArticlesImport = gql`
  mutation executeWA_ArticlesImport($importData: [WA_ArticleImportDataInput!]!) {
    wawiAssist {
      executeWA_ArticlesImport(importData: $importData) {
        recordsInserted
        recordsUpdated
        recordsSkipped
      }
    }
  }
`;

export const executeCustomersImport = gql`
  mutation executeWA_CustomersImport($importData: [WA_CustomerImportDataInput!]!) {
    wawiAssist {
      executeWA_CustomersImport(importData: $importData) {
        recordsInserted
        recordsUpdated
        recordsSkipped
      }
    }
  }
`;

export const listValidationDataForArticlesImport = gql`
  query listWA_ArticleNumbers {
    wawiAssist {
      dictWA_Articles {
        articleNo
      }
    }
  }
`;

export const listValidationDataForCustomersImport = gql`
  query ListWA_CustomerNumbers {
    wawiAssist {
      dictWA_Customers {
        customerNo
      }
    }
  }
`;

export const getImportDataFromFile = gql`
  query GetWA_ImportDataFromFile($jobId: String!) {
    wawiAssist {
      getWA_ImportDataFromFile(jobId: $jobId) {
        data {
          customerImportData {
            customerNo
            isActive
            informationTip
            customerAddressKindId
            customerGroupId
            deliveryNoteGroupId
            salutation
            name
            surname
            internalName
            addition
            street
            postBox
            zipCode
            city
            country
            deliveryAddressSalutation
            deliveryAddressName
            deliveryAddressAddition
            deliveryAddressStreet
            deliveryAddressPostBox
            deliveryAddressZipCode
            deliveryAddressCity
            deliveryAddressCountry
            contactPerson
            phoneBusiness
            phoneBusiness2
            phonePrivate
            phoneMobile
            fax
            email
            emailForInvoice
            website
            priceCategoryId
            taxSystemId
            discountKindId
            quantityDiscountSettingId
            discountOnGroup1
            discountOnGroup2
            discountOnGroup3
            discountOnGroup4
            discountOnGroup5
            discountOnGroup6
            discountOnGroup7
            discountOnGroup8
            discountOnGroup9
            discountOnGroup10
            discountOnGroup11
            discountOnGroup12
            discountOnGroup13
            discountOnGroup14
            discountOnGroup15
            discountOnGroup16
            discountOnGroup17
            discountOnGroup18
            discountOnGroup19
            discountOnGroup20
            invoicingIntervalId
            debitorStatus
            returnLimit
            eInvoiceFormat
            paymentPeriod
            individualPaymentTerms
            skonto
            skontoPeriod
            besrBankId
            debtorAccount
            slipReferenceNo
            directDebitBankId
            bank1Name
            bank1BIN
            bank1AccountNo
            bank1IBAN
            bank1BIC
            bank1MandatNo
            bank1MandatSignatureDate
            bank2Name
            bank2BIN
            bank2AccountNo
            bank2IBAN
            bank2BIC
            bank2MandatNo
            bank2MandatSignatureDate
            isDeliveredMon
            isDeliveredTue
            isDeliveredWed
            isDeliveredThu
            isDeliveredFri
            isDeliveredSat
            isDeliveredSun
            defaultOrderSourceId
            isAllowedAutoOrder
            isDeliverySplitting
            deliverySplittingPartsCount
            isFlexibleOrders
            distributionLowerLimit
            distributionHigherLimit
            transportSectorId
            isAlwaysInTourplan
            tourplanNote
            tourplanId
            tourPreparationArea
            tourDepartureTime
            tourPosition
            tourplanSatId
            tourSatPreparationArea
            tourSatDepartureTime
            tourSatPosition
            tourplanSunId
            tourSunPreparationArea
            tourSunDepartureTime
            tourSunPosition
            defaultNoteInOrder
            defaultNoteInOrder2
            defaultNoteInInvoice
            defaultNoteInInvoice2
            deliveryNoteCopies
            invoiceCopies
            eBillAccount
            orderReportId
            invoiceReportId
            invoiceSummaryReportId
            invoicePaymentSlipReportId
            offerReportId
            isCashAssistCreditCustomer
            caPriceAndDiscountSourceId
            caSpecialDiscount
          }
          articleImportData {
            articleNo
            description
            isActive
            note
            isNotifyOnOrderStage
            discountGroupId
            productionGroupId
            marketingGroupId
            shippingPointId
            saleUnitId
            quantityPerLot
            vatCode
            isNoDiscount
            price1
            price2
            price3
            price4
            price5
            price6
            price7
            price8
            price9
            price10
            isDeliveredMon
            isDeliveredTue
            isDeliveredWed
            isDeliveredThu
            isDeliveredFri
            isDeliveredSat
            isDeliveredSun
            availabilityInProcurementKindId
            isShowOnProductionPrintLists
            isDough
            isFermentationInterrupt
            doughWeightBeforeBaking
            countPerSheetOfCuttingMachine
            countOfSheetsPerCuttingMachine
            weightOfLiterOfDough
            roundLitersTo
            willReplacedWithProductionCalc
            isProductionQuantityRounding
            productionQuantityRoundTo
            caVisibilityStatusId
            eanCode
            isEanCodeManagedByCA
            declaration
            consumersInformation
            consumersStorageInfo
            daysToSell
            daysToConsume
            daysShelfLife
            isStoreInCoolPlace
            storagePlace
            sellingWeight
            shippingWeight
            daysToProduce
            isDoughSplitting
            doughSplittingDough1Amount
          }
        }
        status
      }
    }
  }
`;

export const initProcessingFileWithImportData = gql`
  mutation InitWA_ProcessingFileWithImportData($key: String!) {
    wawiAssist {
      initWA_ProcessingFileWithImportData(key: $key)
    }
  }
`;
