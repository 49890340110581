import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper.ts';

type Keys =
  | ImportIso
  | 'debitor'
  | 'openDebts'
  | 'invoicesDates'
  | 'considerPaymentsUpTo'
  | 'invoiceNo'
  | 'remainingAmount'
  | 'termOfPayment'
  | 'paymentDate'
  | 'paymentDelay'
  | 'reminderStep'
  | 'overdue'
  | 'paid'
  | 'notOverdue'
  | 'from1To15Days'
  | 'from16To30Days'
  | 'moreThan30Days'
  | 'enterPayment'
  | 'payments'
  | 'payment'
  | 'payment_other'
  | 'useOverpayments'
  | 'accountNo'
  | 'billNo'
  | 'paymentInvoiceNo'
  | 'doYouWantToUseFromOverpayments'
  | 'reminderText'
  | 'allCustomers'
  | 'allInvoicesOfSelectedCustomer'
  | 'onlySelectedInvoice'
  | 'emailMessage'
  | 'noRecordSelected'
  | 'reminder'
  | 'reminders'
  | 'reminderHasAlreadyBeenSent'
  | 'invoicePaidNotFully'
  | 'invoiceOverpaid'
  | 'abacusExportAllBills'
  | 'abacusExportNewPayments';
type ImportIso =
  | 'importIso'
  | 'addPaymentsInfo.noData'
  | 'addPaymentsInfo.hint'
  | 'addPaymentsInfo.upload'
  | 'paymentsDetailsInfo.upload'
  | 'paymentsDetailsInfo.report'
  | 'paymentsDetailsInfo.import'
  | 'paymentsDetailsInfo.invoiceNo'
  | 'paymentsDetailsInfo.invoiceDate'
  | 'paymentsDetailsInfo.remainingAmount'
  | 'paymentsDetailsInfo.paymentAmount'
  | 'paymentsDetailsInfo.discountAmount'
  | 'paymentsDetailsInfo.recognizedAmount'
  | 'paymentsDetailsInfo.FIBUAccount'
  | 'paymentsDetailsInfo.status'
  | 'paymentsDetailsInfo.errorMessage'
  | 'paymentsDetailsInfo.subscriberNumber'
  | 'paymentsDetailsInfo.reference';

const t = LocalizationHelper<Keys>('debitor');

export const debitorDe = t({
  debitor: 'Debitor',
  openDebts: 'Offene Debitoren',
  invoicesDates: 'Datum der Rechnungen',
  considerPaymentsUpTo: 'Zahlungen berücksichtigen',
  invoiceNo: 'Rechnung-Nr.',
  remainingAmount: 'Restbetrag',
  termOfPayment: 'Zahlungsziel',
  paymentDate: 'Zahlungs Datum',
  paymentDelay: 'Zahlungs Verzug',
  reminderStep: 'Mahnungs Stufe',
  overdue: 'Überfällig',
  paid: 'Bezahlt',
  notOverdue: 'Nicht Überfällig',
  from1To15Days: '1-15 Tage',
  from16To30Days: '16-30 Tage',
  enterPayment: 'Zahlung verbuchen',
  payments: 'Zahlungen',
  payment: 'Zahlung',
  payment_other: 'Zahlungen',
  useOverpayments: 'überzahlungen verwenden',
  accountNo: 'Konto-Nr.',
  billNo: 'Beleg-Nr.',
  paymentInvoiceNo: 'Zahlung Rechnung-Nr. {{invoiceNo}}',
  doYouWantToUseFromOverpayments:
    'Wollen Sie {{amountUsed}} aus Überzahlungen verwenden ({{overpaymentsAmount}} verfügbar)?',
  moreThan30Days: '> 30 Tage',
  reminderText: 'Mahnungstext',
  allCustomers: 'Alle Kunde',
  allInvoicesOfSelectedCustomer: 'Alle Rechnungen von ausgewähltem Kunden',
  onlySelectedInvoice: 'Nur ausgewählte Rechnung',
  emailMessage:
    'Sehr geehrte Damen und Herren\n \nUnseren Unterlagen zufolge gibt es für die Rechnung noch kein Zahlungseingang. Hiermit möchten wir Sie daran erinnern, dass oben genannte Rechnung noch nicht beglichen wurde.\n \nBitte begleichen Sie den Betrag demnächst.',
  noRecordSelected: 'Keine Datensätze ausgewählt. Bitte wählen Sie zuerst einen Datensatz aus.',
  reminder: 'Mahnung',
  reminders: 'Mahnungen',
  importIso: 'Import ISO (CAMT.054)',
  reminderHasAlreadyBeenSent: 'Die Mahnung wurde schon gesendet.',
  invoicePaidNotFully: 'Rechnung wird nicht vollständig bezahlt',
  invoiceOverpaid: 'Rechnung wird überbezahlt',
  abacusExportAllBills: 'Abacus export (alle Belege)',
  abacusExportNewPayments: 'Abacus export (neue zahlungen)',
  'addPaymentsInfo.noData': 'Keine Daten vorhanden!',
  'addPaymentsInfo.hint': 'Klicken Sie auf die Schaltfläche, um eine Datei hinzuzufügen',
  'addPaymentsInfo.upload': 'Datei hinzufügen',
  'paymentsDetailsInfo.upload': 'Dateiname',
  'paymentsDetailsInfo.report': 'Formular',
  'paymentsDetailsInfo.import': 'Import',
  'paymentsDetailsInfo.reference': 'Referenznummer',
  'paymentsDetailsInfo.invoiceNo': 'Faktura Nr.',
  'paymentsDetailsInfo.invoiceDate': 'Faktura Datum.',
  'paymentsDetailsInfo.remainingAmount': 'Restbetrag',
  'paymentsDetailsInfo.paymentAmount': 'Zahlbetrag',
  'paymentsDetailsInfo.discountAmount': 'Skonto Betrag',
  'paymentsDetailsInfo.recognizedAmount': 'Verbuchter Betrag',
  'paymentsDetailsInfo.FIBUAccount': 'FIBU-Konto',
  'paymentsDetailsInfo.status': 'Status',
  'paymentsDetailsInfo.errorMessage': 'Fehlermeldung',
  'paymentsDetailsInfo.subscriberNumber': 'Teilnehmernummer',
});

export const debitorFr = t({
  debitor: 'Débiteur',
  openDebts: 'Créances ouvertes',
  invoicesDates: 'Dates des factures',
  considerPaymentsUpTo: "Prendre en compte les paiements jusqu'à",
  invoiceNo: 'N° de facture',
  remainingAmount: 'Montant restant',
  termOfPayment: 'Conditions de paiement',
  paymentDate: 'Date de paiement',
  paymentDelay: 'Retard de paiement',
  reminderStep: 'Nombre de rappels',
  overdue: 'En retard',
  paid: 'Payé',
  enterPayment: 'Saisir un paiement',
  payments: 'Paiements',
  payment: 'Paiement',
  payment_other: 'Autres paiements',
  useOverpayments: 'Utiliser les trop-perçus',
  accountNo: 'N° de compte',
  billNo: 'N° de facture',
  paymentInvoiceNo: 'Paiement pour la facture N° {{invoiceNo}}',
  doYouWantToUseFromOverpayments:
    'Voulez-vous utiliser {{amountUsed}} des trop-perçus ({{overpaymentsAmount}} disponibles) ?',
  notOverdue: 'Pas en retard',
  from1To15Days: '1-15 jours',
  from16To30Days: '16-30 jours',
  moreThan30Days: '> 30 jours',
  reminderText: 'Texte de rappel',
  allCustomers: 'Tous les clients',
  allInvoicesOfSelectedCustomer: 'Toutes les factures du client sélectionné',
  onlySelectedInvoice: 'Seulement la facture sélectionnée',
  emailMessage:
    'Madame, Monsieur,\n \nSelon nos dossiers, nous n’avons pas encore reçu votre règlement. Nous vous rappelons que la facture ci-dessus est toujours impayée.\n \nNous vous prions de bien vouloir effectuer votre paiement dans les plus brefs délais.',
  noRecordSelected:
    "Aucun enregistrement sélectionné. Veuillez d'abord sélectionner un enregistrement.",
  reminder: 'Rappel',
  reminders: 'Rappels',
  importIso: 'Importer ISO (CAMT.054)',
  reminderHasAlreadyBeenSent: 'Le rappel a déjà été envoyé.',
  invoicePaidNotFully: 'La facture ne sera pas totalement payée',
  invoiceOverpaid: 'La facture sera surpayée',
  abacusExportAllBills: 'Export Abacus (tous les documents)',
  abacusExportNewPayments: 'Export Abacus (nouveaux paiements)',
  'addPaymentsInfo.noData': 'Aucune donnée disponible !',
  'addPaymentsInfo.hint': 'Cliquez sur le bouton pour ajouter un fichier',
  'addPaymentsInfo.upload': 'Ajouter un fichier',
  'paymentsDetailsInfo.upload': 'Nom du fichier',
  'paymentsDetailsInfo.report': 'Imprimer le formulaire',
  'paymentsDetailsInfo.import': 'Importer',
  'paymentsDetailsInfo.invoiceNo': 'N° de facture',
  'paymentsDetailsInfo.invoiceDate': 'Date de la facture',
  'paymentsDetailsInfo.remainingAmount': 'Montant restant',
  'paymentsDetailsInfo.paymentAmount': 'Montant du paiement',
  'paymentsDetailsInfo.discountAmount': 'Montant de la remise',
  'paymentsDetailsInfo.recognizedAmount': 'Montant reconnu',
  'paymentsDetailsInfo.FIBUAccount': 'Compte FIBU',
  'paymentsDetailsInfo.status': 'Statut',
  'paymentsDetailsInfo.errorMessage': "Message d'erreur",
  'paymentsDetailsInfo.subscriberNumber': "N° d'abonné",
  'paymentsDetailsInfo.reference': 'Référence',
});

export const debitorIt = t({
  debitor: 'Debitore',
  openDebts: 'Crediti aperti',
  invoicesDates: 'Date delle fatture',
  considerPaymentsUpTo: 'Considera i pagamenti fino a',
  invoiceNo: 'Numero fattura',
  remainingAmount: 'Importo rimanente',
  termOfPayment: 'Condizioni di pagamento',
  paymentDate: 'Data di pagamento',
  paymentDelay: 'Ritardo nel pagamento',
  reminderStep: 'Numero solleciti',
  overdue: 'Scaduto',
  paid: 'Pagato',
  enterPayment: 'Inserisci pagamento',
  payments: 'Pagamenti',
  payment: 'Pagamento',
  payment_other: 'Pagamenti',
  useOverpayments: 'Usa pagamenti in eccesso',
  accountNo: 'Numero conto',
  billNo: 'Numero bolletta',
  paymentInvoiceNo: 'Numero fattura di pagamento {{invoiceNo}}',
  doYouWantToUseFromOverpayments:
    'Vuoi usare {{amountUsed}} dai pagamenti in eccesso ({{overpaymentsAmount}} disponibili)?',
  notOverdue: 'Non scaduto',
  from1To15Days: '1-15 giorni',
  from16To30Days: '16-30 giorni',
  moreThan30Days: '> 30 giorni',
  reminderText: 'Testo del sollecito',
  allCustomers: 'Tutti i clienti',
  allInvoicesOfSelectedCustomer: 'Tutte le fatture del cliente selezionato',
  onlySelectedInvoice: 'Solo la fattura selezionata',
  emailMessage:
    'Gentili Signore e Signori,\n \nSecondo i nostri registri, non abbiamo ancora ricevuto un pagamento. Questo è un promemoria che la suddetta fattura risulta ancora non pagata.\n \nVi preghiamo di effettuare il pagamento al più presto.',
  noRecordSelected: 'Nessun record selezionato. Selezionare prima un record.',
  reminder: 'Sollecito',
  reminders: 'Solleciti',
  importIso: 'Importa ISO (CAMT.054)',
  reminderHasAlreadyBeenSent: 'Il sollecito è già stato inviato.',
  invoicePaidNotFully: 'La fattura sarà pagata parzialmente',
  invoiceOverpaid: 'La fattura sarà pagata in eccesso',
  abacusExportAllBills: 'Esportazione Abacus (tutti i documenti)',
  abacusExportNewPayments: 'Esportazione abaco (nuovi pagamenti)',
  'addPaymentsInfo.noData': 'Nessun dato disponibile!',
  'addPaymentsInfo.hint': 'Clicca sul pulsante per aggiungere un file',
  'addPaymentsInfo.upload': 'Aggiungi file',
  'paymentsDetailsInfo.upload': 'Nome del file',
  'paymentsDetailsInfo.report': 'Stampa modulo',
  'paymentsDetailsInfo.import': 'Importa',
  'paymentsDetailsInfo.invoiceNo': 'Numero fattura',
  'paymentsDetailsInfo.invoiceDate': 'Data fattura',
  'paymentsDetailsInfo.remainingAmount': 'Importo rimanente',
  'paymentsDetailsInfo.paymentAmount': 'Importo del pagamento',
  'paymentsDetailsInfo.discountAmount': 'Importo dello sconto',
  'paymentsDetailsInfo.recognizedAmount': 'Importo riconosciuto',
  'paymentsDetailsInfo.FIBUAccount': 'Conto FIBU',
  'paymentsDetailsInfo.status': 'Stato',
  'paymentsDetailsInfo.errorMessage': 'Messaggio di errore',
  'paymentsDetailsInfo.subscriberNumber': 'Numero abbonato',
  'paymentsDetailsInfo.reference': 'Riferimento',
});

export const debitorEn = t({
  debitor: 'Debitor',
  openDebts: 'Open receivables',
  invoicesDates: 'Invoices dates',
  considerPaymentsUpTo: 'Consider payments up to',
  invoiceNo: 'Invoice No.',
  remainingAmount: 'Remaining amount',
  termOfPayment: 'Terms of payment',
  paymentDate: 'Payment date',
  paymentDelay: 'Payment delay',
  reminderStep: 'Reminders count',
  overdue: 'Overdue',
  paid: 'Paid',
  enterPayment: 'Enter payment',
  payments: 'Payments',
  payment: 'Payment',
  payment_other: 'Payments',
  useOverpayments: 'Use overpayments',
  accountNo: 'Account No.',
  billNo: 'Bill No.',
  paymentInvoiceNo: 'Payment Invoice No. {{invoiceNo}}',
  doYouWantToUseFromOverpayments:
    'Do you want to use {{amountUsed}} from overpayments ({{overpaymentsAmount}} available)?',
  notOverdue: 'Not overdue',
  from1To15Days: '1-15 days',
  from16To30Days: '16-30 days',
  moreThan30Days: '> 30 days',
  reminderText: 'Reminder text',
  allCustomers: 'All customers',
  allInvoicesOfSelectedCustomer: 'All invoices of selected customer',
  onlySelectedInvoice: 'Only selected invoice',
  emailMessage:
    'Dear Ladies and Gentlemen\n \nAccording to our records, we have not yet received a remittance. This is to remind you that above invoice is still unpaid.\n \nPlease send your payment soon.',
  noRecordSelected: 'No records selected. Please select a record first.',
  reminder: 'Reminder',
  reminders: 'Reminders',
  importIso: 'Import ISO (CAMT.054)',
  reminderHasAlreadyBeenSent: 'The reminder has already been sent.',
  invoicePaidNotFully: 'Invoice will be paid not fully',
  invoiceOverpaid: 'Invoice will be overpaid',
  abacusExportAllBills: 'Abacus export (all bills)',
  abacusExportNewPayments: 'Abacus export (new payments)',
  'addPaymentsInfo.noData': 'No data available!',
  'addPaymentsInfo.hint': 'Click the button to add a file',
  'addPaymentsInfo.upload': 'Add file',
  'paymentsDetailsInfo.upload': 'File name',
  'paymentsDetailsInfo.report': 'Print form',
  'paymentsDetailsInfo.import': 'Import',
  'paymentsDetailsInfo.invoiceNo': 'Invoice No.',
  'paymentsDetailsInfo.invoiceDate': 'Invoice date.',
  'paymentsDetailsInfo.remainingAmount': 'Remaining amount',
  'paymentsDetailsInfo.paymentAmount': 'Payment amount',
  'paymentsDetailsInfo.discountAmount': 'Discount amount',
  'paymentsDetailsInfo.recognizedAmount': 'Recognized amount',
  'paymentsDetailsInfo.FIBUAccount': 'FIBU Account',
  'paymentsDetailsInfo.status': 'Status',
  'paymentsDetailsInfo.errorMessage': 'Error message',
  'paymentsDetailsInfo.subscriberNumber': 'Subscriber number',
  'paymentsDetailsInfo.reference': 'Reference',
});
