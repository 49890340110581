import { Typography } from '@mui/material';
import { FC } from 'react';

export const Header: FC<IHeader> = ({ listLabel }) => {
  return (
    <>
      {listLabel && (
        <Typography variant='h5' mb={1}>
          {listLabel}
        </Typography>
      )}
    </>
  );
};

interface IHeader {
  listLabel?: string;
}
