import ArticleIcon from '@mui/icons-material/ViewInAr';
import { Button, Stack, Box, Checkbox } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './tableHeader.component';
import { Table } from '../../../../../../../shared/components/table/table.component';
import { Column } from '../../../../../../../shared/components/table/components/common.components';
import { viewRows } from './views/viewRows';
import { ExecuteArticlesImportMutationRes } from '../../../../services/configuration.service';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { Theme as theme } from '../../../../../../../shared/styles/theme/theme.ts';
import { Footer } from '../../../../../../../shared/components/table/components/common.components';
import { configurationService } from '../../../../services/configuration.service';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler.ts';
import { configurationTabLoadingService } from '../../../../configurationTabLoading.service.ts';
import { IOptionsData } from './importDetailsInfo.component.tsx';
import { ArticleNrEditCell } from './cells/article/articleNrEdit.cell.tsx';
import { AnyTextFieldEditCell } from './cells/article/anyTextFieldEdit.cell.tsx';
import { AnyBooleanFieldCell } from './cells/article/anyBooleanField.cell.tsx';
import { AnyDropdownFieldEditCell } from './cells/article/anyDropdownFieldEdit.cell.tsx';
import { AnyDropdownFieldCell } from './cells/article/anyDropdownField.cell.tsx';
import { AnyNumberFieldEditCell } from './cells/article/anyNumberFieldEdit.cell.tsx';
import { IArticleImportData } from '../import.tab.tsx';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service.ts';

export const ArticleTable: FC<IArticleTable> = ({
  dataFromFile,
  heigthWhenCollapsed,
  shownRecordsCountWhenCollapsed,
  existingNumbers,
  options,
}) => {
  const { t } = useTranslation();
  const [gridFullData, setGridFullData] = useState<IArticleImportData[]>([]);
  const [isAllDataShown, setIsAllDataShown] = useState(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());
  const icon = <ArticleIcon color='primary' fontSize='small' sx={{ marginTop: '10px' }} />;

  const toggleSelectAll = () => {
    let selectedIds = new Set<number>();
    if (!selectAll) {
      selectedIds = new Set(gridFullData.map((v) => v?.id));
    }
    setSelectedIds(selectedIds as Set<number>);
    setSelectAll(!selectAll);
  };

  const toggleCheckbox = (id: number) => {
    const newSelectedIds = new Set(selectedIds);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
      setSelectAll(false);
    } else {
      newSelectedIds.add(id);
      const allIdsSelected = gridFullData.every((item) => newSelectedIds.has(item?.id));
      setSelectAll(allIdsSelected);
    }
    setSelectedIds(newSelectedIds);
  };

  useEffect(() => {
    let allData = dataFromFile.map((item) => {
      item.isDuplicate = dataFromFile.some(
        (value) => value.articleNo === item.articleNo && value.id != item.id,
      );
      item.isRecordWhichOverwrites = existingNumbers.some((value) => value === item.articleNo);
      return item;
    });
    allData.sort((a, b) => a.articleNo.localeCompare(b.articleNo));
    setSelectedIds(new Set(allData.map((v) => v?.id)));
    setSelectAll(true);
    setGridFullData(allData);
    setIsAllDataShown(allData?.length <= shownRecordsCountWhenCollapsed);
  }, [dataFromFile]);

  const isDuplicates = useMemo(() => {
    return gridFullData.some((value) => value.isDuplicate === true);
  }, [gridFullData]);

  const isRecordsWhichOverwrites = useMemo(() => {
    return gridFullData.some((value) => value.isRecordWhichOverwrites === true);
  }, [gridFullData]);

  const needNotExpandPossibility = useMemo(() => {
    return gridFullData?.length <= shownRecordsCountWhenCollapsed;
  }, [gridFullData]);

  const gridShownData = useMemo(() => {
    if (needNotExpandPossibility) {
      return gridFullData;
    } else {
      return gridFullData?.slice(0, shownRecordsCountWhenCollapsed);
    }
  }, [gridFullData]);

  const dataToWrite = useMemo(() => {
    let data = structuredClone(gridFullData.filter((item) => selectedIds.has(item.id)));
    data.map((item) => {
      item.isDuplicate = data.some(
        (value) => value.articleNo === item.articleNo && value.id != item.id,
      );
      return item;
    });

    if (data.some((value) => value.isDuplicate === true)) {
      return [];
    } else {
      return data.map(({ id, isDuplicate, isRecordWhichOverwrites, ...keepAttrs }) => keepAttrs);
    }
  }, [gridFullData, selectAll, selectedIds]);

  const legendData = useMemo(() => {
    return [
      {
        color: theme.palette.blue.light,
        title: t('settings.some_existing_record_will_be_overwrited'),
      },
      { color: theme.palette.orange.light, title: t('settings.duplicate_in_import_data') },
    ];
  }, [theme, t]);

  const height = useMemo(() => {
    return needNotExpandPossibility
      ? heigthWhenCollapsed
      : isAllDataShown
      ? heigthWhenCollapsed + 32 * (gridFullData?.length - shownRecordsCountWhenCollapsed)
      : heigthWhenCollapsed;
  }, [
    gridFullData,
    needNotExpandPossibility,
    isAllDataShown,
    heigthWhenCollapsed,
    shownRecordsCountWhenCollapsed,
  ]);

  useEffect(() => {
    const executeImportSub = configurationService.sub
      .executeArticlesImport()
      .pipe(
        responseHandler<ExecuteArticlesImportMutationRes>({
          errorReturnType: {
            recordsInserted: 0,
            recordsUpdated: 0,
            recordsSkipped: gridFullData.length,
          },
        }),
      )
      .subscribe((data) => {
        setGridFullData([]);
        configurationTabLoadingService.pub.loading(false);
        snackbarService.pub.show({
          type: 'success',
          content: t('settings.import_successful', {
            recordsInserted: data.recordsInserted,
            recordsUpdated: data.recordsUpdated,
          }),
        });
        configurationService.pub.listValidationDataForImport(); // updating data to validate existiong records
      });

    return () => {
      executeImportSub.unsubscribe();
    };
  }, []);

  const handleShowAll = () => {
    setIsAllDataShown(true);
  };

  const handleImportArticles = () => {
    setIsAllDataShown(false);
    configurationService.pub.executeArticlesImport({ importData: dataToWrite });
    configurationTabLoadingService.pub.loading(true);
  };

  return (
    <>
      {gridFullData?.length ? (
        <Stack>
          <TableHeader
            icon={icon}
            title={t('article.article')}
            dataLength={dataFromFile?.length}
            shownDataLength={gridShownData?.length}
            isAllDataShown={isAllDataShown}
            isDuplicates={isDuplicates}
            isRecordsWhichOverwrites={isRecordsWhichOverwrites}
            importButtonAvailable={dataToWrite.length > 0}
            onImport={handleImportArticles}
          />
          <Table
            data={(isAllDataShown ? gridFullData : (gridShownData as [])) || []}
            height={height}
            viewRows={viewRows}
            columnVisibilityModel={generateColumnsVisiblityModel(dataFromFile, isAllDataShown)}
            onCellEditStop={(v, e) => {
              if (v.reason === 'enterKeyDown') {
                e.defaultMuiPrevented = true;
              }
            }}
            isCellEditable={() => isAllDataShown}
            focusTabProps={{ enabled: true }}
          >
            <Column
              disableReorder
              width={40}
              maxWidth={40}
              headerAlign='right'
              field='id'
              sortable={false}
              renderHeader={() => (
                <Box>
                  <Checkbox size='small' checked={selectAll} onChange={toggleSelectAll} />
                </Box>
              )}
              renderCell={({ row }) => {
                const id = row?.id;
                if (id) {
                  return (
                    <Box position='relative'>
                      <Checkbox
                        size='small'
                        checked={selectedIds.has(id)}
                        onChange={() => toggleCheckbox(id)}
                      />
                    </Box>
                  );
                }
              }}
            />
            <Column
              field='articleNo'
              headerName={t('common.article_no')}
              width={150}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <ArticleNrEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    existingNumbers: existingNumbers,
                  }}
                />
              )}
            />
            <Column
              field='description'
              headerName={t('common.description')}
              width={300}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 64,
                  }}
                />
              )}
            />
            <Column
              field='isActive'
              headerName={t('article.article_is_active')}
              width={150}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='note'
              headerName={t('common.note')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='isNotifyOnOrderStage'
              headerName={t('article.warn_user_with_this_note')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='discountGroupId'
              headerName={t('article.product_group')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.discountGroups,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.discountGroups,
                  }}
                />
              )}
            />
            <Column
              field='productionGroupId'
              headerName={t('article.production_group')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.productionGroups,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.productionGroups,
                  }}
                />
              )}
            />
            <Column
              field='marketingGroupId'
              headerName={t('article.marketing_group')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.marketingGroups,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.marketingGroups,
                  }}
                />
              )}
            />
            <Column
              field='shippingPointId'
              headerName={t('article.shipping_points')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.shippingPoints,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.shippingPoints,
                  }}
                />
              )}
            />
            <Column
              field='saleUnitId'
              headerName={t('article.sale_unit')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.saleUnits,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.saleUnits,
                  }}
                />
              )}
            />
            <Column
              field='quantityPerLot'
              headerName={t('article.piece_or_unit')}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='vatCode'
              headerName={t('common.vat')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.vatCodes,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.vatCodes,
                  }}
                />
              )}
            />
            <Column
              field='isNoDiscount'
              headerName={t('article.net_article')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='price1'
              headerName={t('common.amount') + ' 1'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price2'
              headerName={t('common.amount') + ' 2'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price3'
              headerName={t('common.amount') + ' 3'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price4'
              headerName={t('common.amount') + ' 4'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price5'
              headerName={t('common.amount') + ' 5'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price6'
              headerName={t('common.amount') + ' 6'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price7'
              headerName={t('common.amount') + ' 7'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price8'
              headerName={t('common.amount') + ' 8'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price9'
              headerName={t('common.amount') + ' 9'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='price10'
              headerName={t('common.amount') + ' 10'}
              width={200}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='isDeliveredMon'
              headerName={t('common.mon')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredTue'
              headerName={t('common.tue')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredWed'
              headerName={t('common.wed')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredThu'
              headerName={t('common.thu')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredFri'
              headerName={t('common.fri')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredSat'
              headerName={t('common.sat')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredSun'
              headerName={t('common.sun')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='availabilityInProcurementKindId'
              headerName={t('article.availability_in_procurement_module')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.procurementProductKinds,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.procurementProductKinds,
                  }}
                />
              )}
            />
            <Column
              field='isShowOnProductionPrintLists'
              headerName={t('article.display_on_production_print_lists')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDough'
              headerName={t('article.dough_item')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isFermentationInterrupt'
              headerName={t('article.fermentation_interrupt')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='doughWeightBeforeBaking'
              headerName={t('article.dough_weight_before_baking')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='countPerSheetOfCuttingMachine'
              headerName={t('article.number_per_sheet')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='countOfSheetsPerCuttingMachine'
              headerName={t('article.sheets_per_production')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='weightOfLiterOfDough'
              headerName={t('article.weight_of_liter_of_dough')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='roundLitersTo'
              headerName={t('article.round_to')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='willReplacedWithProductionCalc'
              headerName={t('article.article_will_be_replaced_with_calculation')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isProductionQuantityRounding'
              headerName={t('article.round_up')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='productionQuantityRoundTo'
              headerName={t('article.round_to')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='caVisibilityStatusId'
              headerName={'ca ' + t('article.visibility')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.visibilityStatuses,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.visibilityStatuses,
                  }}
                />
              )}
            />
            <Column
              field='eanCode'
              headerName={t('article.ean_code')}
              width={200}
              sortable={true}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='isEanCodeManagedByCA'
              headerName={t('article.ean_code_is_managed_in_cashAssist')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='declaration'
              headerName={t('article.declaration')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='consumersInformation'
              headerName={t('article.sales_information_for_customer')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='consumersStorageInfo'
              headerName={t('article.consumers_storage_info')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='daysToSell'
              headerName={t('article.sell_by')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='daysToConsume'
              headerName={t('article.consume_by')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='daysShelfLife'
              headerName={t('article.shelf_life_in_days')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='isStoreInCoolPlace'
              headerName={t('article.item_must_be_kept_cool')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='storagePlace'
              headerName={t('article.storage')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='sellingWeight'
              headerName={t('article.selling_weight_piece')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='shippingWeight'
              headerName={t('article.weight')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='daysToProduce'
              headerName={t('article.days_to_produce')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='isDoughSplitting'
              headerName={t('article.charge_splitting')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='doughSplittingDough1Amount'
              headerName={t('article.kg_pro_charge')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Footer
              numberRows={gridFullData.length}
              legendData={legendData}
              titleRows={t('article.articles', { count: gridFullData.length })}
              height={36}
            />
          </Table>

          {!isAllDataShown && (
            <Button
              onClick={handleShowAll}
              variant='outlined'
              size='small'
              sx={{ width: '200px', marginTop: '16px', margin: '16px auto 0 auto' }}
            >
              {t('common.show_all') + ' / ' + t('customer.editing')}
            </Button>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

export const generateColumnsVisiblityModel = (
  data: Object[],
  expanded: boolean,
): GridColumnVisibilityModel => {
  let columnVisibilityModel: GridColumnVisibilityModel = {};

  if (data.length) {
    Object.keys(data[0]).forEach((column) => {
      columnVisibilityModel[column] = data.some((value) => {
        const arrNameVal = Object.entries(value).find((item) => item[0] === column);
        if (arrNameVal) {
          if (arrNameVal[1] != null && arrNameVal[1] != undefined) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    });
  }

  columnVisibilityModel['id'] = expanded;

  return columnVisibilityModel;
};

interface IArticleTable {
  dataFromFile: IArticleImportData[];
  heigthWhenCollapsed: number;
  shownRecordsCountWhenCollapsed: number;
  existingNumbers: string[];
  options: IOptionsData;
}
