import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { GridApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';
import { orderDetailsState as orderDetailsStateCreate } from '../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../edit/states/orderDetails/orderDetails.state.ts';
import {
  delayFn,
  shouldHorizontalScroll,
} from '../../../../../shared/components/table/functions/sharedFunc.ts';
import { storageHelper } from '../../../../../shared/helpers/storage';

export class EnterKeyNavigation {
  private fields: Array<string> = [];
  private readonly type: Type = 'create';
  constructor(args: { sessionFields?: Array<string>; type: Type }) {
    const { sessionFields, type } = args;
    this.fields = sessionFields || [];
    this.type = type;
  }
  setKeyCombination(fields: Array<string>): void {
    this.fields = fields;
    storageHelper.local.setItem('order_offer.keyCombination', fields);
  }
  getKeyCombination(): Array<string> {
    return this.fields;
  }
  handleEnterKey(field: string, rowId: GridRowId, api: GridApiCommunity) {
    const {
      getAllColumns,
      getSortedRows,
      scroll,
      getRootDimensions,
      getScrollPosition,
      getColumn,
      getColumnPosition,
      setCellFocus,
    } = api;
    const isCurrentFieldInFields = !!this.fields.find((el) => el === field);
    const currentColumn = getColumn(field);
    const currentTemp = getAllColumns().reduce((acc: string[], item) => {
      if (
        (!isCurrentFieldInFields || this.fields.length === 0 || this.fields.includes(item.field)) &&
        (!currentColumn.editable || item.editable) &&
        item.field !== '__reorder__'
      )
        acc.push(item.field);
      return acc;
    }, []);

    const fieldIndex = currentTemp.findIndex((el) => el === field);
    const containerWidth = getRootDimensions()?.viewportInnerSize?.width || 0;
    const { left: scrollLeft } = getScrollPosition();
    if (fieldIndex === currentTemp.length - 1) {
      const filteredRows = getSortedRows().filter((el) => !el.virtualPositionId && el.isChangeable);
      const rowIndex = filteredRows.findIndex((el) => el.id === rowId);
      const nextRow = filteredRows[rowIndex + 1];
      const { width: columnWidth = 0 } = getColumn(currentTemp[0]);
      const columnLeft = getColumnPosition(currentTemp[0]);
      const needToScroll = shouldHorizontalScroll({
        scrollLeft,
        columnLeft,
        columnWidth,
        containerWidth,
      });
      if (nextRow) {
        (this.type === 'create'
          ? orderDetailsStateCreate
          : orderDetailsStateEdit
        ).pub.selectPosition(nextRow as any);
        delayFn(() => {
          setCellFocus(nextRow.id as string, currentTemp[0]);
          if (needToScroll) {
            scroll({ left: columnLeft });
          }
        }, 100);
      } else {
        (this.type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.addPosition({
          type: 'addPosition',
        });
        delayFn(() => {
          if (needToScroll) {
            scroll({ left: columnLeft });
          }
        }, 100);
      }
    } else {
      const nextField = currentTemp[fieldIndex + 1];
      const { width: columnWidth = 0 } = getColumn(nextField);
      const columnLeft = getColumnPosition(nextField);
      const needToScroll = shouldHorizontalScroll({
        scrollLeft,
        columnLeft,
        columnWidth,
        containerWidth,
      });
      delayFn(() => {
        if (needToScroll) {
          scroll({ left: columnLeft });
        }
        setCellFocus(rowId, nextField);
      }, 100);
    }
  }
}

type Type = 'create' | 'edit';
