import { Stack } from '@mui/material';
import { FC } from 'react';
import { IconButton } from '../../../../../../../../order/common/iconButton/iconButton.component';
import { CustomerPricesPopup } from '../popups/customerPrices/customerPrices.popup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { IGeneralTabState, generalTabState } from '../../../../generalTab.state';
import { CopySpecialPricesPopup } from '../../common/popups/copySpecialPrices/copySpecialPrices.popup';

export const ControlButtons: FC<IControlButtons> = (props) => {
  const { customerPricesPopup, selectedPriceByCustomer, copySpecialPricesPopup, currentArticleId } =
    props;
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={2} mb={1}>
      <IconButton
        onClick={() =>
          customerPricesPopup.stream.emit('open', {
            values: {
              row: {
                id: `new_${Date.now()}`,
                specialIsNoDiscount: false,
              },
            },
          })
        }
        color='primary'
        size='large'
        title={t('article.addNewRecord')}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        onClick={() => generalTabState.pub.deletePricesByCustomerPos()}
        color='primary'
        size='large'
        title={t('article.deleteRecord')}
        disabled={!selectedPriceByCustomer}
      >
        <RemoveIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          copySpecialPricesPopup.stream.emit('open', {
            currentArticleId,
            type: 'customerPrices',
          });
        }}
        color='primary'
        size='large'
        title={t('article.copy_special_prices')}
      >
        <ContentCopyIcon />
      </IconButton>
    </Stack>
  );
};

interface IControlButtons {
  customerPricesPopup: CustomerPricesPopup;
  selectedPriceByCustomer: IGeneralTabState['selectedPricesByCustomerPos'];
  copySpecialPricesPopup: CopySpecialPricesPopup;
  currentArticleId: string;
}
