import { FC, useCallback, useEffect } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { orderDetailsState as orderDetailsStateCreate } from '../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../edit/states/orderDetails/orderDetails.state.ts';
import { TableNumericField } from '../../../../../shared/components/table/styles/styleFields.ts';
import { clearRowFromVirtualKeys } from './splitting/additionalFunctions.tsx';
import { blockArrowKeysInMiddle } from '../../../../../shared/components/table/functions/sharedFunc.ts';

export const DiscountEditCell: FC<TDiscountEditCell> = ({ api, type, ...params }) => {
  const { setEditCellValue } = api;
  const { field, id, row, formattedValue, value } = params;
  const numberValue = Number.parseFloat(formattedValue) || 0;

  useEffect(() => {
    setEditCellValue({ id, field, value: numberValue });
  }, []);

  const updateCell = useCallback(
    (v: number | null) => {
      setEditCellValue({ id, field, value: v });
      if (numberValue !== v) {
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          { ...clearedRow, discount: v },
          'discountCell',
        );
      }
    },
    [params, numberValue],
  );

  return (
    <TableNumericField
      onBlur={updateCell}
      inputProps={{ autoComplete: 'off' }}
      onKeyDown={blockArrowKeysInMiddle}
      max={100}
      min={0}
      autoFocus
      noTrailingZeros
      value={value}
      keyChange={false}
    />
  );
};

type TDiscountEditCell = GridRenderEditCellParams & { type: 'create' | 'edit' };
