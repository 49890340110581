import { BehaviorSubject } from 'rxjs';
import { IPrintJobsDetailsState, IProductionTabDataRes } from './printJobsDetails.state';
import { C_Report } from '../../../../graphql/generatedModel';
import { ProductionListRes } from '../../services/printJobs.service';

export class StateHelpers {
  stream$: BehaviorSubject<IPrintJobsDetailsState>;
  constructor(stream$: BehaviorSubject<IPrintJobsDetailsState>) {
    this.stream$ = stream$;
  }

  isReportForPreview(reportId: C_Report | null | undefined): boolean {
    return (
      reportId !== C_Report.R12_LABEL && reportId !== C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE
    );
  }

  isReportPreviewEnabled(report: ProductionListRes[number]): boolean {
    const { addDaysForPreProduction } = report;
    const daysToProduce = addDaysForPreProduction ?? 0; // IF addDaysForPreProduction will be null or underined

    const { dataTabs } = this.stream$.getValue();
    const productionData = dataTabs.production as IProductionTabDataRes | undefined;
    if (productionData === undefined) return false;
    const { countOrders, countOrderPreProduction } = productionData;

    if (daysToProduce > 0 && countOrderPreProduction > 0) {
      return true;
    } else if (daysToProduce === 0 && countOrders > 0) {
      return true;
    } else {
      return false;
    }
  }
}
