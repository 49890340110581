import { FC } from 'react';
import { useLoaderData } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';
import { TMode } from '../../../../../shared/services/mode/mode.service.ts';
import { ITabState } from '../../../states/tabState.model.ts';
import { GeneralForm } from './components/generalForm.component.tsx';
import { generalTabState } from './generalTab.state.ts';

export const GeneralTab: FC<IGeneralTabProps> = ({
  specialPricesAfterCreateOrCopyMode,
  pricesByCustomer,
  resellerPrices,
  mode,
  isDough,
  loading,
}) => {
  const { articleSpecialPrices } = useLoaderData() as ITabState;
  const { getValues } = useFormContext();

  return (
    <>
      <GeneralForm
        handleSubmitValue={(v) => {
          generalTabState.pub.save({
            ...v,
            id: getValues()['id'],
          });
        }}
        loading={loading}
        mode={mode}
        isDough={isDough}
        articleSpecialPrices={specialPricesAfterCreateOrCopyMode || articleSpecialPrices}
        articlePricesByCustomers={pricesByCustomer}
        articleResellerPrices={resellerPrices}
      />
    </>
  );
};
export interface IGeneralTabProps {
  mode: TMode;
  loading: boolean;
  specialPricesAfterCreateOrCopyMode?: ITabState['articleSpecialPrices'] | null;
  pricesByCustomer: ITabState['articlePricesByCustomers'];
  resellerPrices: ITabState['articleResellerPrices'];
  isDough?: boolean;
}
