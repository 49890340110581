import { filter, finalize, map, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import {
  DictWa_ArticleListsQuery,
  DictWa_ArticlesQuery,
  DictWa_AssortmentGroupsQuery,
  DictWa_BanksQuery,
  DictWa_CashAssistProductGroupsQuery,
  DictWa_CashAssistTemplatesQuery,
  DictWa_CustomerGroupsQuery,
  DictWa_CustomerListsQuery,
  DictWa_CustomerProfilesQuery,
  DictWa_CustomersQuery,
  DictWa_DebtRemindersQuery,
  DictWa_DeliveryNoteGroupsQuery,
  DictWa_DiscountGroupsQuery,
  DictWa_MarketingGroupsQuery,
  DictWa_PriceCategoriesQuery,
  DictWa_ProductionGroupsQuery,
  DictWa_QuantityDiscountsQuery,
  DictWa_RecipesWithConnectedArticlesQuery,
  DictWa_ShippingPointsQuery,
  DictWa_StorageLocationsQuery,
  DictWa_TextBlocksQuery,
  DictWa_TourplansQuery,
  DictWa_TransportSectorsQuery,
  DictWa_ProductionPrintListsQuery,
  ListWa_ArticlesForSelectInOrderPositionQuery,
  DictWa_CloudPrintersQuery,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import {
  dictArticleLists,
  dictArticles,
  dictAssortmentGroups,
  dictBanks,
  dictCashAssistProductGroups,
  dictCashAssistTemplates,
  dictCustomerGroups,
  dictCustomerLists,
  dictCustomerProfiles,
  dictCustomers,
  dictDebtReminders,
  dictDeliveryNoteGroups,
  dictDiscountGroups,
  dictMarketingGroups,
  dictPriceCategories,
  dictProductionGroups,
  dictQuantityDiscounts,
  dictRecipesWithConnectedArticles,
  dictShippingPoints,
  dictStorageLocations,
  dictTextBlocks,
  dictTourplans,
  dictTransportSectors,
  dictProductionPrintLists,
  listArticlesForSelectInOrderPosition,
  dictCloudPrinters,
} from './gql/common.gql';
import { storageHelper } from '../../helpers/storage';
import { responseHandler } from '../../responseHandler/responseHandler';

class Pub {
  constructor(private stream$: Subject<IConfigsData>) {}

  common(action: IConfigsData['action'], forceUpdate?: IConfigsData['forceUpdate']) {
    this.stream$.next({ action, forceUpdate });
  }
  clearStream() {
    this.stream$.next({ action: undefined });
  }
}

class Sub {
  private stream$: Observable<IConfigsData>;
  constructor(private incomeStream$: Subject<IConfigsData>) {
    this.stream$ = this.incomeStream$.pipe(shareReplay(1));
  }
  private actionListener(listenerAction: IConfigsData['action']): Observable<IConfigsData> {
    return this.stream$.pipe(
      filter(({ action }) => !!listenerAction?.find((item) => action?.includes(item))),
    );
  }

  dictCustomers(): Observable<DictCustomersRes> {
    return this.actionListener(['dictCustomers']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictCustomers',
        ) as DictCustomersRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictCustomers) as Observable<DictWa_CustomersQuery>).pipe(
          responseHandler<DictWa_CustomersQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_Customers: [],
              },
            },
          }),
          map((res) => {
            const result = res.wawiAssist?.dictWA_Customers;
            if (result) storageHelper.memory.setItem('configsData.dictCustomers', result);
            return (result || []) as DictCustomersRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictCustomerGroups(): Observable<DictCustomerGroupsRes> {
    return this.actionListener(['dictCustomerGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictCustomerGroups',
        ) as DictCustomerGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictCustomerGroups) as Observable<DictWa_CustomerGroupsQuery>).pipe(
          responseHandler<DictWa_CustomerGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_CustomerGroups: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_CustomerGroups as DictCustomerGroupsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictCustomerGroups', v)),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictCustomerLists(): Observable<DictCustomerListsRes> {
    return this.actionListener(['dictCustomerLists']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictCustomerLists',
        ) as DictCustomerListsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictCustomerLists) as Observable<DictWa_CustomerListsQuery>).pipe(
          responseHandler<DictWa_CustomerListsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_CustomerLists: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_CustomerLists as DictCustomerListsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictCustomerLists', v)),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictPostSectors(): Observable<DictPostSectorsRes> {
    return this.actionListener(['dictPostSectors']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictPostSectors',
        ) as DictPostSectorsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);
        return (gqlClient(dictTransportSectors) as Observable<DictWa_TransportSectorsQuery>).pipe(
          responseHandler<DictWa_TransportSectorsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_TransportSectors: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_TransportSectors as DictPostSectorsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictPostSectors', v)),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictQuantityDiscounts(): Observable<DictQuantityDiscountsRes> {
    return this.actionListener(['dictQuantityDiscounts']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictQuantityDiscounts',
        ) as DictQuantityDiscountsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictQuantityDiscounts) as Observable<DictWa_QuantityDiscountsQuery>).pipe(
          responseHandler<DictWa_QuantityDiscountsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_QuantityDiscounts: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_QuantityDiscounts as DictQuantityDiscountsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictQuantityDiscounts', v)),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictDeliveryNoteGroups(): Observable<DictDeliveryNoteGroupsRes> {
    return this.actionListener(['dictDeliveryNoteGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictDeliveryNoteGroups',
        ) as DictDeliveryNoteGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (
          gqlClient(dictDeliveryNoteGroups) as Observable<DictWa_DeliveryNoteGroupsQuery>
        ).pipe(
          responseHandler<DictWa_DeliveryNoteGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_DeliveryNoteGroups: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_DeliveryNoteGroups as DictDeliveryNoteGroupsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictDeliveryNoteGroups', v)),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictPriceCategories(): Observable<DictPriceCategoriesRes> {
    return this.actionListener(['dictPriceCategories']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictPriceCategories',
        ) as DictPriceCategoriesRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictPriceCategories) as Observable<DictWa_PriceCategoriesQuery>).pipe(
          responseHandler<DictWa_PriceCategoriesQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_PriceCategories: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_PriceCategories as DictPriceCategoriesRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictPriceCategories', v)),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictArticleLists(): Observable<DictArticleListsRes> {
    return this.actionListener(['dictArticleLists']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictArticleLists',
        ) as DictArticleListsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictArticleLists) as Observable<DictWa_ArticleListsQuery>).pipe(
          responseHandler<DictWa_ArticleListsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_ArticleLists: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_ArticleLists as DictArticleListsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictArticleLists', v)),
        );
      }),
    );
  }

  dictAssortmentGroups(): Observable<DictAssortmentGroupsRes> {
    return this.actionListener(['dictAssortmentGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictAssortmentGroups',
        ) as DictAssortmentGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictAssortmentGroups) as Observable<DictWa_AssortmentGroupsQuery>).pipe(
          responseHandler<DictWa_AssortmentGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_AssortimentGroups: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_AssortimentGroups as DictAssortmentGroupsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictAssortmentGroups', v)),
        );
      }),
    );
  }

  dictCashAssistProductGroups(): Observable<DictCashAssistProductGroupsRes> {
    return this.actionListener(['dictCashAssistProductGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictCashAssistProductGroups',
        ) as DictCashAssistProductGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (
          gqlClient(dictCashAssistProductGroups) as Observable<DictWa_CashAssistProductGroupsQuery>
        ).pipe(
          responseHandler<DictWa_CashAssistProductGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_CashAssistProductGroups: [],
              },
            },
          }),
          map(
            (res) =>
              res.wawiAssist?.dictWA_CashAssistProductGroups as DictCashAssistProductGroupsRes,
          ),
          tap(
            (v) => v && storageHelper.memory.setItem('configsData.dictCashAssistProductGroups', v),
          ),
        );
      }),
    );
  }

  dictCashAssistTemplates(): Observable<DictCashAssistTemplatesRes> {
    return this.actionListener(['dictCashAssistTemplates']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictCashAssistTemplates',
        ) as DictCashAssistTemplatesRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (
          gqlClient(dictCashAssistTemplates) as Observable<DictWa_CashAssistTemplatesQuery>
        ).pipe(
          responseHandler<DictWa_CashAssistTemplatesQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_CashAssistTemplates: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_CashAssistTemplates as DictCashAssistTemplatesRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictCashAssistTemplates', v)),
        );
      }),
    );
  }

  dictDiscountGroups(): Observable<DictDiscountGroupsRes> {
    return this.actionListener(['dictDiscountGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictDiscountGroups',
        ) as DictDiscountGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictDiscountGroups) as Observable<DictWa_DiscountGroupsQuery>).pipe(
          responseHandler<DictWa_DiscountGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_DiscountGroups: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_DiscountGroups as DictDiscountGroupsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictDiscountGroups', v)),
        );
      }),
    );
  }

  dictMarketingGroups(): Observable<DictMarketingGroupsRes> {
    return this.actionListener(['dictMarketingGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictMarketingGroups',
        ) as DictMarketingGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictMarketingGroups) as Observable<DictWa_MarketingGroupsQuery>).pipe(
          responseHandler<DictWa_MarketingGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_MarketingGroups: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_MarketingGroups as DictMarketingGroupsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictMarketingGroups', v)),
        );
      }),
    );
  }

  dictProductionPrintLists(): Observable<DictProductionPrintListsRes> {
    return this.actionListener(['dictProductionPrintLists']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictProductionPrintLists',
        ) as DictProductionPrintListsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (
          gqlClient(dictProductionPrintLists) as Observable<DictWa_ProductionPrintListsQuery>
        ).pipe(
          responseHandler<DictWa_ProductionPrintListsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_ProductionPrintLists: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_ProductionPrintLists as DictProductionPrintListsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictProductionPrintLists', v)),
        );
      }),
    );
  }

  dictBanks(): Observable<DictBanksRes> {
    return this.actionListener(['dictBanks']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictBanks',
        ) as DictBanksRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictBanks) as Observable<DictWa_BanksQuery>).pipe(
          responseHandler<DictWa_BanksQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_Banks: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_Banks as DictBanksRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictBanks', v)),
        );
      }),
    );
  }

  dictProductionGroups(): Observable<DictProductionGroupsRes> {
    return this.actionListener(['dictProductionGroups']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictProductionGroups',
        ) as DictProductionGroupsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictProductionGroups) as Observable<DictWa_ProductionGroupsQuery>).pipe(
          responseHandler<DictWa_ProductionGroupsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_ProductionGroups: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_ProductionGroups as DictProductionGroupsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictProductionGroups', v)),
        );
      }),
    );
  }

  dictShippingPoints(): Observable<DictShippingPointsRes> {
    return this.actionListener(['dictShippingPoints']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictShippingPoints',
        ) as DictShippingPointsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictShippingPoints) as Observable<DictWa_ShippingPointsQuery>).pipe(
          responseHandler<DictWa_ShippingPointsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_ShippingPoints: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_ShippingPoints as DictShippingPointsRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictShippingPoints', v)),
        );
      }),
    );
  }

  dictTourplans(): Observable<DictTourplansRes> {
    return this.actionListener(['dictTourplans']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictTourplans',
        ) as DictTourplansRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictTourplans) as Observable<DictWa_TourplansQuery>).pipe(
          responseHandler<DictWa_TourplansQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_Tourplans: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_Tourplans as DictTourplansRes),
          tap((v) => v && storageHelper.memory.setItem('configsData.dictTourplans', v)),
        );
      }),
    );
  }

  dictCustomerProfiles(): Observable<DictCustomerProfilesRes> {
    return this.actionListener(['dictCustomerProfiles']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem('configsData.dictCustomerProfiles');
        if (cachedData && !forceUpdate) return of(cachedData);
        return (gqlClient(dictCustomerProfiles) as Observable<DictWa_CustomerProfilesQuery>).pipe(
          responseHandler<DictWa_CustomerProfilesQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_CustomerProfiles: [],
              },
            },
          }),
          map((res) => res.wawiAssist?.dictWA_CustomerProfiles as DictCustomerProfilesRes),
          tap(
            (res) => res && storageHelper.memory.setItem('configsData.dictCustomerProfiles', res),
          ),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictTextBlocks(): Observable<DictTextBlocksRes> {
    return this.actionListener(['dictTextBlocks']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictTextBlocks',
        ) as DictTextBlocksRes | null;

        if (cachedData && !forceUpdate) return of(cachedData);
        return (gqlClient(dictTextBlocks) as Observable<DictWa_TextBlocksQuery>).pipe(
          responseHandler<DictWa_TextBlocksQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_TextBlocks: [],
              },
            },
          }),
          map((res) => {
            const result = res?.wawiAssist?.dictWA_TextBlocks;
            if (result) storageHelper.memory.setItem('configsData.dictTextBlocks', result);
            return (result || []) as DictTextBlocksRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictRecipesWithConnectedArticles(): Observable<DictRecipesWithConnectedArticlesRes> {
    return this.actionListener(['dictRecipesWithConnectedArticles']).pipe(
      switchMap(() => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictRecipesWithConnectedArticles',
        ) as DictRecipesWithConnectedArticlesRes | null;
        if (cachedData) return of(cachedData);

        return (
          gqlClient(
            dictRecipesWithConnectedArticles,
          ) as Observable<DictWa_RecipesWithConnectedArticlesQuery>
        ).pipe(
          responseHandler<DictWa_RecipesWithConnectedArticlesQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_RecipesWithConnectedArticles: [],
              },
            },
          }),
          map((res) => {
            const result = res?.wawiAssist?.dictWA_RecipesWithConnectedArticles;
            if (result)
              storageHelper.memory.setItem('configsData.dictRecipesWithConnectedArticles', result);
            return (result || []) as DictRecipesWithConnectedArticlesRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictArticles(): Observable<DictArticlesRes> {
    return this.actionListener(['dictArticles']).pipe(
      switchMap(() => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictArticles',
        ) as DictArticlesRes | null;
        if (cachedData) return of(cachedData);

        return (gqlClient(dictArticles) as Observable<DictWa_ArticlesQuery>).pipe(
          responseHandler<DictWa_ArticlesQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_Articles: [],
              },
            },
          }),
          map((res) => {
            const result = res?.wawiAssist?.dictWA_Articles;
            if (result) storageHelper.memory.setItem('configsData.dictArticles', result);
            return (result || []) as DictArticlesRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictDebtReminders(): Observable<DictDebtRemindersRes> {
    return this.actionListener(['dictDebtReminders']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictDebtReminders',
        ) as DictDebtRemindersRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictDebtReminders) as Observable<DictWa_DebtRemindersQuery>).pipe(
          responseHandler<DictWa_DebtRemindersQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_DebtReminders: [],
              },
            },
          }),
          map((res) => {
            const result = res?.wawiAssist?.dictWA_DebtReminders;
            if (result) storageHelper.memory.setItem('configsData.dictDebtReminders', result);
            return (result || []) as DictDebtRemindersRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictStorageLocations(): Observable<DictStorageLocationsRes> {
    return this.actionListener(['dictStorageLocations']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictStorageLocations',
        ) as DictStorageLocationsRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictStorageLocations) as Observable<DictWa_StorageLocationsQuery>).pipe(
          responseHandler<DictWa_StorageLocationsQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_StorageLocations: [],
              },
            },
          }),
          map((res) => {
            const result = res?.wawiAssist?.dictWA_StorageLocations;
            if (result) {
              storageHelper.memory.setItem('configsData.dictStorageLocations', result);
            }
            return (result || []) as DictStorageLocationsRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  articlesForOrderPositionList(): Observable<ArticlesForOrderPositionListRes> {
    return this.actionListener(['articlesForOrderPositionList']).pipe(
      switchMap(() => {
        const cachedData = storageHelper.memory.getItem('configsData.articlesForOrderPositionList');
        if (cachedData) return of(cachedData);
        return (
          gqlClient(
            listArticlesForSelectInOrderPosition,
          ) as Observable<ListWa_ArticlesForSelectInOrderPositionQuery>
        ).pipe(
          responseHandler<ListWa_ArticlesForSelectInOrderPositionQuery>({
            errorReturnType: {
              wawiAssist: {
                listWA_ArticlesForSelectInOrderPosition: [],
              },
            },
          }),
          map((res) => {
            let result = res?.wawiAssist
              ?.listWA_ArticlesForSelectInOrderPosition as ArticlesForOrderPositionListRes;
            if (result) {
              result = result.filter((el) => el?.id && el?.label);
              storageHelper.memory.setItem('configsData.articlesForOrderPositionList', result);
            }
            return result || [];
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }

  dictCloudPrinters(): Observable<DictCloudPrintersRes> {
    return this.actionListener(['dictCloudPrinters']).pipe(
      switchMap(({ forceUpdate }) => {
        const cachedData = storageHelper.memory.getItem(
          'configsData.dictCloudPrinters',
        ) as DictCloudPrintersRes | null;
        if (cachedData && !forceUpdate) return of(cachedData);

        return (gqlClient(dictCloudPrinters) as Observable<DictWa_CloudPrintersQuery>).pipe(
          responseHandler<DictWa_CloudPrintersQuery>({
            errorReturnType: {
              wawiAssist: {
                dictWA_CloudPrinters: [],
              },
            },
          }),
          map((res) => {
            const result = res?.wawiAssist?.dictWA_CloudPrinters;
            if (result) {
              storageHelper.memory.setItem('configsData.dictCloudPrinters', result);
            }
            return (result || []) as DictCloudPrintersRes;
          }),
          finalize(() => configsData.pub.clearStream()),
        );
      }),
    );
  }
}

class ConfigsData {
  private stream$ = new Subject<IConfigsData>();
  pub = new Pub(this.stream$);
  sub = new Sub(this.stream$);
}

export const configsData = new ConfigsData();

type Action =
  | 'dictCustomers'
  | 'dictCustomerGroups'
  | 'dictCustomerLists'
  | 'dictPostSectors'
  | 'dictQuantityDiscounts'
  | 'dictDeliveryNoteGroups'
  | 'dictPriceCategories'
  | 'dictArticleLists'
  | 'dictAssortmentGroups'
  | 'dictCashAssistProductGroups'
  | 'dictCashAssistTemplates'
  | 'dictDiscountGroups'
  | 'dictMarketingGroups'
  | 'dictProductionGroups'
  | 'dictShippingPoints'
  | 'dictTourplans'
  | 'dictTextBlocks'
  | 'dictAllCustomerList'
  | 'dictRecipesWithConnectedArticles'
  | 'dictArticles'
  | 'articlesForOrderPositionList'
  | 'dictCustomerProfiles'
  | 'dictBanks'
  | 'dictDebtReminders'
  | 'dictStorageLocations'
  | 'dictProductionPrintLists'
  | 'dictCloudPrinters'
  | 'listAllReports'
  | 'together';

interface IConfigsData {
  action: Action[] | undefined;
  forceUpdate?: boolean;
}

export type DictCustomersRes = NonNullable<
  NonNullable<DictWa_CustomersQuery['wawiAssist']>['dictWA_Customers']
>;
export type DictCustomerGroupsRes = NonNullable<
  NonNullable<DictWa_CustomerGroupsQuery['wawiAssist']>['dictWA_CustomerGroups']
>;
export type DictCustomerListsRes = NonNullable<
  NonNullable<DictWa_CustomerListsQuery['wawiAssist']>['dictWA_CustomerLists']
>;
export type DictPostSectorsRes = NonNullable<
  NonNullable<DictWa_TransportSectorsQuery['wawiAssist']>['dictWA_TransportSectors']
>;
export type DictQuantityDiscountsRes = NonNullable<
  NonNullable<DictWa_QuantityDiscountsQuery['wawiAssist']>['dictWA_QuantityDiscounts']
>;
export type DictDeliveryNoteGroupsRes = NonNullable<
  NonNullable<DictWa_DeliveryNoteGroupsQuery['wawiAssist']>['dictWA_DeliveryNoteGroups']
>;
export type DictPriceCategoriesRes = NonNullable<
  NonNullable<DictWa_PriceCategoriesQuery['wawiAssist']>['dictWA_PriceCategories']
>;
export type DictCustomerProfilesRes = NonNullable<
  NonNullable<DictWa_CustomerProfilesQuery['wawiAssist']>['dictWA_CustomerProfiles']
>;
export type DictTextBlocksRes = NonNullable<
  NonNullable<DictWa_TextBlocksQuery['wawiAssist']>['dictWA_TextBlocks']
>;
export type ArticlesForOrderPositionListRes = NonNullable<
  NonNullable<
    ListWa_ArticlesForSelectInOrderPositionQuery['wawiAssist']
  >['listWA_ArticlesForSelectInOrderPosition']
>;
export type DictArticleListsRes = NonNullable<
  NonNullable<DictWa_ArticleListsQuery['wawiAssist']>['dictWA_ArticleLists']
>;
export type DictDiscountGroupsRes = NonNullable<
  NonNullable<DictWa_DiscountGroupsQuery['wawiAssist']>['dictWA_DiscountGroups']
>;
export type DictProductionGroupsRes = NonNullable<
  NonNullable<DictWa_ProductionGroupsQuery['wawiAssist']>['dictWA_ProductionGroups']
>;
export type DictMarketingGroupsRes = NonNullable<
  NonNullable<DictWa_MarketingGroupsQuery['wawiAssist']>['dictWA_MarketingGroups']
>;
export type DictProductionPrintListsRes = NonNullable<
  NonNullable<DictWa_ProductionPrintListsQuery['wawiAssist']>['dictWA_ProductionPrintLists']
>;
export type DictShippingPointsRes = NonNullable<
  NonNullable<DictWa_ShippingPointsQuery['wawiAssist']>['dictWA_ShippingPoints']
>;
export type DictAssortmentGroupsRes = NonNullable<
  NonNullable<DictWa_AssortmentGroupsQuery['wawiAssist']>['dictWA_AssortimentGroups']
>;

export type DictCashAssistTemplatesRes = NonNullable<
  NonNullable<DictWa_CashAssistTemplatesQuery['wawiAssist']>['dictWA_CashAssistTemplates']
>;
export type DictCashAssistProductGroupsRes = NonNullable<
  NonNullable<DictWa_CashAssistProductGroupsQuery['wawiAssist']>['dictWA_CashAssistProductGroups']
>;
export type DictTourplansRes = NonNullable<
  NonNullable<DictWa_TourplansQuery['wawiAssist']>['dictWA_Tourplans']
>;
export type DictRecipesWithConnectedArticlesRes = NonNullable<
  NonNullable<
    DictWa_RecipesWithConnectedArticlesQuery['wawiAssist']
  >['dictWA_RecipesWithConnectedArticles']
>;
export type DictArticlesRes = NonNullable<
  NonNullable<DictWa_ArticlesQuery['wawiAssist']>['dictWA_Articles']
>;
export type DictBanksRes = NonNullable<
  NonNullable<DictWa_BanksQuery['wawiAssist']>['dictWA_Banks']
>;
export type DictDebtRemindersRes = NonNullable<
  NonNullable<DictWa_DebtRemindersQuery['wawiAssist']>['dictWA_DebtReminders']
>;
export type DictStorageLocationsRes = NonNullable<
  NonNullable<DictWa_StorageLocationsQuery['wawiAssist']>['dictWA_StorageLocations']
>;
export type DictCloudPrintersRes = NonNullable<
  NonNullable<DictWa_CloudPrintersQuery['wawiAssist']>['dictWA_CloudPrinters']
>;
