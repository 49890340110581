import { LoaderFunction } from 'react-router-dom';
import { getCurrentYearPreviousMonthPeriod } from '../../../shared/helpers/utils/utils.helper.ts';
import { OpenDebtsGridDataRes, openDebtsService } from '../services/openDebts.service.ts';
import { take, zipWith } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { format } from 'date-fns';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { storageHelper } from '../../../shared/helpers/storage';
import { dataHelper } from '../../../shared/helpers/data/data.helper.ts';
import {
  configsData,
  DictDebtRemindersRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { C_Report } from '../../../graphql/generatedModel.ts';
import { SelectedInvoiceOptions } from '../openDebts/components/sideControls.component.tsx';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper.ts';

export const openDebtsLoader: LoaderFunction = async (): Promise<IOpenDebtsLoader> => {
  const paymentsUpToDate = localeFormatterHelper.localizedDate();
  const invoicesDates = getCurrentYearPreviousMonthPeriod();
  const sortModel = storageHelper.local.getItem('openDebts.sortModel') || null;
  const previewId =
    storageHelper.local.getItem('openDebts.previewId') ||
    SelectedInvoiceOptions.onlySelectedInvoice;
  const sendingId =
    storageHelper.local.getItem('openDebts.sendingId') ||
    SelectedInvoiceOptions.onlySelectedInvoice;
  let [openDebtsGridData, reminderTextOptions] = await resolveOpenDebtsData(
    invoicesDates,
    paymentsUpToDate,
  );
  const printFormId = C_Report.R10_CUSTOMERS_DEBT_NOTIFIER;
  const reminderTextId = reminderTextOptions?.[0]?.id || null;
  if (openDebtsGridData.length && sortModel) {
    openDebtsGridData = dataHelper
      .data(openDebtsGridData as [])
      .sort({ sortModel })
      .result() as IOpenDebtsLoader['openDebtsGridData'];
  }
  return {
    reminderTextOptions,
    reminderTextId,
    printFormId,
    previewId,
    sendingId,
    paymentsUpToDate,
    invoicesDates,
    openDebtsGridData,
    sortModel,
    selectedRow: null,
  };
};

const resolveOpenDebtsData = async (
  invoicesDates: IOpenDebtsLoader['invoicesDates'],
  paymentsUpToDate: IOpenDebtsLoader['paymentsUpToDate'],
) => {
  return await openDebtsService.globHelpers.streamToPromise(
    openDebtsService.sub.openDebtsGridData().pipe(
      zipWith(configsData.sub.dictDebtReminders()),
      responseHandler<[OpenDebtsGridDataRes, DictDebtRemindersRes]>({
        errorReturnType: [[], []],
      }),
      take(1),
    ),
    () => {
      openDebtsService.pub.openDebtsGridData({
        invoicesFromDate: format(invoicesDates.fromDate, 'yyyy-MM-dd'),
        invoicesToDate: format(invoicesDates.toDate, 'yyyy-MM-dd'),
        paymentsUpToDate: format(paymentsUpToDate, 'yyyy-MM-dd'),
      });
      configsData.pub.common(['dictDebtReminders']);
    },
  );
};

export interface IOpenDebtsLoader {
  invoicesDates: {
    fromDate: Date;
    toDate: Date;
  };
  paymentsUpToDate: Date;
  openDebtsGridData: OpenDebtsGridDataRes;
  reminderTextOptions: DictDebtRemindersRes;
  reminderTextId: DictDebtRemindersRes[number]['id'] | null;
  printFormId: C_Report | null;
  previewId: SelectedInvoiceOptions | null;
  sendingId: SelectedInvoiceOptions | null;
  sortModel: GridSortModel | null;
  selectedRow: OpenDebtsGridDataRes[number] | null;
}
