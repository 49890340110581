import React, { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { TableTextField } from '../../table/styles/styleFields.ts';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { dictionaryState } from '../states/dictionary.state.ts';
import { FilledTextFieldProps } from '@mui/material/TextField/TextField';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { IconButton } from '@mui/material';
import SettingsIco from '@mui/icons-material/Settings';
import { isEnterKey } from '../../../helpers/utils/utils.helper.ts';

export const TextEditCellDict: FC<ITextEditCellDict> = (props) => {
  const {
    value: initValue,
    field,
    id,
    row,
    allowSubmitOnlyChangedValues = false,
    rightButton,
  } = props;
  const { setEditCellValue, stopCellEditMode } = props.api;
  const [value, setValue] = useState(initValue || '');
  const timer: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const updateCell = useCallback(
    (v: string) => {
      if ((!v && !allowSubmitOnlyChangedValues) || value !== v) {
        const updatingCell = { id, field, value: v };
        setEditCellValue(updatingCell);
        dictionaryState.pub.updateCell(updatingCell);
      }
    },
    [props],
  );

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  const handleBlur = (v: React.FocusEvent<HTMLInputElement>) => {
    const newValue = v.target.value;
    updateCell(newValue);
  };

  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && isEnterKey(v)) {
      updateCell(v.target.value);
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableTextField
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      autoFocus
      width='100%'
      InputProps={{
        ...(rightButton && {
          endAdornment: (
            <IconButton
              title={rightButton.title}
              disabled={String(id).startsWith('new')}
              onClick={() =>
                row.invalid
                  ? (timer.current = setTimeout(
                      () =>
                        stopCellEditMode({
                          id,
                          field,
                        }),
                      0,
                    ))
                  : rightButton?.handler()
              }
              sx={{ p: '3.5px', mr: '-5px' }}
              size='small'
            >
              {rightButton.icon ? <rightButton.icon /> : <SettingsIco />}
            </IconButton>
          ),
        }),
      }}
      {...{ value }}
    />
  );
};

interface ITextEditCellDict extends GridRenderEditCellParams {
  allowSubmitOnlyChangedValues?: boolean;
  InputProps?: FilledTextFieldProps['InputProps'];
  rightButton?: {
    icon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
    title: string;
    handler: () => void;
  };
}
