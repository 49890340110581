import { FC, useEffect, useState, useRef, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';
import { IconButton, Stack, Checkbox, styled, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Content as Wrapper } from '../../../../../../../shared/components/content/content.component.tsx';
import { Table } from '../../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../../shared/components/table/components/common.components.tsx';
import { FormCheckbox } from '../../../../../../../shared/components/form';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/RestartAlt';
import { viewCells } from './views/viewCells.ts';
import { viewRows } from './views/viewRows.ts';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler.ts';
import {
  DoughsSplittingInformationRes,
  printJobsService,
  SaveDoughSplittingInformationRes,
  DeleteDoughSplittingInformationRes,
} from '../../../../../services/printJobs.service.ts';
import { format } from 'date-fns';
import { companyConfigService } from '../../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { take, filter } from 'rxjs';
import { storageHelper } from '../../../../../../../shared/helpers/storage/index.ts';
import { IDoughsSplittingPopup } from './doughsSplitting.popup.tsx';
import { GridRowId, GridValueGetterParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { DoughSplittingEditPopup } from '../doughSplittingEdit/doughSplittingEdit.popup.tsx';
import { WarningPopup } from '../../../../../../../shared/components/popupTemplates/warningPopup/warning.popup.tsx';
import { C_Save_Operation_Status } from '../../../../../../../graphql/generatedModel.ts';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service.ts';

export const ContentPopup: FC<IContent> = ({ stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [doughSplittingData, setDoughSplittingData] = useState<IDoughsSplittingData[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<GridRowId | undefined>(undefined);
  const [date, setDate] = useState<string>(
    format(localeFormatterHelper.localizedDate(), 'yyyy-MM-dd'),
  );
  const doughSplittingEditPopup = useMemo(() => new DoughSplittingEditPopup(), []);
  const warningPopup = useMemo(() => new WarningPopup({ closureOverlay: false }), []);
  const selectedRowIdRef = useRef<GridRowId | undefined>(undefined);
  const doughSplittingDataRef = useRef<IDoughsSplittingData[]>([]);
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      isShowSplittedAmountInLiters: false,
      isShowRASemiproductsDetails: false,
    },
  });
  const { watch, getValues } = formMethods;

  useEffect(() => {
    selectedRowIdRef.current = selectedRowId;
  }, [selectedRowId]);

  useEffect(() => {
    doughSplittingDataRef.current = doughSplittingData;
  }, [doughSplittingData]);

  useEffect(() => {
    const subscriptions: any[] = [];

    const handleOpen = (event: IDoughsSplittingPopup) => {
      const eventDate = format(event.date, 'yyyy-MM-dd');
      setDate(eventDate);

      const handleSuccessfulRecordModification = (
        res: SaveDoughSplittingInformationRes | DeleteDoughSplittingInformationRes,
      ) => {
        if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
          const data = structuredClone(doughSplittingDataRef.current);
          const index = data.findIndex((value) => value.id == res.updatedGridItem?.id);
          data[index].resPart1Amount = res.updatedGridItem?.resPart1Amount;
          data[index].resPart2Amount = res.updatedGridItem?.resPart2Amount;
          data[index].resPart3Amount = res.updatedGridItem?.resPart3Amount;
          data[index].resPart4Amount = res.updatedGridItem?.resPart4Amount;
          data[index].resPart5Amount = res.updatedGridItem?.resPart5Amount;
          data[index].resPart1LtAmount = res.updatedGridItem?.resPart1LtAmount;
          data[index].resPart2LtAmount = res.updatedGridItem?.resPart2LtAmount;
          data[index].resPart3LtAmount = res.updatedGridItem?.resPart3LtAmount;
          data[index].resPart4LtAmount = res.updatedGridItem?.resPart4LtAmount;
          data[index].resPart5LtAmount = res.updatedGridItem?.resPart5LtAmount;
          data[index].diffLt = res.updatedGridItem?.diffLt;
          data[index].diff = res.updatedGridItem?.diff;
          data[index].isSplitted = res.updatedGridItem?.isSplitted || false;
          data[index].part1Def = res.updatedGridItem?.part1Def;
          data[index].part2Def = res.updatedGridItem?.part2Def;
          data[index].isLoading = false;
          setDoughSplittingData(data);
          snackbarService.pub.show({
            type: 'success',
            content: t('production.dough_splitting_information_was_saved'),
          });
        }
      };

      const configs = companyConfigService.getCachedConfigs();

      if (configs) {
        formMethods.setValue('isShowSplittedAmountInLiters', configs.isShowSplittedAmountInLiters);
        formMethods.setValue('isShowRASemiproductsDetails', configs.isShowRASemiproductsDetails);
      }

      const formStateSub = formMethods.watch((data, reason) => {
        if (reason.type === 'change' && reason.name) {
          const fieldName = reason.name;
          const dataToSave = { [fieldName]: data[fieldName] };
          companyConfigService
            .saveConfigData(dataToSave)
            .pipe(take(1))
            .subscribe((res) => {
              if (res) {
                const cachedConfigData = storageHelper.session.getItem('companyConfig');
                storageHelper.session.setItem('companyConfig', {
                  ...cachedConfigData,
                  ...dataToSave,
                });
                if (fieldName === 'isShowRASemiproductsDetails') {
                  // Here we need to initiate refreshing data in grid (because we can have some additional records)
                  // and we can do it when sveing operation compleated
                  printJobsService.pub.getDoughsSplittingInformation({ onDate: eventDate });
                }
              }
            });
        }
      });
      subscriptions.push(formStateSub);

      const getDoughsSplittingInformationSub = printJobsService.sub
        .getDoughsSplittingInformation()
        .pipe(responseHandler<DoughsSplittingInformationRes>({ errorReturnType: [] }))
        .subscribe((data) => {
          setDoughSplittingData(data);

          if (data.findIndex((item) => item.id == selectedRowIdRef.current?.valueOf()) === -1) {
            setSelectedRowId(undefined);
          }

          setLoading(false);
        });
      subscriptions.push(getDoughsSplittingInformationSub);

      const deleteDoughSplittingInformationSub = printJobsService.sub
        .deleteDoughSplittingInformation()
        .pipe(
          responseHandler<DeleteDoughSplittingInformationRes>({
            errorReturnType: { status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED },
          }),
        )
        .subscribe(handleSuccessfulRecordModification);
      subscriptions.push(deleteDoughSplittingInformationSub);

      const saveDoughSplittingInformationSub = printJobsService.sub
        .saveDoughSplittingInformation()
        .pipe(
          responseHandler<SaveDoughSplittingInformationRes>({
            errorReturnType: { status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED },
          }),
        )
        .subscribe(handleSuccessfulRecordModification);
      subscriptions.push(saveDoughSplittingInformationSub);

      const doughSplittingEditPopupSub = doughSplittingEditPopup.stream
        .state()
        .pipe(filter((state) => state.action === 'save'))
        .subscribe((state) => {
          setDoughSplittingData((prev) => {
            const index = prev.findIndex((value) => value.id == state.defaultValues.id);
            prev[index].isLoading = true;
            return [...prev];
          });
        });
      subscriptions.push(doughSplittingEditPopupSub);

      printJobsService.pub.getDoughsSplittingInformation({ onDate: eventDate });
    };

    const openSubscription = stream.actionListener('open').pipe(take(1)).subscribe(handleOpen);
    subscriptions.push(openSubscription);

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const getterWithFormatingForWeight = (params: GridValueGetterParams<GridValidRowModel, any>) =>
    params.value !== undefined && params.value !== null
      ? localeFormatterHelper.formatNumber(params.value, {
          returnZero: false,
        })
      : params.value;

  const showEditor = (field: string = '') => {
    const doughSplittingValue = doughSplittingData.find(
      (item) => item.id === selectedRowId?.valueOf(),
    );

    const isLiters = getValues().isShowSplittedAmountInLiters;

    // Here we need to exclude the case when we want to see liters when editing, but faktorLtKg is 0
    if (!doughSplittingValue?.faktorLtKg && isLiters) {
      warningPopup.stream.emit('open', {
        message: t(`production.can_not_edit_splitting_parts_in_liters`),
      });
    } else {
      if (doughSplittingValue) {
        doughSplittingEditPopup.stream.emit('open', {
          defaultValues: {
            id: doughSplittingValue.id,
            title: t('production.dough_splitting'),
            roundTo: doughSplittingValue.roundTo,
            doughNo: doughSplittingValue.doughNo,
            doughName: doughSplittingValue.doughName,
            part1Amount: isLiters
              ? doughSplittingValue.resPart1LtAmount
              : doughSplittingValue.resPart1Amount,
            part2Amount: isLiters
              ? doughSplittingValue.resPart2LtAmount
              : doughSplittingValue.resPart2Amount,
            part3Amount: isLiters
              ? doughSplittingValue.resPart3LtAmount
              : doughSplittingValue.resPart3Amount,
            part4Amount: isLiters
              ? doughSplittingValue.resPart4LtAmount
              : doughSplittingValue.resPart4Amount,
            part5Amount: isLiters
              ? doughSplittingValue.resPart5LtAmount
              : doughSplittingValue.resPart5Amount,
            diff: isLiters ? doughSplittingValue.diffLt : doughSplittingValue.diff,
            isSplitted: doughSplittingValue.isSplitted,
            part1Def: doughSplittingValue.part1Def,
            part2Def: doughSplittingValue.part2Def,
            faktorLtKg: doughSplittingValue.faktorLtKg,
            date: localeFormatterHelper.localizedDate(date),
            isLiters: isLiters,
            doughRequired: isLiters
              ? doughSplittingValue.literDoughRequired
              : doughSplittingValue.kilogramDoughRequired,
            field: field,
          },
        });
      }
    }
  };

  const resetHandler = () => {
    if (selectedRowId) {
      setDoughSplittingData((prev) => {
        const index = prev.findIndex((value) => value.id == selectedRowId);
        prev[index].isLoading = true;
        return [...prev];
      });
      printJobsService.pub.deleteDoughSplittingInformation({
        id: selectedRowId.toString(),
        onDate: date,
      });
    }
  };

  const rowDblClickHandler = (_: any, e: any) => {
    if (!e.isLoading) {
      showEditor((e.target as any).parentElement?.dataset?.field);
    }
  };

  return (
    <Wrapper type='box' {...{ loading }}>
      <Stack width='1242px'>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' spacing={2} mb={1}>
            <IconButton
              color='primary'
              size='large'
              title={t('common.change')}
              onClick={() => showEditor()}
              disabled={
                !selectedRowId ||
                doughSplittingData.find((value) => value.id == selectedRowId)?.isLoading === true
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color='primary'
              size='large'
              title={t('common.clear')}
              onClick={resetHandler}
              disabled={
                !selectedRowId ||
                doughSplittingData.find((value) => value.id == selectedRowId)?.isSplitted ===
                  false ||
                doughSplittingData.find((value) => value.id == selectedRowId)?.isLoading === true
              }
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
          <FormProvider {...formMethods}>
            <form noValidate>
              <FormCheckbox
                label={t('settings.show_liters_in_production_print_lists')}
                name='isShowSplittedAmountInLiters'
              />
              <FormCheckbox
                label={t('production.show_semi_products_details')}
                name='isShowRASemiproductsDetails'
              />
            </form>
          </FormProvider>
        </Stack>
        <Table
          height={278}
          data={doughSplittingData}
          viewCells={viewCells}
          viewRows={viewRows}
          columnVisibilityModel={{
            kilogramDoughRequired: !watch('isShowSplittedAmountInLiters'),
            literDoughRequired: watch('isShowSplittedAmountInLiters'),
            resPart1Amount: !watch('isShowSplittedAmountInLiters'),
            resPart1LtAmount: watch('isShowSplittedAmountInLiters'),
            resPart2Amount: !watch('isShowSplittedAmountInLiters'),
            resPart2LtAmount: watch('isShowSplittedAmountInLiters'),
            resPart3Amount: !watch('isShowSplittedAmountInLiters'),
            resPart3LtAmount: watch('isShowSplittedAmountInLiters'),
            resPart4Amount: !watch('isShowSplittedAmountInLiters'),
            resPart4LtAmount: watch('isShowSplittedAmountInLiters'),
            resPart5Amount: !watch('isShowSplittedAmountInLiters'),
            resPart5LtAmount: watch('isShowSplittedAmountInLiters'),
            diff: !watch('isShowSplittedAmountInLiters'),
            diffLt: watch('isShowSplittedAmountInLiters'),
          }}
          onRowClick={(e) => setSelectedRowId(e.id)}
          onRowDoubleClick={rowDblClickHandler}
          scrollToSelectedRow
        >
          <Column
            field='isSplitted'
            headerName={t('production.splitted')}
            align='left'
            headerAlign='left'
            width={90}
            sortable={false}
            disableReorder
            renderCell={({ value, row }) =>
              row?.isLoading ? (
                <CircularProgress size={20} sx={{ m: 1.5 }} />
              ) : (
                <SCheckbox sx={{ m: 0 }} readOnly disableRipple checked={value} />
              )
            }
          />
          <Column
            field='doughNo'
            headerName={t('common.number')}
            align='left'
            headerAlign='left'
            width={100}
            sortable={false}
            disableReorder
          />
          <Column
            field='doughName'
            headerName={t('common.description')}
            align='left'
            headerAlign='left'
            width={240}
            sortable={false}
            disableReorder
          />
          <Column
            field='kilogramDoughRequired'
            headerName={t('article.dough') + ' (kg)'}
            align='left'
            headerAlign='left'
            width={150}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart1Amount'
            headerName={t('customer.part') + ' 1 (kg)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart2Amount'
            headerName={t('customer.part') + ' 2 (kg)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart3Amount'
            headerName={t('customer.part') + ' 3 (kg)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart4Amount'
            headerName={t('customer.part') + ' 4 (kg)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart5Amount'
            headerName={t('customer.part') + ' 5 (kg)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='diff'
            headerName={t('production.diff') + ' (kg)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='literDoughRequired'
            headerName={t('article.dough') + ' (lt)'}
            align='left'
            headerAlign='left'
            width={150}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart1LtAmount'
            headerName={t('customer.part') + ' 1 (lt)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart2LtAmount'
            headerName={t('customer.part') + ' 2 (lt)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart3LtAmount'
            headerName={t('customer.part') + ' 3 (lt)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart4LtAmount'
            headerName={t('customer.part') + ' 4 (lt)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='resPart5LtAmount'
            headerName={t('customer.part') + ' 5 (lt)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
          <Column
            field='diffLt'
            headerName={t('production.diff') + ' (lt)'}
            align='left'
            headerAlign='left'
            width={110}
            sortable={false}
            valueGetter={getterWithFormatingForWeight}
            disableReorder
          />
        </Table>
      </Stack>
      <doughSplittingEditPopup.Component />
      <warningPopup.Component />
    </Wrapper>
  );
};

const SCheckbox = styled(Checkbox)({
  '& label': {
    margin: 0,
  },
});

interface IContent {
  stream: IPopupWrapper['stream'];
}

interface IDoughsSplittingData {
  id: string;
  roundTo?: number | null;
  doughNo?: string | null;
  doughName?: string | null;
  productionGroupId?: string | null;
  kilogramDoughRequired?: number | null;
  literDoughRequired?: number | null;
  isDoughSplitting: boolean;
  resPart1Amount?: number | null;
  resPart1LtAmount?: number | null;
  resPart2Amount?: number | null;
  resPart2LtAmount?: number | null;
  resPart3Amount?: number | null;
  resPart3LtAmount?: number | null;
  resPart4Amount?: number | null;
  resPart4LtAmount?: number | null;
  resPart5Amount?: number | null;
  resPart5LtAmount?: number | null;
  diff?: number | null;
  diffLt?: number | null;
  isSplitted: boolean;
  part1Def?: number | null;
  part2Def?: number | null;
  faktorLtKg?: number | null;
  isLoading?: boolean | null;
}
