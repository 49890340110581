import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { generalTabState, IGeneralTabState } from '../../generalTab.state';
import { TMode } from '../../../../../../../shared/services/mode/mode.service';
import { GeneralTabOptionsRes } from '../../../../../services/article.service';
import { ITabState } from '../../../../../states/tabState.model';
import { articleListState } from '../../../../../states/articleList.state.ts';
import { TabPanel } from '../../../../../../../shared/components/tabs/tabPanel.component.tsx';
import { CategoriesPrices } from './categoriesPrices/categoriesPrices.components.tsx';
import { CustomersPrices } from './customersPrices/customersPrices.components.tsx';
import { ResellerPrices } from './resellerPrices/resellerPrices.components.tsx';

export const SpecialPrices: FC<ISpecialPrices> = ({
  articleSpecialPrices,
  specialPriceCategoriesColumns,
  createSpecialPriceCategoriesInputs,
  articlePricesByCustomers,
  articleResellerPrices,
  mode,
  customersList,
}) => {
  const isEditMode = mode === 'edit';
  const { t } = useTranslation();
  const [categoriesPricesData, setCategoriesPricesData] =
    useState<ITabState['articleSpecialPrices']>(articleSpecialPrices);
  const [pricesByCustomerData, setPricesByCustomerData] =
    useState<IGeneralTabState['articlePricesByCustomers']>(articlePricesByCustomers);
  const [resellerPricesData, setResellerPricesData] =
    useState<IGeneralTabState['articleResellerPrices']>(articleResellerPrices);
  const [currentArticleId, setCurrentArticleId] = useState('');
  const [pricesActiveTab, setPricesActiveTab] = useState(SpecialPricesTabs.categories);

  useEffect(() => {
    generalTabState.pub.initArticleSpecialPrices(categoriesPricesData);
    generalTabState.pub.initPricesByCustomer(pricesByCustomerData);
    const unsubState = generalTabState.sub
      .state()
      .subscribe(({ articleSpecialPrices, articlePricesByCustomers, articleResellerPrices }) => {
        setCategoriesPricesData(articleSpecialPrices);
        setPricesByCustomerData(articlePricesByCustomers);
        setResellerPricesData(articleResellerPrices);
      });
    const articleListSub = articleListState.sub.state().subscribe(({ selectedArticle }) => {
      setCurrentArticleId(selectedArticle?.id ?? '');
    });
    return () => {
      unsubState.unsubscribe();
      articleListSub.unsubscribe();
    };
  }, []);

  return (
    <>
      <Tabs value={pricesActiveTab} onChange={(_, tab) => setPricesActiveTab(tab)}>
        <Tab label={t('article.price_categories')} value={SpecialPricesTabs.categories} />
        <Tab label={t('article.customer_prices')} value={SpecialPricesTabs.customers} />
        <Tab label={t('article.reseller_prices')} value={SpecialPricesTabs.reseller} />
      </Tabs>
      <Box mt={1}>
        <TabPanel value={SpecialPricesTabs.categories} activeValue={pricesActiveTab}>
          <CategoriesPrices
            createSpecialPriceCategoriesInputs={
              createSpecialPriceCategoriesInputs as ITabState['createSpecialPriceCategoriesInputs']
            }
            {...{
              categoriesPricesData,
              currentArticleId,
              isEditMode,
              specialPriceCategoriesColumns,
            }}
          />
        </TabPanel>
        <TabPanel value={SpecialPricesTabs.customers} activeValue={pricesActiveTab}>
          <CustomersPrices
            {...{ pricesByCustomerData, currentArticleId, customersList, isEditMode }}
          />
        </TabPanel>
        <TabPanel value={SpecialPricesTabs.reseller} activeValue={pricesActiveTab}>
          <ResellerPrices
            {...{ resellerPricesData, currentArticleId, customersList, isEditMode }}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export type TArticleSpecialPrices = ITabState['articleSpecialPrices'];
enum SpecialPricesTabs {
  categories,
  customers,
  reseller,
}

interface ISpecialPrices {
  articleSpecialPrices: TArticleSpecialPrices;
  specialPriceCategoriesColumns: ITabState['specialPriceCategoriesColumns'];
  createSpecialPriceCategoriesInputs?: ITabState['createSpecialPriceCategoriesInputs'];
  articlePricesByCustomers: ITabState['articlePricesByCustomers'];
  articleResellerPrices: ITabState['articleResellerPrices'];
  mode: TMode;
  customersList: GeneralTabOptionsRes['customersList'];
}
