import { GridColDef } from '@mui/x-data-grid-premium';
import React, { ReactElement, Children, JSXElementConstructor, isValidElement } from 'react';
import { TypeTable } from '../table.component';

export const formColumnsAndFooter = ({
  children,
  columnVisibilityModel,
  viewCells,
}: IformColumnsAndFooterArgs): IformColumnsAndFooterRes => {
  const columns: GridColDef[] = [];
  let footer: ReactElement | null = null;

  Children.forEach(children, (child) => {
    if (isColumn(child)) {
      let visibility = true;
      if (columnVisibilityModel) {
        const keys = Object.keys(columnVisibilityModel);
        let findEl: string | null = null;
        for (const key of keys) {
          const groupField = key.split('group_')?.[1];
          /* The group keyword combines multiple columns that contain text after "group_" in their field name.
          Example: group_test: false. All columns containing the word "test" in the field name will be hidden*/
          if (groupField && child.props.field.includes(groupField)) findEl = key;
          else if (key === child.props.field) findEl = key;
        }
        findEl && (visibility = columnVisibilityModel[findEl]);
      }
      if (visibility) {
        columns.push({
          ...child.props,
          ...(viewCells?.actions[child.props.field] && {
            cellClassName: viewCells.actions[child.props.field],
          }),
        });
      }
    }
    if (isFooter(child)) footer = child;
  });

  return { columns, footer };
};

const isColumn = (child: TChild) => {
  const isValid = isValidElement(child);
  return isValid && typeof child.type === 'function' && child.type.name === 'Column';
};

const isFooter = (child: TChild) => {
  const isValid = isValidElement(child);
  return isValid && typeof child.type === 'function' && child.type.name === 'Footer';
};

export const delayFn = (fn: () => void, ms?: number) => {
  let timer = setTimeout(() => {
    fn();
    clearTimeout(timer);
  }, ms || 0);
};

export const shouldHorizontalScroll = (args: {
  scrollLeft: number;
  columnLeft: number;
  containerWidth: number;
  columnWidth: number;
}): boolean => {
  const leftEdge = args.scrollLeft;
  const rightEdge = args.scrollLeft + args.containerWidth;
  const columnRight = args.columnLeft + args.columnWidth;

  const isColumnFullyVisible = args.columnLeft >= leftEdge && columnRight <= rightEdge;

  return !isColumnFullyVisible;
};

export const blockArrowKeysInMiddle = (e: React.KeyboardEvent) => {
  if (e.target instanceof HTMLInputElement) {
    const { selectionStart, selectionEnd, value } = e.target;
    if (
      (e.code === 'ArrowLeft' && selectionStart !== 0 && selectionEnd !== 0) ||
      (e.code === 'ArrowRight' && selectionStart !== value.length && selectionEnd !== value.length)
    ) {
      e.stopPropagation();
    }
  }
};

type TChild = ReactElement<any, string | JSXElementConstructor<any>>;

interface IformColumnsAndFooterArgs {
  children:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactElement<any, string | JSXElementConstructor<any>>[];
  columnVisibilityModel: TypeTable['columnVisibilityModel'];
  viewCells: TypeTable['viewCells'];
}

interface IformColumnsAndFooterRes {
  columns: GridColDef[];
  footer: ReactElement | null;
}
