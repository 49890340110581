import { FC, useEffect, useState } from 'react';
import { IIndividualPrices, IndividualPrices } from './individualPrices/individualPrices.component';
import { conditionsTabState } from '../../../conditions.state';
import { FieldsSection } from '../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../../../../../../../shared/components/tabs';
import { IResellerPrices, ResellerPrices } from './resellerPrices/resellerPrices.component';

export const SpecialPrices: FC<ISpecialPrices> = (props) => {
  const { customerSpecialPrices, productListOptions, customerResellerPrices } = props;
  const { t } = useTranslation();
  const [specialPricesData, setSpecialPricesData] = useState(customerSpecialPrices);
  const [resellerPricesData, setResellerPricesData] = useState(customerResellerPrices);
  const [pricesActiveTab, setPricesActiveTab] = useState(SpecialPricesTabs.individual);

  useEffect(() => {
    conditionsTabState.pub.initCustomerSpecialPrices(specialPricesData);
    conditionsTabState.pub.initCustomerResellerPrices(resellerPricesData);
    const unsubState = conditionsTabState.sub
      .state()
      .subscribe(({ customerSpecialPrices, customerResellerPrices }) => {
        setSpecialPricesData(customerSpecialPrices);
        setResellerPricesData(customerResellerPrices);
      });

    return () => {
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <FieldsSection nomt titleBoxSx={{ pb: 1 }} title={t('customer.articlePrices')}>
      <Tabs value={pricesActiveTab} onChange={(_, tab) => setPricesActiveTab(tab)}>
        <Tab label={t('customer.individualPrices')} value={SpecialPricesTabs.individual} />
        <Tab label={t('customer.resellerPrices')} value={SpecialPricesTabs.reseller} />
      </Tabs>
      <Box mt={1}>
        <TabPanel value={SpecialPricesTabs.individual} activeValue={pricesActiveTab}>
          <IndividualPrices {...{ productListOptions, specialPricesData }} />
        </TabPanel>
        <TabPanel value={SpecialPricesTabs.reseller} activeValue={pricesActiveTab}>
          <ResellerPrices {...{ productListOptions, resellerPricesData }} />
        </TabPanel>
      </Box>
    </FieldsSection>
  );
};

enum SpecialPricesTabs {
  individual,
  reseller,
}

interface ISpecialPrices {
  customerSpecialPrices: IIndividualPrices['specialPricesData'];
  productListOptions: IIndividualPrices['productListOptions'];
  customerResellerPrices: IResellerPrices['resellerPricesData'];
}
