import { FC, useRef, useEffect, useState } from 'react';
import { PopupService } from '../../../../../../shared/components/popup/services/popup.service.ts';
import { Button, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Subscription, take } from 'rxjs';
import { Table } from '../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../shared/components/table/components/common.components.tsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Content as Wrapper } from '../../../../../../shared/components/content/content.component.tsx';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler.ts';
import { IVersionsPopup } from './versions.popup.tsx';
import {
  CreateProductionReportsVersionRes,
  DeleteProductionReportsVersionRes,
  printJobsService,
  ProductionReportsVersionsRes,
} from '../../../../services/printJobs.service.ts';
import { format } from 'date-fns';
import { GridRowId } from '@mui/x-data-grid-premium';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';

export const Content: FC<IContent> = ({ stream }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ProductionReportsVersionsRes>([]);
  const [date, setDate] = useState<string>(
    format(localeFormatterHelper.localizedDate(), 'yyyy-MM-dd'),
  );
  const [selectedRowId, setSelectedRowId] = useState<GridRowId | undefined>(undefined);

  const selectedRowIdRef = useRef<GridRowId | undefined>(undefined);
  useEffect(() => {
    selectedRowIdRef.current = selectedRowId;
  }, [selectedRowId]);

  useEffect(() => {
    const subscriptions: Subscription[] = [];

    const handleOpen = (event: IVersionsPopup) => {
      const date = event.date;
      setDate(date);

      if (!date) return;

      const versionsSubscription = printJobsService.sub
        .getProductionReportsVersions()
        .pipe(responseHandler<ProductionReportsVersionsRes>({ errorReturnType: [] }))
        .subscribe((data) => {
          setLoading(false);
          setData(data);
        });

      subscriptions.push(versionsSubscription);

      printJobsService.pub.getProductionReportsVersions({ onDate: date });

      const createVersionSub = printJobsService.sub
        .createProductionReportsVersion()
        .pipe(
          responseHandler<CreateProductionReportsVersionRes>({
            errorReturnType: {
              id: '',
              versionNo: 0,
              createdDateTime: localeFormatterHelper.localizedDate(),
            },
          }),
        )
        .subscribe((newVersion) => {
          setLoading(false);
          setData((prevData) => [...prevData, newVersion]);
        });

      const deleteVersionSub = printJobsService.sub
        .deleteProductionReportsVersion()
        .pipe(
          responseHandler<DeleteProductionReportsVersionRes>({
            errorReturnType: false,
          }),
        )
        .subscribe((result) => {
          if (result && selectedRowIdRef.current) {
            setData((prevData) => prevData.filter((row) => row.id !== selectedRowIdRef.current));
            setSelectedRowId(undefined);
          }
          setLoading(false);
        });

      subscriptions.push(createVersionSub, deleteVersionSub);
    };

    const openSubscription = stream.actionListener('open').pipe(take(1)).subscribe(handleOpen);

    subscriptions.push(openSubscription);

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const addRowHandler = () => {
    printJobsService.pub.createProductionReportsVersion({ onDate: date });
    setLoading(true);
  };

  const deleteRowHandler = () => {
    printJobsService.pub.deleteProductionReportsVersion({ id: selectedRowId as string });
    setLoading(true);
  };

  return (
    <Wrapper type='box' {...{ loading }} boxProps={{ sx: { minHeight: '256px' } }}>
      <Stack width='402px'>
        <Stack direction='row' spacing={2} mb={1}>
          <IconButton color='primary' size='large' title={t('common.add')} onClick={addRowHandler}>
            <AddIcon />
          </IconButton>
          <IconButton
            disabled={!data || data.length === 0 || !selectedRowId}
            color='primary'
            size='large'
            title={t('common.remove')}
            onClick={deleteRowHandler}
          >
            <RemoveIcon />
          </IconButton>
        </Stack>
        <Table
          heightOffset={528}
          loading={!data}
          data={(data || []) as []}
          onRowClick={(e) => setSelectedRowId(e.id)}
        >
          <Column
            field='versionNo'
            headerName={t('common.description')}
            align='left'
            headerAlign='left'
            width={250}
            sortable={false}
            valueFormatter={({ value }: { value: number }) => `Version ${value}`}
            disableReorder
          />
          <Column
            field='createdDateTime'
            headerName={t('common.created')}
            align='left'
            headerAlign='left'
            width={150}
            valueFormatter={({ value }) =>
              format(localeFormatterHelper.localizedDate(value), 'dd.MM, HH:mm:ss')
            }
            sortable={false}
            disableReorder
          />
        </Table>
        <Stack direction='row' justifyContent='end' mt={3}>
          <Button
            onClick={() => stream.emit('close')}
            variant='outlined'
            color='primary'
            size='medium'
            sx={{ textTransform: 'uppercase' }}
          >
            {t('common.close')}
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

export interface IContent {
  stream: PopupService<IVersionsPopup>;
}
