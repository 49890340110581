import { localeFormatterHelper } from '../formatter/localeFormatter.helper.ts';

function simpleHash(input: string): string {
  let hash = 0;
  if (input.length === 0) return hash.toString();
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash).toString();
}

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}

function setCookie(name: string, value: string, days: number): void {
  let expires = '';
  if (days) {
    const date = localeFormatterHelper.localizedDate();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function checkAndIncrementSentryErrorCount(errorMessage: string, threshold = 20): boolean {
  const hashedErrorMessage = simpleHash(errorMessage);
  const cookieName = `sentry-error-${hashedErrorMessage}`;

  let errorCount = parseInt(getCookie(cookieName) || '0', 10);
  errorCount += 1;

  setCookie(cookieName, errorCount.toString(), 1);

  return errorCount >= threshold;
}
