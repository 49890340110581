import { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '../../../../../../../../../shared/components/form/index.ts';
import { useTranslation } from 'react-i18next';
import { ITabState } from '../../../../../../../states/tabState.model.ts';
import { conditionsTabState, IConditionsTabState } from '../../../../conditions.state.ts';
import { Table } from '../../../../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../../../../shared/components/table/components/common.components.tsx';
import { localeFormatterHelper } from '../../../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { Stack, styled } from '@mui/material';
import { storageHelper } from '../../../../../../../../../shared/helpers/storage/index.ts';
import { IndividualPricesPopup } from './popups/individualPrices/individualPrices.popup.tsx';
import { useFormContext } from 'react-hook-form';
import { CopyIndividualPricesPopup } from './popups/copyIndividualPrices/copyIndividualPrices.popup.tsx';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';
import { ControlButtons } from './components/controlButtons.component.tsx';

export const IndividualPrices: FC<IIndividualPrices> = ({
  specialPricesData,
  productListOptions,
}) => {
  const { t } = useTranslation();
  const [selectPos, setSelectPos] = useState<IConditionsTabState['selectedSpecialPricesPos']>(null);
  const [loading, setLoading] = useState(false);
  const { getValues } = useFormContext();
  const individualPricesPopup = useMemo(
    () =>
      new IndividualPricesPopup({
        productListOptions,
      }),
    [],
  );
  const copyIndividualPricesPopup = useMemo(() => new CopyIndividualPricesPopup(), []);

  useEffect(() => {
    const unsubState = conditionsTabState.sub
      .state()
      .subscribe(({ action, selectedSpecialPricesPos }) => {
        setSelectPos(selectedSpecialPricesPos);
        if (
          action === 'updateSpecialPricesPos' ||
          action === 'deleteSpecialPricesPos' ||
          action === 'copyIndividualPrices'
        ) {
          conditionsTabState.pub.saveSpecialPricesGrid(getValues('id'));
        }
      });
    const subLoadingGrid = conditionsTabState.sub
      .individualPricesGridLoading()
      .subscribe(setLoading);
    return () => {
      unsubState.unsubscribe();
      individualPricesPopup.stream.unsubscribe();
      subLoadingGrid.unsubscribe();
    };
  }, []);

  return (
    <>
      <ControlButtons
        {...{ copyIndividualPricesPopup, individualPricesPopup, selectPos, specialPricesData }}
      />
      <STable
        data={specialPricesData}
        initialState={{
          columns: storageHelper.local.getItem('customer.individualPricesColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('customer.individualPricesColumnModel', v)
        }
        rowSelectionModel={selectPos?.id || undefined}
        onRowClick={(v, e) => {
          conditionsTabState.pub.selectSpecialPricesPos(v.row);
          individualPricesPopup.stream.emit('open', {
            values: { row: v.row, field: (e.target as any).parentElement?.dataset?.field },
          });
        }}
        focusTabProps={{
          enabled: true,
          enabledFields: [
            'fromDate',
            'toDate',
            'description',
            'specialPrice',
            'specialDiscount',
            'specialIsNoDiscount',
            'fromQuantity',
            'comment',
          ],
        }}
        onCellKeyDown={({ row, field }, event) => {
          if (isEnterKey(event)) {
            conditionsTabState.pub.selectSpecialPricesPos(row);
            individualPricesPopup.stream.emit('open', {
              values: { row, field },
            });
          }
        }}
        height={400}
        {...{ loading }}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        <Column
          field='description'
          headerName={t('customer.article')}
          valueGetter={({ value, row }) => row.articleNo && `${row.articleNo} ${value}`}
          sortable={false}
          width={250}
        />
        <Column
          field='regularPrice'
          headerName={t('customer.stdPrice')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
        <Column
          field='specialPrice'
          headerName={t('common.price')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
        <Column
          field='specialDiscount'
          headerName={t('common.discount')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={90}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? `${localeFormatterHelper.formatNumber(value, {
                  precision: 2,
                  noTrailingZeros: true,
                })}%`
              : value
          }
        />
        <Column
          field='specialIsNoDiscount'
          headerName={t('customer.noDiscount')}
          sortable={false}
          align='center'
          headerAlign='center'
          renderCell={({ value }) => (
            <SCellCheckboxContainer data-field='specialIsNoDiscount'>
              <Checkbox disabled size='small' checked={value} />
            </SCellCheckboxContainer>
          )}
          width={110}
        />
        <Column
          field='fromQuantity'
          headerName={t('customer.fromQuantity')}
          align='right'
          headerAlign='right'
          sortable={false}
          valueGetter={({ value }) =>
            value
              ? localeFormatterHelper.formatNumber(value, {
                  precision: 3,
                  noTrailingZeros: true,
                })
              : ''
          }
          width={125}
        />
        <Column
          field='specialPriceType'
          headerName={t('customer.kind')}
          sortable={false}
          valueGetter={({ value }) => value && t(`enums.${value}`)}
          width={200}
        />
        <Column field='comment' headerName={t('customer.comment')} sortable={false} width={300} />
      </STable>
      <individualPricesPopup.Component />
      <copyIndividualPricesPopup.Component />
    </>
  );
};

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

export const SCellCheckboxContainer = styled(Stack)({
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  '& > label': {
    margin: 0,
    cursor: 'pointer!important',
  },
  '& > label > span': {
    opacity: 0.6,
    color: 'inherit!important',
  },
  '& .Mui-checked': {
    color: '#1976d2!important',
    opacity: 1,
  },
});

export interface IIndividualPrices {
  specialPricesData: ITabState['customerSpecialPrices'];
  productListOptions: ITabState['conditionsTabOptions']['productList'];
}
