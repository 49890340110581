import { FC, useCallback, useEffect, useState } from 'react';
import {
  EPeriodTypeForModal,
  IPeriodForTable,
} from '../deliveryPeriods/components/rightSide/tabs/period.popup';
import { PopupService } from '../../../../../../../shared/components/popup/services/popup.service';
import { IAddOrEditPeriod } from './addOrEditPeriod.popup';
import { useTranslation } from 'react-i18next';
import { resetTimeInDate } from '../../../../../../../shared/helpers/utils/utils.helper';
import {
  InitWeekDays,
  isBothDatesFromSamePeriod,
  isDateOutOfPeriods,
  transformDateToString,
} from '../deliveryPeriods/common.popup';
import { Button, Stack } from '@mui/material';
import { DatePicker } from '../../../../../../../shared/components/datePicker/datePicker.component';
import { Checkbox, TextField } from '../../../../../../../shared/components/form';
import { DayBoxes } from '../deliveryPeriods/components/rightSide/tabs/weekDays.popup';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';

export enum EDateType {
  fromDate = 'fromDate',
  toDate = 'toDate',
}

enum EBooleanType {
  isRepeatYearly = 'isRepeatYearly',
  isCustom = 'isCustom',
}

export const Content: FC<IContent> = ({ stream }) => {
  const [t] = useTranslation();
  const [data, setData] = useState<IPeriodForTable>();
  const [periodsData, setPeriodsData] = useState<IPeriodForTable[]>([]);
  const [initData, setInitData] = useState<IPeriodForTable>();
  const {
    id,
    description = '',
    isCustom = false,
    isRepeatYearly = false,
    fromDate = transformDateToString(localeFormatterHelper.localizedDate()),
    toDate = transformDateToString(localeFormatterHelper.localizedDate()),
    weekDays = InitWeekDays,
    typeForModal,
  } = data || {};

  useEffect(() => {
    const openSub = stream.actionListener('open').subscribe((data) => {
      setData(data?.values?.period);
      setInitData(structuredClone(data?.values?.period));
      setPeriodsData(data?.values?.periodsData);
    });
    return () => {
      openSub.unsubscribe();
    };
  }, []);

  const onFromDateChange = (date: Date | null) => {
    if (date) {
      date = resetTimeInDate(date);
      let isFromSame = true;
      if (typeForModal === EPeriodTypeForModal.editOld && data) {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: date,
          toDate: localeFormatterHelper.localizedDate(toDate as string),
          periodsData,
          editPeriod: data,
        });
      } else {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: date,
          toDate: localeFormatterHelper.localizedDate(toDate as string),
          periodsData,
        });
      }
      if (isFromSame) {
        changeDate(date);
      } else {
        changeDate(date, EDateType.fromDate, date);
      }
    }
  };

  const onToDateChange = (date: Date | null) => {
    if (date) {
      date = resetTimeInDate(date);
      let isFromSame = true;
      if (typeForModal === EPeriodTypeForModal.editOld && data) {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: localeFormatterHelper.localizedDate(fromDate as string),
          toDate: date,
          periodsData,
          editPeriod: data,
        });
      } else {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: localeFormatterHelper.localizedDate(fromDate as string),
          toDate: date,
          periodsData,
        });
      }
      if (isFromSame) {
        changeDate(date, EDateType.toDate);
      } else {
        changeDate(date, EDateType.toDate, date);
      }
    }
  };

  const changeDate = (date: Date, type: EDateType = EDateType.fromDate, date2?: Date) => {
    const newDate = transformDateToString(date);
    if (date2) {
      const type2 = type === EDateType.fromDate ? EDateType.toDate : EDateType.fromDate;
      const newDate2 = transformDateToString(date2);
      setData((prev: IPeriodForTable | any) => ({
        ...prev,
        [type]: newDate,
        [type2]: newDate2,
      }));
    } else {
      setData((prev: IPeriodForTable | any) => ({
        ...prev,
        [type]: newDate,
      }));
    }
  };

  const onDescriptionChange = (description: string) => {
    setData((prev: IPeriodForTable | any) => ({
      ...prev,
      description,
    }));
  };

  const onRepeatYearlyChange = (isRepeatYearly: boolean) => {
    changeBoolean(isRepeatYearly);
  };

  const onCustomChange = (isCustom: boolean) => {
    if (isCustom) {
      setData((prev: IPeriodForTable | any) => ({
        ...prev,
        [EBooleanType.isCustom]: isCustom,
        weekDays: prev?.weekDays ? prev?.weekDays : InitWeekDays,
      }));
    } else {
      changeBoolean(isCustom, EBooleanType.isCustom);
    }
  };

  const changeBoolean = (value: boolean, type: EBooleanType = EBooleanType.isRepeatYearly) => {
    setData((prev: IPeriodForTable | any) => ({
      ...prev,
      [type]: value,
    }));
  };

  const shouldDisableDate = useCallback(
    (date: Date) => {
      if (data?.typeForModal === EPeriodTypeForModal.editOld) {
        return !isDateOutOfPeriods(date, periodsData, data);
      }
      return !isDateOutOfPeriods(date, periodsData);
    },
    [periodsData, data],
  );

  const saveData = () => {
    if (data) {
      const { typeForModal: _, ...dataForSave } = data;
      const prev = structuredClone(periodsData);
      const index = prev?.findIndex((period) => period.id === id);
      if (index !== -1) {
        prev[index] = dataForSave;
      } else {
        prev.push(dataForSave);
      }
      stream.emit('save', { values: { periodsData: prev } });
    }
    stream.emit('close');
  };

  useBeforeClosePopup({
    stream,
    isDirty: !equal(initData, data),
  });

  return (
    <Stack width='594px' p={3}>
      <Stack direction='row' justifyContent='space-between'>
        <DatePicker
          navigation={false}
          fieldProps={{ label: t('common.from_date') }}
          width={265}
          value={localeFormatterHelper.localizedDate(fromDate as string)}
          onChange={onFromDateChange}
          onChangeDependencies={[data, periodsData]}
          calendarProps={{ shouldDisableDate }}
        />
        <DatePicker
          navigation={false}
          fieldProps={{ label: t('common.to_date') }}
          width={265}
          value={localeFormatterHelper.localizedDate(toDate as string)}
          onChange={onToDateChange}
          onChangeDependencies={[data, periodsData]}
          calendarProps={{ shouldDisableDate }}
        />
      </Stack>
      <TextField
        value={description}
        onChange={(e) => onDescriptionChange(e?.target?.value)}
        label={t('common.note')}
        width='100%'
        sx={{ margin: '24px 0' }}
      />
      <Checkbox
        checked={isRepeatYearly}
        onChange={(e) => onRepeatYearlyChange(e?.target?.checked)}
        label={t('article.repeat_yearly')}
      />
      <Checkbox
        checked={isCustom}
        onChange={(e) => onCustomChange(e?.target?.checked)}
        label={t('article.use_custom_weekdays_settings')}
      />
      {isCustom && weekDays && (
        <Stack mt={3}>
          <DayBoxes data={weekDays} setData={setData} width='48px' />
        </Stack>
      )}
      <Stack direction='row' justifyContent='flex-end' mt={6}>
        <Button onClick={saveData} variant='contained' sx={{ minWidth: '125px' }}>
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export interface IContent {
  stream: PopupService<IAddOrEditPeriod>;
}
