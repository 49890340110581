import { format, isValid } from 'date-fns';

import {
  C_Created_Invoices_Filter_Mode as FilterMode,
  C_Language,
  C_Payments_Filter_Mode,
  ListWa_CreatedInvoicesQueryVariables as filterVariables,
} from '../../../graphql/generatedModel';
import { WeekDays } from '../../../pages/article/services/article.service';
import {
  IFormatNumberOptions,
  localeFormatterHelper,
} from '../formatter/localeFormatter.helper.ts';
import { FocusEvent } from 'react';
import React from 'react';

export const getFirstAndLastDateOfMonth = (
  currentDate: Date,
  typeMonth: typeMonth,
): Pick<filterVariables['filter'], 'date' | 'dateTo'> => {
  const month = currentDate.getMonth() - (typeMonth === 'LAST_MONTH' ? 1 : 0);
  const year = currentDate.getFullYear();
  return {
    date: format(currentDate.setFullYear(year, month, 1), 'yyyy-MM-dd'),
    dateTo: format(currentDate.setFullYear(year, month + 1, 0), 'yyyy-MM-dd'),
  };
};

export const getFirstAndLastDateOfCurrentMonth = () => {
  const currentDate = localeFormatterHelper.localizedDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  return {
    fromDate: format(currentDate.setFullYear(year, month, 1), 'yyyy-MM-dd'),
    toDate: format(currentDate.setFullYear(year, month + 1, 0), 'yyyy-MM-dd'),
  };
};

export const calculateFutureDate = (fromDate: Date, addedDays: number): Date => {
  const fromDateCopy = localeFormatterHelper.localizedDate(fromDate);
  const sixthDay = fromDateCopy.getDate() + addedDays;
  return localeFormatterHelper.localizedDate(fromDateCopy.setDate(sixthDay));
};

export const resetTimeInDate = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDeliveryTime = (dateOrTime: typeDateOrTime): Date | null => {
  if (typeof dateOrTime === 'object') return dateOrTime;
  if (typeof dateOrTime === 'string') {
    const parsedTime = dateOrTime?.split(':').map(Number);
    if (parsedTime) {
      const date = localeFormatterHelper.localizedDate();
      date.setHours(parsedTime[0]);
      date.setMinutes(parsedTime[1]);
      return isValid(date) ? date : null;
    }
  }
  return null;
};

export const formatDeliveryTime = (time: typeDateOrTime): string | null => {
  if (!time) {
    return null;
  }
  if (typeof time === 'string') {
    return time;
  }
  return `${time?.getHours()}:${time?.getMinutes()}`;
};

export const getLanguageCode = (lang: string): C_Language => {
  switch (lang) {
    case 'de':
      return C_Language.L2_GERMAN;
    case 'en':
      return C_Language.L1_ENGLISH;
    case 'fr':
      return C_Language.L3_FRENCH;
    case 'it':
      return C_Language.L4_ITALIAN;
    default:
      return C_Language.L2_GERMAN;
  }
};

export const getCurrentYearPreviousMonthPeriod = (): { fromDate: Date; toDate: Date } => {
  const today = localeFormatterHelper.localizedDate();
  const fromDate = localeFormatterHelper.localizedDate(
    Date.UTC(today.getUTCFullYear() - 1, today.getUTCMonth(), 1),
  );
  const toDate = localeFormatterHelper.localizedDate(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0),
  );
  return { fromDate, toDate };
};

export const generateWeekShortTitles = (t: (key: string) => string): IShortTitleAndKey[] => [
  { key: WeekDays.isDeliveredMon, shortTitle: t('common.mon') },
  { key: WeekDays.isDeliveredTue, shortTitle: t('common.tue') },
  { key: WeekDays.isDeliveredWed, shortTitle: t('common.wed') },
  { key: WeekDays.isDeliveredThu, shortTitle: t('common.thu') },
  { key: WeekDays.isDeliveredFri, shortTitle: t('common.fri') },
  { key: WeekDays.isDeliveredSat, shortTitle: t('common.sat') },
  { key: WeekDays.isDeliveredSun, shortTitle: t('common.sun') },
];

export interface IShortTitleAndKey {
  key: WeekDays;
  shortTitle: string;
}
export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const formatValue = (value: number | null, options?: IFormatNumberOptions) => {
  if (value === null) return '';
  return localeFormatterHelper.formatNumber(value, {
    precision: 3,
    noTrailingZeros: true,
    ...(options && options),
  });
};

export const formatDate = (dateString: string) =>
  dateString ? localeFormatterHelper.localizedDate(dateString) : null;

type typeDateOrTime = string | Date | null | undefined;

type typeMonth =
  | FilterMode.CURRENT_MONTH
  | FilterMode.LAST_MONTH
  | C_Payments_Filter_Mode.CURRENT_MONTH
  | C_Payments_Filter_Mode.LAST_MONTH;

export const cleanObject = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
};

export const downloadFile = async ({ url, filename, callbackErr }: IDownloadFileArgs) => {
  try {
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json, text/plain,application/zip, image/png, image/jpeg, image/*',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    // Use blob URL to avoid open file in the browser instead of downloading it
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  } catch (err) {
    if (callbackErr) {
      callbackErr(err);
    } else {
      console.error('Error in fetching and downloading file:', err);
    }
  }
};

interface IDownloadFileArgs {
  url: string;
  filename: string;
  callbackErr?: (err: any) => void;
}

export const getJSONValueByPath = (obj: any, path: string) => {
  if (obj === undefined || path === undefined) return undefined;
  return path.split('.').reduce((current, key) => current && current[key], obj);
};

export const getNestedValue = (obj: any, path = '') => {
  const keysArray = path.split('.');
  const res = keysArray.reduce((prev, key) => {
    const isObj = prev && typeof prev === 'object';
    if (isObj) {
      return prev[key];
    }
    return undefined;
  }, obj);
  return res;
};

export const createNestedObject = (
  path: string,
  value: Record<string, any> | any,
): Record<string, any> => {
  return path.split('.').reduceRight((acc, key) => ({ [key]: acc }), value);
};

export const getParentName = (e: FocusEvent<HTMLFormElement>) => {
  let parentElement = e.target.parentElement;
  while (parentElement && !parentElement.getAttribute('name')) {
    parentElement = parentElement.parentElement;
  }
  if (parentElement) {
    const name = parentElement.getAttribute('name') || '';
    return name;
  }
  return '';
};

export function isEnterKey(event: KeyboardEvent | React.KeyboardEvent): boolean {
  return event.key === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Enter';
}

export function mergeDeepObjects(currentValues: any, dataToSave: any): any {
  const updateNestedObject = (current: any, updates: any): any => {
    if (typeof updates !== 'object' || updates === null) {
      return updates;
    }

    const result = { ...current };

    Object.keys(updates).forEach((key) => {
      if (
        typeof updates[key] === 'object' &&
        updates[key] !== null &&
        !Array.isArray(updates[key])
      ) {
        result[key] = updateNestedObject(current[key] || {}, updates[key]);
      } else {
        result[key] = updates[key];
      }
    });

    return result;
  };

  return updateNestedObject(currentValues, dataToSave);
}
