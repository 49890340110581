import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { FC } from 'react';
import { GlobalSearch } from '../../../globalSearch/globalSearch.component';
import { UserInfo } from './components/userInfo/userInfo.component';
import { Logout } from './components/logout/logout.component';
import { Title } from './components/title/title.component';
import { Language } from './components/language/language.component';
import { AppMenu } from './components/hsAppMenu/hsAppMenu.component';
import { Stack, styled } from '@mui/material';

export const TopBar: FC = () => {
  return (
    <SWrapper>
      <SToolbar>
        <Title />
        <Language />
        <GlobalSearch />
        <Stack direction='row' gap='16px'>
          <AppMenu />
          <UserInfo />
          <Logout />
        </Stack>
      </SToolbar>
    </SWrapper>
  );
};

const SWrapper = styled(AppBar)({
  position: 'static',
  color: 'inherit',
  boxShadow: 'none',
  backgroundColor: '#fff',
});

const SToolbar = styled(Toolbar)({
  minHeight: '56px !important',
  justifyContent: 'space-between',
  gap: '24px',
});
