/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Sub } from '../../../../../shared/state/state.abstract.ts';
import {
  BehaviorSubject,
  delay,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  pairwise,
  share,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';
import equal from 'fast-deep-equal/react';
import {
  CreateDraftOrderExtendedRes,
  createOrderService,
  DeleteDraftOrderRes,
  IPosition,
  NewOrderDataExtendedRes,
} from '../../../services/createOrder.service.ts';
import { customerListState } from '../customerList.state.ts';
import { orderSelectionPopup } from '../../components/orderDetails/orderDetails.component.tsx';
import {
  OrderDeliveryCostRes,
  orderService,
  ProductDataRes,
  ProductPriceAndDiscountByQuantityRes,
  ProductPriceAndDiscountByVatRes,
  TaxesByDateRes,
} from '../../../services/order.service.ts';
import {
  C_Discount_Kind,
  C_Kg_Representation,
  C_Sale_Unit,
  C_Vat_Code,
  C_Virtual_Position,
  CreateWa_DraftOrderMutationVariables,
  Wa_OrderPositionInputs,
} from '../../../../../graphql/generatedModel.ts';
import { companyConfigService } from '../../../../../shared/services/companyConfig/companyConfig.service.ts';
import i18n from 'i18next';
import {
  addTotalWeight,
  getTotalWeight,
  IOrderDetailsDataRes,
} from '../../../loaders/createOrder.loader.ts';
import {
  additionalOptions$,
  IAdditionalOptions,
  initOrderDetailsState,
  IOrderData,
  IOrderDetailsState,
  orderDetailsState,
} from './orderDetails.state.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import { notificationCenter } from '../../../../../shared/notificationCenter/notificationCenter.ts';

export class SubImpl extends Sub<IOrderDetailsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private orderGridLoading$ = new BehaviorSubject<boolean>(false);
  private shareOrderGridLoading$: Observable<boolean> = this.orderGridLoading$.pipe(share());
  private cellsLoading$ = new BehaviorSubject<string[]>([]); // Loading by cells
  private shareCellsLoading$ = this.cellsLoading$.pipe(share());
  private calcPriceError$ = new BehaviorSubject<number[]>([]);
  private shareCalcPriceError$ = this.calcPriceError$.pipe(share());
  private deliveryCostLoading$ = new BehaviorSubject<boolean>(false);
  protected shareDeliveryCostLoading$ = this.deliveryCostLoading$.pipe(share());
  private editedCells: Set<string> = new Set(); // Cells edited manually (format - 'idPosition_cellName')
  protected actionHandlers(): Observable<IOrderDetailsState> {
    return merge(
      this.selectOrder(),
      this.addPosition(),
      this.updatePosition(),
      this.deletePosition(),
      this.revertOrderData(),
      this.replaceOrderData(),
      this.queryData(),
      this.quantityCell(),
      this.productCell(),
      this.simpleCell(),
      this.vatCell(),
      this.selectPosition(),
      this.updateState(),
    ).pipe(
      tap((state) => {
        state.dirty =
          state.action !== 'emptyData' && !equal(state.orderDataBackup, state.orderData);
        this.stream$.next({ ...state, action: 'internalUpdate' });
        this.loading$.next(false);
      }),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  orderGridLoading(): Observable<boolean> {
    return this.shareOrderGridLoading$;
  }
  cellsLoading(): Observable<string[]> {
    return this.shareCellsLoading$;
  }
  deliveryCostLoading(): Observable<boolean> {
    return this.shareDeliveryCostLoading$;
  }
  calculationPriceError(): Observable<number[]> {
    return this.shareCalcPriceError$;
  }
  draftOrderSaved(): Observable<void> {
    return createOrderService.sub.createDraftOrder().pipe(
      responseHandler<CreateDraftOrderExtendedRes>({
        errorReturnType: { draftOrderId: '', customerId: '' },
      }),
      filter(({ draftOrderId }) => !!draftOrderId),
      map(({ draftOrderId, customerId }) => {
        customerListState.pub.updateDraftStatus({ id: customerId, draftOrderId });
      }),
    );
  }

  sharedValues(): Observable<ISharedValues> {
    // if you need current values of this state in other places
    const { orderData } = this.stream$.getValue();
    return of({
      orderData,
    });
  }

  /* METHODS OF SECONDARY OPTIONS start */
  additionalOptions(): Observable<IAdditionalOptions> {
    return additionalOptions$.pipe(switchMap(() => merge(this.productList(), this.taxesList())));
  }
  private productList(): Observable<IAdditionalOptions> {
    return orderService.sub.filteredProductData().pipe(
      responseHandler<IAdditionalOptions['productData']>({
        errorReturnType: {
          productList: [],
          listSpecialAssortmentGroups: undefined,
          listSpecialAvailabilityStateForArticles: undefined,
        },
        customErrorHandler: () => 'errors.unknownWithReload',
      }),
      map((productData) => {
        const additionalOptionsState = additionalOptions$.getValue();
        additionalOptionsState.productData = productData;
        return additionalOptionsState;
      }),
    );
  }

  private taxesList(): Observable<IAdditionalOptions> {
    return orderService.sub.taxes().pipe(
      responseHandler<TaxesByDateRes>({
        errorReturnType: [],
      }),
      map((taxes) => {
        const additionalOptionsState = additionalOptions$.getValue();
        additionalOptionsState.taxes = taxes;
        return additionalOptionsState;
      }),
    );
  }
  /* METHODS OF SECONDARY OPTIONS end */

  /* MASTER DATA METHODS start */
  private selectOrder(): Observable<IOrderDetailsState> {
    return orderSelectionPopup.stream.state().pipe(
      filter(({ action }) => action === 'confirm'),
      switchMap((value) => {
        const zipStream = zip(of(value), this.stream$);
        const prevOrderNo = this.stream$.value.orderData.orderSourceInfo.orderNo;
        const currOrderNo = value.confirmedOrder?.orderSourceInfo.orderNo;
        if (prevOrderNo !== currOrderNo) {
          return zipStream.pipe(
            tap(() => this.loading$.next(true)),
            delay(500),
          );
        }
        return zipStream;
      }),
      map(([{ confirmedOrder = {}, saveBackup }, state]) => {
        const orderData = confirmedOrder as IOrderData;
        return {
          ...state,
          orderData,
          ...(saveBackup && { orderDataBackup: structuredClone(orderData) }),
          action: 'selectOrder',
        };
      }),
    );
  }
  private queryData(): Observable<IOrderDetailsState> {
    return customerListState.sub.state().pipe(
      pairwise(),
      filter(
        ([prevV, currV]) =>
          (currV.action === 'selectCustomer' ||
            currV.action === 'filter' ||
            currV.action === 'changeNoOrderStatus') &&
          (prevV.filter.date !== currV.filter.date ||
            prevV.filter.orderType !== currV.filter.orderType ||
            prevV.selectedCustomer?.id !== currV.selectedCustomer?.id),
      ),
      switchMap(([prevListState, listState]) => {
        const { customerId, draftOrderId } = prevListState.selectedCustomer || {};
        if (customerId)
          this.runSaveDraftOrder({ customerId, draftOrderId, date: prevListState.filter.date });
        if (!listState.selectedCustomer)
          return of({ ...initOrderDetailsState, action: 'emptyData' } as IOrderDetailsState);
        return zip(this.stream$, of(listState)).pipe(
          tap(([_, listState]) => {
            this.loading$.next(true);
            createOrderService.pub.orderDetailsData({
              customer: listState.selectedCustomer!,
              orderType: listState.filter.orderType,
              date: listState.filter.date,
            });
            const {
              productData: { listSpecialAssortmentGroups, listSpecialAvailabilityStateForArticles },
            } = additionalOptions$.getValue();
            const isDateChanged =
              prevListState.filter.date !== listState.filter.date ||
              !listSpecialAvailabilityStateForArticles;
            const isNewCustomer =
              prevListState.selectedCustomer?.customerId !== listState.selectedCustomer?.customerId;
            orderService.pub.filteredProductData({
              ...((isNewCustomer || !listSpecialAssortmentGroups) && {
                customerId: listState.selectedCustomer?.customerId,
              }),
              isDateChanged,
              date: listState.filter.date,
              orderType: listState.filter.orderType,
              listSpecialAssortmentGroups,
              listSpecialAvailabilityStateForArticles,
            });

            if (prevListState.filter.date !== listState.filter.date) {
              orderService.pub.taxes({
                date: listState.filter.date,
              });
            }
          }),
          switchMap(([state]) => {
            return createOrderService.sub.orderDetailsData().pipe(
              responseHandler<IOrderDetailsDataRes>({
                errorReturnType: {
                  customerInfo: {},
                  orderData: [],
                },
              }),
              map(({ customerInfo, orderData }) => {
                if (!Object.keys(customerInfo).length || !orderData.length) {
                  // in case of error
                  const emptyState = structuredClone(initOrderDetailsState);
                  emptyState.action = 'errorData';
                  emptyState.orderDataBackup = structuredClone(emptyState.orderData);
                  return emptyState;
                }
                const {
                  customerNo,
                  internalOrFullName,
                  informationTip,
                  deliveryAddress,
                  discountKindId,
                  isDeliverySplitting,
                  deliverySplittingPartsCount,
                  transportSector,
                  transportSectorId,
                  id,
                  ...contacts
                } = customerInfo;
                orderData = addTotalWeight(orderData);
                if (orderData && orderData?.length > 1) {
                  orderSelectionPopup.stream.emit('open', {
                    orders: orderData || [],
                    saveBackup: true,
                  });
                }
                this.editedCells.clear();
                return {
                  ...state,
                  customerInfo: {
                    customerNo,
                    internalOrFullName,
                    informationTip,
                    deliveryAddress,
                    discountKindId,
                    isDeliverySplitting,
                    deliverySplittingPartsCount,
                    transportSector,
                    transportSectorId,
                    id,
                    contacts,
                  },
                  orderData: orderData?.[0] || {},
                  orderDataBackup: structuredClone(orderData?.[0] || {}),
                  selectedPos: null,
                  noData: false,
                  action: 'queryData',
                } as IOrderDetailsState;
              }),
            );
          }),
        );
      }),
    );
  }
  addPosition(): Observable<IOrderDetailsState> {
    return this.actionListener(['addPosition', 'addDeliveryPosition']).pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      switchMap((state) => {
        const updatedState = structuredClone(state);
        if (updatedState.action === 'addDeliveryPosition') {
          return this.getDeliveryCost({ state, force: true });
        } else {
          if (updatedState.selectedPos) {
            updatedState.orderData.positions?.push(updatedState.selectedPos);
          } else {
            const newPosition = {
              virtualPositionId: null,
              articleId: null,
              articleNo: null,
              description: null,
              quantity: null,
              price: 0,
              total: 0,
              discount: 0,
              vatCode: C_Vat_Code.VT5_ZERO,
              indTextDeliveryNote: null,
              indTextProduction: null,
              splittingPartsPreset: null,
              quantityPerLot: null,
              minQuantity: null,
              maxQuantity: null,
              saleUnit: null,
              weight: null,
              isChangeable: true,
              id: Date.now(),
              __reorder__: i18n.t('order.new_position'),
            };
            const deliveryPositionInd = updatedState.orderData.positions?.findIndex(
              (el) => el?.virtualPositionId,
            );
            if (typeof deliveryPositionInd === 'number' && deliveryPositionInd !== -1) {
              updatedState.orderData.positions?.splice(deliveryPositionInd, 0, newPosition);
            } else updatedState.orderData.positions?.push(newPosition);
            updatedState.selectedPos = newPosition;
          }
          return of(updatedState);
        }
      }),
    );
  }
  updatePosition(): Observable<IOrderDetailsState> {
    return this.actionListener('updatePosition').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const { selectedPos } = state;
        updatedState.orderData.positions = updatedState.orderData.positions?.map((pos) => {
          if (pos?.id === selectedPos?.id) return selectedPos;
          return pos;
        });
        return updatedState;
      }),
    );
  }
  deletePosition(): Observable<IOrderDetailsState> {
    return this.actionListener(['deletePosition', 'deleteDeliveryPosition']).pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      map((state) => {
        const updatedState = structuredClone(state);
        if (updatedState.action === 'deleteDeliveryPosition') {
          updatedState.orderData.positions = updatedState.orderData.positions?.filter(
            (pos) => !pos?.virtualPositionId,
          );
        } else {
          let selectedPos: IOrderDetailsState['selectedPos'] = null;
          updatedState.orderData.positions = updatedState.orderData.positions?.filter(
            (pos, i, arr) => {
              if (pos?.id === state.selectedPos?.id) {
                const nextPos = arr?.[i + 1];
                const prevPos = arr?.[i - 1];
                if (i === 0 && arr!.length > 1) {
                  selectedPos =
                    !nextPos?.virtualPositionId && nextPos?.isChangeable ? nextPos : null;
                }
                if (i !== 0) {
                  selectedPos =
                    (!nextPos?.virtualPositionId && nextPos?.isChangeable && nextPos) ||
                    (prevPos?.isChangeable ? prevPos : null);
                }
                return false;
              } else return true;
            },
          );
          updatedState.selectedPos = selectedPos;
        }
        return updatedState;
      }),
      switchMap((state) => {
        const prevSelectedPos = this.stream$.getValue().selectedPos;
        if (
          state.action === 'deletePosition' &&
          prevSelectedPos?.articleId &&
          prevSelectedPos?.quantity &&
          prevSelectedPos?.weight
        ) {
          this.stream$.next({ ...state, action: 'updateState' });
          return this.getDeliveryCost({ state }); // Recalculate delivery data
        } else return of(state);
      }),
    );
  }

  revertOrderData(): Observable<IOrderDetailsState> {
    return this.actionListener('revertOrderData').pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      switchMap((state) =>
        zip(of(state), customerListState.sub.sharedValues()).pipe(
          tap(([_, { customer }]) => {
            if (Number(customer?.draftOrderId)) {
              createOrderService.pub.deleteDraftOrder({
                deleteWaDraftOrderId: customer?.draftOrderId!,
              });
              this.orderGridLoading$.next(true);
            }
          }),
          switchMap(([state, customerData]) => {
            if (Number(customerData.customer?.draftOrderId)) {
              return createOrderService.sub.deleteDraftOrder().pipe(
                responseHandler<DeleteDraftOrderRes>({ errorReturnType: false }),
                tap((response) => {
                  if (!response) this.orderGridLoading$.next(false);
                }),
                filter((response) => response),
                tap(() => {
                  this.orderGridLoading$.next(true);
                  createOrderService.pub.orderData({
                    customer: { ...customerData.customer!, draftOrderId: null },
                    orderType: customerData.orderType,
                    date: customerData.date,
                  });
                }),
                switchMap(() => {
                  return createOrderService.sub.orderData().pipe(
                    responseHandler<NewOrderDataExtendedRes>({
                      errorReturnType: [
                        {
                          ...this.stream$.getValue().orderData,
                          deliveryTime: this.formateDeliveryTime(
                            this.stream$.getValue().orderData.deliveryTime,
                          ),
                        },
                      ],
                    }),
                    map((data) => {
                      state.orderDataBackup = structuredClone(addTotalWeight(data)[0]);
                      this.orderGridLoading$.next(false);
                      customerListState.pub.updateDraftStatus({
                        id: customerData?.customer?.id!,
                        draftOrderId: null,
                      });
                      return { ...state };
                    }),
                  );
                }),
              );
            } else return of(state);
          }),
          map((state) => {
            state.orderData = structuredClone(state.orderDataBackup);
            this.calcPriceError$.next([]);
            return { ...state };
          }),
        ),
      ),
    );
  }
  replaceOrderData(): Observable<IOrderDetailsState> {
    return this.actionListener('replaceOrderData').pipe(
      tap(() => this.orderGridLoading$.next(true)),
      switchMap((state) => {
        const updatedState = structuredClone(state);
        return createOrderService.sub.orderData().pipe(
          responseHandler<NewOrderDataExtendedRes>({
            errorReturnType: [
              {
                ...this.stream$.getValue().orderData,
                deliveryTime: this.formateDeliveryTime(
                  this.stream$.getValue().orderData.deliveryTime,
                ),
              },
            ],
          }),
          map((orderData) => {
            if (orderData?.length > 1) {
              orderSelectionPopup.stream.emit('open', { orders: orderData, saveBackup: false });
            }
            updatedState.orderData = orderData[0] || {};
            this.orderGridLoading$.next(false);
            return updatedState;
          }),
        );
      }),
    );
  }
  selectPosition(): Observable<IOrderDetailsState> {
    return this.actionListener('selectPosition').pipe(delay(10));
  }
  /* ORDER CELL METHODS start */
  private quantityCell(): Observable<IOrderDetailsState> {
    return this.actionListener('quantityCell').pipe(
      mergeMap((state) => {
        const { selectedPos, action, customerInfo } = state;
        const selectedPosOldValues = state.orderData.positions?.find(
          (pos) => pos.id === selectedPos?.id,
        );
        this.preUpdateCell(selectedPos);
        const isNotEditableDiscount =
          customerInfo?.discountKindId === C_Discount_Kind.DK5_QUANTITY_DISCOUNT ||
          customerInfo?.discountKindId === C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS;
        const shouldFetch = Boolean(
          selectedPos?.articleNo &&
            !(
              this.editedCells.has(`${selectedPos?.id}_priceCell`) &&
              (isNotEditableDiscount || this.editedCells.has(`${selectedPos?.id}_discountCell`))
            ),
        );
        return zip(of(selectedPos), customerListState.sub.sharedValues()).pipe(
          tap(([editablePos, { customer, date }]) => {
            if (shouldFetch) {
              orderService.pub.productPriceAndDiscountByQuantity({
                orderAndArticleProps: {
                  articleId: editablePos?.articleId!,
                  customerId: customer?.customerId!,
                  orderDate: date,
                  vatCode: editablePos?.vatCode!,
                  oldQuantity: Number(selectedPosOldValues?.quantity),
                  newQuantity: Number(editablePos?.quantity!),
                },
              });
              this.cellsLoading$.next([...this.cellsLoading$.value, `${editablePos?.id}_quantity`]);
            }
          }),
          switchMap(([editablePos]) => {
            if (shouldFetch) {
              return orderService.sub.productPriceAndDiscountByQuantity().pipe(
                responseHandler<ProductPriceAndDiscountByQuantityRes | 'serverError'>({
                  errorReturnType: 'serverError',
                }),
                map((values) => {
                  const { oldValues, newValues } =
                    (values as ProductPriceAndDiscountByQuantityRes) || {};

                  const shouldUpdatePrice =
                    (oldValues?.price ?? 0) === (selectedPosOldValues?.price ?? 0);
                  const shouldUpdateDiscount =
                    (oldValues?.discount ?? 0) === (selectedPosOldValues?.discount ?? 0);
                  this.cellsLoading$.next(
                    this.cellsLoading$.value.filter(
                      (item) => item !== `${editablePos?.id}_quantity`,
                    ),
                  );
                  if (!selectedPosOldValues) return;
                  const updatedPos = { ...selectedPosOldValues, quantity: editablePos?.quantity };
                  if (!this.editedCells.has(`${updatedPos?.id}_priceCell`)) {
                    if (values === 'serverError' && editablePos?.id) {
                      updatedPos.price = 0;
                      this.calcPriceError$.next([...this.calcPriceError$.value, editablePos?.id]);
                      return updatedPos;
                    } else if (typeof values === 'object' && editablePos?.id && shouldUpdatePrice) {
                      updatedPos.price = newValues.price;
                      this.calcPriceError$.next(
                        this.calcPriceError$.value.filter((item) => item !== editablePos?.id),
                      );
                    }
                  }
                  if (
                    !this.editedCells.has(`${updatedPos?.id}_discountCell`) &&
                    typeof values === 'object' &&
                    shouldUpdateDiscount
                  ) {
                    updatedPos.discount = newValues.discount;
                  }
                  return updatedPos;
                }),
              );
            }
            return of(editablePos);
          }),
          filter((updatedPos) => !!updatedPos), // if position is deleted
          map((updatedPos) => {
            const state = this.stream$.getValue();
            updatedPos!.total = this.calculateTotalDependsOnSaleUnit(updatedPos!);
            state.orderData.positions = state.orderData.positions?.map((el) =>
              el?.id === updatedPos?.id ? updatedPos : el,
            );
            this.editedCells.add(`${updatedPos?.id}_${action}`);
            return { ...state };
          }),
        );
      }),
      switchMap((state) => {
        if (state.selectedPos?.articleId) {
          this.stream$.next({ ...state, action: 'updateState' });
          return this.getDeliveryCost({ state }); // Recalculate delivery data
        } else return of(state);
      }),
    );
  }
  private productCell(): Observable<IOrderDetailsState> {
    return this.actionListener('descriptionCell').pipe(
      mergeMap(({ selectedPos, action }) => {
        this.preUpdateCell(selectedPos);
        return zip(of(selectedPos), customerListState.sub.sharedValues()).pipe(
          tap(([editablePos, customerData]) => {
            orderService.pub.productData({
              orderAndArticleProps: {
                orderDate: customerData.date,
                customerId: customerData.customer?.customerId!,
                articleId: editablePos?.articleId!,
                vatCode: editablePos?.vatCode!,
                quantity: Number(editablePos?.quantity),
              },
            });
            this.cellsLoading$.next([
              ...this.cellsLoading$.value,
              `${editablePos?.id}_description`,
            ]);
          }),
          switchMap(([editablePos]) => {
            return orderService.sub.productData().pipe(
              responseHandler<ProductDataRes | 'serverError'>({ errorReturnType: 'serverError' }),
              filter(() => {
                const state = this.stream$.getValue();
                this.cellsLoading$.next(
                  this.cellsLoading$.value.filter(
                    (item) => item !== `${editablePos?.id}_description`,
                  ),
                );
                return !!state.orderData.positions?.some((el) => el.id === editablePos?.id);
              }), // if position is deleted
              map((values) => {
                const state = this.stream$.getValue();
                let updatedPos = state.orderData.positions?.find(
                  (pos) => pos.id === editablePos?.id,
                )!;
                if (values === 'serverError' && editablePos?.id) {
                  updatedPos.price = 0;
                  this.calcPriceError$.next([...this.calcPriceError$.value, editablePos?.id]);
                } else if (typeof values === 'object') {
                  updatedPos = { ...updatedPos, ...values } as IPosition;
                  this.calcPriceError$.next(
                    this.calcPriceError$.value.filter((item) => item !== editablePos?.id),
                  );
                }
                updatedPos.total = this.calculateTotalDependsOnSaleUnit(updatedPos);
                state.orderData.positions = state.orderData.positions?.map((el) =>
                  el?.id === updatedPos?.id ? updatedPos : el,
                );
                this.editedCells.add(`${editablePos?.id}_${action}`);
                this.editedCells.delete(`${editablePos?.id}_priceCell`);
                this.editedCells.delete(`${editablePos?.id}_discountCell`);
                this.editedCells.delete(`${editablePos?.id}_vatCell`);
                return { ...state };
              }),
            );
          }),
          switchMap((state: IOrderDetailsState) => {
            if (state.selectedPos?.quantity) {
              this.stream$.next({ ...state, action: 'updateState' });
              return this.getDeliveryCost({ state }); // Recalculate delivery data
            } else return of(state);
          }),
        );
      }),
    );
  }
  private simpleCell(): Observable<IOrderDetailsState> {
    return this.actionListener(['priceCell', 'discountCell', 'splittingCell']).pipe(
      map(({ selectedPos, action }) => {
        const updatedPos = structuredClone(selectedPos!);
        if (action === 'priceCell') {
          this.calcPriceError$.next(
            this.calcPriceError$.value.filter((item) => item !== updatedPos?.id),
          );
          updatedPos.total = this.calculateTotalDependsOnSaleUnit(updatedPos);
        }
        const state = this.stream$.getValue();
        state.orderData.positions = state.orderData.positions?.map((el) =>
          el?.id === updatedPos?.id ? updatedPos : el,
        );

        state.selectedPos = updatedPos;
        this.editedCells.add(`${updatedPos?.id}_${action}`);
        return { ...state };
      }),
    );
  }
  private vatCell(): Observable<IOrderDetailsState> {
    return this.actionListener('vatCodeCell').pipe(
      mergeMap((state) => {
        const { selectedPos, action, customerInfo } = state;
        const selectedPosOldValues = state.orderData.positions?.find(
          (pos) => pos.id === selectedPos?.id,
        );
        this.preUpdateCell(selectedPos);
        const isNotEditableDiscount =
          customerInfo?.discountKindId === C_Discount_Kind.DK5_QUANTITY_DISCOUNT ||
          customerInfo?.discountKindId === C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS;
        const shouldFetch = Boolean(
          selectedPos?.articleNo &&
            !(
              this.editedCells.has(`${selectedPos?.id}_priceCell`) &&
              (isNotEditableDiscount || this.editedCells.has(`${selectedPos?.id}_discountCell`))
            ),
        );
        return zip(of(selectedPos), customerListState.sub.sharedValues()).pipe(
          tap(([editablePos, { customer, date }]) => {
            if (shouldFetch) {
              orderService.pub.productPriceAndDiscountByVat({
                orderAndArticleProps: {
                  articleId: editablePos?.articleId!,
                  customerId: customer?.customerId!,
                  orderDate: date,
                  quantity: Number(editablePos?.quantity!),
                  oldVatCode: selectedPosOldValues?.vatCode!,
                  newVatCode: editablePos?.vatCode!,
                },
              });
              this.cellsLoading$.next([...this.cellsLoading$.value, `${editablePos?.id}_vatCode`]);
            }
          }),
          switchMap(([editablePos]) => {
            if (shouldFetch) {
              return orderService.sub.productPriceAndDiscountByVat().pipe(
                responseHandler<ProductPriceAndDiscountByVatRes | 'serverError'>({
                  errorReturnType: 'serverError',
                }),
                map((values) => {
                  const { oldValues, newValues } =
                    (values as ProductPriceAndDiscountByVatRes) || {};
                  const shouldUpdatePrice =
                    (oldValues?.price ?? 0) === (selectedPosOldValues?.price ?? 0);
                  const shouldUpdateDiscount =
                    (oldValues?.discount ?? 0) === (selectedPosOldValues?.discount ?? 0);
                  this.cellsLoading$.next(
                    this.cellsLoading$.value.filter(
                      (item) => item !== `${editablePos?.id}_vatCode`,
                    ),
                  );
                  if (!selectedPosOldValues) return;
                  const updatedPos = {
                    ...selectedPosOldValues,
                    vatCode: editablePos?.vatCode as C_Vat_Code,
                  };
                  if (!this.editedCells.has(`${updatedPos?.id}_priceCell`)) {
                    if (values === 'serverError' && editablePos?.id) {
                      updatedPos.price = 0;
                      updatedPos.total = 0;
                      this.calcPriceError$.next([...this.calcPriceError$.value, editablePos?.id]);
                      return updatedPos;
                    } else if (typeof values === 'object' && editablePos?.id && shouldUpdatePrice) {
                      updatedPos.price = newValues.price;
                      updatedPos.total = this.calculateTotalDependsOnSaleUnit(updatedPos);
                      this.calcPriceError$.next(
                        this.calcPriceError$.value.filter((item) => item !== editablePos?.id),
                      );
                    }
                  }
                  if (
                    !this.editedCells.has(`${updatedPos?.id}_discountCell`) &&
                    typeof values === 'object' &&
                    shouldUpdateDiscount
                  ) {
                    updatedPos.discount = newValues.discount;
                  }
                  return updatedPos;
                }),
              );
            }
            return of(editablePos);
          }),
          filter((updatedPos) => !!updatedPos), // if position is deleted
          map((updatedPos) => {
            const state = this.stream$.getValue();
            state.orderData.positions = state.orderData.positions?.map((el) =>
              el?.id === updatedPos?.id ? updatedPos : el,
            );
            this.editedCells.add(`${updatedPos?.id}_${action}`);
            return { ...state };
          }),
        );
      }),
    );
  }
  /* ORDER CELL METHODS end */
  private updateState(): Observable<IOrderDetailsState> {
    return this.actionListener([
      'updateOrderData',
      'selectOrder',
      'updateState',
      'resetOrderDataChanges',
    ]);
  }
  /* MASTER DATA METHODS end */

  /* UTILITY start */
  async runSaveDraftOrder(params?: {
    customerId: string;
    draftOrderId?: string | null;
    date: CreateWa_DraftOrderMutationVariables['draftOrderProps']['date'];
  }): Promise<void> {
    let { customerId, draftOrderId, date } = params || {};
    if (!params) {
      const { customer, date: listFilterDate } =
        await createOrderService.globHelpers.streamToPromise(
          customerListState.sub.sharedValues(),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
        );
      customerId = customer?.id;
      draftOrderId = customer?.draftOrderId || undefined;
      date = listFilterDate;
    }
    const { dirty } = this.stream$.getValue();
    if (dirty && customerId) {
      const {
        positions = [],
        specialAddress,
        referenceOrderNo,
        deliveryTime,
        linkText,
        note,
        note2,
      } = this.stream$.getValue().orderData;
      const formattedPositions = this.formatPositionsToMutationArgument(positions);
      createOrderService.pub.createDraftOrder({
        draftOrderProps: {
          customerId,
          date,
          ...(draftOrderId && { draftOrderId }),
          positions: formattedPositions,
          specialAddress,
          referenceOrderNo,
          deliveryTime: this.formateDeliveryTime(deliveryTime),
          linkText,
          note,
          note2,
        },
      });
      notificationCenter.notify(orderDetailsState.sub.draftOrderSaved(), {
        type: undefined,
        handler: (stream) => {
          stream.pipe(take(1)).subscribe(() => {
            console.log('Draft order saved');
          });
        },
      });
    }
  }
  private defaultRound(v: number): number {
    const precision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
    const multiplier = Math.pow(10, precision);
    return Math.round(v * multiplier) / multiplier;
  }
  private calculateTotalDependsOnSaleUnit({
    price,
    quantity,
    saleUnit,
  }: {
    price?: number | null;
    quantity?: number | null;
    saleUnit?: C_Sale_Unit | null;
  }): number {
    price = price ? price : 0;
    quantity = quantity ? quantity : 0;
    const kiloAmountRepresentationId =
      companyConfigService.getCachedConfigs()?.kiloAmountRepresentationId;
    if (saleUnit === C_Sale_Unit.SU2_100G) {
      return this.defaultRound((quantity / 100) * price);
    }
    if (
      saleUnit === C_Sale_Unit.SU3_1000G &&
      kiloAmountRepresentationId === C_Kg_Representation.KGR2_1000_IS_1
    ) {
      return this.defaultRound((quantity / 1000) * price);
    }
    return this.defaultRound(quantity * price);
  }

  private getDeliveryCost(args: {
    state: IOrderDetailsState;
    force?: true;
  }): Observable<IOrderDetailsState> {
    const updatedState = structuredClone(args.state);
    const {
      customerInfo: { transportSector },
    } = updatedState;
    const isDeliveryPosition =
      args.force || !!updatedState.orderData.positions?.some((val) => val?.virtualPositionId);
    if (isDeliveryPosition) {
      const deliveryPosition = {
        virtualPositionId: C_Virtual_Position.VP2_DELIVERY_COST,
        quantity: 1,
        vatCode: C_Vat_Code.VT5_ZERO,
        price: 0,
        total: 0,
        weight: 0,
        id: Date.now(),
      } as IPosition;
      if (transportSector?.isPriceRateFixed) {
        const fixedPriceDeliveryPosition = {
          ...deliveryPosition,
          price: transportSector.priceRateFixed,
          total: transportSector.priceRateFixed,
        };
        const fixedPriceDeliveryPositionInd = updatedState.orderData.positions?.findIndex(
          (el) => el?.virtualPositionId,
        );
        if (
          typeof fixedPriceDeliveryPositionInd === 'number' &&
          fixedPriceDeliveryPositionInd !== -1
        ) {
          updatedState.orderData.positions?.splice(
            fixedPriceDeliveryPositionInd,
            1,
            fixedPriceDeliveryPosition,
          );
        } else updatedState.orderData.positions?.push(fixedPriceDeliveryPosition);
        return of(updatedState);
      }
      const totalWeight: number = getTotalWeight(updatedState.orderData.positions);
      if (!totalWeight || !updatedState.orderData.positions?.some((val) => val?.articleId)) {
        const deliveryPositionInd = updatedState.orderData.positions?.findIndex(
          (el) => el?.virtualPositionId,
        );
        if (typeof deliveryPositionInd === 'number' && deliveryPositionInd !== -1) {
          updatedState.orderData.positions?.splice(deliveryPositionInd, 1, deliveryPosition);
        } else updatedState.orderData.positions?.push(deliveryPosition);
        this.deliveryCostLoading$.next(false);
        return of(updatedState);
      }
      return customerListState.sub.sharedValues().pipe(
        tap(({ customer }) => {
          this.deliveryCostLoading$.next(true);
          orderService.pub.orderDeliveryCost({
            customerId: customer!.customerId,
            totalWeight,
          });
        }),
        mergeMap(() => {
          return orderService.sub.orderDeliveryCost().pipe(
            responseHandler<OrderDeliveryCostRes | 'serverError'>({
              errorReturnType: 'serverError',
            }),
            tap((value) => {
              if (value === 'serverError') this.deliveryCostLoading$.next(false);
            }),
            filter((value) => value !== 'serverError'),
            map((value) => {
              const cost = value as OrderDeliveryCostRes;
              const updatedDeliveryPosition = {
                ...deliveryPosition,
                price: cost,
                total: cost,
                weight: totalWeight,
              };
              const deliveryPositionInd = updatedState.orderData.positions?.findIndex(
                (el) => el?.virtualPositionId,
              );
              if (typeof deliveryPositionInd === 'number' && deliveryPositionInd !== -1) {
                updatedState.orderData.positions?.splice(
                  deliveryPositionInd,
                  1,
                  updatedDeliveryPosition,
                );
              } else updatedState.orderData.positions?.push(updatedDeliveryPosition);
              this.deliveryCostLoading$.next(false);
              return updatedState;
            }),
          );
        }),
      );
    }
    return of(updatedState);
  }
  formatPositionsToMutationArgument(
    positions: NonNullable<IOrderDetailsState['orderData']['positions']>,
  ): Wa_OrderPositionInputs[] {
    return positions.reduce<Wa_OrderPositionInputs[]>((outArr, item) => {
      if (item) {
        const {
          articleId,
          quantity,
          price,
          total,
          discount,
          vatCode,
          virtualPositionId,
          indTextDeliveryNote,
          indTextProduction,
          splittingPartsPreset,
        } = item;
        outArr.push({
          quantity: quantity || 0,
          articleId,
          price,
          total,
          discount,
          vatCode,
          virtualPositionId,
          indTextDeliveryNote,
          indTextProduction,
          splittingPartsPreset,
        });
      }
      return outArr;
    }, []);
  }
  private preUpdateCell(position: IOrderDetailsState['selectedPos']) {
    const state = this.stream$.getValue();
    if (position && state.orderData.positions) {
      state.orderData.positions = state.orderData.positions?.map((el) =>
        el.id === position.id ? position : el,
      );
      this.stream$.next({ ...state, action: 'updateState' });
    }
  }
  private formateDeliveryTime(deliveryTime?: string | Date | null) {
    return deliveryTime
      ? typeof deliveryTime === 'string'
        ? deliveryTime
        : `${deliveryTime.getHours()}:${deliveryTime.getMinutes()}`
      : deliveryTime;
  }
  /* UTILITY end */
}

interface ISharedValues {
  orderData: IOrderDetailsState['orderData'];
}
