import { useFormContext } from 'react-hook-form';
import { take } from 'rxjs';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TSideControlsFormValues } from '../openDebts.page';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service';
import { IOpenDebtsLoader } from '../../loaders/openDebts.loader';
import { SelectedInvoiceOptions } from './sideControls.component';
import { PreviewPopup } from '../../../../shared/components/previewPopup/preview.popup';
import { SendEmailPopup } from './openDebtsGrid/popups/sendEmail/sendEmail.popup';
import { MultiEmailPopup } from './openDebtsGrid/popups/sendMultipleEmails/sendMultipleEmails.popup';
import {
  commonRequestsService,
  MergePDFsRes,
} from '../../../../shared/services/commonRequests/commonRequests.service';
import {
  C_History_Pdf_Doctype,
  C_Report,
  Create_R10_CustomersDebtNotifierMutationVariables,
  R10CustomersDebtNotifierInput,
  Wa_MergePdfInput,
  Wa_MergePdFsMutationVariables,
  C_S3_Bucket,
} from '../../../../graphql/generatedModel';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import {
  reportsService,
  IGetMultipleR10NotifierFilesRes,
  IGetMultipleR64NotifierFilesParams,
  IGetMultipleR64NotifierFilesRes,
  IGetR64NotifierFilesRes,
} from '../../../../shared/services/reports/reports.service';

export const useHandleReportAction = ({
  openDebtsGridData,
  selectedRow,
  notifierPreviewPopup,
  emailPopup,
  multiEmailPopup,
}: IUseHandleReportActionParams) => {
  const { getValues } = useFormContext<TSideControlsFormValues>();
  const { t } = useTranslation();
  const noSelectWarning = (message: string): void => {
    snackbarService.pub.show({
      content: message,
      type: 'info',
    });
  };

  const handleAllCustomerAction = (actionType: 'preview' | 'email'): void => {
    const [invoicesDates, paymentsUpToDate, printFormId, reminderTextId] = getValues([
      'invoicesDates',
      'paymentsUpToDate',
      'printFormId',
      'reminderTextId',
    ]);
    const customerInvoices = openDebtsGridData.reduce<
      Record<string, { customerId: string; invoiceIds: Array<string> }>
    >((outObj, { customerId, invoiceId }) => {
      if (!outObj[customerId]) {
        outObj[customerId] = {
          customerId,
          invoiceIds: [invoiceId],
        };
      } else {
        outObj[customerId].invoiceIds.push(invoiceId);
      }
      return outObj;
    }, {});
    const getR10Params = ({
      customerId,
      invoiceIds,
    }: {
      customerId: string;
      invoiceIds: Array<string>;
    }): Create_R10_CustomersDebtNotifierMutationVariables => ({
      params: {
        customerId,
        invoicesList: invoiceIds,
        periodStart: format(invoicesDates.fromDate, 'yyyy-MM-dd'),
        periodEnd: format(invoicesDates.toDate, 'yyyy-MM-dd'),
        paymentsTill: format(paymentsUpToDate, 'yyyy-MM-dd'),
        reminderTextId,
      },
    });
    const getMultipleR10Params: Array<Create_R10_CustomersDebtNotifierMutationVariables> =
      Object.values(customerInvoices).map(getR10Params);
    const getMultipleR64Params: IGetMultipleR64NotifierFilesParams = Object.values(
      customerInvoices,
    ).reduce<IGetMultipleR64NotifierFilesParams>((outObj, { customerId, invoiceIds }) => {
      const r10NotifierParams = getR10Params({ customerId, invoiceIds });
      outObj[customerId] = {
        invoiceIds,
        r10NotifierParams,
      };
      return outObj;
    }, {});

    if (actionType === 'preview') {
      if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
        notifierPreviewPopup.stream.emit('open', {
          pub: () => reportsService.pub.createMultipleDebtReminderReports(getMultipleR10Params),
          // Since sub should be called before pub, pub for this sub is called below to run it after reportsService.sub.createMultipleDebtReminderReports()
          sub: () =>
            commonRequestsService.sub.mergePDFs().pipe(
              responseHandler<MergePDFsRes>({
                errorReturnType: {
                  url: '',
                  s3Key: '',
                },
              }),
              take(1),
            ),
        });
        reportsService.sub
          .createMultipleDebtReminderReports()
          .pipe(
            take(1),
            responseHandler<IGetMultipleR10NotifierFilesRes>({
              customErrorHandler: () => {
                notifierPreviewPopup.stream.emit('close');
                return undefined;
              },
              errorReturnType: [],
            }),
          )
          .subscribe((res: IGetMultipleR10NotifierFilesRes | undefined) => {
            if (res) {
              const filesData: Array<Wa_MergePdfInput> = res?.map((item) => ({
                s3Key: item.s3Key as string,
                s3Bucket: C_S3_Bucket.S3B_MAIN_TEMP,
              }));
              commonRequestsService.pub.mergePDFs({ mergeItems: filesData });
            }
          });
      } else if (
        printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
      ) {
        notifierPreviewPopup.stream.emit('open', {
          pub: () =>
            reportsService.pub.createMultipleDebtReminderWithInvoiceReports(getMultipleR64Params),
          // Since sub should be called before pub, pub for this sub is called below to run it after reportsService.sub.createMultipleDebtReminderWithInvoiceReports()
          sub: () =>
            commonRequestsService.sub.mergePDFs().pipe(
              responseHandler<MergePDFsRes>({
                errorReturnType: {
                  url: '',
                  s3Key: '',
                },
              }),
              take(1),
            ),
        });
        reportsService.sub
          .createMultipleDebtReminderWithInvoiceReports()
          .pipe(
            take(1),
            responseHandler<IGetMultipleR64NotifierFilesRes>({
              customErrorHandler: () => {
                notifierPreviewPopup.stream.emit('close');
                return undefined;
              },
              errorReturnType: [],
            }),
          )
          .subscribe((res: IGetMultipleR64NotifierFilesRes | undefined) => {
            if (res) {
              const filesData = res.reduce<Array<Wa_MergePdfInput>>((outArr, item) => {
                outArr.push({
                  s3Key: item.notifierPdfS3Key,
                  s3Bucket: C_S3_Bucket.S3B_MAIN_TEMP,
                });
                item.invoicesPdfs.forEach((invoice) => {
                  outArr.push({
                    s3Key: invoice.s3Key ?? '',
                    s3Bucket: invoice.bucket,
                  });
                });
                return outArr;
              }, []);
              commonRequestsService.pub.mergePDFs({ mergeItems: filesData });
            }
          });
      }
    } else if (actionType === 'email') {
      if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
        multiEmailPopup.stream.emit('open', {
          params: {
            customerInvoices,
            getMultiNotifierReportFilesPub: () => {
              reportsService.pub.createMultipleDebtReminderReports(getMultipleR10Params);
            },
            getMultiNotifierReportFilesSub: () =>
              reportsService.sub.createMultipleDebtReminderReports(),
          },
        });
      } else if (
        printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
      ) {
        multiEmailPopup.stream.emit('open', {
          params: {
            customerInvoices,
            getMultiNotifierReportFilesPub: () => {
              reportsService.pub.createMultipleDebtReminderWithInvoiceReports(getMultipleR64Params);
            },
            getMultiNotifierReportFilesSub: () =>
              reportsService.sub.createMultipleDebtReminderWithInvoiceReports(),
          },
        });
      }
    }
  };

  const handleActionReport = (actionType: 'preview' | 'email') => {
    const [printFormId, previewId, sendingId, reminderTextId, invoicesDates, paymentsUpToDate] =
      getValues([
        'printFormId',
        'previewId',
        'sendingId',
        'reminderTextId',
        'invoicesDates',
        'paymentsUpToDate',
      ]);
    const selectOption = actionType === 'preview' ? previewId : sendingId;
    if (selectedRow === null && selectOption !== SelectedInvoiceOptions.allCustomers) {
      noSelectWarning(t('debitor.noRecordSelected'));
      return;
    }
    let invoicesList: Array<string> = [];
    switch (selectOption) {
      case SelectedInvoiceOptions.onlySelectedInvoice: {
        invoicesList.push(selectedRow!.invoiceId);
        break;
      }
      case SelectedInvoiceOptions.allInvoicesOfSelectedCustomer: {
        const customerInvoices: Array<string> = openDebtsGridData.reduce<Array<string>>(
          (outArr, item) => {
            if (item.customerId === selectedRow?.customerId) {
              outArr.push(item.invoiceId);
            }
            return outArr;
          },
          [],
        );
        invoicesList = invoicesList.concat(customerInvoices);
        break;
      }
      case SelectedInvoiceOptions.allCustomers: {
        handleAllCustomerAction(actionType);
        return;
      }
    }
    const customerId = selectedRow!.customerId;
    const getReportParams: R10CustomersDebtNotifierInput = {
      customerId,
      invoicesList,
      periodStart: format(invoicesDates.fromDate, 'yyyy-MM-dd'),
      periodEnd: format(invoicesDates.toDate, 'yyyy-MM-dd'),
      paymentsTill: format(paymentsUpToDate, 'yyyy-MM-dd'),
      reminderTextId,
    };
    const r64ReportPubSub = {
      pub: () =>
        reportsService.pub.createDebtReminderWithInvoiceReport({
          r10NotifierParams: { params: getReportParams },
          listPdfHistoryReportParams: {
            params: {
              docsType: C_History_Pdf_Doctype.PDF_INVOICE,
              documentsIds: invoicesList,
            },
          },
        }),
      // Since sub should be called before pub, pub for this sub is called below to run it after reportsService.sub.createDebtReminderWithInvoiceReport()
      sub: () =>
        commonRequestsService.sub.mergePDFs().pipe(
          responseHandler<MergePDFsRes>({
            errorReturnType: {
              url: '',
              s3Key: '',
            },
          }),
        ),
    };
    const pubForMergeR64Files = () => {
      reportsService.sub
        .createDebtReminderWithInvoiceReport()
        .pipe(
          take(1),
          responseHandler<IGetR64NotifierFilesRes>({
            customErrorHandler: () => {
              notifierPreviewPopup.stream.emit('close');
              emailPopup.stream.emit('close');
              return undefined;
            },
            errorReturnType: {
              notifierPdfUrl: '',
              notifierPdfS3Key: '',
              invoicesPdfs: [],
            },
          }),
        )
        .subscribe((res: IGetR64NotifierFilesRes | undefined) => {
          const { notifierPdfS3Key, invoicesPdfs } = res || {};
          if (notifierPdfS3Key && invoicesPdfs && invoicesPdfs.length) {
            const param: Wa_MergePdFsMutationVariables = {
              mergeItems: [
                {
                  s3Key: notifierPdfS3Key,
                  s3Bucket: C_S3_Bucket.S3B_MAIN_TEMP,
                },
              ],
            };
            invoicesPdfs.forEach((invoice) => {
              (param.mergeItems as Wa_MergePdfInput[]).push({
                s3Key: invoice.s3Key ?? '',
                s3Bucket: invoice.bucket,
              });
            });
            commonRequestsService.pub.mergePDFs(param);
          }
        });
    };

    switch (actionType) {
      case 'preview': {
        if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
          notifierPreviewPopup.stream.emit('open', {
            pub: () => reportsService.pub.createDebtReminderReport({ params: getReportParams }),
            sub: () => reportsService.sub.createDebtReminderReport(),
          });
        } else if (
          printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
        ) {
          notifierPreviewPopup.stream.emit('open', r64ReportPubSub);
          pubForMergeR64Files();
        }
        break;
      }
      case 'email': {
        if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
          emailPopup.stream.emit('open', {
            params: {
              customerId: getReportParams.customerId,
              invoicesList,
              getNotifierReportFilesPub: () => {
                reportsService.pub.createDebtReminderReport({ params: getReportParams });
              },
              getNotifierReportFilesSub: () => reportsService.sub.createDebtReminderReport(),
            },
          });
        } else if (
          printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
        ) {
          emailPopup.stream.emit('open', {
            params: {
              customerId: getReportParams.customerId,
              invoicesList,
              getNotifierReportFilesPub: () =>
                reportsService.pub.createDebtReminderWithInvoiceReport({
                  r10NotifierParams: { params: getReportParams },
                  listPdfHistoryReportParams: {
                    params: {
                      docsType: C_History_Pdf_Doctype.PDF_INVOICE,
                      documentsIds: invoicesList,
                    },
                  },
                }),
              getNotifierReportFilesSub: () =>
                reportsService.sub.createDebtReminderWithInvoiceReport(),
            },
          });
        }
        break;
      }
    }
  };

  return { handleActionReport };
};

interface IUseHandleReportActionParams {
  openDebtsGridData: IOpenDebtsLoader['openDebtsGridData'];
  selectedRow: IOpenDebtsLoader['selectedRow'];
  notifierPreviewPopup: PreviewPopup;
  emailPopup: SendEmailPopup;
  multiEmailPopup: MultiEmailPopup;
}
