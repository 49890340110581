import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  ExportAbacusWa_PaymentsMutation,
  ExportAbacusWa_PaymentsMutationVariables,
  ListPaymentsQuery,
  ListPaymentsQueryVariables,
} from '../../../graphql/generatedModel.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { abacusExportPayments, listPayments } from './gql/payments.gql.ts';

type Actions = 'listPayments' | 'abacusExport' | undefined;

class PubImpl extends Pub<Actions> {
  listPayments(params: ListPaymentsQueryVariables) {
    this.emit('listPayments', params);
  }

  abacusExport(params: ExportAbacusWa_PaymentsMutationVariables) {
    this.emit('abacusExport', params);
  }
}
class SubImpl extends Sub<Actions> {
  listPayments(): Observable<ListPaymentsRes> {
    return this.actionListener('listPayments').pipe(
      switchMap(({ params }) => {
        return gqlClient(listPayments, params) as Observable<ListPaymentsQuery>;
      }),
      map((data) => {
        return data.wawiAssist?.listWA_Payments as ListPaymentsRes;
      }),
    );
  }

  abacusExport(): Observable<AbacusExportRes> {
    return this.actionListener('abacusExport').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          abacusExportPayments,
          params,
        ) as Observable<ExportAbacusWa_PaymentsMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.exportAbacusWA_Payments as AbacusExportRes;
      }),
    );
  }
}

class PaymentsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const paymentsService = new PaymentsService();

export type ListPaymentsRes = NonNullable<
  NonNullable<ListPaymentsQuery['wawiAssist']>['listWA_Payments']
>;

export type AbacusExportRes = NonNullable<
  NonNullable<ExportAbacusWa_PaymentsMutation['wawiAssist']>['exportAbacusWA_Payments']
>;
