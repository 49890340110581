import { Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { ICheckboxData, ICheckboxList } from '../checkboxList.component';
import { Checkbox } from '../../fields/checkbox.component';
import { EmptyBox } from './emptyBox.component';

export const List: FC<IList> = (props) => {
  const { withDivider, listData, setListData, onChange, data, emptyListIndication, minHeight } =
    props;

  const changeCheckboxes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copyListData = [...listData];
    const currentIndex = copyListData.findIndex((item) => item.id === e.target.id);
    const el = { ...data[currentIndex] };
    el.checked = e.target.checked;
    copyListData.splice(currentIndex, 1, el);
    setListData(copyListData);
    onChange?.(copyListData);
  };

  return (
    <Stack {...(withDivider && { py: 1, px: 3 })} height='100%' direction='column'>
      {listData.length ? (
        listData.map(({ checked, ...props }) => (
          <Checkbox key={props.id} checked={checked} onChange={changeCheckboxes} {...props} />
        ))
      ) : (
        <EmptyBox {...{ emptyListIndication, minHeight }} />
      )}
    </Stack>
  );
};

interface IList
  extends Pick<
    ICheckboxList,
    'withDivider' | 'onChange' | 'data' | 'emptyListIndication' | 'minHeight'
  > {
  listData: ICheckboxData[];
  setListData: Dispatch<SetStateAction<ICheckboxData[]>>;
}
