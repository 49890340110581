import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { DropdownButton, CommandMenuItem, useCommands, useActive } from '@remirror/react';
import FormatSizeIcon from '@mui/icons-material/FormatSize';

export const FontSizePicker: FC<IFontSizePickerProps> = ({ fontSizes, defaultFontSize }) => {
  const { setFontSize } = useCommands();
  const { fontSize } = useActive();
  const activeFs: string | undefined = fontSizes.find((size) => fontSize({ size: `${size}pt` }));
  const { t } = useTranslation();
  const buttonNode = useMemo(
    () =>
      activeFs || defaultFontSize ? (
        <SDropdownValue>{activeFs || defaultFontSize}</SDropdownValue>
      ) : (
        <SNonSetValue />
      ),
    [activeFs],
  );

  return (
    <SBox>
      <DropdownButton aria-label={t('common.font_size')} icon={buttonNode}>
        {fontSizes.map((size) => (
          <CommandMenuItem
            key={size}
            commandName='setFontSize'
            onSelect={() => setFontSize(`${size}pt`)}
            enabled={setFontSize.enabled(size)}
            active={size === activeFs}
            label={size}
            icon={null}
            displayDescription={false}
          />
        ))}
      </DropdownButton>
    </SBox>
  );
};

const SBox = styled(Box)(() => ({
  backgroundColor: '#fff',
  display: 'inline-block',
  '& > button': {
    height: '28px',
  },
}));
const SDropdownValue = styled('span')({ fontSize: '14px' });
const SNonSetValue = styled(FormatSizeIcon)({ fontSize: '16px' });

interface IFontSizePickerProps {
  fontSizes: string[];
  defaultFontSize?: string;
}
