import { FC, useCallback } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { orderDetailsState as orderDetailsStateCreate } from '../../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../../edit/states/orderDetails/orderDetails.state.ts';
import { TableNumericField } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { clearRowFromVirtualKeys } from '../splitting/additionalFunctions.tsx';
import { C_Order_Type } from '../../../../../../graphql/generatedModel.ts';
import { blockArrowKeysInMiddle } from '../../../../../../shared/components/table/functions/sharedFunc.ts';

export const QuantityEditCell: FC<TQuantityEditCell> = ({ api, type, orderType, ...params }) => {
  const { setEditCellValue } = api;
  const { value, field, id, formattedValue, row } = params;

  const updateCell = useCallback(
    (v: number | null) => {
      const condition = typeof v === 'number' && orderType === C_Order_Type.OT4_RETURN && v > 0;
      const processedValue = condition ? v * -1 : v;
      setEditCellValue({ id, field, value: processedValue });
      if (Number(formattedValue) !== processedValue) {
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          { ...clearedRow, quantity: processedValue },
          'quantityCell',
        );
      }
    },
    [params, orderType],
  );

  return (
    <TableNumericField
      inputProps={{ autoComplete: 'off' }}
      onBlur={updateCell}
      min={-10000000}
      autoFocus
      precision={3}
      noTrailingZeros
      value={value}
      onKeyDown={blockArrowKeysInMiddle}
      keyChange={false}
    />
  );
};

type TQuantityEditCell = GridRenderEditCellParams & {
  type: 'create' | 'edit';
  orderType: C_Order_Type;
};
