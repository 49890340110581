import {
  FC,
  useCallback,
  useState,
  FocusEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { TableTextField } from '../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { IArticleImportData } from '../../../import.tab.tsx';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const ArticleNrEditCell: FC<IArticleNrEditCell> = ({
  params,
  data,
  setData,
  existingNumbers,
}) => {
  const { row, api, id, field, value: initVal } = params;

  const [value, setValue] = useState<string>(initVal !== null ? initVal : '');
  const { setEditCellValue, stopCellEditMode } = api;

  const updateCell = useCallback(
    (value: string) => {
      setEditCellValue({ id, field, value });
      let newData = structuredClone(data);
      const index = newData.findIndex((item) => item.id == id);
      if (index > -1) {
        newData[index].articleNo = value;
        newData = newData.map((item) => {
          item.isDuplicate = newData.some(
            (value) => value.articleNo === item.articleNo && value.id != item.id,
          );
          item.isRecordWhichOverwrites = existingNumbers.some((value) => value === item.articleNo);
          return item;
        });
      }
      setData(newData);
      stopCellEditMode({ id, field });
    },
    [row],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    updateCell(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && isEnterKey(e)) {
      updateCell(e.target.value);
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <TableTextField
      inputProps={{ maxLength: 16 }}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      autoFocus
      value={value}
    />
  );
};

interface IArticleNrEditCell {
  params: GridRenderEditCellParams;
  data: IArticleImportData[];
  setData: Dispatch<SetStateAction<IArticleImportData[]>>;
  existingNumbers: string[];
}
