import { Pub, Service, Sub } from '../../../../shared/services/service.abstract.ts';
import {
  Observable,
  delay,
  filter,
  finalize,
  map,
  of,
  repeat,
  switchMap,
  take,
  takeWhile,
  zip,
} from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import {
  listTaxesPeriodsAndRates,
  listValidationDataForArticlesImport,
  listValidationDataForCustomersImport,
  executeArticlesImport,
  executeCustomersImport,
  initProcessingFileWithImportData,
  getImportDataFromFile,
} from './gql/configuration.gql.ts';
import {
  ListWa_TaxesPeriodsAndRatesQuery as ListTaxesPeriodsAndRatesQuery,
  ListWa_ArticleNumbersQuery as ListArticleNumbersQuery,
  ListWa_CustomerNumbersQuery as ListCustomerNumbersQuery,
  GetWa_ImportDataFromFileQuery as GetImportDataFromFileQuery,
  InitWa_ProcessingFileWithImportDataMutation as InitProcessingFileWithImportDataMutation,
  InitWa_ProcessingFileWithImportDataMutationVariables as InitProcessingFileWithImportDataMutationVariables,
  ExecuteWa_ArticlesImportMutation as ExecuteArticlesImportMutation,
  ExecuteWa_ArticlesImportMutationVariables as ExecuteArticlesImportMutationVariables,
  ExecuteWa_CustomersImportMutation as ExecuteCustomersImportMutation,
  ExecuteWa_CustomersImportMutationVariables as ExecuteCustomersImportMutationVariables,
  C_Xls_File_Processing_Status,
} from '../../../../graphql/generatedModel.ts';
import { IValidationData } from '../components/tabs/import/components/importDetailsInfo.component.tsx';
import {
  configsData,
  DictCustomerGroupsRes,
  DictPostSectorsRes,
  DictQuantityDiscountsRes,
  DictDeliveryNoteGroupsRes,
  DictPriceCategoriesRes,
  DictDiscountGroupsRes,
  DictMarketingGroupsRes,
  DictProductionGroupsRes,
  DictShippingPointsRes,
  DictTourplansRes,
  DictBanksRes,
  DictStorageLocationsRes,
} from '../../../../shared/services/configsData/configsData.service.ts';
import { storageHelper } from '../../../../shared/helpers/storage';

type Actions =
  | 'listTaxesPeriodsAndRates'
  | 'listImportDataFromFile'
  | 'listValidationDataForImport'
  | 'executeArticlesImport'
  | 'executeCustomersImport'
  | 'importTabOptions'
  | 'getImportDataFromFile'
  | undefined;
class PubImpl extends Pub<Actions> {
  listTaxesPeriodsAndRates() {
    this.emit('listTaxesPeriodsAndRates');
  }
  listValidationDataForImport() {
    this.emit('listValidationDataForImport');
  }
  getImportDataFromFile(params: InitProcessingFileWithImportDataMutationVariables) {
    this.emit('getImportDataFromFile', params);
  }
  executeArticlesImport(params: ExecuteArticlesImportMutationVariables) {
    this.emit('executeArticlesImport', params);
  }
  executeCustomersImport(params: ExecuteCustomersImportMutationVariables) {
    this.emit('executeCustomersImport', params);
  }
  importTabOptions(): void {
    this.emit('importTabOptions');
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  listTaxesPeriodsAndRates(): Observable<ListTaxesPeriodsAndRatesQueryRes> {
    return this.actionListener('listTaxesPeriodsAndRates').pipe(
      switchMap(() => {
        return gqlClient(listTaxesPeriodsAndRates) as Observable<ListTaxesPeriodsAndRatesQuery>;
      }),
      map((data: ListTaxesPeriodsAndRatesQuery) => {
        return data.wawiAssist?.listWA_TaxesPeriodsAndRates as ListTaxesPeriodsAndRatesQueryRes;
      }),
    );
  }

  listValidationDataForImport(): Observable<IValidationData> {
    return this.actionListener('listValidationDataForImport').pipe(
      switchMap(() => {
        return zip(
          gqlClient(listValidationDataForArticlesImport),
          gqlClient(listValidationDataForCustomersImport),
        );
      }),
      map((data: [ListArticleNumbersQuery, ListCustomerNumbersQuery]) => {
        return {
          existingArticleNumbers:
            data[0]?.wawiAssist?.dictWA_Articles.map((item) => item.articleNo) || [],
          existingCustomerNumbers:
            data[1]?.wawiAssist?.dictWA_Customers.map((item) => item.customerNo) || [],
        } as IValidationData;
      }),
    );
  }

  getImportDataFromFile(): Observable<ExtendedListImportDataFromFileQueryRes> {
    return this.actionListener('getImportDataFromFile').pipe(
      switchMap(({ params }) => {
        return zip(gqlClient(initProcessingFileWithImportData, params), of(params)) as Observable<
          [
            InitProcessingFileWithImportDataMutation,
            InitProcessingFileWithImportDataMutationVariables,
          ]
        >;
      }),
      delay(1300),
      switchMap(([res, params]) => {
        const maxPollingAttempts = 150;
        const jobId = res?.wawiAssist?.initWA_ProcessingFileWithImportData;
        return (
          gqlClient(getImportDataFromFile, { jobId }) as Observable<GetImportDataFromFileQuery>
        ).pipe(
          repeat({ delay: 2000 }),
          map((v) => {
            return {
              res: v.wawiAssist?.getWA_ImportDataFromFile as GetImportDataFromFileQueryRes,
              params,
            };
          }),
          takeWhile((v) => {
            const isInProgress = v?.res?.status === C_Xls_File_Processing_Status.SCS_LOADING;
            return isInProgress;
          }, true),
          filter((v, ind) => {
            const isInProgress = v?.res?.status === C_Xls_File_Processing_Status.SCS_LOADING;
            const maxAttemptsReached = ind + 1 > maxPollingAttempts;
            return !isInProgress || maxAttemptsReached;
          }),
          take(1),
        );
      }),
      map(({ res }) => {
        const data = res?.data as ExtendedListImportDataFromFileQueryRes;
        if (data.articleImportData.length) {
          data.articleImportData = data.articleImportData.map((el, i) => ({
            ...el,
            id: Date.now() + i,
          }));
        }
        if (data.customerImportData.length) {
          data.customerImportData = data.customerImportData.map((el, i) => ({
            ...el,
            id: Date.now() + i,
          }));
        }
        return data;
      }),
      finalize(() => configurationService.pub.clearStream()),
    );
  }

  executeArticlesImport(): Observable<ExecuteArticlesImportMutationRes> {
    return this.actionListener('executeArticlesImport').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          executeArticlesImport,
          params,
        ) as Observable<ExecuteArticlesImportMutation>;
      }),
      map((data: ExecuteArticlesImportMutation) => {
        return data.wawiAssist?.executeWA_ArticlesImport as ExecuteArticlesImportMutationRes;
      }),
    );
  }

  executeCustomersImport(): Observable<ExecuteCustomersImportMutationRes> {
    return this.actionListener('executeCustomersImport').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          executeCustomersImport,
          params,
        ) as Observable<ExecuteCustomersImportMutation>;
      }),
      map((data: ExecuteCustomersImportMutation) => {
        return data.wawiAssist?.executeWA_CustomersImport as ExecuteCustomersImportMutationRes;
      }),
    );
  }

  importTabOptions(): Observable<ImportTabOptionsRes> {
    return this.actionListener('importTabOptions').pipe(
      switchMap(() => {
        const customerGroups = storageHelper.memory.getItem('configsData.dictCustomerGroups');
        const postSectors = storageHelper.memory.getItem('configsData.dictPostSectors');
        const quantityDiscounts = storageHelper.memory.getItem('configsData.dictQuantityDiscounts');
        const deliveryNoteGroups = storageHelper.memory.getItem(
          'configsData.dictDeliveryNoteGroups',
        );
        const priceCategories = storageHelper.memory.getItem('configsData.dictPriceCategories');
        const discountGroups = storageHelper.memory.getItem('configsData.dictDiscountGroups');
        const marketingGroups = storageHelper.memory.getItem('configsData.dictMarketingGroups');
        const productionGroups = storageHelper.memory.getItem('configsData.dictProductionGroups');
        const shippingPoints = storageHelper.memory.getItem('configsData.dictShippingPoints');
        const tourplans = storageHelper.memory.getItem('configsData.dictTourplans');
        const banks = storageHelper.memory.getItem('configsData.dictBanks');
        const storageLocations = storageHelper.memory.getItem('configsData.dictStorageLocations');

        if (
          Array.isArray(customerGroups) &&
          Array.isArray(postSectors) &&
          Array.isArray(quantityDiscounts) &&
          Array.isArray(deliveryNoteGroups) &&
          Array.isArray(priceCategories) &&
          Array.isArray(discountGroups) &&
          Array.isArray(marketingGroups) &&
          Array.isArray(productionGroups) &&
          Array.isArray(shippingPoints) &&
          Array.isArray(tourplans) &&
          Array.isArray(banks) &&
          Array.isArray(storageLocations)
        ) {
          return of({
            customerGroups,
            postSectors,
            quantityDiscounts,
            deliveryNoteGroups,
            priceCategories,
            discountGroups,
            marketingGroups,
            productionGroups,
            shippingPoints,
            tourplans,
            banks,
            storageLocations,
          });
        }
        configsData.pub.common([
          'dictCustomerGroups',
          'dictPostSectors',
          'dictQuantityDiscounts',
          'dictDeliveryNoteGroups',
          'dictPriceCategories',
          'dictDiscountGroups',
          'dictMarketingGroups',
          'dictProductionGroups',
          'dictShippingPoints',
          'dictTourplans',
          'dictBanks',
          'dictStorageLocations',
        ]);
        return zip(
          configsData.sub.dictCustomerGroups(),
          configsData.sub.dictPostSectors(),
          configsData.sub.dictQuantityDiscounts(),
          configsData.sub.dictDeliveryNoteGroups(),
          configsData.sub.dictPriceCategories(),
          configsData.sub.dictDiscountGroups(),
          configsData.sub.dictMarketingGroups(),
          configsData.sub.dictProductionGroups(),
          configsData.sub.dictShippingPoints(),
          configsData.sub.dictTourplans(),
          configsData.sub.dictBanks(),
          configsData.sub.dictStorageLocations(),
        );
      }),
      map((v) => {
        // v is from zip operator (fetched data)
        if (Array.isArray(v)) {
          return {
            customerGroups: v[0] || [],
            postSectors: v[1] || [],
            quantityDiscounts: v[2] || [],
            deliveryNoteGroups: v[3] || [],
            priceCategories: v[4] || [],
            discountGroups: v[5] || [],
            marketingGroups: v[6] || [],
            productionGroups: v[7] || [],
            shippingPoints: v[8] || [],
            tourplans: v[9] || [],
            banks: v[10] || [],
            storageLocations: v[11] || [],
          } as ImportTabOptionsRes;
        }
        // v is from of operator (cached data)
        return v;
      }),
    );
  }
}

class ConfigurationService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const configurationService = new ConfigurationService();

export type ListTaxesPeriodsAndRatesQueryRes = NonNullable<
  NonNullable<ListTaxesPeriodsAndRatesQuery['wawiAssist']>['listWA_TaxesPeriodsAndRates']
>;

export type ExecuteArticlesImportMutationRes = NonNullable<
  NonNullable<ExecuteArticlesImportMutation['wawiAssist']>['executeWA_ArticlesImport']
>;

export type ExecuteCustomersImportMutationRes = NonNullable<
  NonNullable<ExecuteCustomersImportMutation['wawiAssist']>['executeWA_CustomersImport']
>;

export type ListArticleImportDataFromFileQueryRes = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GetImportDataFromFileQuery['wawiAssist']>['getWA_ImportDataFromFile']
    >['data']
  >['articleImportData']
>;

export type ListCustomerImportDataFromFileQueryRes = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GetImportDataFromFileQuery['wawiAssist']>['getWA_ImportDataFromFile']
    >['data']
  >['customerImportData']
>;

export type ListImportDataFromFileQueryRes = NonNullable<
  NonNullable<
    NonNullable<GetImportDataFromFileQuery['wawiAssist']>['getWA_ImportDataFromFile']
  >['data']
>;

export type GetImportDataFromFileQueryRes = NonNullable<
  NonNullable<GetImportDataFromFileQuery['wawiAssist']>['getWA_ImportDataFromFile']
>;

export type InitProcessingFileWithImportDataMutationRes = NonNullable<
  NonNullable<
    InitProcessingFileWithImportDataMutation['wawiAssist']
  >['initWA_ProcessingFileWithImportData']
>;

export type ExtendedArticleImportDataFromFile = ListArticleImportDataFromFileQueryRes[number] & {
  id: number;
  isDuplicate: boolean;
  isRecordWhichOverwrites: boolean;
};

export type ExtendedCustomerImportDataFromFile = ListCustomerImportDataFromFileQueryRes[number] & {
  id: number;
  isDuplicate: boolean;
  isRecordWhichOverwrites: boolean;
};

export interface ImportTabOptionsRes {
  customerGroups: DictCustomerGroupsRes;
  postSectors: DictPostSectorsRes;
  quantityDiscounts: DictQuantityDiscountsRes;
  deliveryNoteGroups: DictDeliveryNoteGroupsRes;
  priceCategories: DictPriceCategoriesRes;
  discountGroups: DictDiscountGroupsRes;
  marketingGroups: DictMarketingGroupsRes;
  productionGroups: DictProductionGroupsRes;
  shippingPoints: DictShippingPointsRes;
  tourplans: DictTourplansRes;
  banks: DictBanksRes;
  storageLocations: DictStorageLocationsRes;
}

export interface ExtendedListImportDataFromFileQueryRes
  extends Omit<ListImportDataFromFileQueryRes, 'articleImportData' | 'customerImportData'> {
  articleImportData: ExtendedArticleImportDataFromFile[];
  customerImportData: ExtendedCustomerImportDataFromFile[];
}
