import { take } from 'rxjs';
import { FieldsRow, FormNumericField } from '../../../shared/components/form';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictPriceCategoriesRes,
} from '../../../shared/services/configsData/configsData.service';
import {
  ArticleGeneralTabDataRes,
  articleService,
  GeneralTabOptionsRes,
} from '../services/article.service';
import { ITabState } from '../states/tabState.model';
import { Column } from '../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper';
import { FieldErrors } from 'react-hook-form';
import { C_Sale_Unit, C_Vat_Code } from '../../../graphql/generatedModel';

const defaultArticleGeneralTabData: ArticleGeneralTabDataRes = {
  id: '',
  description: null,
  note: null,
  isNotifyOnOrderStage: false,
  articleNo: null,
  isActive: false,
  discountGroupId: null,
  productionGroupId: null,
  marketingGroupId: null,
  saleUnitId: C_Sale_Unit.SU1_PIECE,
  quantityPerLot: null,
  vatCode: C_Vat_Code.VT1_NORMAL,
  isNoDiscount: null,
  shippingPointId: null,
  price1: null,
  price2: null,
  price3: null,
  price4: null,
  price5: null,
  price6: null,
  price7: null,
  price8: null,
  price9: null,
  price10: null,
  articleSpecialPrices: [],
  articlePricesByCustomers: [],
  articleResellerPrices: [],
};

export async function resolveDataForGeneralTab(data: ITabState): Promise<ITabState> {
  const defaultValues: ArticleGeneralTabDataRes = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articleGeneralTabData().pipe(
          responseHandler<ArticleGeneralTabDataRes>({
            errorReturnType: defaultArticleGeneralTabData,
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articleGeneralTabData({ id: data.articleId! });
        },
      )
    : ({} as ArticleGeneralTabDataRes);
  const { articleSpecialPrices, articlePricesByCustomers, articleResellerPrices, ...rest } =
    defaultValues;
  data.defaultValues = { ...rest };

  const priceCategories =
    (await articleService.globHelpers.streamToPromise(
      configsData.sub.dictPriceCategories().pipe(take(1)),
      () => {
        configsData.pub.common(['dictPriceCategories']);
      },
    )) || [];

  data.articleSpecialPrices = articleSpecialPrices;
  data.articlePricesByCustomers = articlePricesByCustomers;
  data.articleResellerPrices = articleResellerPrices;

  data.articleSpecialPrices = generateSpecialPricesWithOnlyUsablePrices(
    articleSpecialPrices,
    priceCategories,
  );

  data.generalPriceCategories = priceCategories
    .filter((item) => item.label)
    .map((item) => item.label)
    .map((categoryName, i) => {
      if (categoryName) {
        const label = categoryName;
        const value = `price${i + 1}`;
        return <FormNumericField key={label} name={value} label={label} min={-1000000} shrink />;
      }
    }) as React.ReactElement[];

  data.specialPriceCategoriesColumns = priceCategories
    .filter((item) => item.label)
    .map((item) => item.label)
    .map((categoryName, i) => {
      const label = categoryName;
      const value = `price${i + 1}`;
      return (
        <Column
          key={label}
          field={value}
          headerName={label as string}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
      );
    });

  data.createSpecialPriceCategoriesInputs = (focusedField: string, errors: FieldErrors) => {
    const inputs = priceCategories
      .filter((item) => item.label)
      .map((item) => item.label)
      .map((categoryName, i) => {
        const label = categoryName;
        const value = `price${i + 1}`;
        if (i === 0) {
          return (
            <FormNumericField
              width='117px'
              key={label}
              autoFocus={value === focusedField}
              name={value}
              label={label}
              error={Boolean(errors['price1'])}
            />
          );
        }
        return (
          <FormNumericField
            width='117.6px'
            key={label}
            autoFocus={value === focusedField}
            name={value}
            label={label}
            error={Boolean(errors['price1'])}
            controllerProps={{
              rules: {
                deps: ['price1'],
              },
            }}
          />
        );
      });

    return (
      <FieldsRow
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          rowGap: 2,
          '& > div:nth-of-type(6)': {
            marginLeft: 0,
          },
        }}
      >
        {inputs}
      </FieldsRow>
    );
  };

  return data;
}

export async function resolveGeneralTabOptions(data: ITabState): Promise<ITabState> {
  data.generalTabOptions = await articleService.globHelpers.streamToPromise(
    articleService.sub.generalTabOptions().pipe(
      responseHandler<GeneralTabOptionsRes>({
        customErrorHandler() {
          return 'article.filter_not_loaded';
        },
        errorReturnType: {
          discountGroups: [],
          productionGroups: [],
          marketingGroups: [],
          shippingPoints: [],
          customersList: [],
          isShowShippingPointsInArticle: false,
        },
      }),
      take(1),
    ),
    () => articleService.pub.generalTabOptions(),
  );
  return data;
}

// delete price[x] fields that have not counterpart in priceCategories
export const generateSpecialPricesWithOnlyUsablePrices = (
  articleSpecialPrices: TArticleSpecialPrices,
  priceCategories: DictPriceCategoriesRes,
): TArticleSpecialPrices => {
  return articleSpecialPrices?.map(({ id, description, fromDate, toDate, isActual, ...p }) => {
    const specialPriceRowData = { id, description, fromDate, toDate, isActual };
    const prices = p as TSpecialPrices;
    const visiblePrices = priceCategories
      .filter((item) => item.label)
      .map((item) => item.label)
      .reduce((res, _, i) => {
        const key = `price${i + 1}` as TSpecialPricesKey;
        const value = prices?.[key];
        return { ...res, [key]: value };
      }, {});
    return { ...specialPriceRowData, ...visiblePrices };
  });
};

type TArticleSpecialPrices = ArticleGeneralTabDataRes['articleSpecialPrices'];

type TSpecialPrices = Pick<
  TArticleSpecialPrices[number],
  | 'price1'
  | 'price2'
  | 'price3'
  | 'price4'
  | 'price5'
  | 'price6'
  | 'price7'
  | 'price8'
  | 'price9'
  | 'price10'
>;

export type TSpecialPricesKey = keyof TSpecialPrices;
