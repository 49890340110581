import { Observable, finalize, merge, tap, filter, take } from 'rxjs';

import {
  printFormsService,
  ReportsConfigurationRes,
  SaveReportsConfigurationRes,
} from '../services/printForms.service';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import {
  SaveReportsConfigurationMutationVariables,
  C_Save_Operation_Status,
} from '../../../../graphql/generatedModel.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';

class PubImpl extends Pub<IReportsConfigurationTabState> {
  recordData(dataToSave: IReportsConfigurationTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  saveData(dataToSave?: IReportsConfigurationTabState['dataToSave']) {
    if (dataToSave) {
      this.emit('saveData', { dataToSave });
    } else {
      this.emit('saveData', {});
    }
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<IReportsConfigurationTabState> {
  protected actionHandlers(): Observable<IReportsConfigurationTabState> {
    return merge(this.saveData());
  }

  private saveData(): Observable<IReportsConfigurationTabState> {
    return this.actionListener('saveData').pipe(
      tap(({ dataToSave }) => {
        printFormsService.sub
          .saveReportsConfiguration()
          .pipe(
            responseHandler<SaveReportsConfigurationRes | undefined>({
              success: () => 'settings.configuration_saved',
              customErrorHandler: () => 'settings.configuration_not_saved',
              errorReturnType: {
                status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED,
              },
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe();

        printFormsService.pub.saveReportsConfiguration({
          dataToSave,
        } as SaveReportsConfigurationMutationVariables);
      }),
      finalize(() => reportsConfigurationTabState.pub.clearStream()),
    );
  }
}

class ReportsConfigurationTabState extends State<IReportsConfigurationTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const reportsConfigurationTabState = new ReportsConfigurationTabState({
  action: undefined,
  dataToSave: {},
});

export interface IReportsConfigurationTabState {
  action: undefined | 'recordData' | 'saveData';
  dataToSave: ReportsConfigurationRes | object;
}
