import { format } from 'date-fns';
import { ClientError } from 'graphql-request';
import { TFunction } from 'i18next';
import {
  delay,
  EMPTY,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  repeat,
  retry,
  switchMap,
  takeWhile,
  finalize,
  zipWith,
  take,
  tap,
  zip,
} from 'rxjs';
import {
  C_Email_Msg_Doctype,
  C_Global_Error_Code,
  C_History_Pdf_Doctype,
  C_Report,
  C_Win_Report_Task_Status,
  Create_R3_CustomerAddressesListReportMutation,
  Create_R3_CustomerAddressesListReportMutationVariables,
  Create_R4_CustomerFactSheetReportMutation,
  Create_R4_CustomerFactSheetReportMutationVariables,
  Create_R5_CustomerPhonesListReportMutation,
  Create_R5_CustomerPhonesListReportMutationVariables,
  Create_R36_ArticleListAllPriceCategoriesReportMutation,
  Create_R36_ArticleListAllPriceCategoriesReportMutationVariables,
  Create_R50_ArticleListWithImageReportMutation,
  Create_R50_ArticleListWithImageReportMutationVariables,
  Create_R6_ArticleListReportMutation,
  Create_R6_ArticleListReportMutationVariables,
  ExtractXmlFromPdfOfInvoicesMutation,
  ExtractXmlFromPdfOfInvoicesMutationVariables,
  GetTasksStatusQuery,
  GetWa_PdfHistoryReportQuery,
  GetWa_PdfHistoryReportQueryVariables,
  ListWa_DataForMultiEmailsQuery,
  ListWa_DataForMultiEmailsQueryVariables,
  ListWa_PdfHistoryReportsQuery,
  ListWa_PdfHistoryReportsQueryVariables,
  SendEmailWithDocumentMutation,
  SendEmailWithDocumentMutationVariables,
  Wa_PdfFileOfInvoiceInput,
  Create_R8_DeliveryNoteA4WithPriceReportMutation,
  Create_R8_DeliveryNoteA4WithPriceReportMutationVariables,
  Create_R29_DeliveryNoteA5LandscapeWithPriceReportMutation,
  Create_R29_DeliveryNoteA5LandscapeWithPriceReportMutationVariables,
  Create_R9_InvoiceA4ReportMutation,
  Create_R9_InvoiceA4ReportMutationVariables,
  Create_R10_CustomersDebtNotifierMutation,
  Create_R10_CustomersDebtNotifierMutationVariables,
  Create_R24_PaymentJournalA4Mutation,
  Create_R24_PaymentJournalA4MutationVariables,
  Create_R35_ExtendedPaymentJournalMutation,
  Create_R35_ExtendedPaymentJournalMutationVariables,
  Create_R22_BesrImportA4Mutation,
  Create_R22_BesrImportA4MutationVariables,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper';
import { companyConfigService } from '../../services/companyConfig/companyConfig.service';
import { Pub, Service, Sub } from '../service.abstract';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  r36_ArticleReportAllPriceCategoriesPreview,
  r6_ArticleReportPreview,
  r50_ArticleReportWithImagePreview,
  extractXmlFromPdfOfInvoices,
  getDataForMultiEmails,
  getTasksStatus,
  getWAPdfHistoryReport,
  listWAPdfHistoryReports,
  sendEmailWithDocument,
  r3_CustomerReportAddressesListPreview,
  r4_CustomerReportFactSheetPreview,
  r5_CustomerReportPhonesListPreview,
  r8_DeliveryNoteA4WithPricePreview,
  r29_DeliveryNoteA5LandscapeWithPricePreview,
  r9_InvoiceA4Preview,
  r22_BESRImportJournalPreview,
  r10_CustomersDebtNotifierPreview,
  r24_PaymentJournalA4Preview,
  r35_ExtendedPaymentJournalPreview,
} from './gql/reports.gql';

type Actions =
  | 'getPdfHistoryReport'
  | 'listPdfHistoryReports'
  | 'sendEmailWithDocument'
  | 'sendMultiEmailsWithDocument'
  | 'extractXmlFromPdfOfInvoices'
  | 'getDataForMultiEmails'
  | 'createArticlesReport'
  | 'createCustomersReport'
  | 'createOrderReport'
  | 'createInvoiceReport'
  | 'createBESRImportJournalReport'
  | 'createDebtReminderReport'
  | 'createMultipleDebtReminderReports'
  | 'createDebtReminderWithInvoiceReport'
  | 'createMultipleDebtReminderWithInvoiceReports'
  | 'createPaymentJournalReport'
  | undefined;

class PubImpl extends Pub<Actions> {
  getPdfHistoryReport(params: GetWa_PdfHistoryReportQueryVariables) {
    this.emit('getPdfHistoryReport', params);
  }

  listPdfHistoryReports(params: ListWa_PdfHistoryReportsQueryVariables) {
    this.emit('listPdfHistoryReports', params);
  }

  sendEmailWithDocument(params: SendEmailWithDocumentMutationVariables): void {
    this.emit('sendEmailWithDocument', params);
  }

  sendMultiEmailsWithDocument(params: SendEmailWithDocumentMutationVariables[]): void {
    this.emit('sendMultiEmailsWithDocument', params);
  }

  extractXmlFromPdfOfInvoices(params: ExtractXmlFromPdfOfInvoicesMutationVariables) {
    this.emit('extractXmlFromPdfOfInvoices', params);
  }

  getDataForMultiEmails(params: ListWa_DataForMultiEmailsQueryVariables) {
    this.emit('getDataForMultiEmails', params);
  }
  createArticlesReport(params: IArticlesReportParams): void {
    this.emit('createArticlesReport', params);
    this.emit(undefined);
  }
  createCustomersReport(params: ICustomersReportParams): void {
    this.emit('createCustomersReport', params);
  }
  createOrderReport(params: IOrderReportParams): void {
    this.emit('createOrderReport', params);
  }
  createInvoiceReport(params: IInvoiceReportParams): void {
    this.emit('createInvoiceReport', params);
  }
  createBESRImportJournalReport(params: Create_R22_BesrImportA4MutationVariables) {
    this.emit('createBESRImportJournalReport', params);
  }
  createDebtReminderReport(params: Create_R10_CustomersDebtNotifierMutationVariables) {
    this.emit('createDebtReminderReport', params);
  }
  createMultipleDebtReminderReports(
    params: Array<Create_R10_CustomersDebtNotifierMutationVariables>,
  ) {
    this.emit('createMultipleDebtReminderReports', params);
  }
  createDebtReminderWithInvoiceReport(params: IGetR64NotifierFilesParams) {
    this.emit('createDebtReminderWithInvoiceReport', params);
  }
  createMultipleDebtReminderWithInvoiceReports(params: IGetMultipleR64NotifierFilesParams) {
    this.emit('createMultipleDebtReminderWithInvoiceReports', params);
  }
  createPaymentJournalReport(params: IPaymentJournalReportParams) {
    this.emit('createPaymentJournalReport', params);
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  getPdfHistoryReport(): Observable<GetWAPdfHistoryReportRes> {
    return this.actionListener('getPdfHistoryReport').pipe(
      switchMap(({ params }) => {
        return gqlClient(getWAPdfHistoryReport, params) as Observable<GetWa_PdfHistoryReportQuery>;
      }),
      map((data) => data?.wawiAssist?.getWA_PdfHistoryReport as GetWAPdfHistoryReportRes),
    );
  }
  waitAndGetPdfHistoryReport(): Observable<GetWAPdfHistoryReportRes> {
    return this.getPdfHistoryReport().pipe(
      this._retryer({
        retryCondition: ({ response }) =>
          response.errors?.[0].extensions.code === C_Global_Error_Code.GEC20_NO_DATA,
      }),
    );
  }

  listPdfHistoryReports(): Observable<ListWAPdfHistoryReportRes> {
    return this.actionListener('listPdfHistoryReports').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          listWAPdfHistoryReports,
          params,
        ) as Observable<ListWa_PdfHistoryReportsQuery>;
      }),
      map((data) => data?.wawiAssist?.listWA_PdfHistoryReports as ListWAPdfHistoryReportRes),
    );
  }
  waitAndListPdfHistoryReports(): Observable<ListWAPdfHistoryReportRes> {
    return this.listPdfHistoryReports().pipe(
      this._retryer({
        retryCondition: ({ response }) =>
          response.errors?.[0].extensions.code === C_Global_Error_Code.GEC20_NO_DATA,
      }),
    );
  }

  private _retryer<T>(options: IRetryerArgs) {
    const { retryCondition, attempts = 10, interval = 1000 } = options || {};
    return (stream: Observable<T>) => {
      return stream.pipe(
        retry({
          count: attempts,
          delay: (v: ClientError) => {
            const cond = retryCondition(v);
            if (cond) {
              return from([v]).pipe(delay(interval));
            }
            throw v;
          },
        }),
      );
    };
  }

  sendEmailWithDocument(): Observable<SendEmailWithDocumentPlusParamsRes> {
    return this.actionListener('sendEmailWithDocument').pipe(
      switchMap(({ params }: { params: SendEmailWithDocumentMutationVariables }) => {
        const { docType, documentId } = params;
        return zip(
          gqlClient(sendEmailWithDocument, params) as Observable<SendEmailWithDocumentMutation>,
          of({ docType, documentId }),
        );
      }),
      map(([res, variables]) => {
        return {
          ...res.wawiAssist?.sendEmailWithDocument,
          ...variables,
        } as SendEmailWithDocumentPlusParamsRes;
      }),
    );
  }

  sendMultiEmailsWithDocument(): Observable<SendEmailWithDocumentPlusParamsRes[]> {
    return this.actionListener('sendMultiEmailsWithDocument').pipe(
      switchMap(({ params }: { params: SendEmailWithDocumentMutationVariables[] }) => {
        const emailsRequests = params.map((variables) =>
          gqlClient(sendEmailWithDocument, { ...variables }).pipe(
            map((res: SendEmailWithDocumentMutation) => ({ res, variables })),
          ),
        );
        return forkJoin(emailsRequests).pipe(
          map((responses) => {
            return responses.map(({ res, variables }) => {
              const response = res.wawiAssist?.sendEmailWithDocument as SendEmailWithDocumentRes;
              const { docType, documentId } = variables;
              return {
                ...response,
                docType,
                documentId,
              } as SendEmailWithDocumentPlusParamsRes;
            });
          }),
        );
      }),
    );
  }

  extractXmlFromPdfOfInvoices(): Observable<ExtractXmlFromPdfOfInvoicesRes> {
    return this.actionListener('extractXmlFromPdfOfInvoices').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          extractXmlFromPdfOfInvoices,
          params,
        ) as Observable<ExtractXmlFromPdfOfInvoicesMutation>;
      }),
      map(
        (data) => data?.wawiAssist?.extractXmlFromPdfOfInvoices as ExtractXmlFromPdfOfInvoicesRes,
      ),
    );
  }

  getDataForMultiEmails(): Observable<DataForMultiEmailsRes> {
    return this.actionListener('getDataForMultiEmails').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getDataForMultiEmails,
          params,
        ) as Observable<ListWa_DataForMultiEmailsQuery>;
      }),
      map((data) => data.wawiAssist?.listWA_DataForMultiEmails as DataForMultiEmailsRes),
    );
  }
  createArticlesReport(): Observable<WindowsGeneratedReportPreviewRes> {
    return this.actionListener('createArticlesReport').pipe(
      switchMap(({ params }: { params: IArticlesReportParams }) => {
        switch (params.reportId) {
          case C_Report.R6_ARTICLE_LIST: {
            const { articlesIds, priceValidOn, priceMode, customerId, priceCategory } = params;
            const variables: Create_R6_ArticleListReportMutationVariables = {
              params: {
                articlesIds,
                priceValidOn: format(
                  localeFormatterHelper.localizedDate(new Date(priceValidOn)),
                  'yyyy-MM-dd',
                ),
              },
            };

            if (priceMode === 'byCustomer') {
              variables.params.customerId = customerId;
            }
            if (priceMode === 'byPriceCategory') {
              variables.params.priceCategory = priceCategory;
            }
            return gqlClient(r6_ArticleReportPreview, variables).pipe(
              map((res: Create_R6_ArticleListReportMutation) => {
                return res.wawiAssist
                  ?.create_r6_ArticleListReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          case C_Report.R50_ARTICLE_LIST_WITH_IMAGES: {
            const { articlesIds, priceValidOn, priceMode, customerId, priceCategory } = params;
            const variables: Create_R50_ArticleListWithImageReportMutationVariables = {
              params: {
                articlesIds,
                priceValidOn: format(
                  localeFormatterHelper.localizedDate(new Date(priceValidOn)),
                  'yyyy-MM-dd',
                ),
              },
            };

            if (priceMode === 'byCustomer') {
              variables.params.customerId = customerId;
            }
            if (priceMode === 'byPriceCategory') {
              variables.params.priceCategory = priceCategory;
            }
            return gqlClient(r50_ArticleReportWithImagePreview, variables).pipe(
              map((res: Create_R50_ArticleListWithImageReportMutation) => {
                return res.wawiAssist
                  ?.create_r50_ArticleListWithImageReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          case C_Report.R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES: {
            const { articlesIds, priceValidOn } = params;
            const variables: Create_R36_ArticleListAllPriceCategoriesReportMutationVariables = {
              params: {
                articlesIds,
                priceValidOn: format(
                  localeFormatterHelper.localizedDate(new Date(priceValidOn)),
                  'yyyy-MM-dd',
                ),
              },
            };
            return gqlClient(r36_ArticleReportAllPriceCategoriesPreview, variables).pipe(
              map((res: Create_R36_ArticleListAllPriceCategoriesReportMutation) => {
                return res.wawiAssist
                  ?.create_r36_ArticleListAllPriceCategoriesReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }
          default:
            return EMPTY;
        }
      }),
      delay(2000),
      switchMap((reportTaskResult) => {
        const { taskId } = reportTaskResult;
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((tasks) => {
            const [item] = tasks;
            reportTaskResult.status = item.status;
            return reportTaskResult;
          }),
        );
      }),
    );
  }
  createCustomersReport(): Observable<WindowsGeneratedReportPreviewRes> {
    return this.actionListener('createCustomersReport').pipe(
      switchMap(({ params }: { params: ICustomersReportParams }) => {
        switch (params.reportId) {
          case C_Report.R3_ADDRESS_LIST_A4_LANDSCAPE: {
            const { customersIds } = params;
            const variables: Create_R3_CustomerAddressesListReportMutationVariables = {
              customersIds,
            };
            return gqlClient(r3_CustomerReportAddressesListPreview, variables).pipe(
              map((res: Create_R3_CustomerAddressesListReportMutation) => {
                return res.wawiAssist
                  ?.create_r3_CustomerAddressesListReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          case C_Report.R4_FACTSHEET: {
            const { customersIds } = params;
            const variables: Create_R4_CustomerFactSheetReportMutationVariables = {
              customersIds,
            };
            return gqlClient(r4_CustomerReportFactSheetPreview, variables).pipe(
              map((res: Create_R4_CustomerFactSheetReportMutation) => {
                return res.wawiAssist
                  ?.create_r4_CustomerFactSheetReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          case C_Report.R5_PHONES_LIST: {
            const { customersIds } = params;
            const variables: Create_R5_CustomerPhonesListReportMutationVariables = {
              customersIds,
            };
            return gqlClient(r5_CustomerReportPhonesListPreview, variables).pipe(
              map((res: Create_R5_CustomerPhonesListReportMutation) => {
                return res.wawiAssist
                  ?.create_r5_CustomerPhonesListReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }
          default:
            return EMPTY;
        }
      }),
      delay(2000),
      switchMap((reportTaskResult) => {
        const { taskId } = reportTaskResult;
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((tasks) => {
            const [item] = tasks;
            reportTaskResult.status = item.status;
            return reportTaskResult;
          }),
        );
      }),
    );
  }
  createOrderReport(): Observable<WindowsGeneratedReportPreviewRes> {
    return this.actionListener('createOrderReport').pipe(
      switchMap(({ params }: { params: IOrderReportParams }) => {
        switch (params.reportId) {
          case C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE: {
            const { orderId } = params;
            const variables: Create_R8_DeliveryNoteA4WithPriceReportMutationVariables = {
              orderId,
            };
            return gqlClient(r8_DeliveryNoteA4WithPricePreview, variables).pipe(
              map((res: Create_R8_DeliveryNoteA4WithPriceReportMutation) => {
                return res.wawiAssist
                  ?.create_r8_DeliveryNoteA4WithPriceReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          case C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE: {
            const { orderId } = params;
            const variables: Create_R29_DeliveryNoteA5LandscapeWithPriceReportMutationVariables = {
              orderId,
            };
            return gqlClient(r29_DeliveryNoteA5LandscapeWithPricePreview, variables).pipe(
              map((res: Create_R29_DeliveryNoteA5LandscapeWithPriceReportMutation) => {
                return res.wawiAssist
                  ?.create_r29_DeliveryNoteA5LandscapeWithPriceReport as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          default:
            return EMPTY;
        }
      }),
      delay(2000),
      switchMap((reportTaskResult) => {
        const { taskId } = reportTaskResult;
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((tasks) => {
            const [item] = tasks;
            reportTaskResult.status = item.status;
            return reportTaskResult;
          }),
        );
      }),
    );
  }
  createInvoiceReport(): Observable<WindowsGeneratedReportPreviewRes> {
    return this.actionListener('createInvoiceReport').pipe(
      switchMap(({ params }: { params: IInvoiceReportParams }) => {
        switch (params.reportId) {
          case C_Report.R9_INVOICE_A4: {
            const { invoiceId } = params;
            const variables: Create_R9_InvoiceA4ReportMutationVariables = {
              invoiceId,
            };
            return gqlClient(r9_InvoiceA4Preview, variables).pipe(
              map((res: Create_R9_InvoiceA4ReportMutation) => {
                return res.wawiAssist
                  ?.create_r9_InvoiceA4Report as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          default:
            return EMPTY;
        }
      }),
      delay(2000),
      switchMap((reportTaskResult) => {
        const { taskId } = reportTaskResult;
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((tasks) => {
            const [item] = tasks;
            reportTaskResult.status = item.status;
            return reportTaskResult;
          }),
        );
      }),
    );
  }
  createBESRImportJournalReport(): Observable<WindowsGeneratedReportPreviewRes> {
    return this.actionListener('createBESRImportJournalReport').pipe(
      switchMap(({ params }) => {
        return gqlClient(r22_BESRImportJournalPreview, params).pipe(
          map((res: Create_R22_BesrImportA4Mutation) => {
            return res.wawiAssist?.create_r22_BESRImportA4 as WindowsGeneratedReportPreviewRes;
          }),
        );
      }),
      delay(2000),
      switchMap((reportTaskResult) => {
        const { taskId } = reportTaskResult;
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((tasks) => {
            const [item] = tasks;
            reportTaskResult.status = item.status;
            return reportTaskResult;
          }),
        );
      }),
    );
  }
  createDebtReminderReport(): Observable<IGetR10NotifierFileRes> {
    return this.actionListener('createDebtReminderReport').pipe(
      this._queryR10Notifier(),
      finalize(() => reportsService.pub.clearStream()),
    );
  }
  createMultipleDebtReminderReports(): Observable<IGetMultipleR10NotifierFilesRes> {
    return this.actionListener('createMultipleDebtReminderReports').pipe(
      switchMap(({ params }) => {
        const requests: Array<Observable<GetR10NotifierRequestRes & { customerId: string }>> =
          params.map((gqlVariables: Create_R10_CustomersDebtNotifierMutationVariables) =>
            gqlClient(r10_CustomersDebtNotifierPreview, gqlVariables).pipe(
              map(
                (res: Create_R10_CustomersDebtNotifierMutation) =>
                  res.wawiAssist?.create_r10_CustomersDebtNotifier,
              ),
              map((res) => ({ ...res, customerId: gqlVariables.params.customerId })),
            ),
          );
        return forkJoin(requests);
      }),
      delay(5000),
      switchMap((res) => {
        const tasksId = res.map((item) => item.taskId);

        return this._taskStatusPolling({ tasksId, maxPollingAttempts: 20 }).pipe(
          map((v) => {
            const items = v.map((item) => {
              const isReady: boolean = item.status === C_Win_Report_Task_Status.WRTS2_READY;
              const generateResItem = res.find((it) => it.taskId === item.taskId);
              if (!isReady || !generateResItem) {
                throw new Error();
              }
              return generateResItem;
            });

            return items;
          }),
        );
      }),
      finalize(() => reportsService.pub.clearStream()),
    );
  }
  createDebtReminderWithInvoiceReport(): Observable<IGetR64NotifierFilesRes> {
    return this.actionListener('createDebtReminderWithInvoiceReport').pipe(
      map((v) => {
        const { r10NotifierParams, listPdfHistoryReportParams } =
          v.params as IGetR64NotifierFilesParams;
        reportsService.pub.listPdfHistoryReports(listPdfHistoryReportParams);
        v.params = r10NotifierParams;
        return v;
      }),
      this._queryR10Notifier(),
      zipWith(
        reportsService.sub
          .waitAndListPdfHistoryReports()
          .pipe(responseHandler<ListWAPdfHistoryReportRes>({ errorReturnType: { reports: [] } })),
      ),
      map((v) => {
        const notifierPdfUrl = v?.[0]?.url!;
        const notifierPdfS3Key = v?.[0]?.s3Key;
        const invoicesPdfs = v?.[1]?.reports || [];
        if (!notifierPdfS3Key || !invoicesPdfs.length) {
          throw new Error();
        }
        const resInvoices = invoicesPdfs.filter(
          (invoiceReport) =>
            invoiceReport.reportId !== C_Report.R17_INVOICE_SUMMARY &&
            invoiceReport.reportId !== C_Report.R52_DELIVERY_OVERVIEW,
        );

        return {
          notifierPdfUrl,
          notifierPdfS3Key,
          invoicesPdfs: resInvoices,
        };
      }),
      finalize(() => reportsService.pub.clearStream()),
    );
  }
  createMultipleDebtReminderWithInvoiceReports(): Observable<IGetMultipleR64NotifierFilesRes> {
    return this.actionListener('createMultipleDebtReminderWithInvoiceReports').pipe(
      tap((v) => {
        const params: IGetMultipleR64NotifierFilesParams = v.params;
        const invoicesId = Object.values(params).reduce<Array<string>>((outArr, item) => {
          return [...outArr, ...item.invoiceIds];
        }, []);
        reportsService.pub.listPdfHistoryReports({
          params: {
            docsType: C_History_Pdf_Doctype.PDF_INVOICE,
            documentsIds: invoicesId,
          },
        });
      }),
      switchMap((v) => {
        const params = v.params as IGetMultipleR64NotifierFilesParams;
        const r10Requests = Object.values(params).map(({ r10NotifierParams }) =>
          gqlClient(r10_CustomersDebtNotifierPreview, r10NotifierParams).pipe(
            map(
              (res: Create_R10_CustomersDebtNotifierMutation) =>
                res.wawiAssist?.create_r10_CustomersDebtNotifier as GetR10NotifierRequestRes,
            ),
            map((res) => ({ ...res, customerId: r10NotifierParams.params.customerId })),
          ),
        );
        return zip(
          forkJoin(r10Requests),
          reportsService.sub
            .waitAndListPdfHistoryReports()
            .pipe(responseHandler<ListWAPdfHistoryReportRes>({ errorReturnType: { reports: [] } })),
          of(params),
        );
      }),
      delay(5000),
      switchMap((res) => {
        const [notifiersRes, invoicesRes, params] = res || [];
        const tasksId = notifiersRes?.map((item) => item.taskId);

        return this._taskStatusPolling({ tasksId, maxPollingAttempts: 20 }).pipe(
          map((v) => {
            const items = v.map((item) => {
              const isReady: boolean = item.status === C_Win_Report_Task_Status.WRTS2_READY;
              const generateResItem = res?.[0]?.find((it) => it.taskId === item.taskId);

              if (!isReady || !generateResItem) {
                throw new Error();
              }
              const { url, s3Key, customerId } = generateResItem || {};
              const customerInvoicesId = params[customerId]?.invoiceIds || [];
              const invoicesPdfs =
                invoicesRes.reports.reduce(
                  (outArr: IGetR64NotifierFilesRes['invoicesPdfs'], invoiceReport) => {
                    const isInvoiceOfCustomer = !!customerInvoicesId.find(
                      (invoiceId) => invoiceId === invoiceReport.documentId,
                    );
                    const isInvoiceSummaryReport =
                      invoiceReport.reportId === C_Report.R17_INVOICE_SUMMARY ||
                      invoiceReport.reportId === C_Report.R52_DELIVERY_OVERVIEW;
                    if (isInvoiceOfCustomer && !isInvoiceSummaryReport) {
                      outArr.push(invoiceReport);
                    }
                    return outArr;
                  },
                  [],
                ) || [];

              return {
                notifierPdfUrl: url,
                notifierPdfS3Key: s3Key,
                invoicesPdfs,
                customerId,
              };
            });

            return items;
          }),
        );
      }),
      finalize(() => reportsService.pub.clearStream()),
    );
  }
  createPaymentJournalReport(): Observable<WindowsGeneratedReportPreviewRes> {
    return this.actionListener('createPaymentJournalReport').pipe(
      switchMap(({ params }: { params: IPaymentJournalReportParams }) => {
        switch (params.reportId) {
          case C_Report.R24_PAYMENT_JOURNAL_A4_LANDSCAPE: {
            const { paymentsId } = params;
            const variables: Create_R24_PaymentJournalA4MutationVariables = {
              paymentsId,
            };
            return gqlClient(r24_PaymentJournalA4Preview, variables).pipe(
              map((res: Create_R24_PaymentJournalA4Mutation) => {
                return res.wawiAssist
                  ?.create_r24_PaymentJournalA4 as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          case C_Report.R35_EXTENDED_PAYMENT_JOURNAL: {
            const { paymentsId } = params;
            const variables: Create_R35_ExtendedPaymentJournalMutationVariables = {
              paymentsId,
            };
            return gqlClient(r35_ExtendedPaymentJournalPreview, variables).pipe(
              map((res: Create_R35_ExtendedPaymentJournalMutation) => {
                return res.wawiAssist
                  ?.create_r35_ExtendedPaymentJournal as WindowsGeneratedReportPreviewRes;
              }),
            );
          }

          default:
            return EMPTY;
        }
      }),
      delay(2000),
      switchMap((reportTaskResult) => {
        const { taskId } = reportTaskResult;
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((tasks) => {
            const [item] = tasks;
            reportTaskResult.status = item.status;
            return reportTaskResult;
          }),
        );
      }),
      finalize(() => reportsService.pub.clearStream()),
    );
  }

  private _queryR10Notifier() {
    return (stream: Observable<any>): Observable<IGetR10NotifierFileRes> =>
      stream.pipe(
        switchMap(
          ({ params }) =>
            gqlClient(
              r10_CustomersDebtNotifierPreview,
              params,
            ) as Observable<Create_R10_CustomersDebtNotifierMutation>,
        ),
        delay(2000),
        switchMap((res) => {
          const resData = res.wawiAssist
            ?.create_r10_CustomersDebtNotifier as IGetR10NotifierFileRes;
          const { taskId } = resData || {};
          const nonSuccessRes = {
            taskId,
            url: null,
            s3Key: null,
          };
          if (!taskId) {
            return of(nonSuccessRes);
          }
          return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
            map((v) => {
              if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
                return nonSuccessRes;
              }
              return resData;
            }),
          );
        }),
      );
  }

  private _taskStatusPolling({
    tasksId,
    maxPollingAttempts = 10,
  }: {
    tasksId: Array<number>;
    maxPollingAttempts?: number;
  }): Observable<GetWinReportsStatusRes> {
    return (gqlClient(getTasksStatus, { tasksId }) as Observable<GetTasksStatusQuery>).pipe(
      map((res: GetTasksStatusQuery) => {
        return res.wawiAssist?.getWinReportTasksStatuses as GetWinReportsStatusRes;
      }),
      repeat({ count: maxPollingAttempts, delay: 2000 }),
      takeWhile((tasks) => {
        return (
          tasks?.every(
            (item) =>
              item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
              item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
          ) as boolean,
          true
        );
      }),
      filter((tasks) => {
        const isInProcess = tasks?.some(
          (item) =>
            item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
            item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
        );
        return !isInProcess;
      }),
      take(1),
    );
  }
}

class ReportsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const reportsService = new ReportsService();

interface IRetryerArgs {
  retryCondition: (err: ClientError) => boolean;
  attempts?: number;
  interval?: number;
}

export type GetWAPdfHistoryReportRes = NonNullable<
  NonNullable<GetWa_PdfHistoryReportQuery['wawiAssist']>['getWA_PdfHistoryReport']
>;
export type ListWAPdfHistoryReportRes = NonNullable<
  NonNullable<ListWa_PdfHistoryReportsQuery['wawiAssist']>['listWA_PdfHistoryReports']
>;
export type SendEmailWithDocumentRes = NonNullable<
  NonNullable<SendEmailWithDocumentMutation['wawiAssist']>['sendEmailWithDocument']
>;
export interface SendEmailWithDocumentPlusParamsRes extends SendEmailWithDocumentRes {
  docType: C_Email_Msg_Doctype;
  documentId: number | string;
}
export type ExtractXmlFromPdfOfInvoicesRes = NonNullable<
  NonNullable<ExtractXmlFromPdfOfInvoicesMutation['wawiAssist']>['extractXmlFromPdfOfInvoices']
>;

export type DataForMultiEmailsRes = NonNullable<
  NonNullable<ListWa_DataForMultiEmailsQuery['wawiAssist']>['listWA_DataForMultiEmails']
>;

export type ReportIdsForArticle =
  | C_Report.R6_ARTICLE_LIST
  | C_Report.R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES
  | C_Report.R50_ARTICLE_LIST_WITH_IMAGES;
export interface IArticlesReportParams {
  reportId: ReportIdsForArticle;
  articlesIds: Array<string>;
  priceValidOn: Date | string;
  priceMode: 'byCustomer' | 'byPriceCategory';
  customerId?: string;
  priceCategory?: string;
}

export type ReportIdsForCustomer =
  | C_Report.R3_ADDRESS_LIST_A4_LANDSCAPE
  | C_Report.R4_FACTSHEET
  | C_Report.R5_PHONES_LIST;
export interface ICustomersReportParams {
  reportId: ReportIdsForCustomer;
  customersIds: Array<string>;
}

export type ReportIdsForOrder =
  | C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE
  | C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE;
export interface IOrderReportParams {
  reportId: ReportIdsForOrder;
  orderId: string;
}

export type ReportIdsForInvoice = C_Report.R9_INVOICE_A4;
export interface IInvoiceReportParams {
  reportId: ReportIdsForInvoice;
  invoiceId: string;
}

export type ReportIdsForDebtReminder =
  | C_Report.R10_CUSTOMERS_DEBT_NOTIFIER
  | C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP;
export interface IDebtReminderReportParams {
  reportId: ReportIdsForDebtReminder;
  invoiceId: string;
  invoiceDate: Date;
  customerId: string;
}

export type ReportIdsForPaymentJournal =
  | C_Report.R24_PAYMENT_JOURNAL_A4_LANDSCAPE
  | C_Report.R35_EXTENDED_PAYMENT_JOURNAL;
export interface IPaymentJournalReportParams {
  reportId: ReportIdsForPaymentJournal;
  paymentsId: string[];
}

type GetR10NotifierRequestRes = NonNullable<
  NonNullable<
    Create_R10_CustomersDebtNotifierMutation['wawiAssist']
  >['create_r10_CustomersDebtNotifier']
>;
export interface IGetR10NotifierFileRes extends Omit<GetR10NotifierRequestRes, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}
export type IGetMultipleR10NotifierFilesRes = Array<
  { customerId: string } & IGetR10NotifierFileRes
>;

interface IGetR64NotifierFilesParams {
  r10NotifierParams: Create_R10_CustomersDebtNotifierMutationVariables;
  listPdfHistoryReportParams: ListWa_PdfHistoryReportsQueryVariables;
}
export interface IGetR64NotifierFilesRes {
  notifierPdfUrl: string;
  notifierPdfS3Key: string;
  invoicesPdfs: ListWAPdfHistoryReportRes['reports'];
}
export interface IGetMultipleR64NotifierFilesParams {
  [customerId: string]: {
    r10NotifierParams: Create_R10_CustomersDebtNotifierMutationVariables;
    invoiceIds: Array<string>;
  };
}
export type IGetMultipleR64NotifierFilesRes = Array<
  { customerId: string } & IGetR64NotifierFilesRes
>;

type GetWinReportsStatusRes = NonNullable<
  NonNullable<GetTasksStatusQuery['wawiAssist']>['getWinReportTasksStatuses']
>;

export type WindowsGeneratedReportPreviewRes = NonNullable<
  NonNullable<Create_R6_ArticleListReportMutation['wawiAssist']>['create_r6_ArticleListReport'] & {
    status: C_Win_Report_Task_Status;
  }
>;

export const getPdfDocTypeFromEmailDocType = (
  emailDocType: C_Email_Msg_Doctype,
): C_History_Pdf_Doctype | undefined => {
  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_INVOICE:
      return C_History_Pdf_Doctype.PDF_INVOICE;

    case C_Email_Msg_Doctype.EML_ORDER:
      return C_History_Pdf_Doctype.PDF_ORDER;

    case C_Email_Msg_Doctype.EML_OFFER:
      return C_History_Pdf_Doctype.PDF_OFFER;

    default:
      return undefined;
  }
};

export const getFileNameForEmailWithDocument = (
  t: TFunction,
  emailDocType: C_Email_Msg_Doctype,
  documentNo: number,
  reportId: C_Report,
): string => {
  let attachmentFileName: string;

  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_ORDER:
      attachmentFileName = `${t('common.delivery_note')}_${documentNo}.pdf`;
      break;

    case C_Email_Msg_Doctype.EML_OFFER:
      attachmentFileName = `${t('offer.offer')}_${documentNo}.pdf`;
      break;

    case C_Email_Msg_Doctype.EML_INVOICE:
      switch (reportId) {
        case C_Report.R9_INVOICE_A4:
        case C_Report.R19_INVOICE_A4_DETAILED:
        case C_Report.R31_INVOICE_A4_ARTICLE_GROUPING:
        case C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING:
          attachmentFileName = `${t('invoice.invoice')}_${documentNo}.pdf`;
          break;

        case C_Report.R18_INVOICE_PAYMENTSLIP:
        case C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR:
          attachmentFileName = `${t('invoice.payment_slip')}_${documentNo}.pdf`;
          break;

        case C_Report.R17_INVOICE_SUMMARY:
        case C_Report.R52_DELIVERY_OVERVIEW:
          attachmentFileName = `${t('invoice.invoice_summary')}_${documentNo}.pdf`;
          break;

        default:
          attachmentFileName = `${t('common.report')}.pdf`;
      }
      break;

    default:
      attachmentFileName = `${t('common.report')}.pdf`;
  }

  return attachmentFileName;
};

export const getFileNameForInvoiceXml = (documentNo: number): string => {
  return `xrechnung_${documentNo}.xml`;
};

export const getTextForEmailWithDocument = (
  t: TFunction,
  emailDocType: C_Email_Msg_Doctype,
  documentNoReplacement?: number,
): string => {
  const configs = companyConfigService.getCachedConfigs();
  let emailText = '';

  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_INVOICE:
      emailText = configs?.isDefaultEmailTextForInvoice
        ? t('invoice.email_message')
        : configs?.customEmailTextForInvoice ?? '';
      if (documentNoReplacement !== null) {
        emailText = emailText.replace('[FakturaNr]', String(documentNoReplacement));
      }
      break;

    case C_Email_Msg_Doctype.EML_ORDER:
      emailText = configs?.isDefaultEmailTextForOrder
        ? t('order.email_message')
        : configs?.customEmailTextForOrder ?? '';
      if (documentNoReplacement !== null) {
        emailText = emailText.replace('[LieferscheinNr]', String(documentNoReplacement));
      }
      break;

    case C_Email_Msg_Doctype.EML_OFFER:
      emailText = configs?.isDefaultEmailTextForOffer
        ? t('offer.email_message')
        : configs?.customEmailTextForOffer ?? '';
      if (documentNoReplacement !== null) {
        emailText = emailText.replace('[OfferNr]', String(documentNoReplacement));
      }
      break;

    case C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE:
      emailText = configs?.isDefaultEmailTextForDebtor
        ? t('debitor.emailMessage')
        : configs?.customEmailTextForDebtor ?? '';
      break;
  }

  return emailText;
};

export const getSubjectForEmailWithDocument = (
  t: TFunction,
  emailDocType: C_Email_Msg_Doctype,
  documentNo?: number,
  documentDate?: Date,
): string => {
  let subject = '';

  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_INVOICE:
      subject =
        documentNo !== undefined
          ? `${t('common.invoice')} ${documentNo}` +
            (documentDate !== undefined
              ? ` ${t('common.for').toLowerCase()} ${localeFormatterHelper.formatDate(
                  documentDate,
                )}`
              : '')
          : t('common.invoice');
      break;

    case C_Email_Msg_Doctype.EML_ORDER:
      subject =
        documentNo !== undefined
          ? `${t('common.order')} ${documentNo}` +
            (documentDate !== undefined
              ? ` ${t('common.for').toLowerCase()} ${localeFormatterHelper.formatDate(
                  documentDate,
                )}`
              : '')
          : t('common.order');
      break;

    case C_Email_Msg_Doctype.EML_OFFER:
      subject =
        documentNo !== undefined
          ? `${t('common.offer')} ${documentNo}` +
            (documentDate !== undefined
              ? ` ${t('common.for').toLowerCase()} ${localeFormatterHelper.formatDate(
                  documentDate,
                )}`
              : '')
          : t('common.offer');
      break;

    case C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE:
      subject = t('debitor.reminder');
      break;
  }

  return subject;
};

export const getFilesDataToExtractXmlFromPdfOfInvoices = (
  reportsData: ListWAPdfHistoryReportRes['reports'],
): Array<Wa_PdfFileOfInvoiceInput> => {
  const justInvoicePdfsData = reportsData.filter(
    (pdfFileData) =>
      pdfFileData.reportId === C_Report.R9_INVOICE_A4 ||
      pdfFileData.reportId === C_Report.R19_INVOICE_A4_DETAILED ||
      pdfFileData.reportId === C_Report.R31_INVOICE_A4_ARTICLE_GROUPING ||
      pdfFileData.reportId === C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING,
  );

  return justInvoicePdfsData.map((invoicePdfData) => {
    return {
      invoiceId: invoicePdfData.documentId,
      pdfFile: {
        s3Bucket: invoicePdfData.bucket,
        s3Key: invoicePdfData.s3Key ?? '',
      },
    };
  });
};
