import { TypeTable } from '../../../../../shared/components/table/table.component.tsx';
import { Theme as theme } from '../../../../../shared/styles/theme/theme.ts';
import { viewCells } from './viewCells.ts';

export const viewRows: TypeTable['viewRows'] = {
  action: ({ row }) => {
    if (row.virtualPositionId) {
      return 'deliveryCost';
    }
    if (typeof row.isChangeable === 'boolean' && !row.isChangeable) {
      return 'disabled';
    }
    return 'row';
  },
  styles: [
    {
      className: 'deliveryCost',
      bgColor: theme.palette.lightGreen.light,
      bgColorHover: theme.palette.lightGreen.light,
      rowStyles: {
        '&.MuiDataGrid-row div': {
          outline: 'none!important',
        },
      },
    },
    {
      className: 'disabled',
      rowStyles: {
        color: theme.palette.text.disabled,
        '&:hover': {
          backgroundColor: 'inherit',
          cursor: 'default',
        },
        '& .deleteBtn': {
          display: 'none',
        },
        '&:hover .deleteBtn': {
          display: 'flex',
        },
      },
    },
    {
      className: 'row',
      rowStyles: {
        '&.MuiDataGrid-row.Mui-selected': {
          ...(viewCells?.styles &&
            viewCells.styles.reduce((acc: Record<string, any>, el) => {
              if (el.className.includes('editable')) {
                let tag: any = el.className.split('-');
                if (tag.length > 1) {
                  tag = `.${tag[0]}`;
                } else tag = 'div';
                acc[`& > ${tag}`] = Object.getOwnPropertyDescriptor(
                  el.cellStyles,
                  '&:hover',
                )?.value;
              }
              return acc;
            }, {})),
        },
        '& .deleteBtn': {
          display: 'none',
        },
        '&:hover .deleteBtn, &.MuiDataGrid-row.Mui-selected .deleteBtn': {
          display: 'flex',
        },
        '&:hover .starIcon': {
          display: 'block',
        },
        '&.MuiDataGrid-row.Mui-selected .starIcon': {
          display: 'block',
        },
      },
    },
  ],
};
