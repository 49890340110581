import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'invoice'
  | 'invoices_list'
  | 'create_invoice'
  | 'create_invoices'
  | 'multi_create_invoice'
  | 'multi_create_invoice_confirmation'
  | 'no_customers_for_multi_create_invoice'
  | 'creating_multi_invoice'
  | 'number'
  | 'customer_active'
  | 'delivery_note_group'
  | 'invoicing_interval'
  | 'send_letter'
  | 'email_service'
  | 'no_data'
  | 'invoice_date'
  | 'text'
  | 'creating_invoice'
  | 'orders'
  | 'items'
  | 'number_of_created_invoices'
  | 'auto_preview_email'
  | 'customers'
  | 'invoices'
  | 'invoices_range'
  | 'open_invoices_to_date'
  | 'delete_invoice'
  | 'deleting_invoice'
  | 'invoice_successfully_deleted'
  | 'want_to_delete_invoice'
  | 'datetime_of_last_email_message'
  | 'invoice_no'
  | 'legend_is_exported'
  | 'legend_e_invoice'
  // errors messages -->
  | 'error_failed_to_delete_entry'
  | 'error_failed_to_load_deleted_invoices'
  | 'error_failed_to_load_order_positions'
  | 'error_failed_to_load_customer_details'
  | 'error_failed_to_load_invoice_data'
  | 'error_failed_to_load_created_invoice_list'
  | 'error_failed_to_get_order_for_created_invoice'
  | 'error_failed_to_load_report_a4'
  | 'error_failed_to_load_invoice_summary'
  | 'error_failed_to_load_invoice_payment_slip'
  | 'error_failed_to_load_invoice_a4_detailed'
  | 'error_failed_to_load_invoice_journal'
  | 'error_failed_to_load_invoice_article_grouping'
  | 'error_failed_to_load_invoice_customer_grouping'
  | 'error_failed_to_load_invoice_delivery_overview'
  | 'error_failed_to_load_invoice_payment_slip_with_qr'
  | 'error_order_position_not_loaded'
  | 'error_customers_details_not_loaded'
  | 'error_invoice_not_created'
  | 'invoice_not_saved'
  | 'invoice_with_id_not_saved'
  | 'abacus_export'
  // <-- errors messages
  | 'legend_auto_debit_and_exported'
  | 'legend_is_deleted'
  | 'email_message'
  | 'action'
  | 'delete_reason'
  | 'manual_selection'
  | 'create_multiple_invoices'
  | 'payment_slip'
  | 'invoice_summary';

const t = LocalizationHelper<Keys>('invoice');

export const invoiceDe = t({
  invoice: 'Rechnung',
  invoices_list: 'Rechnungsliste',
  create_invoice: 'Rechnung erstellen',
  create_invoices: 'Rechnungen erstellen',
  number: 'Nummer',
  customers: 'Kunden',
  invoices: 'Rechnungen',
  customer_active: 'Kunde ist aktiv',
  delivery_note_group: 'Lieferscheingruppe',
  invoicing_interval: 'Abrechnung',
  send_letter: 'Briefversand',
  email_service: 'Email Service',
  no_data: 'Keine Daten',
  invoice_date: 'Rechnungsdatum',
  text: 'Text',
  creating_invoice: 'Rechnung erstellen...',
  orders: 'Bestellungen',
  items: 'Positionen',
  number_of_created_invoices: 'Anzahl der erstellten Rechnungen',
  auto_preview_email: 'Automatische Vorschau/Email',
  invoice_not_saved: 'Fehler: Rechnung nicht gespeichert!',
  invoice_no: 'Rechnung-Nr.',
  invoices_range: 'Rechnungen Bereich',
  open_invoices_to_date: 'Offene Rechnungen bis Datum',
  delete_invoice: 'Rechnung stornieren',
  deleting_invoice: 'Rechnung stornieren...',
  invoice_successfully_deleted: 'Rechnung erfolgreich gelöscht',
  want_to_delete_invoice: 'Wollen Sie die Rechnung wirklich stornieren?',
  datetime_of_last_email_message: 'Email wurde verschickt am {{date}} um {{time}}',
  legend_is_exported: 'Exportiert (Sage)',
  legend_e_invoice: 'E-Rechnung',
  legend_auto_debit_and_exported: 'Bankeinzug bezahlt / Exportiert (Abacus)',
  legend_is_deleted: 'Stornierte Rechnung',
  email_message:
    'Sehr geehrte Damen und Herren\n \nIn der Beilage erhalten Sie die Rechnung [FakturaNr] zur Zahlung. Wir danken Ihnen für Ihren Auftrag.\n \nMit freundlichen Grüssen',
  action: 'Aktion',
  delete_reason: 'Löschen Grund',
  error_order_position_not_loaded: 'Bestellpositionen wurden nicht hochgeladen!',
  error_customers_details_not_loaded: 'Kundendaten wurden nicht hochgeladen!',
  invoice_with_id_not_saved: 'Fehler: Rechnung {{id}} nicht gespeichert!',
  multi_create_invoice: 'Mehrere Rechnungen erstellen',
  multi_create_invoice_confirmation:
    'Sie möchten Rechnungen automatisch erstellen für {{count}} Kunden?',
  no_customers_for_multi_create_invoice:
    'Es gibt keine berechtigten Kunden, mehrere Rechnungen zu erstellen.',
  creating_multi_invoice: 'Erstellen mehrerer Rechnungen',
  error_invoice_not_created: 'Rechnung wurde nicht erstellt!',
  manual_selection: 'Manuelle Auswahl',
  create_multiple_invoices: 'Mehrere Rechnungen erstellen',
  error_failed_to_delete_entry: 'Eintrag konnte nicht gelöscht werden',
  error_failed_to_load_deleted_invoices: 'Gelöschte Rechnungen konnten nicht geladen werden',
  error_failed_to_load_order_positions: 'Auftragspositionen konnten nicht geladen werden',
  error_failed_to_load_customer_details: 'Kundendaten konnten nicht geladen werden',
  error_failed_to_load_invoice_data: 'Rechnungsdaten konnten nicht geladen werden',
  error_failed_to_load_created_invoice_list:
    'Die erstellte Rechnungsliste konnte nicht geladen werden',
  error_failed_to_get_order_for_created_invoice:
    'Für die erstellte Rechnung konnten keine Bestellungen abgerufen werden',
  error_failed_to_load_report_a4: 'Rechnungsbericht A4 konnte nicht geladen werden',
  error_failed_to_load_invoice_summary: 'Rechnungszusammenfassung konnte nicht geladen werden',
  error_failed_to_load_invoice_payment_slip:
    'Der Zahlungsbeleg der Rechnung konnte nicht geladen werden',
  error_failed_to_load_invoice_a4_detailed:
    'Das Laden der detaillierten A4-Rechnung ist fehlgeschlagen',
  error_failed_to_load_invoice_journal: 'Rechnungsjournal konnte nicht geladen werden',
  error_failed_to_load_invoice_article_grouping:
    'Artikelgruppierungsrechnung A4 konnte nicht geladen werden',
  error_failed_to_load_invoice_customer_grouping:
    'Kundengruppierungsrechnung A4 konnte nicht geladen werden',
  error_failed_to_load_invoice_delivery_overview:
    'Die Rechnungszustellungsübersicht konnte nicht geladen werden',
  error_failed_to_load_invoice_payment_slip_with_qr:
    'Der Zahlungsbeleg mit QR-Code konnte nicht geladen werden',
  payment_slip: 'Einzahlungsschein',
  invoice_summary: 'Zusammenzug zu Rechnung',
  abacus_export: 'Abacus XML-Export',
});

export const invoiceFr = t({
  invoice: 'Facture',
  invoices_list: 'Liste des factures',
  create_invoice: 'Créer une facture',
  create_invoices: 'Créer des factures',
  number: 'Numéro',
  customers: 'Clients',
  invoices: 'Factures',
  customer_active: 'Le client est actif',
  delivery_note_group: 'Groupe de bons de livraison',
  invoicing_interval: 'Intervalle de facturation',
  send_letter: 'Envoyer une lettre',
  email_service: 'Service de messagerie',
  no_data: 'Pas de données',
  invoice_date: 'Date de la facture',
  text: 'Texte',
  creating_invoice: 'Création de la facture...',
  orders: 'Commandes',
  items: 'Articles',
  auto_preview_email: 'Aperçu/Email automatique',
  invoice_no: 'Facture N°',
  invoice_not_saved: 'Erreur : Facture non enregistrée !',
  invoice_with_id_not_saved: 'Erreur : Facture {{id}} non enregistrée !',
  invoices_range: 'Plage des factures',
  open_invoices_to_date: 'Factures ouvertes à ce jour',
  delete_invoice: 'Supprimer la facture',
  deleting_invoice: 'Suppression de la facture...',
  invoice_successfully_deleted: 'Facture supprimée avec succès',
  want_to_delete_invoice: 'Voulez-vous vraiment supprimer la facture ?',
  datetime_of_last_email_message: "L'email a été envoyé le {{date}} à {{time}}",
  legend_is_exported: 'Exportée (Sage)',
  legend_e_invoice: 'E-facture',
  number_of_created_invoices: 'Nombre de factures créées',
  legend_auto_debit_and_exported: 'Payée par prélèvement automatique / Exportée (Abacus)',
  legend_is_deleted: 'Facture supprimée',
  email_message:
    'Madame, Monsieur\n \nVeuillez trouver ci-joint la facture [FakturaNr] à régler. Merci pour votre commande.\n \nCordialement',
  action: 'Action',
  delete_reason: 'Raison de la suppression',
  error_order_position_not_loaded: "Les positions de commande n'ont pas été chargées !",
  error_customers_details_not_loaded: "Les détails des clients n'ont pas été chargés !",
  multi_create_invoice: 'Création multiple de factures',
  multi_create_invoice_confirmation:
    'Voulez-vous automatiquement créer des factures pour {{count}} clients ?',
  no_customers_for_multi_create_invoice:
    'Aucun client éligible pour la création multiple de factures.',
  creating_multi_invoice: 'Création de factures multiples',
  error_invoice_not_created: "La facture n'a pas été créée !",
  manual_selection: 'Sélection manuelle',
  create_multiple_invoices: 'Créer plusieurs factures',
  error_failed_to_delete_entry: "Échec de la suppression de l'entrée",
  error_failed_to_load_deleted_invoices: 'Échec du chargement des factures supprimées',
  error_failed_to_load_order_positions: 'Échec du chargement des positions de commande',
  error_failed_to_load_customer_details: 'Échec du chargement des détails du client',
  error_failed_to_load_invoice_data: 'Échec du chargement des données de la facture',
  error_failed_to_load_created_invoice_list: 'Échec du chargement de la liste des factures créées',
  error_failed_to_get_order_for_created_invoice:
    'Échec de la récupération de la commande pour la facture créée',
  error_failed_to_load_report_a4: 'Échec du chargement du rapport de la facture A4',
  error_failed_to_load_invoice_summary: 'Échec du chargement du récapitulatif de la facture',
  error_failed_to_load_invoice_payment_slip:
    'Échec du chargement du bulletin de paiement de la facture',
  error_failed_to_load_invoice_a4_detailed: 'Échec du chargement de la facture détaillée A4',
  error_failed_to_load_invoice_journal: 'Échec du chargement du journal des factures',
  error_failed_to_load_invoice_article_grouping:
    "Échec du chargement du regroupement d'articles pour la facture A4",
  error_failed_to_load_invoice_customer_grouping:
    'Échec du chargement du regroupement des clients pour la facture A4',
  error_failed_to_load_invoice_delivery_overview:
    "Échec du chargement de l'aperçu des livraisons pour la facture",
  error_failed_to_load_invoice_payment_slip_with_qr:
    'Échec du chargement du bulletin de paiement de la facture avec QR-code',
  payment_slip: 'Bordereau de paiement',
  invoice_summary: 'Résumé de facture',
  abacus_export: 'Abacus XML-Export',
});

export const invoiceIt = t({
  invoice: 'Fattura',
  invoices_list: 'Elenco fatture',
  create_invoice: 'Crea fattura',
  create_invoices: 'Crea fatture',
  number: 'Numero',
  customers: 'Clienti',
  invoices: 'Fatture',
  customer_active: 'Cliente attivo',
  delivery_note_group: 'Gruppo bolla di consegna',
  invoicing_interval: 'Intervallo di fatturazione',
  send_letter: 'Invia lettera',
  email_service: 'Servizio email',
  no_data: 'Nessun dato',
  invoice_date: 'Data fattura',
  text: 'Testo',
  creating_invoice: 'Creazione fattura...',
  orders: 'Ordini',
  items: 'Articoli',
  auto_preview_email: 'Anteprima/email automatica',
  invoice_no: 'N. Fattura',
  invoice_not_saved: 'Errore: Fattura non salvata!',
  invoice_with_id_not_saved: 'Errore: Fattura con ID {{id}} non salvata!',
  invoices_range: 'Intervallo fatture',
  open_invoices_to_date: 'Fatture aperte alla data',
  delete_invoice: 'Elimina fattura',
  deleting_invoice: 'Eliminazione fattura...',
  invoice_successfully_deleted: 'Fattura eliminata con successo',
  want_to_delete_invoice: 'Sei sicuro di voler eliminare la fattura?',
  datetime_of_last_email_message: 'Email inviata il {{date}} alle {{time}}',
  legend_is_exported: 'Esportato (Sage)',
  legend_e_invoice: 'Fattura elettronica',
  number_of_created_invoices: 'Numero di fatture create',
  legend_auto_debit_and_exported: 'Pagata tramite addebito diretto / Esportata (Abacus)',
  legend_is_deleted: 'Fattura eliminata',
  email_message:
    'Gentili Signore e Signori\n\nIn allegato troverete la fattura [FakturaNr] da pagare. Grazie per il vostro ordine.\n\nCordiali saluti',
  action: 'Azione',
  delete_reason: "Motivo dell'eliminazione",
  error_order_position_not_loaded: "Posizioni dell'ordine non caricate!",
  error_customers_details_not_loaded: 'Dettagli clienti non caricati!',
  multi_create_invoice: 'Creazione multipla di fatture',
  multi_create_invoice_confirmation: 'Vuoi creare automaticamente fatture per {{count}} clienti?',
  no_customers_for_multi_create_invoice:
    'Non ci sono clienti idonei per la creazione multipla di fatture.',
  creating_multi_invoice: 'Creazione multipla di fatture in corso',
  error_invoice_not_created: 'Fattura non creata!',
  manual_selection: 'Selezione manuale',
  create_multiple_invoices: 'Crea fatture multiple',
  error_failed_to_delete_entry: 'Eliminazione voce non riuscita',
  error_failed_to_load_deleted_invoices: 'Impossibile caricare le fatture eliminate',
  error_failed_to_load_order_positions: "Impossibile caricare le posizioni dell'ordine",
  error_failed_to_load_customer_details: 'Impossibile caricare i dettagli del cliente',
  error_failed_to_load_invoice_data: 'Impossibile caricare i dati della fattura',
  error_failed_to_load_created_invoice_list: "Impossibile caricare l'elenco delle fatture create",
  error_failed_to_get_order_for_created_invoice:
    "Impossibile ottenere l'ordine per la fattura creata",
  error_failed_to_load_report_a4: 'Impossibile caricare il report della fattura A4',
  error_failed_to_load_invoice_summary: 'Impossibile caricare il riepilogo della fattura',
  error_failed_to_load_invoice_payment_slip:
    'Impossibile caricare la cedola di pagamento della fattura',
  error_failed_to_load_invoice_a4_detailed: 'Impossibile caricare la fattura A4 dettagliata',
  error_failed_to_load_invoice_journal: 'Impossibile caricare il giornale delle fatture',
  error_failed_to_load_invoice_article_grouping:
    'Impossibile caricare la fattura raggruppata per articoli A4',
  error_failed_to_load_invoice_customer_grouping:
    'Impossibile caricare la fattura raggruppata per clienti A4',
  error_failed_to_load_invoice_delivery_overview:
    'Impossibile caricare il riepilogo delle consegne della fattura',
  error_failed_to_load_invoice_payment_slip_with_qr:
    'Impossibile caricare la cedola di pagamento della fattura con codice QR',
  payment_slip: 'Bollettino di pagamento',
  invoice_summary: 'Riepilogo fattura',
  abacus_export: 'Abacus XML-Export',
});

export const invoiceEn = t({
  invoice: 'Invoice',
  invoices_list: 'Invoices list',
  create_invoice: 'Create invoice',
  create_invoices: 'Create invoices',
  number: 'Number',
  customers: 'Customers',
  invoices: 'Invoices',
  customer_active: 'Customer is active',
  delivery_note_group: 'Delivery note group',
  invoicing_interval: 'Invoicing interval',
  send_letter: 'Send letter',
  email_service: 'Email service',
  no_data: 'No data',
  invoice_date: 'Invoice date',
  text: 'Text',
  creating_invoice: 'Creating invoice...',
  orders: 'Orders',
  items: 'Items',
  auto_preview_email: 'Automatic preview/email',
  invoice_no: 'Invoice No.',
  invoice_not_saved: 'Error: Invoice not saved!',
  invoice_with_id_not_saved: 'Error: Invoice {{id}} not saved!',
  invoices_range: 'Invoices range',
  open_invoices_to_date: 'Open invoices to date',
  delete_invoice: 'Delete invoice',
  deleting_invoice: 'Deleting invoice...',
  invoice_successfully_deleted: 'Invoice successfully deleted',
  want_to_delete_invoice: 'Do you really want to delete the invoice?',
  datetime_of_last_email_message: 'Email was sent on {{date}} at {{time}}',
  legend_is_exported: 'Exported (Sage)',
  legend_e_invoice: 'E-invoice',
  number_of_created_invoices: 'Number of created invoices',
  legend_auto_debit_and_exported: 'Paid by bank-debit / Exported (Abacus)',
  legend_is_deleted: 'Deleted invoice',
  email_message:
    'Dear Ladies and Gentlemen\n \nEnclosed you will receive the invoice [FakturaNr] to pay. Thank you for your order.\n \nBest regards',
  action: 'Action',
  delete_reason: 'Delete reason',
  error_order_position_not_loaded: 'Order positions was not uploaded!',
  error_customers_details_not_loaded: 'Customers details was not uploaded!',
  multi_create_invoice: 'Multi create invoices',
  multi_create_invoice_confirmation:
    'Do you want to automatically create invoices for {{count}} customers?',
  no_customers_for_multi_create_invoice:
    'There are no eligible customers to multi create invoices.',
  creating_multi_invoice: 'Creating multi invoices',
  error_invoice_not_created: 'Invoice has not been created!',
  manual_selection: 'Manual selection',
  create_multiple_invoices: 'Create multiple invoices',
  error_failed_to_delete_entry: 'Failed to delete entry',
  error_failed_to_load_deleted_invoices: 'Failed to load the deleted invoices',
  error_failed_to_load_order_positions: 'Failed to load the order positions',
  error_failed_to_load_customer_details: 'Failed to load the customer details',
  error_failed_to_load_invoice_data: 'Failed to load the invoice data',
  error_failed_to_load_created_invoice_list: 'Failed to load the created invoice list',
  error_failed_to_get_order_for_created_invoice: 'Failed to get order for the created invoice',
  error_failed_to_load_report_a4: 'Failed to load the invoice report A4',
  error_failed_to_load_invoice_summary: 'Failed to load the invoice summary',
  error_failed_to_load_invoice_payment_slip: 'Failed to load the invoice payment slip',
  error_failed_to_load_invoice_a4_detailed: 'Failed to load the detailed invoice A4',
  error_failed_to_load_invoice_journal: 'Failed to load the invoice journal',
  error_failed_to_load_invoice_article_grouping: 'Failed to load the article grouping invoice A4',
  error_failed_to_load_invoice_customer_grouping: 'Failed to load the customer grouping invoice A4',
  error_failed_to_load_invoice_delivery_overview: 'Failed to load the invoice delivery overview',
  error_failed_to_load_invoice_payment_slip_with_qr:
    'Failed to load the invoice payment slip with QR-code',
  payment_slip: 'Payment slip',
  invoice_summary: 'Invoice summary',
  abacus_export: 'Abacus XML-Export',
});
