import { Button, ButtonProps } from '@mui/material';
import { ChangeEvent, EventHandler, FC } from 'react';
import { take } from 'rxjs';
import { IUploadFileCommon, uploadFilesToS3 } from '../fileUpload.component';

export const UploadButton: FC<IButton> = ({
  accept = '*',
  multiple = false,
  place,
  variant = 'contained',
  color = 'primary',
  text = '',
  component = 'label',
  onLoading,
  onUploaded,
  ...rest
}) => {
  const handleFileUpload: EventHandler<ChangeEvent<HTMLInputElement>> = (evt) => {
    evt.stopPropagation();

    const files = evt.currentTarget.files;
    if (typeof onLoading === 'function' && files?.length !== 0) {
      onLoading();
    }
    uploadFilesToS3({ files, place })
      .pipe(take(1))
      .subscribe((result) => {
        if (typeof onUploaded === 'function') {
          onUploaded(result);
        }
      });
  };
  return (
    <Button variant={variant} color={color} {...rest} component={component}>
      {text}
      <input type='file' hidden accept={accept} multiple={multiple} onChange={handleFileUpload} />
    </Button>
  );
};
interface IButton extends IUploadFileCommon, ButtonProps {
  text: string;
}
