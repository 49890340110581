import { PutResult } from '@aws-amplify/storage';
import { map, Observable, zip } from 'rxjs';
import { storageHelper } from '../../helpers/storage';
import { responseHandler } from '../../responseHandler/responseHandler';
import { UploadButton } from './components/uploadButton.component';
import { UploadField } from './components/uploadField.component';
import { UploadListItemButton } from './components/uploadListItemButton.component';

export const FileUpload = {
  Button: UploadButton,
  Field: UploadField,
  ListItemButton: UploadListItemButton,
};

export function uploadFilesToS3({
  files,
  place,
}: IUploadFileToS3Props): Observable<IUploadFileToS3Return> {
  const filesForUpload: Array<Observable<PutResult>> = [];
  const len = files?.length || 0;
  for (let i = 0; i < len; i++) {
    filesForUpload.push(storageHelper.file.upload(`${place}/${files![i].name}`, files![i]));
  }
  return zip(filesForUpload).pipe(
    map((result) => {
      return { files: result };
    }),
    responseHandler<IUploadFileToS3Return>({
      errorReturnType: { files: [] },
    }),
  );
}

export interface IUploadFileToS3Props {
  files: FileList | null;
  place: IUploadFileCommon['place'];
}
export interface IUploadFileToS3Return {
  files: PutResult[];
}
export interface IUploadFileCommon extends Partial<Pick<HTMLInputElement, 'accept' | 'multiple'>> {
  place: string;
  onLoading?: () => void;
  onUploaded?: (result: IUploadFileToS3Return) => void;
}
