import { FC, useState } from 'react';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { Stack, Alert } from '@mui/material';
import { Checkbox } from '../../../../shared/components/form';
import { ToggleActionButton } from '../../../../shared/components/toggleActionButton/toggleActionButton.tsx';
import { useTranslation } from 'react-i18next';
import { EnterKeyNavigation } from '../orderGridItems/supportingFunctions/enterKeyNavigation.ts';

export const EnterNavigateButton: FC<IEnterNavigateButton> = ({ enterKeyNavigation }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(new Set(enterKeyNavigation.getKeyCombination()));

  const handeChange = (checkboxName: string) => {
    setData((prevState) => {
      if (prevState.has(checkboxName)) {
        prevState.delete(checkboxName);
      } else prevState.add(checkboxName);
      enterKeyNavigation.setKeyCombination(Array.from(prevState));
      return new Set(prevState);
    });
  };

  return (
    <ToggleActionButton
      color='primary'
      size='large'
      icon={<KeyboardIcon />}
      title={t('order.navigate_with_keyboard_Enter')}
    >
      <Stack px={2} pt={1} pb={2}>
        <Checkbox
          checked={data.has('quantity')}
          onChange={() => handeChange('quantity')}
          label={t('common.quantity')}
        />
        <Checkbox
          checked={data.has('description')}
          onChange={() => handeChange('description')}
          label={t('common.description')}
        />
        <Alert severity='info' sx={{ mt: 1, width: 368 }}>
          {t('order.navigation_with_Enter_info')}
        </Alert>
      </Stack>
    </ToggleActionButton>
  );
};

interface IEnterNavigateButton {
  enterKeyNavigation: EnterKeyNavigation;
}
