import { FC, useState, DragEvent, ChangeEvent, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import {
  AddOutlined as AddOutlinedIcon,
  DeleteSharp as DeleteSharpIcon,
  CreateSharp as CreateSharpIcon,
  DownloadSharp as DownloadSharpIcon,
  AddToPhotos as AddToPhotosIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import imagePickerBg from '../../icons/image_picker_bg.png';
import { IconWrapper } from './iconWrapper.component';
import { fromBlob } from 'image-resize-compress';

export const ImagePicker: FC<IImagePickerProps> = ({
  base64ImageURL,
  onError,
  onUpdate,
  onDelete,
  imageTypeList,
  width,
  height,
}) => {
  const { t } = useTranslation();
  const allowedImageMimeTypes = imageTypeList || ['image/jpeg', 'image/png', 'image/jpg'];
  const imageMimeType = allowedImageMimeTypes.join(',');
  const [file, setFile] = useState<File | null>(null);
  const [fileDataURL, setFileDataURL] = useState<string | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const [_, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setFileDataURL(base64ImageURL ? `data:image/png;base64,${base64ImageURL}` : null);
  }, [base64ImageURL]);

  const handleFileLoad = async (file: File) => {
    setLoading(true);

    const reader = new FileReader();

    if (!allowedImageMimeTypes.includes(file.type as ImageMimeType)) {
      onError && onError(2);
      setLoading(false);
      return;
    }
    if (file.size > 100000) {
      const resizedFile = await fromBlob(file, 80, 800, 'auto', 'png');
      reader.readAsDataURL(resizedFile);
      setFile(resizedFile as File);
    } else {
      reader.readAsDataURL(file);
      setFile(file);
    }

    reader.onloadend = () => {
      const imageStringCode = (reader.result as string).split(',')?.[1];
      onUpdate(imageStringCode);
      setFileDataURL(reader.result as string);
    };
    reader.onerror = () => {
      onError && onError(3);
    };
    setLoading(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      handleFileLoad(file).then();
    }
  };

  const handleFileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!allowedImageMimeTypes.includes(file.type as ImageMimeType)) {
      onError && onError(2);
      return;
    }
    handleFileLoad(file).then();
    setIsDraggingOver(false);
  };

  const handleFileDownload = () => {
    if (fileDataURL) {
      const link = document.createElement('a');
      link.href = fileDataURL;
      link.download = file ? file.name : 'image';
      link.click();
    }
  };
  return (
    <SImageWrapper
      onDrop={(e: DragEvent<HTMLDivElement>) => {
        handleFileDrop(e);
      }}
      onDragOver={(e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
      }}
      onDragLeave={(e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
      }}
      className={isDraggingOver ? 'highlight' : ''}
      {...(width && { width })}
      {...(height && { height })}
    >
      <>
        {isDraggingOver ? (
          <IconWrapper svgsize='50px' width='100%'>
            <AddToPhotosIcon color='disabled' style={{ pointerEvents: 'none' }} />
            <SDragAndDropText>{t('article.drop_file_here')}</SDragAndDropText>
          </IconWrapper>
        ) : fileDataURL ? (
          <SImageActions className='image-actions'>
            <IconWrapper width='17%'>
              <label htmlFor='update_image'>
                <IconWrapper>
                  <CreateSharpIcon />
                </IconWrapper>
              </label>
              <input
                id='update_image'
                type='file'
                accept={imageMimeType}
                style={{ display: 'none' }}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </IconWrapper>
            <IconWrapper width='17%' onClick={handleFileDownload}>
              <DownloadSharpIcon />
            </IconWrapper>
            <IconWrapper
              width='17%'
              onClick={() => {
                onDelete();
                setFile(null);
                setFileDataURL(null);
              }}
            >
              <DeleteSharpIcon />
            </IconWrapper>
          </SImageActions>
        ) : (
          <SDefaultImageAction
            className='image-actions'
            onClick={() => document.getElementById('add_image')?.click()}
          >
            <IconWrapper svgsize='24px' width='100%'>
              <label htmlFor='add_image' onClick={(e) => e.stopPropagation()}>
                <AddOutlinedIcon />
              </label>
              <input
                id='add_image'
                type='file'
                accept={imageMimeType}
                style={{ display: 'none' }}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </IconWrapper>
          </SDefaultImageAction>
        )}
        {fileDataURL && (
          <SImage>
            <img src={fileDataURL} alt='Not found' />
          </SImage>
        )}
      </>
    </SImageWrapper>
  );
};

const SImageActions = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  transition: '0.3s ease',
  opacity: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.63)',
  borderRadius: 4,
  backdropFilter: 'blur(3px)',
}));

const SDefaultImageAction = styled(SImageActions)(() => ({
  backdropFilter: 'none',
  // background: 'var(--backdrop-fill, rgba(0, 0, 0, 0.50))',
  cursor: 'pointer',
}));

const SImageWrapper = styled(Box)<Pick<IImagePickerProps, 'width' | 'height'>>(
  ({ width, height }) => ({
    width: width ? width + 'px' : '300px',
    height: height ? height + 'px' : '170px',
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
    background: `url(${imagePickerBg}) center center / cover no-repeat, var(--action-hover, rgba(0, 0, 0, 0.04))`,
    borderRadius: 4,
    transition: 'all 0.3s ease',
    // '&.highlight': {
    //   boxShadow: 'inset 0px 0px 7px rgba(0, 0, 0, 0.15)',
    //   border: `1px dashed var(--input-outlined-enabled-border, rgba(0, 0, 0, 0.23))`,
    // },
    '&:hover': {
      '& .image-actions': {
        opacity: 1,
      },
    },
  }),
);

const SImage = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '& > img': {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    padding: '1px',
  },
}));

const SDragAndDropText = styled('span')(({ theme }) => ({
  color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
  textAlign: 'center',
  textTransform: 'none',
  fontFamily: theme.typography.fontFamily,
  fontSize: 14,
  marginTop: 2,
}));

type ImageMimeType = 'image/jpeg' | 'image/png' | 'image/jpg' | 'image/*';

export interface IImagePickerProps {
  base64ImageURL?: string | undefined;
  width?: number;
  height?: number;
  imageTypeList?: ImageMimeType[] | undefined;
  onError?: (message: number | undefined) => void;
  onUpdate: (imageStringCode: string) => void;
  onDelete: () => void;
}
