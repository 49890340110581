import { Button, Stack, Box, Checkbox } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import CustomerIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './tableHeader.component';
import { Table } from '../../../../../../../shared/components/table/table.component';
import {
  Column,
  Footer,
} from '../../../../../../../shared/components/table/components/common.components';
import { viewRows } from './views/viewRows';
import { generateColumnsVisiblityModel } from './articleTable.component';
import { Theme as theme } from '../../../../../../../shared/styles/theme/theme.ts';
import {
  configurationService,
  ExecuteCustomersImportMutationRes,
} from '../../../../services/configuration.service';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler.ts';
import { configurationTabLoadingService } from '../../../../configurationTabLoading.service.ts';
import { IOptionsData } from './importDetailsInfo.component.tsx';
import { CustomerNrEditCell } from './cells/customer/customerNrEdit.cell.tsx';
import { AnyTextFieldEditCell } from './cells/customer/anyTextFieldEdit.cell.tsx';
import { AnyBooleanFieldCell } from './cells/customer/anyBooleanField.cell.tsx';
import { AnyDropdownFieldEditCell } from './cells/customer/anyDropdownFieldEdit.cell.tsx';
import { AnyDropdownFieldCell } from './cells/customer/anyDropdownField.cell.tsx';
import { AnyNumberFieldEditCell } from './cells/customer/anyNumberFieldEdit.cell.tsx';
import { ICustomerImportData } from '../import.tab.tsx';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service.ts';

export const CustomerTable: FC<ICustomerTable> = ({
  dataFromFile,
  heigthWhenCollapsed,
  shownRecordsCountWhenCollapsed,
  existingNumbers,
  options,
}) => {
  const { t } = useTranslation();
  const [gridFullData, setGridFullData] = useState<ICustomerImportData[]>([]);
  const [isAllDataShown, setIsAllDataShown] = useState(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());
  const icon = <CustomerIcon color='primary' fontSize='small' sx={{ marginTop: '10px' }} />;

  const toggleSelectAll = () => {
    let selectedIds = new Set<number>();
    if (!selectAll) {
      selectedIds = new Set(gridFullData.map((v) => v?.id));
    }
    setSelectedIds(selectedIds as Set<number>);
    setSelectAll(!selectAll);
  };

  const toggleCheckbox = (id: number) => {
    const newSelectedIds = new Set(selectedIds);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
      setSelectAll(false);
    } else {
      newSelectedIds.add(id);
      const allIdsSelected = gridFullData.every((item) => newSelectedIds.has(item?.id));
      setSelectAll(allIdsSelected);
    }
    setSelectedIds(newSelectedIds);
  };

  useEffect(() => {
    let allData = dataFromFile.map((item) => {
      item.isDuplicate = dataFromFile.some(
        (value) => value.customerNo === item.customerNo && value.id != item.id,
      );
      item.isRecordWhichOverwrites = existingNumbers.some((value) => value === item.customerNo);
      return item;
    });
    allData.sort((a, b) => a.customerNo.localeCompare(b.customerNo));
    setSelectedIds(new Set(allData.map((v) => v?.id)));
    setSelectAll(true);
    setGridFullData(allData);
    setIsAllDataShown(allData?.length <= shownRecordsCountWhenCollapsed);
  }, [dataFromFile]);

  const isDuplicates = useMemo(() => {
    return gridFullData.some((value) => value.isDuplicate === true);
  }, [gridFullData]);

  const isRecordsWhichOverwrites = useMemo(() => {
    return gridFullData.some((value) => value.isRecordWhichOverwrites === true);
  }, [gridFullData]);

  const needNotExpandPossibility = useMemo(() => {
    return gridFullData?.length <= shownRecordsCountWhenCollapsed;
  }, [gridFullData]);

  const gridShownData = useMemo(() => {
    if (needNotExpandPossibility) {
      return gridFullData;
    } else {
      return gridFullData?.slice(0, shownRecordsCountWhenCollapsed);
    }
  }, [gridFullData]);

  const dataToWrite = useMemo(() => {
    let data = structuredClone(gridFullData.filter((item) => selectedIds.has(item.id)));
    data.map((item) => {
      item.isDuplicate = data.some(
        (value) => value.customerNo === item.customerNo && value.id != item.id,
      );
      return item;
    });

    if (data.some((value) => value.isDuplicate === true)) {
      return [];
    } else {
      return data.map(({ id, isDuplicate, isRecordWhichOverwrites, ...keepAttrs }) => keepAttrs);
    }
  }, [gridFullData, selectAll, selectedIds]);

  const legendData = useMemo(() => {
    return [
      {
        color: theme.palette.blue.light,
        title: t('settings.some_existing_record_will_be_overwrited'),
      },
      { color: theme.palette.orange.light, title: t('settings.duplicate_in_import_data') },
    ];
  }, [theme, t]);

  const height = useMemo(() => {
    return needNotExpandPossibility
      ? heigthWhenCollapsed
      : isAllDataShown
      ? heigthWhenCollapsed + 32 * (gridFullData?.length - shownRecordsCountWhenCollapsed)
      : heigthWhenCollapsed;
  }, [
    gridFullData,
    needNotExpandPossibility,
    isAllDataShown,
    heigthWhenCollapsed,
    shownRecordsCountWhenCollapsed,
  ]);

  useEffect(() => {
    const executeImportSub = configurationService.sub
      .executeCustomersImport()
      .pipe(
        responseHandler<ExecuteCustomersImportMutationRes>({
          errorReturnType: {
            recordsInserted: 0,
            recordsUpdated: 0,
            recordsSkipped: gridFullData.length,
          },
        }),
      )
      .subscribe((data) => {
        setGridFullData([]);
        configurationTabLoadingService.pub.loading(false);
        snackbarService.pub.show({
          type: 'success',
          content: t('settings.import_successful', {
            recordsInserted: data.recordsInserted,
            recordsUpdated: data.recordsUpdated,
          }),
        });
        configurationService.pub.listValidationDataForImport(); // updating data to validate existiong records
      });

    return () => {
      executeImportSub.unsubscribe();
    };
  }, []);

  const handleShowAll = () => {
    setIsAllDataShown(true);
  };

  const handleImportCustomers = () => {
    setIsAllDataShown(false);
    configurationService.pub.executeCustomersImport({ importData: dataToWrite });
    configurationTabLoadingService.pub.loading(true);
  };

  return (
    <>
      {gridFullData?.length ? (
        <Stack>
          <TableHeader
            icon={icon}
            title={t('customer.customer')}
            dataLength={dataFromFile?.length}
            shownDataLength={gridShownData?.length}
            isAllDataShown={isAllDataShown}
            isDuplicates={isDuplicates}
            isRecordsWhichOverwrites={isRecordsWhichOverwrites}
            importButtonAvailable={dataToWrite.length > 0}
            onImport={handleImportCustomers}
          />
          <Table
            data={(isAllDataShown ? gridFullData : (gridShownData as [])) || []}
            height={height}
            viewRows={viewRows}
            columnVisibilityModel={generateColumnsVisiblityModel(dataFromFile, isAllDataShown)}
            onCellEditStop={(v, e) => {
              if (v.reason === 'enterKeyDown') {
                e.defaultMuiPrevented = true;
              }
            }}
            isCellEditable={() => isAllDataShown}
            focusTabProps={{ enabled: true }}
          >
            <Column
              disableReorder
              width={40}
              maxWidth={40}
              headerAlign='right'
              field='id'
              sortable={false}
              renderHeader={() => (
                <Box>
                  <Checkbox size='small' checked={selectAll} onChange={toggleSelectAll} />
                </Box>
              )}
              renderCell={({ row }) => {
                const id = row?.id;
                if (id) {
                  return (
                    <Box position='relative'>
                      <Checkbox
                        size='small'
                        checked={selectedIds.has(id)}
                        onChange={() => toggleCheckbox(id)}
                      />
                    </Box>
                  );
                }
              }}
            />
            <Column
              field='customerNo'
              headerName={t('common.customer_no')}
              width={150}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <CustomerNrEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    existingNumbers: existingNumbers,
                  }}
                />
              )}
            />
            <Column
              field='name'
              headerName={t('common.name')}
              width={300}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='isActive'
              headerName={t('customer.customer_active')}
              width={170}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='informationTip'
              headerName={t('common.note')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='customerAddressKindId'
              headerName={t('customer.type_of_address')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.customerAddressKinds,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.customerAddressKinds,
                  }}
                />
              )}
            />
            <Column
              field='customerGroupId'
              headerName={t('customer.group')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.customerGroups,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.customerGroups,
                  }}
                />
              )}
            />
            <Column
              field='deliveryNoteGroupId'
              headerName={t('customer.delivery_note_group')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.deliveryNoteGroups,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.deliveryNoteGroups,
                  }}
                />
              )}
            />
            <Column
              field='salutation'
              headerName={t('customer.salutation')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='surname'
              headerName={t('customer.surname')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='internalName'
              headerName={t('customer.internal_name')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 60,
                  }}
                />
              )}
            />
            <Column
              field='addition'
              headerName={t('customer.additive')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='street'
              headerName={t('customer.street')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='postBox'
              headerName={t('customer.post_box')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='zipCode'
              headerName={t('customer.zip_code')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 12,
                  }}
                />
              )}
            />
            <Column
              field='city'
              headerName={t('customer.city')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='country'
              headerName={t('customer.country')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressSalutation'
              headerName={t('customer.salutation')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressName'
              headerName={t('common.name')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressAddition'
              headerName={t('customer.additive')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressStreet'
              headerName={t('customer.street')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressPostBox'
              headerName={t('customer.post_box')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressZipCode'
              headerName={t('customer.zip_code')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 12,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressCity'
              headerName={t('customer.city')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='deliveryAddressCountry'
              headerName={t('customer.country')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='contactPerson'
              headerName={t('customer.contact_person')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='phoneBusiness'
              headerName={t('customer.phone_business')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='phoneBusiness2'
              headerName={t('customer.phone_business') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='phonePrivate'
              headerName={t('customer.phone_private')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='phoneMobile'
              headerName={t('customer.mobile')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='fax'
              headerName={t('customer.fax')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='email'
              headerName={t('common.email')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='emailForInvoice'
              headerName={t('customer.email_for_invoices')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='website'
              headerName={t('customer.website')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='priceCategoryId'
              headerName={t('customer.priceCategories')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.priceCategories,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.priceCategories,
                  }}
                />
              )}
            />
            <Column
              field='taxSystemId'
              headerName={t('customer.vat_system')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.taxSystems,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.taxSystems,
                  }}
                />
              )}
            />
            <Column
              field='discountKindId'
              headerName={t('customer.discountKind')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.discountKinds,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.discountKinds,
                  }}
                />
              )}
            />
            <Column
              field='quantityDiscountSettingId'
              headerName={t('customer.quantityDiscountValue')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.quantityDiscounts,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.quantityDiscounts,
                  }}
                />
              )}
            />
            <Column
              field='discountOnGroup1'
              headerName={t('customer.discountPercent') + ' 1'}
              width={200}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
              sortable={false}
            />
            <Column
              field='discountOnGroup2'
              headerName={t('customer.discountPercent') + ' 2'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup3'
              headerName={t('customer.discountPercent') + ' 3'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup4'
              headerName={t('customer.discountPercent') + ' 4'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup5'
              headerName={t('customer.discountPercent') + ' 5'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup6'
              headerName={t('customer.discountPercent') + ' 6'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup7'
              headerName={t('customer.discountPercent') + ' 7'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup8'
              headerName={t('customer.discountPercent') + ' 8'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup9'
              headerName={t('customer.discountPercent') + ' 9'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup10'
              headerName={t('customer.discountPercent') + ' 10'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup11'
              headerName={t('customer.discountPercent') + ' 11'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup12'
              headerName={t('customer.discountPercent') + ' 12'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup13'
              headerName={t('customer.discountPercent') + ' 13'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup14'
              headerName={t('customer.discountPercent') + ' 14'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup15'
              headerName={t('customer.discountPercent') + ' 15'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup16'
              headerName={t('customer.discountPercent') + ' 16'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup17'
              headerName={t('customer.discountPercent') + ' 17'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup18'
              headerName={t('customer.discountPercent') + ' 18'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup19'
              headerName={t('customer.discountPercent') + ' 19'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    max: 100,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='discountOnGroup20'
              headerName={t('customer.discountPercent') + ' 20'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='invoicingIntervalId'
              headerName={t('customer.termOfPaymentInDays')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.invoiceIntervals,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.invoiceIntervals,
                  }}
                />
              )}
            />
            <Column
              field='debitorStatus'
              headerName={t('customer.debtor')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.debitorStatuses,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.debitorStatuses,
                  }}
                />
              )}
            />
            <Column
              field='returnLimit'
              headerName={t('customer.returnLimitIn')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='eInvoiceFormat'
              headerName={t('customer.e_invoice_format')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.eInvoiceFormats,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.eInvoiceFormats,
                  }}
                />
              )}
            />
            <Column
              field='paymentPeriod'
              headerName={t('customer.paymentDeadline')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='individualPaymentTerms'
              headerName={t('customer.termOfPaymentInDays')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='skonto'
              headerName={t('customer.cashDiscountIn')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='skontoPeriod'
              headerName={t('customer.cashDiscount')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='besrBankId'
              headerName={t('customer.bank')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.banks,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.banks,
                  }}
                />
              )}
            />
            <Column
              field='debtorAccount'
              headerName={t('customer.debtorAccount')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='slipReferenceNo'
              headerName={t('customer.slipReference')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='directDebitBankId'
              headerName={t('customer.debit')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.directDebitBanks,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.directDebitBanks,
                  }}
                />
              )}
            />
            <Column
              field='bank1Name'
              headerName={t('customer.bankName') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank1BIN'
              headerName={t('customer.bin') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 16,
                  }}
                />
              )}
            />
            <Column
              field='bank1AccountNo'
              headerName={t('customer.accountNo') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank1IBAN'
              headerName={t('customer.iban') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank1BIC'
              headerName={t('customer.bic') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank1MandatNo'
              headerName={t('customer.mandatNumber') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank1MandatSignatureDate'
              headerName={t('customer.dateOfMandatSignature') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank2Name'
              headerName={t('customer.bankName') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank2BIN'
              headerName={t('customer.bin') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 16,
                  }}
                />
              )}
            />
            <Column
              field='bank2AccountNo'
              headerName={t('customer.accountNo') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank2IBAN'
              headerName={t('customer.iban') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank2BIC'
              headerName={t('customer.bic') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank2MandatNo'
              headerName={t('customer.mandatNumber') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='bank2MandatSignatureDate'
              headerName={t('customer.dateOfMandatSignature') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredMon'
              headerName={t('common.mon')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredTue'
              headerName={t('common.tue')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredWed'
              headerName={t('common.wed')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredThu'
              headerName={t('common.thu')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredFri'
              headerName={t('common.fri')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredSat'
              headerName={t('common.sat')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliveredSun'
              headerName={t('common.sun')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='defaultOrderSourceId'
              headerName={t('customer.select_default_setting')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.customerDefOrderSources,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.customerDefOrderSources,
                  }}
                />
              )}
            />
            <Column
              field='isAllowedAutoOrder'
              headerName={t('customer.createAutomaticallyFromPresetting')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='isDeliverySplitting'
              headerName={t('customer.enablePartialDelivery')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='deliverySplittingPartsCount'
              headerName={t('customer.numberOfPieces')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='isFlexibleOrders'
              headerName={t('customer.flexible_orders')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='distributionLowerLimit'
              headerName={t('customer.limit_for_SmartPicking') + ' ' + t('common.from')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='distributionHigherLimit'
              headerName={t('customer.limit_for_SmartPicking') + ' ' + t('common.to')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='transportSectorId'
              headerName={t('settings.post_sectors_one')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.postSectors,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.postSectors,
                  }}
                />
              )}
            />
            <Column
              field='isAlwaysInTourplan'
              headerName={t('customer.addInTourIfNoDelivery')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='tourplanNote'
              headerName={t('customer.tourNote')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='tourplanId'
              headerName={t('customer.standardTour')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.tourplans,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.tourplans,
                  }}
                />
              )}
            />
            <Column
              field='tourPreparationArea'
              headerName={t('customer.setUpStation')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 16,
                  }}
                />
              )}
            />
            <Column
              field='tourDepartureTime'
              headerName={t('customer.deliveryTime')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='tourPosition'
              headerName={t('customer.drivingPos')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='tourplanSatId'
              headerName={t('customer.saturdayTour')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.tourplans,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.tourplans,
                  }}
                />
              )}
            />
            <Column
              field='tourSatPreparationArea'
              headerName={t('customer.setUpStationSat')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 16,
                  }}
                />
              )}
            />
            <Column
              field='tourSatDepartureTime'
              headerName={t('customer.deliveryTime') + ' ' + t('common.sat')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='tourSatPosition'
              headerName={t('customer.drivingPos') + ' ' + t('common.sat')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='tourplanSunId'
              headerName={t('customer.sundayTour')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.tourplans,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.tourplans,
                  }}
                />
              )}
            />
            <Column
              field='tourSunPreparationArea'
              headerName={t('customer.setUpStationSun')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 16,
                  }}
                />
              )}
            />
            <Column
              field='tourSunDepartureTime'
              headerName={t('customer.deliveryTime') + ' ' + t('common.sun')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 32,
                  }}
                />
              )}
            />
            <Column
              field='tourSunPosition'
              headerName={t('customer.drivingPos') + ' ' + t('common.sun')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='defaultNoteInOrder'
              headerName={t('customer.delivery_note_comments') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='defaultNoteInOrder2'
              headerName={t('customer.delivery_note_comments') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='defaultNoteInInvoice'
              headerName={t('customer.invoice_comments') + ' 1'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='defaultNoteInInvoice2'
              headerName={t('customer.invoice_comments') + ' 2'}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                  }}
                />
              )}
            />
            <Column
              field='deliveryNoteCopies'
              headerName={t('customer.copy_other') + ' ' + t('common.order')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='invoiceCopies'
              headerName={t('customer.copy_other') + ' ' + t('common.invoice')}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Column
              field='eBillAccount'
              headerName={t('customer.e_bill_account_id')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderEditCell={(params) => (
                <AnyTextFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    maxLength: 40,
                  }}
                />
              )}
            />
            <Column
              field='invoiceReportId'
              headerName={t('common.invoice') + ' ' + t('common.print_form')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.reports,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.reports,
                  }}
                />
              )}
            />
            <Column
              field='invoiceSummaryReportId'
              headerName={t('customer.summary_form')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.reports,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.reports,
                  }}
                />
              )}
            />
            <Column
              field='invoicePaymentSlipReportId'
              headerName={t('customer.payment_slip_form')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.reports,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.reports,
                  }}
                />
              )}
            />
            <Column
              field='orderReportId'
              headerName={t('common.order') + ' ' + t('common.print_form')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.reports,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.reports,
                  }}
                />
              )}
            />
            <Column
              field='offerReportId'
              headerName={t('common.offer') + ' ' + t('common.print_form')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.reports,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.reports,
                  }}
                />
              )}
            />
            <Column
              field='isCashAssistCreditCustomer'
              headerName={t('customer.canBuyOnCreditOnCashAssist')}
              width={200}
              sortable={false}
              align='center'
              renderCell={(params) => (
                <AnyBooleanFieldCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    editable: isAllDataShown,
                  }}
                />
              )}
            />
            <Column
              field='caPriceAndDiscountSourceId'
              headerName={t('customer.takeReductionsAndPricesFrom')}
              width={200}
              sortable={false}
              editable={isAllDataShown}
              renderCell={(params) => (
                <AnyDropdownFieldCell
                  {...{
                    params: params,
                    options: options.discountsAndPricesKinds,
                  }}
                />
              )}
              renderEditCell={(params) => (
                <AnyDropdownFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    options: options.discountsAndPricesKinds,
                  }}
                />
              )}
            />
            <Column
              field='caSpecialDiscount'
              headerName={t('common.discount') + ' ' + 'CA'}
              width={200}
              sortable={false}
              valueFormatter={(param) => (param.value ? param.value + '%' : '')}
              renderEditCell={(params) => (
                <AnyNumberFieldEditCell
                  {...{
                    params: params,
                    data: gridFullData,
                    setData: setGridFullData,
                    min: 0,
                  }}
                />
              )}
              editable={isAllDataShown}
              headerAlign='right'
              align='right'
            />
            <Footer
              numberRows={gridFullData.length}
              legendData={legendData}
              titleRows={t('customer.customers', { count: gridFullData.length })}
              height={36}
            />
          </Table>
          {!isAllDataShown && (
            <Button
              onClick={handleShowAll}
              variant='outlined'
              size='small'
              sx={{ width: '200px', marginTop: '16px', margin: '16px auto 0 auto' }}
            >
              {t('common.show_all') + ' / ' + t('customer.editing')}
            </Button>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

interface ICustomerTable {
  dataFromFile: ICustomerImportData[];
  heigthWhenCollapsed: number;
  shownRecordsCountWhenCollapsed: number;
  existingNumbers: string[];
  options: IOptionsData;
}
