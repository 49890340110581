import { map, Observable, switchMap } from 'rxjs';
import {
  GetToolboxConfigDataQuery,
  PrepareToolboxExportFilesQuery,
  PrepareToolboxExportFilesQueryVariables,
  SaveConfigDataMutation,
  SaveConfigDataMutationVariables,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { exportToolbox, getToolboxArticleListsFilter } from './gql/toolbox.gql';
import { saveConfigData } from '../../../shared/services/companyConfig/gql/getConfigs.gql';

class PubImpl extends Pub<Actions> {
  getToolboxArticleListsFilter(): void {
    this.emit('getToolboxArticleListsFilter');
  }
  saveToolboxArticleListsFilter(params: SaveConfigDataMutationVariables): void {
    this.emit('saveToolboxArticleListsFilter', params);
  }
  exportToolbox(params: PrepareToolboxExportFilesQueryVariables): void {
    this.emit('exportToolbox', params);
  }
}

class SubImpl extends Sub<Actions> {
  getToolboxArticleListsFilter(): Observable<ToolboxArticleListsFilterRes> {
    return this.actionListener('getToolboxArticleListsFilter').pipe(
      switchMap(
        () => gqlClient(getToolboxArticleListsFilter) as Observable<GetToolboxConfigDataQuery>,
      ),
      map(
        (res) =>
          res.wawiAssist?.getConfigData
            .toolboxExportArticleListsFilterCheckedState as ToolboxArticleListsFilterRes,
      ),
    );
  }

  saveToolboxArticleListsFilter(): Observable<SaveToolboxArticleListsFilterRes> {
    return this.actionListener('saveToolboxArticleListsFilter').pipe(
      switchMap(
        ({ params }) => gqlClient(saveConfigData, params) as Observable<SaveConfigDataMutation>,
      ),
      map((res) => res.wawiAssist?.saveConfigData as SaveToolboxArticleListsFilterRes),
    );
  }

  exportToolbox(): Observable<ExportToolboxRes> {
    return this.actionListener('exportToolbox').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(exportToolbox, params) as Observable<PrepareToolboxExportFilesQuery>,
      ),
      map((res) => res.wawiAssist?.prepareToolboxExportFiles as ExportToolboxRes),
    );
  }
}

class ToolboxService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const toolboxService = new ToolboxService();

type Actions = 'getToolboxArticleListsFilter' | 'saveToolboxArticleListsFilter' | 'exportToolbox';

export type ToolboxArticleListsFilterRes = NonNullable<
  NonNullable<
    NonNullable<GetToolboxConfigDataQuery['wawiAssist']>['getConfigData']
  >['toolboxExportArticleListsFilterCheckedState']
>;
type SaveToolboxArticleListsFilterRes = NonNullable<
  NonNullable<SaveConfigDataMutation['wawiAssist']>['saveConfigData']
>;
export type ExportToolboxRes = NonNullable<
  NonNullable<PrepareToolboxExportFilesQuery['wawiAssist']>['prepareToolboxExportFiles']
>;
