import { FC, ForwardedRef, forwardRef, useCallback, useMemo, useRef } from 'react';
import {
  ParagraphExtension,
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  FontSizeExtension,
  TextColorExtension,
  NodeFormattingExtension,
} from 'remirror/extensions';
import {
  Remirror,
  EditorComponent,
  Toolbar,
  BasicFormattingButtonGroup,
  useRemirror,
} from '@remirror/react';
import { ClickAwayListener } from '@mui/material';

import { FontSizePicker } from './components/fontSizePicker.component';
import { ColorPicker } from './components/colorPicker.component';
import 'remirror/styles/all.css';
import { valueHelper } from '../../helpers/valueProcessor/value.helper.ts';
import styled from '@mui/material/styles/styled';
import { AlignmentButtons } from './components/alignButtons/alignButtons.component.tsx';
import { prosemirrorNodeToHtml } from 'remirror';
import { useUpdateFromOutside } from './components/alignButtons/hooks/useUpdateFromOutside.ts';
import { EditorState, Transaction } from '@remirror/pm/state';
import { EditorView } from '@remirror/pm/view';

export const TextEditor: FC<ITextEditorProps> = forwardRef(
  (
    { defaultValue, onChange, onBlur, defaultFormat, disabled, toolbarItems, minHeight },
    ref: ForwardedRef<null>,
  ) => {
    const { fontSize: defaultFontSize } = defaultFormat || {};
    const paragraphExtension = useMemo(
      () =>
        new ParagraphExtension({
          ...(defaultFontSize && {
            extraAttributes: {
              // Add default inline styles for every new <p> (on Enter press f.e.) and for every <p> without default styles in html defaultValue
              style: {
                default: () => `font-size: ${defaultFontSize}pt;`,
                toDOM: ({ style }) => {
                  return (style as string)?.includes?.('font-size') && // Don't add default font-size style if <p> is already has it.
                    !(style as string)?.includes?.(`font-size: ${defaultFontSize}pt`) // Workaround to add inline font-size for new <p> (on Enter press f.e.)
                    ? ''
                    : `font-size: ${defaultFontSize}pt;`;
                },
              },
            },
          }),
        }),
      [defaultFontSize],
    );
    const { state, manager, setState } = useRemirror({
      extensions: () => [
        paragraphExtension,
        new FontSizeExtension({
          ...(defaultFontSize && { defaultSize: defaultFontSize.toString(), unit: 'pt' }),
        }),
        new BoldExtension({}),
        new ItalicExtension(),
        new UnderlineExtension(),
        new TextColorExtension({}),
        new NodeFormattingExtension({}),
      ],
      content: defaultValue,
      stringHandler: 'html',
    });

    const value = prosemirrorNodeToHtml(state.doc);
    useUpdateFromOutside({ manager, setState, defaultValue, value });

    const hasBlured = useRef(false);
    const handleChange = useCallback(
      ({ state, tr: transaction }: IChangeArgs) => {
        setState(state);
        if (transaction?.docChanged) {
          const html = prosemirrorNodeToHtml(state.doc);
          onChange?.(valueHelper.checkValueInHtml(html) ? html : null);
        }
      },
      [onChange, setState],
    );
    const handleClickOutside = useCallback(() => {
      if (hasBlured.current) {
        // Run handler only on the first click outside (ignore subsequent clicks)
        hasBlured.current = false;
        if (value) {
          onBlur?.(valueHelper.checkValueInHtml(value) ? value : null);
        }
      }
    }, [hasBlured.current, value]);

    return (
      <ClickAwayListener onClickAway={handleClickOutside}>
        <SRemirrorBox
          className='remirror-theme'
          onBlur={() => {
            hasBlured.current = true;
          }}
          onFocus={() => {
            hasBlured.current = false;
          }}
          {...{ toolbarItems, minHeight, ref }}
        >
          <Remirror manager={manager} onChange={handleChange} state={state} editable={!disabled}>
            {!disabled && (
              <Toolbar sx={{ backgroundColor: 'transparent', maxHeight: '28px' }}>
                <BasicFormattingButtonGroup />
                {(!toolbarItems || toolbarItems.includes('size')) && (
                  <FontSizePicker
                    fontSizes={FONT_SIZES}
                    defaultFontSize={defaultFontSize?.toString()}
                  />
                )}
                {(!toolbarItems || toolbarItems.includes('color')) && (
                  <ColorPicker colors={FONT_COLORS} />
                )}
                {toolbarItems?.includes?.('alignment') && <AlignmentButtons />}
              </Toolbar>
            )}
            <EditorComponent />
          </Remirror>
        </SRemirrorBox>
      </ClickAwayListener>
    );
  },
);

const FONT_SIZES = ['8', '10', '12', '14', '16', '18', '20', '24', '30'];
// prettier-ignore
const FONT_COLORS = [
	'#000000', '#D32D1F', '#F19D38', '#FFFE54', '#3C8725', '#2B66C5', '#8D41F6',
	'#FFFFFF', '#F3CECD', '#FCECCF', '#FFFED1', '#D1E7CE', '#D0DFF3', '#E7D7FC',
	'#BBBBBB', '#DF6E6A', '#F6C375', '#FFFE7E', '#7AB66F', '#74A2DB', '#B989F8',
	'#888888', '#931C12', '#A86D25', '#B2B138', '#285E17', '#1C48AB', '#632EAB',
	'#444444', '#540C06', '#603F11', '#66651C', '#143609', '#0D2962', '#381962'
]

export interface ITextEditorProps {
  onChange?: (v: string | null) => void;
  onBlur?: (v: string | null) => void; // Fired only in "dirty" state (i.e. if value was changed)
  defaultValue?: string;
  defaultFormat?: {
    fontSize?: number;
  };
  disabled?: boolean;
  toolbarItems?: TToolbar[];
  minHeight?: number;
}

type TToolbar = 'bold' | 'italic' | 'size' | 'color' | 'underline' | 'alignment';

interface IChangeArgs {
  tr?: Transaction;
  state: EditorState;
  previousState: EditorState;
  internalUpdate: boolean;
  firstRender: boolean;
  view: EditorView;
}

const SRemirrorBox = styled('div')<{
  toolbarItems: ITextEditorProps['toolbarItems'];
  minHeight: ITextEditorProps['minHeight'];
}>(({ theme, toolbarItems, minHeight }) => ({
  '& .ProseMirror': {
    boxShadow: `${theme.palette.grey['400']} 0px 0px 0px 0.06em`,
    ...(typeof minHeight === 'number' && { minHeight: `${minHeight}px` }),
    '&:hover': {
      boxShadow: `${theme.palette.black.main} 0px 0px 0px 0.06em`,
    },
    '&:active, &:focus': {
      boxShadow: `${theme.palette.blue.dark} 0px 0px 0px 0.11em`,
    },
  },
  ...(toolbarItems &&
    !toolbarItems.includes('bold') && {
      '& [aria-label="Bold (⌘B)"]': {
        display: 'none',
      },
    }),
  ...(toolbarItems &&
    !toolbarItems.includes('italic') && {
      '& [aria-label="Italic (⌘I)"]': {
        display: 'none',
      },
    }),
  ...(toolbarItems &&
    !toolbarItems.includes('underline') && {
      '& [aria-label="Underline (⌘U)"]': {
        display: 'none',
      },
    }),
  ...(toolbarItems &&
    !toolbarItems.length && {
      '& .remirror-editor-wrapper': {
        paddingTop: 0,
      },
    }),
}));
