import React, { MutableRefObject } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { delayFn, shouldHorizontalScroll } from '../functions/sharedFunc.ts';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { GridTreeNode, MuiEvent, GridCellMode } from '@mui/x-data-grid-premium';
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';

export const useCustomArrowNavigation = ({ apiRef }: IUseCustomArrowNavigation) => {
  const {
    setCellFocus,
    getSortedRows,
    setColumnHeaderFocus,
    getAllColumns,
    scroll,
    getColumnPosition,
    getColumn,
    getScrollPosition,
    getRootDimensions,
  } = apiRef.current;

  return {
    customArrowHandlerFn: (args: ICustomArrowHandlerFnArgs) => {
      const { params, event, handleNextRow, additionalParams } = args;
      const { ignoreVirtualPositionInBottom } = additionalParams;
      const { id, field, cellMode } = params;

      const rows = getSortedRows();
      const currentIndex = rows.findIndex((el) => el.id === id);
      const prevRow = rows[currentIndex - 1];
      const nextRow = rows[currentIndex + 1];
      switch (event.code) {
        case 'ArrowUp':
          if (!!prevRow) {
            handleNextRow?.({ nextRow: prevRow, cellMode, creatingNewRow: false });
            cellMode === 'edit' && setCellFocus(prevRow.id, field);
          } else {
            setColumnHeaderFocus(field);
            delayFn(() => {
              setCellFocus(rows[currentIndex].id, field);
            });
          }
          break;
        case 'ArrowDown':
          const additionalCondition = ignoreVirtualPositionInBottom
            ? !nextRow?.virtualPositionId
            : true;
          if (nextRow && additionalCondition) {
            handleNextRow?.({ nextRow, cellMode, creatingNewRow: false });
            cellMode === 'edit' && setCellFocus(nextRow?.id, field);
          } else {
            handleNextRow?.({ cellMode, creatingNewRow: true });
            return field;
          }
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          const containerWidth = getRootDimensions()?.viewportInnerSize?.width || 0;
          const { left: scrollLeft } = getScrollPosition();
          const columnModel = getAllColumns().reduce<Array<string>>((acc, item) => {
            if (item.field !== '__reorder__') {
              acc.push(item.field);
            }
            return acc;
          }, []);
          const columnIndex = columnModel.findIndex((el) => el === field);
          if (event.code === 'ArrowLeft') {
            const prevColumnField = columnModel[columnIndex - 1];
            if (prevColumnField) {
              const { width: columnWidth = 0 } = getColumn(prevColumnField);
              const columnLeft = getColumnPosition(prevColumnField);
              const needToScroll = shouldHorizontalScroll({
                scrollLeft,
                columnLeft,
                columnWidth,
                containerWidth,
              });
              delayFn(() => {
                setCellFocus(id, prevColumnField);
                if (needToScroll) {
                  scroll({ left: getColumnPosition(prevColumnField) });
                }
              }, 100);
            } else if (prevRow) {
              const prevColumnField = columnModel[columnModel.length - 1];
              handleNextRow?.({ nextRow: prevRow, cellMode, creatingNewRow: false });
              delayFn(() => {
                setCellFocus(prevRow.id, prevColumnField);
                scroll({ left: getColumnPosition(prevColumnField) });
              }, 100);
            }
          }
          if (event.code === 'ArrowRight') {
            const nextColumnField = columnModel[columnIndex + 1];
            if (nextColumnField) {
              const { width: columnWidth = 0 } = getColumn(nextColumnField);
              const columnLeft = getColumnPosition(nextColumnField);
              const needToScroll = shouldHorizontalScroll({
                scrollLeft,
                columnLeft,
                columnWidth,
                containerWidth,
              });
              delayFn(() => {
                setCellFocus(id, nextColumnField);
                if (needToScroll) {
                  scroll({ left: columnLeft });
                }
              }, 100);
            } else if (nextRow) {
              const nextColumnField = columnModel[0];
              handleNextRow?.({ nextRow, cellMode, creatingNewRow: false });
              delayFn(() => {
                setCellFocus(nextRow.id, nextColumnField);
                scroll({ left: getColumnPosition(nextColumnField) });
              }, 100);
            } else {
              const firstColumnField = columnModel[0];
              handleNextRow?.({ nextRow, cellMode, creatingNewRow: true });
              delayFn(() => {
                scroll({ left: getColumnPosition(firstColumnField) });
              }, 100);
              return firstColumnField;
            }
          }
          break;
      }
    },
  };
};

interface IUseCustomArrowNavigation {
  apiRef: MutableRefObject<GridApiPremium>;
}

interface ICustomArrowHandlerFnArgs {
  params: GridCellParams<any, unknown, unknown, GridTreeNode>;
  event: MuiEvent<React.KeyboardEvent<HTMLElement>>;
  handleNextRow?: (args: {
    nextRow?: GridRowModel;
    creatingNewRow?: boolean;
    cellMode: GridCellMode;
  }) => void | string;
  additionalParams: {
    ignoreVirtualPositionInBottom?: boolean;
  };
}
