import { C_Country, C_Special_Price_Type, C_Tax_System } from '../../../graphql/generatedModel.ts';
import {
  DictArticlesRes,
  DictBanksRes,
  DictCustomerGroupsRes,
  DictCustomerProfilesRes,
  DictCustomersRes,
  DictDeliveryNoteGroupsRes,
  DictDiscountGroupsRes,
  DictPostSectorsRes,
  DictPriceCategoriesRes,
  DictQuantityDiscountsRes,
  DictTourplansRes,
} from '../../../shared/services/configsData/configsData.service';
import {
  IDefaultOrderState,
  initDefaultOrderState,
} from '../components/tabs/defaultOrder/defaultOrder.state.ts';
import { CustomerConditionsRes } from '../services/customer.service.ts';

export interface IGeneralTabOptions {
  customerGroups?: DictCustomerGroupsRes;
  deliveryNoteGroups?: DictDeliveryNoteGroupsRes;
  customerDefaultProfiles?: DictCustomerProfilesRes;
}
export interface IDefaultOrderTabOptions {
  productList?: DictArticlesRes;
}

export interface IConditionsTabOptions {
  priceCategories?: DictPriceCategoriesRes;
  productList?: DictArticlesRes;
  customers?: DictCustomersRes;
  banks?: DictBanksRes;
  discountGroups?: DictDiscountGroupsRes;
  quantityDiscounts?: DictQuantityDiscountsRes;
}
export interface ITourTabOptions {
  tourplanList?: DictTourplansRes;
  postSectorsList?: DictPostSectorsRes;
}

export enum CustomerTabs {
  general = 'general',
  forms = 'forms',
  lists = 'lists',
  availability = 'availability',
  tour = 'tour',
  cashAssist = 'cashAssist',
  defaultOrder = 'defaultOrder',
  conditions = 'conditions',
}
export interface ITabState extends IConditionsTabData {
  customerId: string;
  defaultValues: any;
  generalTabOptions: IGeneralTabOptions;
  defaultOrderTabOptions: IDefaultOrderTabOptions;
  defaultOrderTabData: IDefaultOrderState;
  configData?: {
    globalTaxSystemId: C_Tax_System;
    countryId: C_Country;
  };
  tourTabOptions: ITourTabOptions;
}
interface IConditionsTabData {
  conditionsTabOptions: IConditionsTabOptions;
  customerSpecialPrices: ICustomerSpecialPrices[];
  customerResellerPrices: CustomerConditionsRes['customerResellerPrices'];
}
export const tabStateModel: ITabState = {
  customerId: '',
  defaultValues: {},
  generalTabOptions: {},
  defaultOrderTabOptions: {},
  tourTabOptions: {},
  defaultOrderTabData: initDefaultOrderState,
  conditionsTabOptions: {},
  customerSpecialPrices: [],
  customerResellerPrices: [],
};

export type CustomerConditionsPos = CustomerConditionsRes['customerSpecialPrices'][number];
interface ICustomerSpecialPrices extends Omit<CustomerConditionsPos, 'specialPriceType'> {
  specialPriceType: C_Special_Price_Type | null;
}
