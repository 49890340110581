import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { IImagePickerProps, ImagePicker } from '../../imagePicker/imagePicker.component';

export const FormImagePicker: FC<IFormImagePickerProps> = ({ name, onUpdate, ...props }) => {
  return (
    <Controller
      name={name}
      render={({ field: { value, onChange: onFieldChange } }) => {
        return (
          <ImagePicker
            base64ImageURL={value || undefined}
            onUpdate={(imageStringCode) => {
              onFieldChange(imageStringCode);
              onUpdate?.(imageStringCode);
            }}
            onDelete={() => {
              onFieldChange('');
              onUpdate?.('');
            }}
            {...props}
          />
        );
      }}
    />
  );
};

interface IFormImagePickerProps extends Partial<IImagePickerProps> {
  name: string;
}
