import { GridSortModel } from '@mui/x-data-grid-premium';
import { BehaviorSubject, Observable, map, merge, of, share, switchMap, tap } from 'rxjs';
import {
  C_Global_Error_Code,
  DeleteWa_InvoiceMutationVariables as DeleteInvoiceMutationVariables,
  C_Created_Invoices_Filter_Mode as FilterMode,
  ListWa_CreatedInvoicesQueryVariables as filterVariables,
} from '../../../../graphql/generatedModel';

import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service';
import { dataHelper } from '../../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../../shared/helpers/storage';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import { ITextForSnackbar } from '../../create/states/invoiceDetails.state';
import {
  CreatedInvoicesRes,
  DeleteInvoiceRes,
  ExportAbacusRes,
  invoiceListService,
} from '../../services/invoiceList.service';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import i18n from '../../../../i18n/init';

export const initInvoiceListState: IInvoiceListState = {
  action: undefined,
  createdInvoicesList: [],
  createdInvoicesListLength: 0,
  filter: {
    filterBy: FilterMode.CURRENT_MONTH,
    withDeleted: false,
    emailState: null,
    customerId: undefined,
    customerGroupId: undefined,
    customerListId: undefined,
    invoicesNoRange: undefined,
    date: undefined,
    dateTo: undefined,
  },
  sortModel: [{ field: 'invoiceNo', sort: 'asc' }],
  search: '',
  selectedInvoice: null,
};

class PubImpl extends Pub<IInvoiceListState> {
  init(params: Partial<IInvoiceListState>) {
    this.emit('init', params);
  }
  getCreatedInvoices() {
    const { filter } = this.stream$.getValue();
    this.emit('getCreatedInvoices', { filter });
  }
  filter(filter: TFilter, shouldPerformRequest = true) {
    invoiceListState.sub.shouldPerformRequest$.next(shouldPerformRequest);
    this.emit('filter', { filter });
  }
  sort(sortModel: GridSortModel) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('invoiceList.sortModel', sortModel);
  }
  search(search: string) {
    this.emit('search', { search });
  }
  selectInvoice(selectedInvoice: IInvoiceListState['selectedInvoice']) {
    this.emit('selectInvoice', { selectedInvoice });
    storageHelper.session.setItem('invoiceList.selectedInvoice', selectedInvoice);
  }
  deleteInvoice(params: DeleteInvoiceMutationVariables, snackBarText: ITextForSnackbar) {
    this.emit('deleteInvoice', { params, snackBarText });
  }
  abacusExportInvoices(invoicesIdsForExport: string[]) {
    invoiceListService.pub.abacusExport({
      params: { invoiceIds: invoicesIdsForExport },
    });
    this.emit('abacusExportInvoices', {});
  }
}

class SubImpl extends Sub<IInvoiceListState> {
  public shouldPerformRequest$ = new BehaviorSubject<boolean>(false);
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<IInvoiceListState> {
    return merge(
      this.updateState(),
      this.getCreatedInvoices(),
      this.deleteInvoice(),
      this.abacusExportInvoices(),
    ).pipe(
      map(({ createdInvoicesList, createdInvoicesListLength, sortModel, search, ...rest }) => {
        let sortedList = [...(createdInvoicesList || [])];
        let selectedInvoice = rest.selectedInvoice;
        let action = rest.action;
        createdInvoicesListLength = createdInvoicesList.length || 0;
        createdInvoicesList = dataHelper
          .data(createdInvoicesList as [])
          .sort({
            sortModel,
            callback: (data) => {
              sortedList = data as [];
            },
          })
          .search({
            search,
            fields: [
              'invoiceNo',
              'customerNo',
              'internalOrFullName',
              'invoiceTotal',
              ['invoiceDate'],
            ],
          })
          .result() as CreatedInvoicesRes;
        // Check if selectedRecord is in new list. Otherwise, select the first one from the list.
        if (
          rest.action === 'getCreatedInvoices' ||
          rest.action === 'filter' ||
          rest.action === 'deleteInvoice'
        ) {
          const isSelectedInvoiceInList = !!createdInvoicesList.find(
            (item) => item?.invoiceId === selectedInvoice?.invoiceId,
          );
          if (!isSelectedInvoiceInList) {
            selectedInvoice = createdInvoicesList[0] || null;
            action = 'selectInvoice';
            storageHelper.session.setItem('invoiceList.selectedInvoice', selectedInvoice);
          }
        }
        const newState = {
          ...rest,
          search,
          createdInvoicesList,
          createdInvoicesListLength,
          sortModel,
          action,
          selectedInvoice,
        };
        this.stream$.next({
          ...newState,
          createdInvoicesList: sortedList,
          action: 'internalUpdate',
        });
        return newState;
      }),
      tap(() => this.loading$.next(false)),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  private getCreatedInvoices(): Observable<IInvoiceListState> {
    return this.actionListener(['getCreatedInvoices', 'filter']).pipe(
      switchMap((state) => {
        const shouldPerformRequest = !(
          state.action === 'filter' && !this.shouldPerformRequest$.getValue()
        );
        if (shouldPerformRequest) {
          this.loading$.next(true);
          invoiceListService.pub.createdInvoicesList({ filter: state.filter });
          this.shouldPerformRequest$.next(false);
          return invoiceListService.sub.createdInvoicesList().pipe(
            responseHandler<CreatedInvoicesRes>({
              customErrorHandler: () => 'invoice.error_failed_to_load_created_invoice_list',
              errorReturnType: [],
            }),
            map((invoiceList) => ({
              ...state,
              createdInvoicesList: [...invoiceList],
            })),
          );
        } else {
          this.shouldPerformRequest$.next(false);
          return of(state);
        }
      }),
    );
  }

  private deleteInvoice(): Observable<IInvoiceListState> {
    return this.actionListener('deleteInvoice').pipe(
      tap(({ params, snackBarText }) => {
        if (params && snackBarText) {
          const { loading } = snackBarText;
          snackbarService.pub.show({
            content: loading,
            type: 'loading',
            noAutoHide: true,
          });
          invoiceListService.pub.deleteInvoice({
            ...params,
          });
        }
      }),
      switchMap((state) => {
        return invoiceListService.sub.deleteInvoice().pipe(
          responseHandler<DeleteInvoiceRes>({
            customErrorHandler: () => 'invoice.error_failed_to_delete_entry',
            errorReturnType: false,
          }),
          map((data) => {
            if (state.snackBarText) {
              const { success, error } = state.snackBarText;
              snackbarService.pub.hide();
              if (data) {
                this.loading$.next(true);
                snackbarService.pub.show({
                  content: success,
                  type: 'success',
                });
                const { createdInvoicesList, selectedInvoice } = state;
                const newList = createdInvoicesList.filter(
                  (invoice) => invoice?.invoiceId !== selectedInvoice?.invoiceId,
                );
                return {
                  ...state,
                  createdInvoicesList: newList,
                };
              } else {
                snackbarService.pub.show({
                  content: error,
                  type: 'error',
                });
              }
            }
            return state;
          }),
        );
      }),
    );
  }

  private abacusExportInvoices(): Observable<IInvoiceListState> {
    return this.actionListener('abacusExportInvoices').pipe(
      tap(() => {
        snackbarService.pub.show({
          type: 'loading',
          content: i18n.t('common.data_processing'),
          noAutoHide: true,
        });
      }),
      switchMap((stateV) => {
        return invoiceListService.sub.abacusExport().pipe(
          responseHandler<ExportAbacusRes>({
            errorReturnType: { url: '' },
            customErrorHandler: (err) => {
              snackbarService.pub.hide();
              return err.code === C_Global_Error_Code.GEC20_NO_DATA ? 'invoice.no_data' : undefined;
            },
          }),
          map((exportRes) => {
            if (exportRes.url) {
              snackbarService.pub.hide();
              // Update list since invoices were marked as exported
              invoiceListState.pub.getCreatedInvoices();
              stateV.abacusExportUrl = exportRes.url;
            } else {
              stateV.action = undefined;
            }
            return stateV;
          }),
        );
      }),
    );
  }

  private updateState(): Observable<IInvoiceListState> {
    return this.actionListener(['sort', 'search', 'selectInvoice']);
  }
}

class InvoiceListState extends State<IInvoiceListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const invoiceListState = new InvoiceListState(initInvoiceListState);

export type TFilter = filterVariables['filter'];
export interface IInvoiceListState {
  action:
    | 'init'
    | 'internalUpdate'
    | 'loader'
    | 'getCreatedInvoices'
    | 'filter'
    | 'search'
    | 'sort'
    | 'selectInvoice'
    | 'deleteInvoice'
    | 'removeSelectedInvoice'
    | 'abacusExportInvoices'
    | undefined;
  createdInvoicesList: CreatedInvoicesRes;
  createdInvoicesListLength: number;
  filter: TFilter;
  sortModel: GridSortModel;
  search: string;
  selectedInvoice: CreatedInvoicesRes[number] | null;
  abacusExportUrl?: string;
  params?: any;
  snackBarText?: ITextForSnackbar;
}
