import { map, Observable, switchMap, take, tap, zip } from 'rxjs';

import {
  DeleteWa_OrderMutation,
  DeleteWa_OrderMutationVariables as DeleteOrderVariables,
  GetCustomerInformationQuery as CustomerInfoQuery,
  GetWa_OrderDataOfCreatedOrderQuery,
  GetWa_OrderDataOfCreatedOrderQueryVariables as GetOrderDataVariable,
  ListWa_CreatedOrdersQuery,
  ListWa_CreatedOrdersQueryVariables as CreatedOrdersVariables,
  SaveOrderMutation,
  SaveOrderMutationVariables,
  CreateWa_OrdersFromXmlFileMutation,
  CreateWa_OrdersFromXmlFileMutationVariables,
  Scalars,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  deleteOrder,
  getCreatedOrdersForCustomer,
  getCustomerInformation,
  getOrderDataOfCreatedOrder,
  saveOrder,
  createOrdersFromXMLFile,
} from './gql/editOrder.gql.ts';

type Actions =
  | 'createdOrdersList'
  | 'deleteOrder'
  | 'orderData'
  | 'saveOrder'
  | 'orderDetailsData'
  | 'createOrdersFromXMLFile';

class PubImpl extends Pub<Actions> {
  createdOrdersList(params: CreatedOrdersVariables) {
    this.emit('createdOrdersList', params);
  }
  deleteOrder(params: DeleteOrderVariables) {
    this.emit('deleteOrder', params);
  }
  orderData(params: GetOrderDataVariable) {
    this.emit('orderData', params);
  }
  orderDetailsData(params: OrderDetailsDataParams) {
    this.emit('orderDetailsData', params);
  }
  saveOrder(params: SaveOrderMutationVariables) {
    this.emit('saveOrder', params);
  }
  createOrdersFromXMLFile(params: CreateWa_OrdersFromXmlFileMutationVariables) {
    this.emit('createOrdersFromXMLFile', params);
  }
}

class SubImpl extends Sub<Actions> {
  createdOrdersList(): Observable<CreatedOrdersListRes> {
    return this.actionListener('createdOrdersList').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getCreatedOrdersForCustomer,
          params,
        ) as Observable<ListWa_CreatedOrdersQuery>;
      }),
      map((data: ListWa_CreatedOrdersQuery) => {
        return (data?.wawiAssist?.listWA_CreatedOrders || []) as CreatedOrdersListRes;
      }),
    );
  }
  deleteOrder(): Observable<DeleteOrderRes> {
    return this.actionListener('deleteOrder').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteOrder, params) as Observable<DeleteWa_OrderMutation>;
      }),
      map((data: DeleteWa_OrderMutation) => {
        return data?.wawiAssist?.deleteWA_Order as DeleteOrderRes;
      }),
    );
  }
  orderData(): Observable<IOrderDataRes> {
    return this.actionListener('orderData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getOrderDataOfCreatedOrder,
          params,
        ) as Observable<GetWa_OrderDataOfCreatedOrderQuery>;
      }),
      take(1),
      map((data: GetWa_OrderDataOfCreatedOrderQuery) => {
        const orderData = (data?.wawiAssist?.getWA_OrderDataOfCreatedOrder || {}) as OrderDataRes;
        const { positions } = orderData || {};
        if (positions && positions.length) {
          orderData.positions = positions.map((el, i) => ({
            ...el,
            id: Date.now() + i,
            __reorder__: `${el?.articleNo ? '(' + el?.articleNo + ')' : ''} ${
              el?.description || ''
            }`,
          })) as [];
        }
        return orderData as IOrderDataRes;
      }),
    );
  }
  saveOrder(): Observable<SaveOrderRes> {
    return this.actionListener('saveOrder').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveOrder, params) as Observable<SaveOrderMutation>;
      }),
      map((data) => {
        return data?.wawiAssist?.saveWA_Order as SaveOrderRes;
      }),
    );
  }
  orderDetailsData(): Observable<OrderDetailsDataRes> {
    return this.actionListener('orderDetailsData').pipe(
      tap(({ params }) => {
        editOrderService.pub.orderData({ orderId: params.orderId });
      }),
      switchMap(({ params }) => {
        return zip(
          this.orderData(),
          gqlClient(getCustomerInformation, {
            getCustomerDataId: params.customerId,
          }) as Observable<CustomerInfoQuery>,
        ).pipe(
          map(([orderData, customerData]) => {
            const customerInfo = (customerData?.wawiAssist?.getCustomerData ||
              {}) as CustomerInfoRes;
            return {
              customerInfo,
              orderData,
            } as OrderDetailsDataRes;
          }),
        );
      }),
    );
  }
  createOrdersFromXMLFile(): Observable<CreateOrdersFromXMLFileRes> {
    return this.actionListener('createOrdersFromXMLFile').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          createOrdersFromXMLFile,
          params,
        ) as Observable<CreateWa_OrdersFromXmlFileMutation>;
      }),
      map((data: CreateWa_OrdersFromXmlFileMutation) => {
        return (data?.wawiAssist?.createWA_OrdersFromXMLFile || []) as CreateOrdersFromXMLFileRes;
      }),
    );
  }
}

class EditOrderService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const editOrderService = new EditOrderService();

export type CreatedOrdersListRes = NonNullable<
  NonNullable<ListWa_CreatedOrdersQuery['wawiAssist']>['listWA_CreatedOrders']
>;

export type CreateOrdersFromXMLFileRes = NonNullable<
  NonNullable<CreateWa_OrdersFromXmlFileMutation['wawiAssist']>['createWA_OrdersFromXMLFile']
>;

export type DeleteOrderRes = NonNullable<
  NonNullable<DeleteWa_OrderMutation['wawiAssist']>['deleteWA_Order']
>;

type OrderDataRes = NonNullable<
  NonNullable<GetWa_OrderDataOfCreatedOrderQuery>['wawiAssist']
>['getWA_OrderDataOfCreatedOrder'];

export type SaveOrderRes = NonNullable<
  NonNullable<SaveOrderMutation['wawiAssist']>['saveWA_Order']
>;

export type TOrderData = NonNullable<NonNullable<OrderDataRes>>;
/*  add id type in the order position  */
type TOrderPosition = NonNullable<NonNullable<TOrderData['positions']>[number]>;

export interface IPosition extends TOrderPosition {
  id: number;
  __reorder__: string;
}

export interface IOrderDataRes extends Omit<TOrderData, 'positions'> {
  positions?: (IPosition | null)[];
}
/* end */

type OrderDetailsDataParams = {
  orderId: Scalars['ID'];
  customerId: Scalars['ID'];
};

export type CustomerInfoRes = NonNullable<
  NonNullable<CustomerInfoQuery['wawiAssist']>['getCustomerData']
>;

export type OrderDetailsDataRes = {
  customerInfo: CustomerInfoRes;
  orderData: IOrderDataRes;
};
