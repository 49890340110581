import { yupResolver } from '@hookform/resolvers/yup';
import { FC, FocusEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { Select } from '../../../../../../shared/components/form';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component';
import {
  createNestedObject,
  getJSONValueByPath,
  getParentName,
  mergeDeepObjects,
} from '../../../../../../shared/helpers/utils/utils.helper';
import { ReportsConfigurationRes } from '../../../services/printForms.service';
import {
  IReportsConfigurationTabState,
  reportsConfigurationTabState,
} from '../../../states/reportsConfiguration.state';
import { ITabState } from '../../../states/tabState.model';
import { ArticlePricelistConfig } from './components/articlePricelistConfig';
import { DeliveryNoteA4Config } from './components/deliveryNoteA4Config';
import { InvoiceConfig } from './components/invoiceConfig';
import { DebtReminderConfig } from './components/debtReminderConfig';
import { ReportPreviewArea } from './components/reportPreviewArea';
import { createValidationSchema } from './reportsConfiguration.schema';
import { Content } from '../../../../../../shared/components/content/content.component';
import { Box, Stack, styled } from '@mui/material';

export enum ConfigurablePrintFormTypes {
  ARTICLE_PRICELIST,
  DELIVERY_NOTE_A4,
  INVOICE,
  DEBT_REMINDER,
}

const validationSchema = createValidationSchema();

export const ReportsConfigurationTab: FC = () => {
  const defaultPrintFormType = ConfigurablePrintFormTypes.ARTICLE_PRICELIST;
  const [printFormType, setPrintFormType] = useState(defaultPrintFormType);
  const [expandedState, setExpandedState] = useState(true);

  const { t } = useTranslation();
  const { inputDataForReportsPreview, reportConfiguration: initTabData } =
    useLoaderData() as ITabState;

  const formMethods = useForm<ReportsConfigurationRes>({
    mode: 'onChange',
    defaultValues: initTabData,
    resolver: yupResolver(validationSchema),
  });

  const {
    watch,
    reset,
    formState: { errors, dirtyFields, isDirty },
  } = formMethods;

  useEffect(() => {
    const formStateSub = watch((data, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldPath = reason.name;
        const fieldValue = getJSONValueByPath(data, fieldPath);
        const dataToSave = createNestedObject(fieldPath, fieldValue);
        reportsConfigurationTabState.pub.recordData(dataToSave);
      }
    });

    const reportsConfigurationTabStateSub = reportsConfigurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        reset((currentValues) => {
          const res = mergeDeepObjects(currentValues, dataToSave);
          return res;
        });
      });

    return () => {
      formStateSub.unsubscribe();
      reportsConfigurationTabStateSub.unsubscribe();
    };
  }, []);

  const printFormsTypesOptions: ISelectOption[] = useMemo(
    () => [
      {
        id: ConfigurablePrintFormTypes.ARTICLE_PRICELIST,
        label: t('settings.print_form_article_pricelist'),
      },
      {
        id: ConfigurablePrintFormTypes.DELIVERY_NOTE_A4,
        label: t('settings.print_form_delivery_note_a4'),
      },
      {
        id: ConfigurablePrintFormTypes.INVOICE,
        label: t('settings.print_form_invoice'),
      },
      {
        id: ConfigurablePrintFormTypes.DEBT_REMINDER,
        label: t('settings.print_form_debt_reminder'),
      },
    ],
    [t],
  );

  const handlePrintFormTypeChange = (value: ISelectOption | null) => {
    if (value) {
      setPrintFormType(value.id as ConfigurablePrintFormTypes);
    }
  };

  const onSubmit = (e: FocusEvent<HTMLFormElement>) => {
    const parentName = getParentName(e);
    const targetName = e.target.name || parentName;
    const needSave =
      !getJSONValueByPath(errors, targetName) && !!getJSONValueByPath(dirtyFields, targetName);
    if (needSave) {
      const isAdditionalFieldRequired = checkIsAdditionalFieldRequired(targetName);
      if (isAdditionalFieldRequired) {
        const dataToSave = formDataWithAdditionalFields(targetName, formMethods.getValues());
        reportsConfigurationTabState.pub.saveData(dataToSave);
      } else {
        reportsConfigurationTabState.pub.saveData();
      }
    }
  };

  return (
    <>
      <Content
        type='stack'
        stackProps={{
          flexDirection: 'row',
          height: '100%',
          ...(expandedState && { gap: 2 }),
        }}
      >
        <SStack direction='column' gap={2} opened={expandedState} pt='5px'>
          <Select
            label={t('settings.print_form_type')}
            options={printFormsTypesOptions}
            value={printFormsTypesOptions.find((el) => el.id === printFormType)}
            disableClearable
            disableTextInput
            onChange={(_, v) => handlePrintFormTypeChange(v)}
          />
          <FormProvider {...formMethods}>
            <form style={{ width: 400 }} noValidate onBlur={onSubmit}>
              {printFormType === ConfigurablePrintFormTypes.ARTICLE_PRICELIST && (
                <ArticlePricelistConfig
                  valuesPath='articlePricelist'
                  defaultValuesPath='articlePricelistDefaultValues'
                />
              )}
              {printFormType === ConfigurablePrintFormTypes.DELIVERY_NOTE_A4 && (
                <DeliveryNoteA4Config
                  valuesPath='deliveryNoteA4'
                  defaultValuesPath='deliveryNoteA4DefaultValues'
                />
              )}
              {printFormType === ConfigurablePrintFormTypes.INVOICE && (
                <InvoiceConfig valuesPath='invoice' defaultValuesPath='invoiceDefaultValues' />
              )}
              {printFormType === ConfigurablePrintFormTypes.DEBT_REMINDER && (
                <DebtReminderConfig
                  valuesPath='debtReminder'
                  defaultValuesPath='debtReminderDefaultValues'
                />
              )}
            </form>
          </FormProvider>
        </SStack>
        <Box sx={{ width: '100%' }}>
          <ReportPreviewArea
            inputDataForGeneratePreview={inputDataForReportsPreview}
            printFormType={printFormType}
            disabledPreview={isDirty}
            onExpandedState={setExpandedState}
            expandedState={expandedState}
          />
        </Box>
      </Content>
    </>
  );
};

const getPathPrefix = (targetName: string): string => {
  const parts = targetName.split('.');
  parts.pop();
  return parts.join('.');
};

const getLastPartOfPath = (targetName: string): string => {
  const parts = targetName.split('.');
  const lastPart = parts[parts.length - 1];
  return lastPart;
};

const checkIsFontSettings = (lastPartOfPath: string): boolean => {
  return lastPartOfPath === 'size' || lastPartOfPath === 'family' || lastPartOfPath === 'style';
};

const checkIsAdditionalFieldRequired = (targetName: string): boolean => {
  const lastPart = getLastPartOfPath(targetName);
  return checkIsFontSettings(lastPart);
};

const formDataWithAdditionalFields = (targetName: string, allData: ReportsConfigurationRes) => {
  const prefix = getPathPrefix(targetName);
  const lastPart = getLastPartOfPath(targetName);
  const isFontSettings = checkIsFontSettings(lastPart);
  if (isFontSettings) {
    const sizePath = `${prefix}.size`;
    const sizeValue = getJSONValueByPath(allData, sizePath);
    const familyPath = `${prefix}.family`;
    const familyValue = getJSONValueByPath(allData, familyPath);
    const stylePath = `${prefix}.style`;
    const styleValue = getJSONValueByPath(allData, stylePath);
    const dataToSave = createNestedObject(prefix, {
      family: familyValue,
      size: sizeValue,
      style: styleValue,
    });
    return dataToSave as IReportsConfigurationTabState['dataToSave'];
  }
};
const SStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'opened',
})<{ opened?: boolean }>(({ opened }) => ({
  width: opened ? 400 : 0,
  minWidth: opened ? 400 : 0,
  transition: '0.3s',
  overflowX: 'auto',
}));

export interface IReportConfigurationValuesPaths {
  valuesPath: Exclude<keyof ReportsConfigurationRes, '__typename'>;
  defaultValuesPath: Exclude<keyof ReportsConfigurationRes, '__typename'>;
}

export interface IReportAnyObjectTunerProps {
  reportActualValuesPath: Exclude<keyof ReportsConfigurationRes, '__typename'>;
  reportDefaultValuesPath: Exclude<keyof ReportsConfigurationRes, '__typename'>;
  reportObjectName: string;
}
