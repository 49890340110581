/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../../../../../shared/components/popup/services/popup.service';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import { CopyResellerPrices } from './copyResellerPrices.form';

export class CopyResellerPricesPopup extends Popup<ICopyResellerPrices> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const { t } = useTranslation();
      const [currentCustomerId, setCurrentCustomerId] = useState('');

      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 498 },
        }));
      }, []);

      const formMethods = useForm({
        mode: 'onBlur',
      });

      useEffect(() => {
        stream.state().subscribe(({ action, currentCustomerId }) => {
          if (action === 'open') setCurrentCustomerId(currentCustomerId);
          if (action === 'close') formMethods.reset();
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.copy_reseller_prices')}>
          <FormProvider {...formMethods}>
            <CopyResellerPrices {...{ currentCustomerId, stream }} />
          </FormProvider>
        </SWrapper>
      );
    };
  }
}

interface ICopyResellerPrices {
  action: TPopupDefaultActions;
  currentCustomerId: string;
}
