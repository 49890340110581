import { Box, Divider, Stack, styled } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { ICheckboxData, ICheckboxList } from '../checkboxList.component';
import { Checkbox } from '../../fields/checkbox.component';

export const ControlPanel: FC<IControlPanel> = (props) => {
  const { controlLabel, headerRightOptions, withDivider, data, listData, setListData, onChange } =
    props;

  const checkValueDifference = (
    data: ICheckboxList['data'],
    key: keyof ICheckboxList['data'][number],
  ) => {
    return !data.every((item) => item[key] === data?.[0]?.[key]);
  };

  const changeAllCheckboxes = (state: boolean) => {
    setListData(
      listData.map((item) => {
        item.checked = state;
        return { ...item };
      }),
    );
  };

  return (
    <>
      {(controlLabel || headerRightOptions) && (
        <>
          <Stack
            flexWrap='nowrap'
            flexDirection='row'
            alignItems='center'
            {...(withDivider && { py: 1, px: 3 })}
          >
            {controlLabel && !!data.length && (
              <Checkbox
                sx={{ flexGrow: 1 }}
                label={controlLabel}
                indeterminate={checkValueDifference(listData, 'checked')}
                checked={listData.every((el) => el.checked)}
                onChange={(e) => {
                  if (e.target.checked) changeAllCheckboxes(true);
                  else changeAllCheckboxes(false);
                  onChange?.(listData);
                }}
              />
            )}
            {headerRightOptions && !controlLabel && (
              <SHeaderRightOption>{headerRightOptions}</SHeaderRightOption>
            )}
            {headerRightOptions && controlLabel && headerRightOptions}
          </Stack>
          {withDivider && <Divider />}
        </>
      )}
    </>
  );
};

const SHeaderRightOption = styled(Box)`
  position: absolute;
  right: 24px;
  top: 16px;
`;

interface IControlPanel
  extends Pick<
    ICheckboxList,
    'controlLabel' | 'headerRightOptions' | 'withDivider' | 'data' | 'onChange'
  > {
  listData: ICheckboxData[];
  setListData: Dispatch<SetStateAction<ICheckboxData[]>>;
}
