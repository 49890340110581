import { Stack, Typography, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import PageIcon from './pageIcon.component';
import { topbarService } from '../../services/topBar.service';

export const Title: FC = () => {
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const topbarSub = topbarService.sub.title().subscribe(setTitle);
    return () => {
      topbarSub.unsubscribe();
    };
  }, []);

  return (
    <STitleContainer>
      <PageIcon />
      <STitle variant='h1'>{title}</STitle>
    </STitleContainer>
  );
};

const STitleContainer = styled(Stack)(() => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',

  flexDirection: 'row',
  '& > h1': { marginLeft: 8 },
}));

const STitle = styled(Typography)(() => ({
  marginBottom: 0,
  fontSize: '20px',
  fontWeight: 500,
}));
