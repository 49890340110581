import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TableTextField } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { orderDetailsState as orderDetailsStateCreate } from '../../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../../edit/states/orderDetails/orderDetails.state.ts';
import { clearRowFromVirtualKeys } from './additionalFunctions.tsx';
import { blockArrowKeysInMiddle } from '../../../../../../shared/components/table/functions/sharedFunc.ts';

export const SplittingEditCell: FC<ISplittingEditCell> = ({ params, type }) => {
  const { field, value: initVal, row } = params;
  const [value, setValue] = useState(initVal);
  const updateCell = useCallback(
    (v: string) => {
      if (initVal !== v) {
        const [clearField, indexVal] = field.split('_');
        let values: (string | null)[] | null = [...(row?.[clearField] || [])];
        values[Number(indexVal)] = v;
        const emptyValues = values.every((el) => !el);
        values = emptyValues
          ? null
          : Array.from(values, (e) => (typeof e === 'undefined' || e === '' ? null : e));
        const clearedRow = clearRowFromVirtualKeys(row);
        const state = type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit;
        state.pub.updateCell({ ...clearedRow, [clearField]: values }, 'splittingCell');
      }
    },
    [params],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currV = e.target.value;
    const checked = /[^0-9%]/.test(currV);
    if (currV.includes('%') && currV.length === 1) return;
    if (currV.includes('r') && currV.length === 1) setValue(currV);
    const perCount = currV.match(/%/gi) || [];
    if (!checked) {
      if (perCount.length > 1) return;
      if (currV.slice(-1) === '%') setValue(currV);
      if (perCount.length === 1 && typeof Number(currV.slice(-1)) === 'number') return;
      setValue(currV);
    }
  };
  const handleBlur = (v: React.FocusEvent<HTMLInputElement>) => {
    const newValue = v.target.value;
    updateCell(newValue);
  };

  return (
    <TableTextField
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={blockArrowKeysInMiddle}
      inputProps={{ sx: { textAlign: 'right' }, autoComplete: 'off' }}
      autoFocus
      value={value}
    />
  );
};
interface ISplittingEditCell {
  params: GridRenderEditCellParams;
  type: 'create' | 'edit';
}
