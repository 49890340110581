import { useEditorState } from '@remirror/react';
import { AlignValue } from '../alignButtons.component';

export const useCurrentAlignment = () => {
  const { doc } = useEditorState();
  const alignment = doc?.firstChild?.attrs?.nodeTextAlignment as AlignValue;

  if (alignment) {
    return alignment;
  }

  return null;
};
