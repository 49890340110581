import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormNumericField, FormCheckbox } from '../../../../../../../../shared/components/form';
import { useFormContext } from 'react-hook-form';
import { getJSONValueByPath } from '../../../../../../../../shared/helpers/utils/utils.helper';
import { IReportAnyObjectTunerProps } from '../../reportsConfiguration.tab';
import { FormImagePicker } from '../../../../../../../../shared/components/form/formConnected/formImagePicker.component';
import { SFirstGridItem } from './reportObjectTuner.tsx';
import { reportsConfigurationTabState } from '../../../../../states/reportsConfiguration.state.ts';

export const PageBgImageTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  //reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <SFirstGridItem item xs={12}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </SFirstGridItem>
      <Grid item xs={12}>
        <FormImagePicker
          name={`${reportActualValuesPath}.${reportObjectName}.image`}
          onUpdate={() => reportsConfigurationTabState.pub.saveData()}
        />
      </Grid>
    </Grid>
  );
};

export const ReportImageTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(reportDefaultValuesPath);

  return (
    <Grid container spacing={2}>
      <SFirstGridItem item xs={12}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </SFirstGridItem>
      <Grid item xs={12}>
        <FormImagePicker
          name={`${reportActualValuesPath}.${reportObjectName}.image`}
          onUpdate={() => reportsConfigurationTabState.pub.saveData()}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.left`}
          placeholder={defaultValues?.[reportObjectName]?.['left']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.top')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.top`}
          placeholder={defaultValues?.[reportObjectName]?.['top']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.top`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.height`}
          placeholder={defaultValues?.[reportObjectName]?.['height']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.height`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.width`}
          placeholder={defaultValues?.[reportObjectName]?.['width']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.width`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={0}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
    </Grid>
  );
};

export const ReportFooterImageTuner: FC<IReportAnyObjectTunerProps> = ({
  reportActualValuesPath,
  reportDefaultValuesPath,
  reportObjectName,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const defaultValues = getValues(reportDefaultValuesPath);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormImagePicker
          name={`${reportActualValuesPath}.${reportObjectName}.image`}
          onUpdate={() => reportsConfigurationTabState.pub.saveData()}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.left')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.left`}
          placeholder={defaultValues?.[reportObjectName]?.['left']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
              ? t(
                  String(
                    getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.left`)
                      .message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormCheckbox
          label={t('settings.visible')}
          name={`${reportActualValuesPath}.${reportObjectName}.isVisible`}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.height')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.height`}
          placeholder={defaultValues?.[reportObjectName]?.['height']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.height`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.height`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumericField
          label={`${t('settings.width')} ${t('settings.cm_brackets')}`}
          name={`${reportActualValuesPath}.${reportObjectName}.width`}
          placeholder={defaultValues?.[reportObjectName]?.['width']?.toString()}
          error={Boolean(
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`),
          )}
          helperText={
            getJSONValueByPath(errors, `${reportActualValuesPath}.${reportObjectName}.width`)
              ? t(
                  String(
                    getJSONValueByPath(
                      errors,
                      `${reportActualValuesPath}.${reportObjectName}.width`,
                    ).message,
                  ),
                )
              : ''
          }
          controls
          fullWidth
          step={0.05}
          min={-100}
          max={100}
          precision={2}
          noTrailingZeros
          returnZero
        />
      </Grid>
    </Grid>
  );
};
