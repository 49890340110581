import { forkJoin, map, Observable, switchMap, take, tap, zipWith, of, finalize, zip } from 'rxjs';
import {
  GetCustomerInfoQuery,
  GetCustomerInfoQueryVariables,
  ListWa_PotentialAndCreatedOffersQuery,
  ListWa_PotentialAndCreatedOffersQueryVariables,
  GetOfferDataQuery,
  GetOfferDataQueryVariables,
  C_Offer_Selection_Mode,
  ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQueryVariables as ListSpecialAvailabilityState,
  ListSpecialAssortmentGroupsOfCustomerQueryVariables as ListSpecialAssortmentGroups,
  ListSpecialAssortmentGroupsOfCustomerQuery,
  ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery,
  GetWa_ArticleDataAsOrderPositionQueryVariables as ProductDataVariables,
  GetWa_ArticleDataAsOrderPositionQuery as ProductDataQuery,
  GetWa_ArticleOrderPriceAndDiscountQuery as ProductOrderPriceAndDiscountQuery,
  GetWa_ArticleOrderPriceAndDiscountQueryVariables as ProductPriceAndDiscountVariables,
  ListWa_ActualTaxsQueryVariables as ListTaxesVariables,
  ListWa_ActualTaxsQuery as ListTaxesQuery,
  CreateWa_DraftOfferMutationVariables as CreateDraftOfferVariables,
  CreateWa_DraftOfferMutation,
  CreateWa_OfferMutationVariables as CreateOfferVariables,
  CreateWa_OfferMutation,
  SaveWa_OfferMutationVariables as SaveOfferVariables,
  SaveWa_OfferMutation,
  AcceptWa_OfferMutationVariables as AcceptOfferVariables,
  AcceptWa_OfferMutation,
  DeclineWa_OfferMutationVariables as DeclinedOfferVariables,
  DeclineWa_OfferMutation,
  DeleteDraftOfferMutation,
  DeleteDraftOfferMutationVariables,
  C_Virtual_Position,
  Wa_OrderAndArticleInputs,
  Exact,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  acceptOffer,
  createDraftOffer,
  createOffer,
  declineOffer,
  getCustomerInfo,
  getListPotentialAndCreatedOffers,
  getOfferData,
  saveOffer,
  deleteDraftOffer,
} from './gql/offer.gql.ts';
import {
  ArticlesForOrderPositionListRes,
  configsData,
} from '../../../shared/services/configsData/configsData.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import {
  listSpecialAssortmentGroupsOfCustomer,
  listSpecialAvailabilityState,
  getArticleDataAsOrderPosition,
  getArticlePriceAndDiscount,
  getTaxesByDate,
} from '../../order/services/gql/order.gql.ts';
import { companyConfigService } from '../../../shared/services/companyConfig/companyConfig.service.ts';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper.ts';

type Actions =
  | 'getListPotentialAndCreatedOffers'
  | 'offerDetails'
  | 'offerData'
  | 'filteredOfferProductList'
  | 'productData'
  | 'productPriceAndDiscount'
  | 'productPriceAndDiscountByQuantity'
  | 'productPriceAndDiscountByVat'
  | 'deleteDraftOffer'
  | 'taxes'
  | 'createDraftOffer'
  | 'createOffer'
  | 'saveOffer'
  | 'acceptOffer'
  | 'declineOffer'
  | undefined;

class PubImpl extends Pub<Actions> {
  clearStream() {
    this.emit(undefined, {});
  }
  getListPotentialAndCreatedOffers(params: ListWa_PotentialAndCreatedOffersQueryVariables) {
    this.emit('getListPotentialAndCreatedOffers', params);
  }
  offerDetails(params: IOfferDetailsParams) {
    this.emit('offerDetails', params);
  }
  offerData(params: IOfferDetailsParams) {
    this.emit('offerData', params);
  }
  filteredOfferProductData(params: IFilteredOfferProductListParams) {
    this.emit('filteredOfferProductList', params);
  }
  productData(params: ProductDataVariables) {
    this.emit('productData', params);
  }
  productPriceAndDiscount(params: ProductPriceAndDiscountVariables): void {
    this.emit('productPriceAndDiscount', params);
  }
  productPriceAndDiscountByQuantity(params: ProductPriceAndDiscountByQuantityVariables): void {
    this.emit('productPriceAndDiscountByQuantity', params);
  }
  productPriceAndDiscountByVat(params: ProductPriceAndDiscountByVatVariables): void {
    this.emit('productPriceAndDiscountByVat', params);
  }
  taxes(params: ListTaxesVariables): void {
    this.emit('taxes', params);
  }
  createDraftOffer(params: CreateDraftOfferVariables): void {
    this.emit('createDraftOffer', params);
  }
  createOffer(params: CreateOfferVariables): void {
    this.emit('createOffer', params);
  }
  saveOffer(params: SaveOfferVariables): void {
    this.emit('saveOffer', params);
  }
  acceptOffer(params: AcceptOfferVariables): void {
    this.emit('acceptOffer', params);
  }
  declineOffer(params: DeclinedOfferVariables): void {
    this.emit('declineOffer', params);
  }
  deleteDraftOffer(params: DeleteDraftOfferMutationVariables): void {
    this.emit('deleteDraftOffer', params);
  }
}

class SubImpl extends Sub<Actions> {
  getListPotentialAndCreatedOffers(): Observable<ListPotentialAndCreatedOffersRes> {
    return this.actionListener('getListPotentialAndCreatedOffers').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getListPotentialAndCreatedOffers,
          params,
        ) as Observable<ListWa_PotentialAndCreatedOffersQuery>;
      }),
      map((data: ListWa_PotentialAndCreatedOffersQuery) => {
        const offerList = data.wawiAssist?.listWA_PotentialAndCreatedOffers?.map((item) => {
          const { customerId, draftOfferId, offerId, customerNr, internalOrFullName } = item || {};
          return {
            ...item,
            id: `${customerId}${draftOfferId}${offerId}`,
            customerNumberAndName: `${customerNr} ${internalOrFullName}`,
          };
        });
        return (offerList || []) as ListPotentialAndCreatedOffersRes;
      }),
    );
  }

  offerData(): Observable<INewOfferDataExtendedRes> {
    return this.actionListener('offerData').pipe(
      switchMap(({ params }) => {
        const { customerId, offerForDate, ...args } = params as IOfferDetailsParams;
        let newOfferDataArgs: GetOfferDataQueryVariables['selectionProps'] = {
          mode: C_Offer_Selection_Mode.DEFAULT_ORDER_OF_CUSTOMER,
          customerId,
          offerForDate,
        };
        if (params.mode) {
          newOfferDataArgs = params;
        } else {
          if (Number(args.draftOfferId)) {
            newOfferDataArgs = {
              mode: C_Offer_Selection_Mode.FROM_DRAFT_OFFER_BY_ID,
              draftOfferId: args.draftOfferId,
            };
          }
          if (Number(args.offerId)) {
            newOfferDataArgs = {
              mode: C_Offer_Selection_Mode.FROM_OFFER_BY_ID,
              offerId: args.offerId,
            };
          }
        }
        return gqlClient(getOfferData, {
          selectionProps: newOfferDataArgs,
        }) as Observable<GetOfferDataQuery>;
      }),
      take(1),
      map((data: GetOfferDataQuery) => {
        const offerData = (data.wawiAssist?.getWA_OfferData || []) as GetOfferDataRes;
        return offerData.map((item) => {
          if (item?.positions && item.positions.length) {
            item.positions = item.positions.map((el, i) => ({
              ...el,
              id: Date.now() + i,
              __reorder__: `${el?.articleNo ? '(' + el?.articleNo + ')' : ''} ${
                el?.description || ''
              }`,
              ...(el?.virtualPositionId === C_Virtual_Position.VP3_GROUP_HEADER && {
                description: el.indTextDeliveryNote,
              }),
            })) as [];
          }
          return item;
        }) as INewOfferDataExtendedRes;
      }),
    );
  }

  offerDetails(): Observable<OfferDetailsRes> {
    return this.actionListener('offerDetails').pipe(
      tap(({ params }) => {
        offerService.pub.offerData(params);
      }),
      switchMap(({ params: { customerId } }) => {
        const variables: GetCustomerInfoQueryVariables = { getCustomerDataId: customerId };
        return forkJoin({
          customerInfo: gqlClient(getCustomerInfo, variables).pipe(
            map<GetCustomerInfoQuery, CustomerInfoRes>(
              (data) => data!.wawiAssist!.getCustomerData!,
            ),
          ),
          offerData: this.offerData(),
        });
      }),
    );
  }

  filteredOfferProductData(): Observable<IFilteredOfferProductDataRes> {
    return this.actionListener('filteredOfferProductList').pipe(
      tap(() => {
        configsData.pub.common(['articlesForOrderPositionList']);
      }),
      switchMap(
        ({
          params: {
            customerId,
            date,
            listSpecialAssortmentGroups,
            listSpecialAvailabilityStateForArticles,
            isDateChanged = true,
          },
        }) => {
          return configsData.sub.articlesForOrderPositionList().pipe(
            zipWith(
              (customerId || !listSpecialAssortmentGroups
                ? gqlClient(listSpecialAssortmentGroupsOfCustomer, {
                    customerId,
                  })
                : of(
                    listSpecialAssortmentGroups,
                  )) as Observable<ListSpecialAssortmentGroupsOfCustomerQuery>,
              (isDateChanged || !listSpecialAvailabilityStateForArticles
                ? gqlClient(listSpecialAvailabilityState, {
                    onDate: date,
                  })
                : of(
                    listSpecialAvailabilityStateForArticles,
                  )) as Observable<ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery>,
            ),
            responseHandler<
              [
                ArticlesForOrderPositionListRes,
                ListSpecialAssortmentGroupsOfCustomerQuery,
                ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery,
              ]
            >({ errorReturnType: [[], {}, {}] }),
            map(([products, listSpecialAssortment, listSpecialAvailabilityState]) => {
              const listSpecialAssortmentGroups =
                listSpecialAssortment?.wawiAssist
                  ?.listWA_SpecialAssortmentGroupsOfCustomerForSelectInOrderPosition ||
                (listSpecialAssortment as []);
              const listSpecialAvailabilityStateForArticles =
                listSpecialAvailabilityState?.wawiAssist
                  ?.listWA_SpecialAvailabilityStateForArticlesSelectedInOrderPosition ||
                (listSpecialAvailabilityState as []);
              const filteredProducts = products.filter((item) => {
                if (!(!item?.isDough && item?.isActive)) return false;

                let passByAssortment = true;
                let passByDate: boolean;
                if (item?.specialAssortmentGroups.length && listSpecialAssortmentGroups?.length) {
                  passByAssortment = item.specialAssortmentGroups.some((v) =>
                    listSpecialAssortmentGroups.includes(v),
                  );
                }
                const dateValue: boolean | undefined =
                  listSpecialAvailabilityStateForArticles?.find(
                    (el) => el?.articleId === item?.id,
                  )?.isDelivered;

                if (typeof dateValue === 'boolean') {
                  passByDate = dateValue;
                } else {
                  // Filter by deliverySunday...deliverySaturday flags in case article doesn't exist in dateMapExclude
                  const deliveryDays = [
                    item?.isDeliveredSun,
                    item?.isDeliveredMon,
                    item?.isDeliveredTue,
                    item?.isDeliveredWed,
                    item?.isDeliveredThu,
                    item?.isDeliveredFri,
                    item?.isDeliveredSat,
                  ];
                  const valueByDay =
                    deliveryDays[localeFormatterHelper.localizedDate(date).getDay()];
                  passByDate = typeof valueByDay === 'boolean' ? valueByDay : true;
                }
                return passByAssortment && passByDate;
              });
              const productList = filteredProducts.map((item) => ({
                id: item.id,
                articleNo: item.articleNo,
                label: item.label,
                vatCode: item.vatCode,
                quantityPerLot: item.quantityPerLot,
                saleUnit: item.saleUnit,
                isDeliveredMon: item.isDeliveredMon,
                isDeliveredTue: item.isDeliveredTue,
                isDeliveredWed: item.isDeliveredWed,
                isDeliveredThu: item.isDeliveredThu,
                isDeliveredFri: item.isDeliveredFri,
                isDeliveredSat: item.isDeliveredSat,
                isDeliveredSun: item.isDeliveredSun,
                specialAssortmentGroups: item.specialAssortmentGroups,
                isActive: item.isActive,
                isDough: item.isDough,
              }));
              return {
                productList,
                listSpecialAssortmentGroups,
                listSpecialAvailabilityStateForArticles,
              };
            }),
            finalize(() => offerService.pub.clearStream()),
          );
        },
      ),
    );
  }

  productData(): Observable<ProductDataRes> {
    return this.actionListener('productData').pipe(
      take(1),
      switchMap(({ params }) => gqlClient(getArticleDataAsOrderPosition, params)),
      map((data) => data.wawiAssist?.getWA_ArticleDataAsOrderPosition),
      finalize(() => offerService.pub.clearStream()),
    );
  }

  productPriceAndDiscount(): Observable<ProductPriceAndDiscountRes> {
    return this.actionListener('productPriceAndDiscount').pipe(
      take(1),
      switchMap(({ params }) => gqlClient(getArticlePriceAndDiscount, params)),
      map((data) => data.wawiAssist?.getWA_ArticleOrderPriceAndDiscount),
      finalize(() => offerService.pub.clearStream()),
    );
  }

  productPriceAndDiscountByQuantity(): Observable<ProductPriceAndDiscountByQuantityRes> {
    return this.actionListener('productPriceAndDiscountByQuantity').pipe(
      take(1),
      switchMap(({ params }) => {
        const { orderAndArticleProps } = params as ProductPriceAndDiscountByQuantityVariables;
        const { oldQuantity, newQuantity, ...commonArgs } = orderAndArticleProps;
        return zip(
          gqlClient(getArticlePriceAndDiscount, {
            orderAndArticleProps: { ...commonArgs, quantity: oldQuantity },
          }) as Observable<ProductOrderPriceAndDiscountQuery>,
          gqlClient(getArticlePriceAndDiscount, {
            orderAndArticleProps: { ...commonArgs, quantity: newQuantity },
          }) as Observable<ProductOrderPriceAndDiscountQuery>,
        );
      }),
      map(([oldValues, newValues]) => {
        return {
          oldValues: oldValues.wawiAssist?.getWA_ArticleOrderPriceAndDiscount,
          newValues: newValues.wawiAssist?.getWA_ArticleOrderPriceAndDiscount,
        } as ProductPriceAndDiscountByQuantityRes;
      }),
    );
  }

  productPriceAndDiscountByVat(): Observable<ProductPriceAndDiscountByVatRes> {
    return this.actionListener('productPriceAndDiscountByVat').pipe(
      take(1),
      switchMap(({ params }) => {
        const { orderAndArticleProps } = params as ProductPriceAndDiscountByVatVariables;
        const { oldVatCode, newVatCode, ...commonArgs } = orderAndArticleProps;
        return zip(
          gqlClient(getArticlePriceAndDiscount, {
            orderAndArticleProps: { ...commonArgs, vatCode: oldVatCode },
          }) as Observable<ProductOrderPriceAndDiscountQuery>,
          gqlClient(getArticlePriceAndDiscount, {
            orderAndArticleProps: { ...commonArgs, vatCode: newVatCode },
          }) as Observable<ProductOrderPriceAndDiscountQuery>,
        );
      }),
      map(([oldValues, newValues]) => {
        return {
          oldValues: oldValues.wawiAssist?.getWA_ArticleOrderPriceAndDiscount,
          newValues: newValues.wawiAssist?.getWA_ArticleOrderPriceAndDiscount,
        } as ProductPriceAndDiscountByVatRes;
      }),
    );
  }

  taxes(): Observable<TaxesByDateRes> {
    return this.actionListener('taxes').pipe(
      switchMap(({ params }) => {
        return gqlClient(getTaxesByDate, params) as Observable<ListTaxesQuery>;
      }),
      map((data: ListTaxesQuery) => {
        const precision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
        const result = (data?.wawiAssist?.listWA_ActualTaxs || [])?.map((item) => ({
          ...item,
          label:
            localeFormatterHelper.formatNumber(Number.parseFloat(item?.label || ''), {
              precision,
              noTrailingZeros: true,
            }) + '%',
        }));
        return result as TaxesByDateRes;
      }),
      finalize(() => offerService.pub.clearStream()),
    );
  }

  createDraftOffer(): Observable<CreateDraftOfferExtendedRes> {
    return this.actionListener('createDraftOffer').pipe(
      switchMap(({ params }: { params: CreateDraftOfferVariables }) => {
        return (
          gqlClient(createDraftOffer, params) as Observable<CreateWa_DraftOfferMutation>
        ).pipe(
          map((data: CreateWa_DraftOfferMutation) => {
            return {
              draftOfferId: data?.wawiAssist?.createWA_DraftOffer as CreateDrafOfferRes,
              customerId: params.draftOfferProps.customerId,
            };
          }),
        );
      }),
    );
  }

  createOffer(): Observable<CreateOfferRes> {
    return this.actionListener('createOffer').pipe(
      switchMap(({ params }: { params: CreateOfferVariables }) => {
        return (gqlClient(createOffer, params) as Observable<CreateWa_OfferMutation>).pipe(
          map((data: CreateWa_OfferMutation) => {
            return data?.wawiAssist?.createWA_Offer as CreateOfferRes;
          }),
        );
      }),
    );
  }

  saveOffer(): Observable<SaveOfferRes> {
    return this.actionListener('saveOffer').pipe(
      switchMap(({ params }: { params: SaveOfferVariables }) => {
        return (gqlClient(saveOffer, params) as Observable<SaveWa_OfferMutation>).pipe(
          map((data: SaveWa_OfferMutation) => {
            return data?.wawiAssist?.saveWA_Offer as SaveOfferRes;
          }),
        );
      }),
    );
  }

  acceptOffer(): Observable<AcceptOfferRes> {
    return this.actionListener('acceptOffer').pipe(
      switchMap(({ params }: { params: AcceptOfferVariables }) => {
        return (gqlClient(acceptOffer, params) as Observable<AcceptWa_OfferMutation>).pipe(
          map((data: AcceptWa_OfferMutation) => {
            return data?.wawiAssist?.acceptWA_Offer as AcceptOfferRes;
          }),
        );
      }),
    );
  }

  declineOffer(): Observable<DeclineOfferRes> {
    return this.actionListener('declineOffer').pipe(
      switchMap(({ params }: { params: DeclinedOfferVariables }) => {
        return (gqlClient(declineOffer, params) as Observable<DeclineWa_OfferMutation>).pipe(
          map((data: DeclineWa_OfferMutation) => {
            return data?.wawiAssist?.declineWA_Offer as DeclineOfferRes;
          }),
        );
      }),
    );
  }

  deleteDraftOffer(): Observable<DeleteDraftOfferRes> {
    return this.actionListener('deleteDraftOffer').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDraftOffer, params) as Observable<DeleteDraftOfferMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.deleteWA_DraftOffer as DeleteDraftOfferRes;
      }),
    );
  }
}

class OfferService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const offerService = new OfferService();

export interface IExtendedListPotentialAndCreatedOffersRes
  extends NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          ListWa_PotentialAndCreatedOffersQuery['wawiAssist']
        >['listWA_PotentialAndCreatedOffers']
      >
    >[number]
  > {
  id: string;
  customerNumberAndName: string;
}

export type CreateDrafOfferRes = NonNullable<
  NonNullable<CreateWa_DraftOfferMutation['wawiAssist']>['createWA_DraftOffer']
>;

export type CreateDraftOfferExtendedRes = {
  draftOfferId: CreateDrafOfferRes;
  customerId: CreateDraftOfferVariables['draftOfferProps']['customerId'];
};

export type CreateOfferRes = NonNullable<
  NonNullable<CreateWa_OfferMutation['wawiAssist']>['createWA_Offer']
>;

export type SaveOfferRes = NonNullable<
  NonNullable<SaveWa_OfferMutation['wawiAssist']>['saveWA_Offer']
>;

export type AcceptOfferRes = NonNullable<
  NonNullable<AcceptWa_OfferMutation['wawiAssist']>['acceptWA_Offer']
>;

export type DeclineOfferRes = NonNullable<
  NonNullable<DeclineWa_OfferMutation['wawiAssist']>['declineWA_Offer']
>;

export type ListPotentialAndCreatedOffersRes = IExtendedListPotentialAndCreatedOffersRes[];
export type CustomerInfoRes = NonNullable<
  NonNullable<GetCustomerInfoQuery['wawiAssist']>['getCustomerData']
>;
export type GetOfferDataRes = NonNullable<
  NonNullable<GetOfferDataQuery['wawiAssist']>['getWA_OfferData']
>;

export interface OfferDetailsRes {
  customerInfo: Partial<CustomerInfoRes>;
  offerData: INewOfferDataExtendedRes;
}

export interface IOfferDetailsParams
  extends Omit<GetOfferDataQueryVariables['selectionProps'], 'mode' | 'offerNo'> {
  mode?: GetOfferDataQueryVariables['selectionProps']['mode'];
  offerNo?: number | null;
}

/*  add id type in the offer position  */
type TOfferPosition = NonNullable<
  NonNullable<NonNullable<GetOfferDataRes[number]>['positions']>[number]
>;
export interface IPosition extends TOfferPosition {
  id: number;
  __reorder__?: string;
}
type TOfferData = NonNullable<NonNullable<GetOfferDataRes>[number]>;
export interface INewOfferDataRes extends Omit<TOfferData, 'positions'> {
  positions?: IPosition[];
}
export type INewOfferDataExtendedRes = INewOfferDataRes[];
/* end */

interface IFilteredOfferProductListParams {
  date: ListSpecialAvailabilityState['onDate'];
  isDateChanged?: boolean; // trigger for call the listSpecialAvailabilityState query
  customerId?: ListSpecialAssortmentGroups['customerId'];
  listSpecialAssortmentGroups?: ListSpecialAssortmentGroupsRes;
  listSpecialAvailabilityStateForArticles?: ListSpecialAvailabilityStateForArticlesRes;
}

export interface IFilteredOfferProductDataRes {
  productList: ArticlesForOfferPosition[];
  listSpecialAssortmentGroups?: ListSpecialAssortmentGroupsRes;
  listSpecialAvailabilityStateForArticles?: ListSpecialAvailabilityStateForArticlesRes;
}

export type ListSpecialAssortmentGroupsRes = NonNullable<
  NonNullable<
    ListSpecialAssortmentGroupsOfCustomerQuery['wawiAssist']
  >['listWA_SpecialAssortmentGroupsOfCustomerForSelectInOrderPosition']
>;

export type ListSpecialAvailabilityStateForArticlesRes = NonNullable<
  NonNullable<
    ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery['wawiAssist']
  >['listWA_SpecialAvailabilityStateForArticlesSelectedInOrderPosition']
>;

export type ProductDataRes = NonNullable<
  NonNullable<ProductDataQuery['wawiAssist']>['getWA_ArticleDataAsOrderPosition']
>;

export type ProductPriceAndDiscountRes = NonNullable<
  NonNullable<ProductOrderPriceAndDiscountQuery['wawiAssist']>['getWA_ArticleOrderPriceAndDiscount']
>;

export type TaxesByDateRes = NonNullable<
  NonNullable<ListTaxesQuery>['wawiAssist']
>['listWA_ActualTaxs'];

export type DeleteDraftOfferRes = NonNullable<
  NonNullable<DeleteDraftOfferMutation['wawiAssist']>['deleteWA_DraftOffer']
>;

type ArticlesForOfferPosition = Pick<
  ArticlesForOrderPositionListRes[number],
  | 'id'
  | 'articleNo'
  | 'label'
  | 'vatCode'
  | 'quantityPerLot'
  | 'saleUnit'
  | 'isDeliveredMon'
  | 'isDeliveredTue'
  | 'isDeliveredWed'
  | 'isDeliveredThu'
  | 'isDeliveredFri'
  | 'isDeliveredSat'
  | 'isDeliveredSun'
  | 'specialAssortmentGroups'
  | 'isActive'
  | 'isDough'
>;

type ProductPriceAndDiscountByQuantityInputs = Omit<Wa_OrderAndArticleInputs, 'quantity'> & {
  oldQuantity: Wa_OrderAndArticleInputs['quantity'];
  newQuantity: Wa_OrderAndArticleInputs['quantity'];
};

export type ProductPriceAndDiscountByQuantityVariables = Exact<{
  orderAndArticleProps: ProductPriceAndDiscountByQuantityInputs;
}>;

export type ProductPriceAndDiscountByQuantityRes = {
  oldValues: ProductPriceAndDiscountRes;
  newValues: ProductPriceAndDiscountRes;
};

type ProductPriceAndDiscountByVatInputs = Omit<Wa_OrderAndArticleInputs, 'vatCode'> & {
  oldVatCode: Wa_OrderAndArticleInputs['vatCode'];
  newVatCode: Wa_OrderAndArticleInputs['vatCode'];
};

export type ProductPriceAndDiscountByVatVariables = Exact<{
  orderAndArticleProps: ProductPriceAndDiscountByVatInputs;
}>;

export type ProductPriceAndDiscountByVatRes = {
  oldValues: ProductPriceAndDiscountRes;
  newValues: ProductPriceAndDiscountRes;
};
