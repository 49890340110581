import { ReactExtensions } from '@remirror/react';
import { useEffect } from 'react';
import { EditorState, RemirrorManager } from 'remirror';
import {
  BoldExtension,
  FontSizeExtension,
  ItalicExtension,
  NodeFormattingExtension,
  ParagraphExtension,
  TextColorExtension,
  UnderlineExtension,
} from 'remirror/extensions';

export const useUpdateFromOutside = (args: IArgs) => {
  const { defaultValue, value, manager, setState } = args;

  useEffect(() => {
    const updateFromOutside = defaultValue !== value;
    if (updateFromOutside) {
      const text = defaultValue || '';
      const newState = manager.createState({ content: text });
      setState(newState);
    }
  }, [defaultValue, manager]);
};

interface IArgs {
  defaultValue?: string;
  value?: string;
  manager: RemirrorManager<
    ReactExtensions<
      | ParagraphExtension
      | FontSizeExtension
      | BoldExtension
      | ItalicExtension
      | UnderlineExtension
      | TextColorExtension
      | NodeFormattingExtension
    >
  >;
  setState: (state: Readonly<EditorState>) => void;
}
