import { t } from 'i18next';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { IconButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GotoSettingsIcon } from '../../../../../../../svg/custom.svg.tsx';
import { FieldsSection, FormCheckboxList } from '../../../../../../../shared/components/form';

export const CloudPrintersSection: FC = () => {
  const navigate = useNavigate();

  return (
    <FieldsSection
      marginTop={'31px'}
      title={t('article.printout_on_production_printers')}
      titleBoxSx={{ pt: 0 }}
      nomt
    >
      <FormCheckboxList
        name='articleCloudPrintersCheckedState'
        minWidth='350px'
        maxHeight='290px'
        emptyListIndication={{
          title: t('article.production_printers_are_not_configured'),
          text: (
            <Trans
              style={{ display: 'flex' }}
              i18nKey='common.click_button_above_to_configure'
              components={{ icon: <SSettingsIcon /> }}
            />
          ),
        }}
        headerRightOptions={
          <IconButton
            sx={{ ml: 'auto', height: '40px' }}
            onClick={() => navigate('/settings/configuration/production')}
            title={t('common.settings')}
          >
            <GotoSettingsIcon />
          </IconButton>
        }
      />
    </FieldsSection>
  );
};

const SSettingsIcon = styled(GotoSettingsIcon)(({ theme }) => ({
  marginRight: 2,
  marginLeft: 2,
  height: '24px !important',
  width: '24px !important',
  verticalAlign: 'bottom',
  color: `${theme.palette.text.secondary}!important`,
}));
