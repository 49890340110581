import { FC, useCallback, useEffect } from 'react';
import { companyConfigService } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TableNumericField } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { orderDetailsState as orderDetailsStateCreate } from '../../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../../edit/states/orderDetails/orderDetails.state.ts';
import { clearRowFromVirtualKeys } from '../splitting/additionalFunctions.tsx';
import { blockArrowKeysInMiddle } from '../../../../../../shared/components/table/functions/sharedFunc.ts';

export const PriceEditCell: FC<TPriceEditCell> = ({ api, ...params }) => {
  const precision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
  const { setEditCellValue } = api;
  const { value, field, id, row, type, formattedValue } = params;

  useEffect(() => {
    setEditCellValue({ id, field, value: formattedValue });
  }, []);

  const updateCell = useCallback(
    (v: number | null) => {
      if (Number(value) !== v) {
        setEditCellValue({ id, field, value: v });
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          { ...clearedRow, price: v },
          'priceCell',
        );
      }
    },
    [params, precision],
  );

  return (
    <TableNumericField
      onBlur={updateCell}
      inputProps={{ autoComplete: 'off' }}
      autoFocus
      keyChange={false}
      onKeyDown={blockArrowKeysInMiddle}
      {...{ precision, value }}
    />
  );
};

type TPriceEditCell = GridRenderEditCellParams & { type: 'create' | 'edit' };
