import { Pub, Service, Sub } from '../../../services/service.abstract.ts';
import {
  CreateDictDebtReminderMutation,
  CreateDictDebtReminderMutationVariables,
  CreateDictQuantityDiscountMutation,
  CreateDictQuantityDiscountMutationVariables,
  CreateDictStorageLocationMutation,
  CreateDictStorageLocationMutationVariables,
  CreateDictWa_ArticleListMutation as CreateDictArticleListMutation,
  CreateDictWa_ArticleListMutationVariables as CreateDictArticleListMutationVariables,
  CreateDictWa_AssortimentGroupMutation as CreateDictAssortmentGroupMutation,
  CreateDictWa_AssortimentGroupMutationVariables as CreateDictAssortmentGroupMutationVariables,
  CreateDictWa_BankMutation,
  CreateDictWa_BankMutationVariables,
  CreateDictWa_CustomerGroupMutation as CreateDictCustomerGroupMutation,
  CreateDictWa_CustomerGroupMutationVariables as CreateDictCustomerGroupMutationVariables,
  CreateDictWa_CustomerListMutation as CreateDictCustomerListMutation,
  CreateDictWa_CustomerListMutationVariables as CreateDictCustomerListMutationVariables,
  CreateDictWa_MarketingGroupMutation as CreateDictMarketingGroupMutation,
  CreateDictWa_MarketingGroupMutationVariables as CreateDictMarketingGroupMutationVariables,
  CreateDictWa_ProductionGroupMutation as CreateDictProductionGroupsMutation,
  CreateDictWa_ProductionGroupMutationVariables as CreateDictProductionGroupsMutationVariables,
  CreateDictWa_ShippingPointMutation as CreateDictShippingPointMutation,
  CreateDictWa_ShippingPointMutationVariables as CreateDictShippingPointMutationVariables,
  CreateDictWa_TextBlockMutation as CreateDictTextBlockMutation,
  CreateDictWa_TextBlockMutationVariables as CreateDictTextBlockMutationVariables,
  CreateDictWa_TourplanMutation as CreateDictTourplanMutation,
  CreateDictWa_TourplanMutationVariables as CreateDictTourplanMutationVariables,
  CreateDictWa_TransportSectorMutation,
  CreateDictWa_TransportSectorMutationVariables,
  DeleteDictDebtReminderMutation,
  DeleteDictDebtReminderMutationVariables,
  DeleteDictQuantityDiscountMutation,
  DeleteDictQuantityDiscountMutationVariables,
  DeleteDictStorageLocationMutation,
  DeleteDictStorageLocationMutationVariables,
  DeleteDictWa_ArticleListMutation as DeleteDictArticleListMutation,
  DeleteDictWa_ArticleListMutationVariables as DeleteDictArticleListMutationVariables,
  DeleteDictWa_AssortimentGroupMutation as DeleteDictAssortmentGroupMutation,
  DeleteDictWa_AssortimentGroupMutationVariables as DeleteDictAssortmentGroupMutationVariables,
  DeleteDictWa_BankMutation,
  DeleteDictWa_BankMutationVariables,
  DeleteDictWa_CustomerGroupMutation as DeleteDictCustomerGroupMutation,
  DeleteDictWa_CustomerGroupMutationVariables as DeleteDictCustomerGroupMutationVariables,
  DeleteDictWa_CustomerListMutation as DeleteDictCustomerListMutation,
  DeleteDictWa_CustomerListMutationVariables as DeleteDictCustomerListMutationVariables,
  DeleteDictWa_DeliveryNoteGroupMutation as DeleteDictDeliveryNoteGroupMutation,
  DeleteDictWa_DeliveryNoteGroupMutationVariables as DeleteDictDeliveryNoteGroupMutationVariables,
  DeleteDictWa_DiscountGroupMutation as DeleteDictDiscountGroupMutation,
  DeleteDictWa_DiscountGroupMutationVariables as DeleteDictDiscountGroupMutationVariables,
  DeleteDictWa_MarketingGroupMutation as DeleteDictMarketingGroupMutation,
  DeleteDictWa_MarketingGroupMutationVariables as DeleteDictMarketingGroupMutationVariables,
  DeleteDictWa_PriceCategoryMutation as DeleteDictPriceCategoryMutation,
  DeleteDictWa_PriceCategoryMutationVariables as DeleteDictPriceCategoryMutationVariables,
  DeleteDictWa_ProductionGroupMutation as DeleteDictProductionGroupsMutation,
  DeleteDictWa_ProductionGroupMutationVariables as DeleteDictProductionGroupsMutationVariables,
  DeleteDictWa_ShippingPointMutation as DeleteDictShippingPointMutation,
  DeleteDictWa_ShippingPointMutationVariables as DeleteDictShippingPointMutationVariables,
  DeleteDictWa_TextBlockMutation as DeleteDictTextBlockMutation,
  DeleteDictWa_TextBlockMutationVariables as DeleteDictTextBlockMutationVariables,
  DeleteDictWa_TourplanMutation as DeleteDictTourplanMutation,
  DeleteDictWa_TourplanMutationVariables as DeleteDictTourplanMutationVariables,
  DeleteDictWa_TransportSectorMutation,
  DeleteDictWa_TransportSectorMutationVariables,
  SaveDictDebtReminderMutation,
  SaveDictDebtReminderMutationVariables,
  SaveDictQuantityDiscountMutation,
  SaveDictQuantityDiscountMutationVariables,
  SaveDictStorageLocationMutation,
  SaveDictStorageLocationMutationVariables,
  SaveDictWa_ArticleListMutation as SaveDictArticleListMutation,
  SaveDictWa_ArticleListMutationVariables as SaveDictArticleListMutationVariables,
  SaveDictWa_AssortimentGroupMutation as SaveDictAssortmentGroupMutation,
  SaveDictWa_AssortimentGroupMutationVariables as SaveDictAssortmentGroupMutationVariables,
  SaveDictWa_BankMutation,
  SaveDictWa_BankMutationVariables,
  SaveDictWa_CustomerGroupMutation as SaveDictCustomerGroupMutation,
  SaveDictWa_CustomerGroupMutationVariables as SaveDictCustomerGroupMutationVariables,
  SaveDictWa_CustomerListMutation as SaveDictCustomerListMutation,
  SaveDictWa_CustomerListMutationVariables as SaveDictCustomerListMutationVariables,
  SaveDictWa_DeliveryNoteGroupMutation as SaveDictDeliveryNoteGroupMutation,
  SaveDictWa_DeliveryNoteGroupMutationVariables as SaveDictDeliveryNoteGroupMutationVariables,
  SaveDictWa_DiscountGroupMutation as SaveDictDiscountGroupMutation,
  SaveDictWa_DiscountGroupMutationVariables as SaveDictDiscountGroupVariables,
  SaveDictWa_MarketingGroupMutation as SaveDictMarketingGroupMutation,
  SaveDictWa_MarketingGroupMutationVariables as SaveDictMarketingGroupMutationVariables,
  SaveDictWa_PriceCategoryMutation as SaveDictPriceCategoryMutation,
  SaveDictWa_PriceCategoryMutationVariables as SaveDictPriceCategoryMutationVariables,
  SaveDictWa_ProductionGroupMutation as SaveDictProductionGroupsMutation,
  SaveDictWa_ProductionGroupMutationVariables as SaveDictProductionGroupsMutationVariables,
  SaveDictWa_ShippingPointMutation as SaveDictShippingPointMutation,
  SaveDictWa_ShippingPointMutationVariables as SaveDictShippingPointMutationVariables,
  SaveDictWa_TextBlockMutation as SaveDictTextBlockMutation,
  SaveDictWa_TextBlockMutationVariables as SaveDictTextBlockMutationVariables,
  SaveDictWa_TourplanMutation as SaveDictTourplanMutation,
  SaveDictWa_TourplanMutationVariables as SaveDictTourplanMutationVariables,
  SaveDictWa_TransportSectorMutation,
  SaveDictWa_TransportSectorMutationVariables,
  CreateDictWa_CustomerProfileMutation,
  SaveDictWa_CustomerProfileMutation,
  DeleteDictWa_CustomerProfileMutation,
  CreateDictWa_CustomerProfileMutationVariables,
  SaveDictWa_CustomerProfileMutationVariables,
  DeleteDictWa_CustomerProfileMutationVariables,
  CreateDictCloudPrinterMutation,
  SaveDictCloudPrinterMutation,
  DeleteDictCloudPrinterMutation,
  CreateDictCloudPrinterMutationVariables,
  SaveDictCloudPrinterMutationVariables,
  DeleteDictCloudPrinterMutationVariables,
} from '../../../../graphql/generatedModel.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import {
  createDictArticleLists,
  createDictAssortmentGroups,
  createDictBanks,
  createDictCustomerGroups,
  createDictCustomerLists,
  createDictCustomerProfiles,
  createDictDebtReminders,
  createDictMarketingGroups,
  createDictPostSectors,
  createDictProductionGroups,
  createDictQuantityDiscounts,
  createDictShippingPoints,
  createDictStorageLocations,
  createDictTextBlocks,
  createDictTourplans,
  createDictCloudPrinter,
  deleteDictArticleLists,
  deleteDictAssortmentGroups,
  deleteDictBanks,
  deleteDictCustomerGroups,
  deleteDictCustomerLists,
  deleteDictCustomerProfiles,
  deleteDictDebtReminders,
  deleteDictDeliveryNoteGroups,
  deleteDictDiscountGroups,
  deleteDictMarketingGroups,
  deleteDictPostSectors,
  deleteDictPriceCategories,
  deleteDictProductionGroups,
  deleteDictQuantityDiscounts,
  deleteDictShippingPoints,
  deleteDictStorageLocations,
  deleteDictTextBlocks,
  deleteDictTourplans,
  deleteDictCloudPrinter,
  saveDictArticleLists,
  saveDictAssortmentGroups,
  saveDictBanks,
  saveDictCustomerGroups,
  saveDictCustomerLists,
  saveDictCustomerProfiles,
  saveDictDebtReminders,
  saveDictDeliveryNoteGroups,
  saveDictDiscountGroups,
  saveDictMarketingGroups,
  saveDictPostSectors,
  saveDictPriceCategories,
  saveDictProductionGroups,
  saveDictQuantityDiscounts,
  saveDictShippingPoints,
  saveDictStorageLocations,
  saveDictTextBlocks,
  saveDictTourplans,
  saveDictCloudPrinter,
} from './gql/dictionary.gql.ts';
import { GetDictCustomerProfileRes } from './dictionaryAdditional.service.ts';

/* todo Required condition. The root must match the name of the dictionaryType.
    Also, the action must be of the form save + dictionaryType, delete + dictionaryType
    or create + dictionaryType (only for unlimited lists) */

type Actions = SaveActions | DeleteActions | CreateActions | undefined;

export type SaveActions =
  | 'saveDictDiscountGroups'
  | 'saveDictPriceCategories'
  | 'saveDictDeliveryNoteGroups'
  | 'saveDictArticleLists'
  | 'saveDictCustomerLists'
  | 'saveDictCustomerGroups'
  | 'saveDictTextBlocks'
  | 'saveDictPostSectors'
  | 'saveDictCustomerProfiles'
  | 'saveDictTourplans'
  | 'saveDictShippingPoints'
  | 'saveDictProductionGroups'
  | 'saveDictMarketingGroups'
  | 'saveDictAssortmentGroups'
  | 'saveDictQuantityDiscounts'
  | 'saveDictBanks'
  | 'saveDictDebtReminders'
  | 'saveDictStorageLocations'
  | 'saveDictCloudPrinters';
export type DeleteActions =
  | 'deleteDictDiscountGroups'
  | 'deleteDictPriceCategories'
  | 'deleteDictArticleLists'
  | 'deleteDictCustomerLists'
  | 'deleteDictDeliveryNoteGroups'
  | 'deleteDictCustomerGroups'
  | 'deleteDictTextBlocks'
  | 'deleteDictPostSectors'
  | 'deleteDictCustomerProfiles'
  | 'deleteDictMarketingGroups'
  | 'deleteDictProductionGroups'
  | 'deleteDictShippingPoints'
  | 'deleteDictTourplans'
  | 'deleteDictAssortmentGroups'
  | 'deleteDictQuantityDiscounts'
  | 'deleteDictBanks'
  | 'deleteDictDebtReminders'
  | 'deleteDictStorageLocations'
  | 'deleteDictCloudPrinters';
export type CreateActions =
  | 'createDictArticleLists'
  | 'createDictCustomerLists'
  | 'createDictCustomerGroups'
  | 'createDictTextBlocks'
  | 'createDictPostSectors'
  | 'createDictCustomerProfiles'
  | 'createDictTourplans'
  | 'createDictProductionGroups'
  | 'createDictShippingPoints'
  | 'createDictMarketingGroups'
  | 'createDictAssortmentGroups'
  | 'createDictQuantityDiscounts'
  | 'createDictBanks'
  | 'createDictDebtReminders'
  | 'createDictStorageLocations'
  | 'createDictCloudPrinters';

class PubImpl extends Pub<Actions> {
  saveDictDiscountGroups(params: SaveDictDiscountGroupVariables) {
    this.emit('saveDictDiscountGroups', params);
  }
  deleteDictDiscountGroups(params: DeleteDictDiscountGroupMutationVariables) {
    this.emit('deleteDictDiscountGroups', params);
  }
  saveDictPriceCategories(params: SaveDictPriceCategoryMutationVariables) {
    this.emit('saveDictPriceCategories', params);
  }
  deleteDictPriceCategories(params: DeleteDictPriceCategoryMutationVariables) {
    this.emit('deleteDictPriceCategories', params);
  }
  saveDictDeliveryNoteGroups(params: SaveDictDeliveryNoteGroupMutationVariables) {
    this.emit('saveDictDeliveryNoteGroups', params);
  }
  deleteDictDeliveryNoteGroups(params: DeleteDictDeliveryNoteGroupMutationVariables) {
    this.emit('deleteDictDeliveryNoteGroups', params);
  }
  createDictArticleLists(params: CreateDictArticleListMutationVariables) {
    this.emit('createDictArticleLists', params);
  }
  saveDictArticleLists(params: SaveDictArticleListMutationVariables) {
    this.emit('saveDictArticleLists', params);
  }
  deleteDictArticleLists(params: DeleteDictArticleListMutationVariables) {
    this.emit('deleteDictArticleLists', params);
  }
  createDictCustomerLists(params: CreateDictCustomerListMutationVariables) {
    this.emit('createDictCustomerLists', params);
  }
  saveDictCustomerLists(params: SaveDictCustomerListMutationVariables) {
    this.emit('saveDictCustomerLists', params);
  }
  deleteDictCustomerLists(params: DeleteDictCustomerListMutationVariables) {
    this.emit('deleteDictCustomerLists', params);
  }
  createDictCustomerGroups(params: CreateDictCustomerGroupMutationVariables) {
    this.emit('createDictCustomerGroups', params);
  }
  saveDictCustomerGroups(params: SaveDictCustomerGroupMutationVariables) {
    this.emit('saveDictCustomerGroups', params);
  }
  deleteDictCustomerGroups(params: DeleteDictCustomerGroupMutationVariables) {
    this.emit('deleteDictCustomerGroups', params);
  }
  createDictPostSectors(params: CreateDictWa_TransportSectorMutationVariables) {
    this.emit('createDictPostSectors', params);
  }
  saveDictPostSectors(params: SaveDictWa_TransportSectorMutationVariables) {
    this.emit('saveDictPostSectors', params);
  }
  deleteDictPostSectors(params: DeleteDictWa_TransportSectorMutationVariables) {
    this.emit('deleteDictPostSectors', params);
  }
  createDictCustomerProfiles(params: CreateDictWa_CustomerProfileMutationVariables) {
    this.emit('createDictCustomerProfiles', params);
  }
  saveDictCustomerProfiles(params: SaveDictWa_CustomerProfileMutationVariables) {
    this.emit('saveDictCustomerProfiles', params);
  }
  deleteDictCustomerProfiles(params: DeleteDictWa_CustomerProfileMutationVariables) {
    this.emit('deleteDictCustomerProfiles', params);
  }
  createDictTextBlocks(params: CreateDictTextBlockMutationVariables) {
    this.emit('createDictTextBlocks', params);
  }
  saveDictTextBlocks(params: SaveDictTextBlockMutationVariables) {
    this.emit('saveDictTextBlocks', params);
  }
  deleteDictTextBlocks(params: DeleteDictTextBlockMutationVariables) {
    this.emit('deleteDictTextBlocks', params);
  }
  createDictTourplans(params: CreateDictTourplanMutationVariables) {
    this.emit('createDictTourplans', params);
  }
  saveDictTourplans(params: SaveDictTourplanMutationVariables) {
    this.emit('saveDictTourplans', params);
  }
  deleteDictTourplans(params: DeleteDictTourplanMutationVariables) {
    this.emit('deleteDictTourplans', params);
  }
  createDictProductionGroups(params: CreateDictProductionGroupsMutationVariables) {
    this.emit('createDictProductionGroups', params);
  }
  saveDictProductionGroups(params: SaveDictProductionGroupsMutationVariables) {
    this.emit('saveDictProductionGroups', params);
  }
  deleteDictProductionGroups(params: DeleteDictProductionGroupsMutationVariables) {
    this.emit('deleteDictProductionGroups', params);
  }
  createDictShippingPoints(params: CreateDictShippingPointMutationVariables) {
    this.emit('createDictShippingPoints', params);
  }
  saveDictShippingPoints(params: SaveDictShippingPointMutationVariables) {
    this.emit('saveDictShippingPoints', params);
  }
  deleteDictShippingPoints(params: DeleteDictShippingPointMutationVariables) {
    this.emit('deleteDictShippingPoints', params);
  }
  createDictMarketingGroups(params: CreateDictMarketingGroupMutationVariables) {
    this.emit('createDictMarketingGroups', params);
  }
  saveDictMarketingGroups(params: SaveDictMarketingGroupMutationVariables) {
    this.emit('saveDictMarketingGroups', params);
  }
  deleteDictMarketingGroups(params: DeleteDictMarketingGroupMutationVariables) {
    this.emit('deleteDictMarketingGroups', params);
  }
  createDictAssortmentGroups(params: CreateDictAssortmentGroupMutationVariables) {
    this.emit('createDictAssortmentGroups', params);
  }
  saveDictAssortmentGroups(params: SaveDictAssortmentGroupMutationVariables) {
    this.emit('saveDictAssortmentGroups', params);
  }
  deleteDictAssortmentGroups(params: DeleteDictAssortmentGroupMutationVariables) {
    this.emit('deleteDictAssortmentGroups', params);
  }
  deleteDictQuantityDiscounts(params: DeleteDictQuantityDiscountMutationVariables) {
    this.emit('deleteDictQuantityDiscounts', params);
  }
  saveDictQuantityDiscounts(params: SaveDictQuantityDiscountMutationVariables) {
    this.emit('saveDictQuantityDiscounts', params);
  }
  createDictQuantityDiscounts(params: CreateDictQuantityDiscountMutationVariables) {
    this.emit('createDictQuantityDiscounts', params);
  }
  createDictBanks(params: CreateDictWa_BankMutationVariables) {
    this.emit('createDictBanks', params);
  }
  saveDictBanks(params: SaveDictWa_BankMutationVariables) {
    this.emit('saveDictBanks', params);
  }
  deleteDictBanks(params: DeleteDictWa_BankMutationVariables) {
    this.emit('deleteDictBanks', params);
  }
  createDictDebtReminders(params: CreateDictDebtReminderMutationVariables) {
    this.emit('createDictDebtReminders', params);
  }
  saveDictDebtReminders(params: SaveDictDebtReminderMutationVariables) {
    this.emit('saveDictDebtReminders', params);
  }
  deleteDictDebtReminders(params: DeleteDictDebtReminderMutationVariables) {
    this.emit('deleteDictDebtReminders', params);
  }
  createDictStorageLocations(params: CreateDictStorageLocationMutationVariables) {
    this.emit('createDictStorageLocations', params);
  }
  saveDictStorageLocations(params: SaveDictStorageLocationMutationVariables) {
    this.emit('saveDictStorageLocations', params);
  }
  deleteDictStorageLocations(params: DeleteDictStorageLocationMutationVariables) {
    this.emit('deleteDictStorageLocations', params);
  }
  createDictCloudPrinters(params: CreateDictCloudPrinterMutationVariables) {
    this.emit('createDictCloudPrinters', params);
  }
  saveDictCloudPrinters(params: SaveDictCloudPrinterMutationVariables) {
    this.emit('saveDictCloudPrinters', params);
  }
  deleteDictCloudPrinters(params: DeleteDictCloudPrinterMutationVariables) {
    this.emit('deleteDictCloudPrinters', params);
  }
}

class SubImpl extends Sub<Actions> {
  saveDictDiscountGroups(): Observable<SaveDictDiscountGroupMutationRes> {
    return this.actionListener('saveDictDiscountGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictDiscountGroups, params);
      }),
      map((data: SaveDictDiscountGroupMutation) => {
        return data.wawiAssist?.saveDictWA_DiscountGroup as SaveDictDiscountGroupMutationRes;
      }),
    );
  }
  deleteDictDiscountGroups(): Observable<DeleteDictDiscountGroupMutationRes> {
    return this.actionListener('deleteDictDiscountGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictDiscountGroups, params);
      }),
      map((data: DeleteDictDiscountGroupMutation) => {
        return data.wawiAssist?.deleteDictWA_DiscountGroup as DeleteDictDiscountGroupMutationRes;
      }),
    );
  }
  saveDictPriceCategories(): Observable<SaveDictPriceCategoryRes> {
    return this.actionListener('saveDictPriceCategories').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictPriceCategories, params);
      }),
      map((data: SaveDictPriceCategoryMutation) => {
        return data.wawiAssist?.saveDictWA_PriceCategory as SaveDictPriceCategoryRes;
      }),
    );
  }
  deleteDictPriceCategories(): Observable<DeleteDictPriceCategoryRes> {
    return this.actionListener('deleteDictPriceCategories').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictPriceCategories, params);
      }),
      map((data: DeleteDictPriceCategoryMutation) => {
        return data.wawiAssist?.deleteDictWA_PriceCategory as DeleteDictPriceCategoryRes;
      }),
    );
  }
  saveDictDeliveryNoteGroups(): Observable<SaveDictDeliveryNoteGroupRes> {
    return this.actionListener('saveDictDeliveryNoteGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictDeliveryNoteGroups, params);
      }),
      map((data: SaveDictDeliveryNoteGroupMutation) => {
        return data.wawiAssist?.saveDictWA_DeliveryNoteGroup as SaveDictDeliveryNoteGroupRes;
      }),
    );
  }
  deleteDictDeliveryNoteGroups(): Observable<DeleteDeliveryNoteGroupRes> {
    return this.actionListener('deleteDictDeliveryNoteGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictDeliveryNoteGroups, params);
      }),
      map((data: DeleteDictDeliveryNoteGroupMutation) => {
        return data.wawiAssist?.deleteDictWA_DeliveryNoteGroup as DeleteDeliveryNoteGroupRes;
      }),
    );
  }
  createDictArticleLists(): Observable<CreateDictArticleListRes> {
    return this.actionListener('createDictArticleLists').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictArticleLists, params);
      }),
      map((data: CreateDictArticleListMutation) => {
        return data.wawiAssist?.createDictWA_ArticleList as CreateDictArticleListRes;
      }),
    );
  }
  saveDictArticleLists(): Observable<SaveDictArticleListRes> {
    return this.actionListener('saveDictArticleLists').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictArticleLists, params);
      }),
      map((data: SaveDictArticleListMutation) => {
        return data.wawiAssist?.saveDictWA_ArticleList as SaveDictArticleListRes;
      }),
    );
  }
  deleteDictArticleLists(): Observable<DeleteDictArticleListRes> {
    return this.actionListener('deleteDictArticleLists').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictArticleLists, params);
      }),
      map((data: DeleteDictArticleListMutation) => {
        return data.wawiAssist?.deleteDictWA_ArticleList as DeleteDictArticleListRes;
      }),
    );
  }
  createDictCustomerLists(): Observable<CreateDictCustomerListRes> {
    return this.actionListener('createDictCustomerLists').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictCustomerLists, params);
      }),
      map((data: CreateDictCustomerListMutation) => {
        return data.wawiAssist?.createDictWA_CustomerList as CreateDictCustomerListRes;
      }),
    );
  }
  saveDictCustomerLists(): Observable<SaveDictCustomerListRes> {
    return this.actionListener('saveDictCustomerLists').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictCustomerLists, params);
      }),
      map((data: SaveDictCustomerListMutation) => {
        return data.wawiAssist?.saveDictWA_CustomerList as SaveDictCustomerListRes;
      }),
    );
  }
  deleteDictCustomerLists(): Observable<DeleteDictCustomerListRes> {
    return this.actionListener('deleteDictCustomerLists').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictCustomerLists, params);
      }),
      map((data: DeleteDictCustomerListMutation) => {
        return data.wawiAssist?.deleteDictWA_CustomerList as DeleteDictCustomerListRes;
      }),
    );
  }
  createDictCustomerGroups(): Observable<CreateDictCustomerGroupRes> {
    return this.actionListener('createDictCustomerGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictCustomerGroups, params);
      }),
      map((data: CreateDictCustomerGroupMutation) => {
        return data.wawiAssist?.createDictWA_CustomerGroup as CreateDictCustomerGroupRes;
      }),
    );
  }
  saveDictCustomerGroups(): Observable<SaveDictCustomerGroupRes> {
    return this.actionListener('saveDictCustomerGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictCustomerGroups, params);
      }),
      map((data: SaveDictCustomerGroupMutation) => {
        return data.wawiAssist?.saveDictWA_CustomerGroup as SaveDictCustomerGroupRes;
      }),
    );
  }
  deleteDictCustomerGroups(): Observable<DeleteDictWa_CustomerGroupRes> {
    return this.actionListener('deleteDictCustomerGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictCustomerGroups, params);
      }),
      map((data: DeleteDictCustomerGroupMutation) => {
        return data.wawiAssist?.deleteDictWA_CustomerGroup as DeleteDictWa_CustomerGroupRes;
      }),
    );
  }
  createDictPostSectors(): Observable<CreateDictPostSectorsRes> {
    return this.actionListener('createDictPostSectors').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictPostSectors, params);
      }),
      map((data: CreateDictWa_TransportSectorMutation) => {
        return data.wawiAssist?.createDictWA_TransportSector as CreateDictPostSectorsRes;
      }),
    );
  }
  saveDictPostSectors(): Observable<SaveDictPostSectorsRes> {
    return this.actionListener('saveDictPostSectors').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictPostSectors, params);
      }),
      map((data: SaveDictWa_TransportSectorMutation) => {
        return data.wawiAssist?.saveDictWA_TransportSector as SaveDictPostSectorsRes;
      }),
    );
  }
  deleteDictPostSectors(): Observable<DeleteDictPostSectorsRes> {
    return this.actionListener('deleteDictPostSectors').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictPostSectors, params);
      }),
      map((data: DeleteDictWa_TransportSectorMutation) => {
        return data.wawiAssist?.deleteDictWA_TransportSector as DeleteDictPostSectorsRes;
      }),
    );
  }
  createDictCustomerProfiles(): Observable<CreateDictCustomerProfilesRes> {
    return this.actionListener('createDictCustomerProfiles').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictCustomerProfiles, params);
      }),
      map((data: CreateDictWa_CustomerProfileMutation) => {
        return data.wawiAssist?.createDictWA_CustomerProfile as CreateDictCustomerProfilesRes;
      }),
    );
  }
  saveDictCustomerProfiles(): Observable<SaveDictCustomerProfilesRes> {
    return this.actionListener('saveDictCustomerProfiles').pipe(
      switchMap(({ params }) => {
        const p = params?.fields as GetDictCustomerProfileRes;
        const { periods, periodsWithCustomWeekDays, vacations, weekDays, ...rest } = p || {};
        let input;

        input = {
          fields: { ...rest },
          id: params?.id,
        };

        if (weekDays) {
          input = {
            ...input,
            fields: {
              ...input.fields,
              ...weekDays,
            },
          };
        }

        if (periods || periodsWithCustomWeekDays || vacations) {
          input = {
            ...input,
            fields: {
              ...input.fields,
              customerDeliveryPeriodsSettings: {
                customerRegularDeliveryPeriods: periods ? periods.map(({ id: _, ...v }) => v) : [],
                customerCustomWeekDaysAndDeliveryPeriods: periodsWithCustomWeekDays
                  ? periodsWithCustomWeekDays.map(({ period, weekDays, description }) => ({
                      description,
                      fromDate: period?.fromDate,
                      toDate: period?.toDate,
                      isRepeatYearly: period?.isRepeatYearly,
                      ...weekDays,
                    }))
                  : [],
                customerVacations: vacations ? vacations.map(({ id: _, ...v }) => v) : [],
              },
            },
          };
        }

        return gqlClient(
          saveDictCustomerProfiles,
          input as SaveDictWa_CustomerProfileMutationVariables,
        );
      }),
      map((data: SaveDictWa_CustomerProfileMutation) => {
        return data.wawiAssist?.saveDictWA_CustomerProfile as SaveDictCustomerProfilesRes;
      }),
    );
  }
  deleteDictCustomerProfiles(): Observable<DeleteDictCustomerProfilesRes> {
    return this.actionListener('deleteDictCustomerProfiles').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictCustomerProfiles, params);
      }),
      map((data: DeleteDictWa_CustomerProfileMutation) => {
        return data.wawiAssist?.deleteDictWA_CustomerProfile as DeleteDictCustomerProfilesRes;
      }),
    );
  }
  createDictTextBlocks(): Observable<CreateDictTextBlockRes> {
    return this.actionListener('createDictTextBlocks').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictTextBlocks, params);
      }),
      map((data: CreateDictTextBlockMutation) => {
        return data.wawiAssist?.createDictWA_TextBlock as CreateDictTextBlockRes;
      }),
    );
  }
  saveDictTextBlocks(): Observable<SaveDictTextBlockRes> {
    return this.actionListener('saveDictTextBlocks').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictTextBlocks, params);
      }),
      map((data: SaveDictTextBlockMutation) => {
        return data.wawiAssist?.saveDictWA_TextBlock as SaveDictTextBlockRes;
      }),
    );
  }
  deleteDictTextBlocks(): Observable<DeleteDictTextBlockRes> {
    return this.actionListener('deleteDictTextBlocks').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictTextBlocks, params);
      }),
      map((data: DeleteDictTextBlockMutation) => {
        return data.wawiAssist?.deleteDictWA_TextBlock as DeleteDictTextBlockRes;
      }),
    );
  }
  createDictTourplans(): Observable<CreateDictTourplanRes> {
    return this.actionListener('createDictTourplans').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictTourplans, params);
      }),
      map((data: CreateDictTourplanMutation) => {
        return data.wawiAssist?.createDictWA_Tourplan as CreateDictTourplanRes;
      }),
    );
  }
  saveDictTourplans(): Observable<SaveDictTourplanRes> {
    return this.actionListener('saveDictTourplans').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictTourplans, params);
      }),
      map((data: SaveDictTourplanMutation) => {
        return data.wawiAssist?.saveDictWA_Tourplan as SaveDictTourplanRes;
      }),
    );
  }
  deleteDictTourplans(): Observable<DeleteDictTourplanRes> {
    return this.actionListener('deleteDictTourplans').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictTourplans, params);
      }),
      map((data: DeleteDictTourplanMutation) => {
        return data.wawiAssist?.deleteDictWA_Tourplan as DeleteDictTourplanRes;
      }),
    );
  }
  createDictProductionGroups(): Observable<CreateDictProductionGroupsRes> {
    return this.actionListener('createDictProductionGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictProductionGroups, params);
      }),
      map((data: CreateDictProductionGroupsMutation) => {
        return data.wawiAssist?.createDictWA_ProductionGroup as CreateDictProductionGroupsRes;
      }),
    );
  }
  saveDictProductionGroups(): Observable<SaveDictProductionGroupsRes> {
    return this.actionListener('saveDictProductionGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictProductionGroups, params);
      }),
      map((data: SaveDictProductionGroupsMutation) => {
        return data.wawiAssist?.saveDictWA_ProductionGroup as SaveDictProductionGroupsRes;
      }),
    );
  }
  deleteDictProductionGroups(): Observable<DeleteDictProductionGroupsRes> {
    return this.actionListener('deleteDictProductionGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictProductionGroups, params);
      }),
      map((data: DeleteDictProductionGroupsMutation) => {
        return data.wawiAssist?.deleteDictWA_ProductionGroup as DeleteDictProductionGroupsRes;
      }),
    );
  }
  createDictShippingPoints(): Observable<CreateDictShippingPointsRes> {
    return this.actionListener('createDictShippingPoints').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictShippingPoints, params);
      }),
      map((data: CreateDictShippingPointMutation) => {
        return data.wawiAssist?.createDictWA_ShippingPoint as CreateDictShippingPointsRes;
      }),
    );
  }
  saveDictShippingPoints(): Observable<SaveDictShippingPointsRes> {
    return this.actionListener('saveDictShippingPoints').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictShippingPoints, params);
      }),
      map((data: SaveDictShippingPointMutation) => {
        return data.wawiAssist?.saveDictWA_ShippingPoint as SaveDictShippingPointsRes;
      }),
    );
  }
  deleteDictShippingPoints(): Observable<DeleteDictShippingPointsRes> {
    return this.actionListener('deleteDictShippingPoints').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictShippingPoints, params);
      }),
      map((data: DeleteDictShippingPointMutation) => {
        return data.wawiAssist?.deleteDictWA_ShippingPoint as DeleteDictShippingPointsRes;
      }),
    );
  }
  createDictMarketingGroups(): Observable<CreateDictMarketingGroupRes> {
    return this.actionListener('createDictMarketingGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictMarketingGroups, params);
      }),
      map((data: CreateDictMarketingGroupMutation) => {
        return data.wawiAssist?.createDictWA_MarketingGroup as CreateDictMarketingGroupRes;
      }),
    );
  }
  saveDictMarketingGroups(): Observable<SaveDictMarketingGroupRes> {
    return this.actionListener('saveDictMarketingGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictMarketingGroups, params);
      }),
      map((data: SaveDictMarketingGroupMutation) => {
        return data.wawiAssist?.saveDictWA_MarketingGroup as SaveDictMarketingGroupRes;
      }),
    );
  }
  deleteDictMarketingGroups(): Observable<DeleteDictMarketingGroupRes> {
    return this.actionListener('deleteDictMarketingGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictMarketingGroups, params);
      }),
      map((data: DeleteDictMarketingGroupMutation) => {
        return data.wawiAssist?.deleteDictWA_MarketingGroup as DeleteDictMarketingGroupRes;
      }),
    );
  }
  createDictAssortmentGroups(): Observable<CreateDictAssortmentGroupRes> {
    return this.actionListener('createDictAssortmentGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictAssortmentGroups, params);
      }),
      map((data: CreateDictAssortmentGroupMutation) => {
        return data.wawiAssist?.createDictWA_AssortimentGroup as CreateDictAssortmentGroupRes;
      }),
    );
  }
  saveDictAssortmentGroups(): Observable<SaveDictAssortmentGroupRes> {
    return this.actionListener('saveDictAssortmentGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictAssortmentGroups, params);
      }),
      map((data: SaveDictAssortmentGroupMutation) => {
        return data.wawiAssist?.saveDictWA_AssortimentGroup as SaveDictAssortmentGroupRes;
      }),
    );
  }
  deleteDictAssortmentGroups(): Observable<DeleteDictAssortmentGroupRes> {
    return this.actionListener('deleteDictAssortmentGroups').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictAssortmentGroups, params);
      }),
      map((data: DeleteDictAssortmentGroupMutation) => {
        return data.wawiAssist?.deleteDictWA_AssortimentGroup as DeleteDictAssortmentGroupRes;
      }),
    );
  }
  createDictQuantityDiscounts(): Observable<CreateDictQuantityDiscountMutationRes> {
    return this.actionListener('createDictQuantityDiscounts').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictQuantityDiscounts, params);
      }),
      map((data: CreateDictQuantityDiscountMutation) => {
        return data.wawiAssist
          ?.createDictWA_QuantityDiscount as CreateDictQuantityDiscountMutationRes;
      }),
    );
  }
  saveDictQuantityDiscounts(): Observable<SaveDictQuantityDiscountMutationRes> {
    return this.actionListener('saveDictQuantityDiscounts').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictQuantityDiscounts, params);
      }),
      map((data: SaveDictQuantityDiscountMutation) => {
        return data.wawiAssist?.saveDictWA_QuantityDiscount as SaveDictQuantityDiscountMutationRes;
      }),
    );
  }
  deleteDictQuantityDiscounts(): Observable<DeleteDictQuantityDiscountMutationRes> {
    return this.actionListener('deleteDictQuantityDiscounts').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictQuantityDiscounts, params);
      }),
      map((data: DeleteDictQuantityDiscountMutation) => {
        return data.wawiAssist
          ?.deleteDictWA_QuantityDiscount as DeleteDictQuantityDiscountMutationRes;
      }),
    );
  }
  createDictBanks(): Observable<CreateDictBankRes> {
    return this.actionListener('createDictBanks').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictBanks, params);
      }),
      map((data: CreateDictWa_BankMutation) => {
        return data.wawiAssist?.createDictWA_Bank as CreateDictBankRes;
      }),
    );
  }
  saveDictBanks(): Observable<SaveDictBankRes> {
    return this.actionListener('saveDictBanks').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictBanks, params);
      }),
      map((data: SaveDictWa_BankMutation) => {
        return data.wawiAssist?.saveDictWA_Bank as SaveDictBankRes;
      }),
    );
  }
  deleteDictBanks(): Observable<DeleteDictBankRes> {
    return this.actionListener('deleteDictBanks').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictBanks, params);
      }),
      map((data: DeleteDictWa_BankMutation) => {
        return data.wawiAssist?.deleteDictWA_Bank as DeleteDictBankRes;
      }),
    );
  }
  createDictDebtReminders(): Observable<CreateDictDebtReminderRes> {
    return this.actionListener('createDictDebtReminders').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictDebtReminders, params);
      }),
      map((data: CreateDictDebtReminderMutation) => {
        return data.wawiAssist?.createDictWA_DebtReminder as CreateDictDebtReminderRes;
      }),
    );
  }
  saveDictDebtReminders(): Observable<SaveDictDebtReminderRes> {
    return this.actionListener('saveDictDebtReminders').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictDebtReminders, params);
      }),
      map((data: SaveDictDebtReminderMutation) => {
        return data.wawiAssist?.saveDictWA_DebtReminder as SaveDictDebtReminderRes;
      }),
    );
  }
  deleteDictDebtReminders(): Observable<DeleteDictDebtReminderRes> {
    return this.actionListener('deleteDictDebtReminders').pipe(
      switchMap(({ params: { id } }) => {
        return gqlClient(deleteDictDebtReminders, { id });
      }),
      map((data: DeleteDictDebtReminderMutation) => {
        return data.wawiAssist?.deleteDictWA_DebtReminder as DeleteDictDebtReminderRes;
      }),
    );
  }
  createDictStorageLocations(): Observable<CreateDictStorageLocationRes> {
    return this.actionListener('createDictStorageLocations').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictStorageLocations, params);
      }),
      map((data: CreateDictStorageLocationMutation) => {
        return data.wawiAssist?.createDictWA_StorageLocation as CreateDictStorageLocationRes;
      }),
    );
  }
  saveDictStorageLocations(): Observable<SaveDictStorageLocationRes> {
    return this.actionListener('saveDictStorageLocations').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictStorageLocations, params);
      }),
      map((data: SaveDictStorageLocationMutation) => {
        return data.wawiAssist?.saveDictWA_StorageLocation as SaveDictStorageLocationRes;
      }),
    );
  }
  deleteDictStorageLocations(): Observable<DeleteDictStorageLocationRes> {
    return this.actionListener('deleteDictStorageLocations').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictStorageLocations, params);
      }),
      map((data: DeleteDictStorageLocationMutation) => {
        return data.wawiAssist?.deleteDictWA_StorageLocation as DeleteDictStorageLocationRes;
      }),
    );
  }
  createDictCloudPrinters(): Observable<CreateDictCloudPrinterRes> {
    return this.actionListener('createDictCloudPrinters').pipe(
      switchMap(({ params }) => {
        return gqlClient(createDictCloudPrinter, params);
      }),
      map((data: CreateDictCloudPrinterMutation) => {
        return data.wawiAssist?.createDictWA_CloudPrinter as CreateDictCloudPrinterRes;
      }),
    );
  }
  saveDictCloudPrinters(): Observable<SaveDictCloudPrinterRes> {
    return this.actionListener('saveDictCloudPrinters').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveDictCloudPrinter, params);
      }),
      map((data: SaveDictCloudPrinterMutation) => {
        return data.wawiAssist?.saveDictWA_CloudPrinter as SaveDictCloudPrinterRes;
      }),
    );
  }
  deleteDictCloudPrinters(): Observable<DeleteDictCloudPrinterRes> {
    return this.actionListener('deleteDictCloudPrinters').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDictCloudPrinter, params);
      }),
      map((data: DeleteDictCloudPrinterMutation) => {
        return data.wawiAssist?.deleteDictWA_CloudPrinter as DeleteDictCloudPrinterRes;
      }),
    );
  }
}

class DictionaryActionsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const dictionaryActionsService = new DictionaryActionsService();

export type SaveDictDiscountGroupMutationRes = NonNullable<
  NonNullable<SaveDictDiscountGroupMutation['wawiAssist']>['saveDictWA_DiscountGroup']
>;
export type DeleteDictDiscountGroupMutationRes = NonNullable<
  NonNullable<DeleteDictDiscountGroupMutation['wawiAssist']>['deleteDictWA_DiscountGroup']
>;
export type SaveDictPriceCategoryRes = NonNullable<
  NonNullable<SaveDictPriceCategoryMutation['wawiAssist']>['saveDictWA_PriceCategory']
>;
export type DeleteDictPriceCategoryRes = NonNullable<
  NonNullable<DeleteDictPriceCategoryMutation['wawiAssist']>['deleteDictWA_PriceCategory']
>;
export type SaveDictDeliveryNoteGroupRes = NonNullable<
  NonNullable<SaveDictDeliveryNoteGroupMutation['wawiAssist']>['saveDictWA_DeliveryNoteGroup']
>;
export type DeleteDeliveryNoteGroupRes = NonNullable<
  NonNullable<DeleteDictDeliveryNoteGroupMutation['wawiAssist']>['deleteDictWA_DeliveryNoteGroup']
>;
export type CreateDictArticleListRes = NonNullable<
  NonNullable<CreateDictArticleListMutation['wawiAssist']>['createDictWA_ArticleList']
>;
export type SaveDictArticleListRes = NonNullable<
  NonNullable<SaveDictArticleListMutation['wawiAssist']>['saveDictWA_ArticleList']
>;
export type DeleteDictArticleListRes = NonNullable<
  NonNullable<DeleteDictArticleListMutation['wawiAssist']>['deleteDictWA_ArticleList']
>;
export type CreateDictCustomerListRes = NonNullable<
  NonNullable<CreateDictCustomerListMutation['wawiAssist']>['createDictWA_CustomerList']
>;
export type SaveDictCustomerListRes = NonNullable<
  NonNullable<SaveDictCustomerListMutation['wawiAssist']>['saveDictWA_CustomerList']
>;
export type DeleteDictCustomerListRes = NonNullable<
  NonNullable<DeleteDictCustomerListMutation['wawiAssist']>['deleteDictWA_CustomerList']
>;
export type CreateDictCustomerGroupRes = NonNullable<
  NonNullable<CreateDictCustomerGroupMutation['wawiAssist']>['createDictWA_CustomerGroup']
>;
export type SaveDictCustomerGroupRes = NonNullable<
  NonNullable<SaveDictCustomerGroupMutation['wawiAssist']>['saveDictWA_CustomerGroup']
>;
export type DeleteDictWa_CustomerGroupRes = NonNullable<
  NonNullable<DeleteDictCustomerGroupMutation['wawiAssist']>['deleteDictWA_CustomerGroup']
>;
export type CreateDictPostSectorsRes = NonNullable<
  NonNullable<CreateDictWa_TransportSectorMutation['wawiAssist']>['createDictWA_TransportSector']
>;
export type SaveDictPostSectorsRes = NonNullable<
  NonNullable<SaveDictWa_TransportSectorMutation['wawiAssist']>['saveDictWA_TransportSector']
>;
export type DeleteDictPostSectorsRes = NonNullable<
  NonNullable<DeleteDictWa_TransportSectorMutation['wawiAssist']>['deleteDictWA_TransportSector']
>;
export type CreateDictCustomerProfilesRes = NonNullable<
  NonNullable<CreateDictWa_CustomerProfileMutation['wawiAssist']>['createDictWA_CustomerProfile']
>;
export type SaveDictCustomerProfilesRes = NonNullable<
  NonNullable<SaveDictWa_CustomerProfileMutation['wawiAssist']>['saveDictWA_CustomerProfile']
>;
export type DeleteDictCustomerProfilesRes = NonNullable<
  NonNullable<DeleteDictWa_CustomerProfileMutation['wawiAssist']>['deleteDictWA_CustomerProfile']
>;
export type CreateDictTextBlockRes = NonNullable<
  NonNullable<CreateDictTextBlockMutation['wawiAssist']>['createDictWA_TextBlock']
>;
export type SaveDictTextBlockRes = NonNullable<
  NonNullable<SaveDictTextBlockMutation['wawiAssist']>['saveDictWA_TextBlock']
>;
export type DeleteDictTextBlockRes = NonNullable<
  NonNullable<DeleteDictTextBlockMutation['wawiAssist']>['deleteDictWA_TextBlock']
>;
export type CreateDictTourplanRes = NonNullable<
  NonNullable<CreateDictTourplanMutation['wawiAssist']>['createDictWA_Tourplan']
>;
export type SaveDictTourplanRes = NonNullable<
  NonNullable<SaveDictTourplanMutation['wawiAssist']>['saveDictWA_Tourplan']
>;
export type DeleteDictTourplanRes = NonNullable<
  NonNullable<DeleteDictTourplanMutation['wawiAssist']>['deleteDictWA_Tourplan']
>;
export type CreateDictMarketingGroupRes = NonNullable<
  NonNullable<CreateDictMarketingGroupMutation['wawiAssist']>['createDictWA_MarketingGroup']
>;
export type SaveDictMarketingGroupRes = NonNullable<
  NonNullable<SaveDictMarketingGroupMutation['wawiAssist']>['saveDictWA_MarketingGroup']
>;
export type DeleteDictMarketingGroupRes = NonNullable<
  NonNullable<DeleteDictMarketingGroupMutation['wawiAssist']>['deleteDictWA_MarketingGroup']
>;
export type CreateDictProductionGroupsRes = NonNullable<
  NonNullable<CreateDictProductionGroupsMutation['wawiAssist']>['createDictWA_ProductionGroup']
>;
export type SaveDictProductionGroupsRes = NonNullable<
  NonNullable<SaveDictProductionGroupsMutation['wawiAssist']>['saveDictWA_ProductionGroup']
>;
export type DeleteDictProductionGroupsRes = NonNullable<
  NonNullable<DeleteDictProductionGroupsMutation['wawiAssist']>['deleteDictWA_ProductionGroup']
>;
export type CreateDictShippingPointsRes = NonNullable<
  NonNullable<CreateDictShippingPointMutation['wawiAssist']>['createDictWA_ShippingPoint']
>;
export type SaveDictShippingPointsRes = NonNullable<
  NonNullable<SaveDictShippingPointMutation['wawiAssist']>['saveDictWA_ShippingPoint']
>;
export type DeleteDictShippingPointsRes = NonNullable<
  NonNullable<DeleteDictShippingPointMutation['wawiAssist']>['deleteDictWA_ShippingPoint']
>;
export type CreateDictAssortmentGroupRes = NonNullable<
  NonNullable<CreateDictAssortmentGroupMutation['wawiAssist']>['createDictWA_AssortimentGroup']
>;
export type SaveDictAssortmentGroupRes = NonNullable<
  NonNullable<SaveDictAssortmentGroupMutation['wawiAssist']>['saveDictWA_AssortimentGroup']
>;
export type DeleteDictAssortmentGroupRes = NonNullable<
  NonNullable<DeleteDictAssortmentGroupMutation['wawiAssist']>['deleteDictWA_AssortimentGroup']
>;
export type DeleteDictQuantityDiscountMutationRes = NonNullable<
  NonNullable<DeleteDictQuantityDiscountMutation['wawiAssist']>['deleteDictWA_QuantityDiscount']
>;
export type SaveDictQuantityDiscountMutationRes = NonNullable<
  NonNullable<SaveDictQuantityDiscountMutation['wawiAssist']>['saveDictWA_QuantityDiscount']
>;
export type CreateDictQuantityDiscountMutationRes = NonNullable<
  NonNullable<CreateDictQuantityDiscountMutation['wawiAssist']>['createDictWA_QuantityDiscount']
>;
export type CreateDictBankRes = NonNullable<
  NonNullable<CreateDictWa_BankMutation['wawiAssist']>['createDictWA_Bank']
>;
export type SaveDictBankRes = NonNullable<
  NonNullable<SaveDictWa_BankMutation['wawiAssist']>['saveDictWA_Bank']
>;
export type DeleteDictBankRes = NonNullable<
  NonNullable<DeleteDictWa_BankMutation['wawiAssist']>['deleteDictWA_Bank']
>;
export type CreateDictDebtReminderRes = NonNullable<
  NonNullable<CreateDictDebtReminderMutation['wawiAssist']>['createDictWA_DebtReminder']
>;
export type SaveDictDebtReminderRes = NonNullable<
  NonNullable<SaveDictDebtReminderMutation['wawiAssist']>['saveDictWA_DebtReminder']
>;
export type DeleteDictDebtReminderRes = NonNullable<
  NonNullable<DeleteDictDebtReminderMutation['wawiAssist']>['deleteDictWA_DebtReminder']
>;
export type CreateDictStorageLocationRes = NonNullable<
  NonNullable<CreateDictStorageLocationMutation['wawiAssist']>['createDictWA_StorageLocation']
>;
export type SaveDictStorageLocationRes = NonNullable<
  NonNullable<SaveDictStorageLocationMutation['wawiAssist']>['saveDictWA_StorageLocation']
>;
export type DeleteDictStorageLocationRes = NonNullable<
  NonNullable<DeleteDictStorageLocationMutation['wawiAssist']>['deleteDictWA_StorageLocation']
>;
export type CreateDictCloudPrinterRes = NonNullable<
  NonNullable<CreateDictCloudPrinterMutation['wawiAssist']>['createDictWA_CloudPrinter']
>;
export type SaveDictCloudPrinterRes = NonNullable<
  NonNullable<SaveDictCloudPrinterMutation['wawiAssist']>['saveDictWA_CloudPrinter']
>;
export type DeleteDictCloudPrinterRes = NonNullable<
  NonNullable<DeleteDictCloudPrinterMutation['wawiAssist']>['deleteDictWA_CloudPrinter']
>;
