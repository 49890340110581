import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { overviewTabLoadingService } from './tabs/overviewTabLoading.service';
import { ITabState, OverviewTabs } from '../states/tabState.model';
import { TabContentContainer } from '../../../../shared/components/tabs/tabsContainer.component';
import { ContentLoading } from '../../../../shared/components/loading/loading.component';
import { TabPanel, Tabs } from '../../../../shared/components/tabs';
import { Stack, Tab } from '@mui/material';
import { t } from 'i18next';
import { transitionService } from '../../../../shared/components/loading/service/transition.service';
import { navigateService } from '../../../../shared/services/navigate/navigate.service';
import { OrdersOverviewTab } from './tabs/orders/ordersOverview.tab';
import { ArticlesOverviewTab } from './tabs/articles/articlesOverview.tab';
import { initOverviewState, overviewState } from '../states/overview.state';
import {
  DateRangePicker,
  IDateValue,
} from '../../../../shared/components/datePicker/dateRangePicker.component';
import { format } from 'date-fns';
import { FormSelect, SelectMenuWithFooter } from '../../../../shared/components/form';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from '../../../../shared/components/page/page.component';
import { localeFormatterHelper } from '../../../../shared/helpers/formatter/localeFormatter.helper.ts';

const OverviewDetails: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { tab } = useParams() as { tab: OverviewTabs };
  const {
    deliveryNoteGroups,
    datePeriod: initDatePeriod,
    deliveryNoteGroupId: initDeliveryNoteGroupId,
  } = useLoaderData() as ITabState;
  const formMethods = useForm<IForm>({
    mode: 'onChange',
    defaultValues: {
      deliveryNoteGroupId: initDeliveryNoteGroupId,
    },
  });
  const [datePeriod, setDatePeriod] = useState(initDatePeriod);

  const handleActiveTab: THandleActiveTab = (_, tab) => {
    if (!loading) {
      transitionService.pub.off();
      overviewTabLoadingService.pub.loading(true);
      navigateService.pub.navigateTo(`/order/overview/${tab}`);
    }
  };

  const handleCalendarChange = (datePeriod: IDateValue) => {
    const { fromDate, toDate } = datePeriod;
    if (fromDate && toDate) {
      const formatPeriodDate = {
        fromDate: format(fromDate, 'yyyy-MM-dd'),
        toDate: format(toDate, 'yyyy-MM-dd'),
      };
      overviewState.pub.updateDatePeriod(formatPeriodDate);
    }
  };

  useEffect(() => {
    overviewState.pub.init({
      ...initOverviewState,
      datePeriod: initDatePeriod,
      deliveryNoteGroupId: initDeliveryNoteGroupId,
    });

    const subOverviewState = overviewState.sub
      .state()
      .subscribe(({ datePeriod, deliveryNoteGroupId, action }) => {
        setDatePeriod(datePeriod);
        if (action === 'updateDeliverynoteGroupId') {
          formMethods?.reset({ deliveryNoteGroupId });
        }
      });

    const unsubLoading = overviewTabLoadingService.sub.loading().subscribe(setLoading);
    return () => {
      unsubLoading.unsubscribe();
      subOverviewState.unsubscribe();
    };
  }, []);

  return (
    <Page title={t('order.overview')}>
      <FormProvider {...formMethods}>
        <Stack marginBottom='24px' display='flex' flexDirection='row' gap={2}>
          <DateRangePicker
            defaultValue={{
              fromDate: localeFormatterHelper.localizedDate(datePeriod?.fromDate),
              toDate: localeFormatterHelper.localizedDate(datePeriod?.toDate),
            }}
            onChange={handleCalendarChange}
            width={300}
            fieldProps={{
              disabled: loading,
            }}
          />
          <FormSelect
            name='deliveryNoteGroupId'
            label={t('order.delivery_note_group')}
            options={(deliveryNoteGroups as []) || []}
            PaperComponent={(props) => (
              <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
            )}
            onChange={(v) => overviewState.pub.updateDeliverynoteGroupId(v ? Number(v) : null)}
            disabled={loading}
          />
        </Stack>
        <Tabs value={tab} onChange={handleActiveTab}>
          <Tab label={t('order.orders_overview')} value={OverviewTabs.deliveryNoteOverview} />
          <Tab label={t('order.articles_overview')} value={OverviewTabs.articleOverview} />
        </Tabs>
        <TabContentContainer>
          <ContentLoading loading={loading}>
            <>
              <TabPanel value={OverviewTabs.deliveryNoteOverview} activeValue={tab}>
                <OrdersOverviewTab />
              </TabPanel>
              <TabPanel value={OverviewTabs.articleOverview} activeValue={tab}>
                <ArticlesOverviewTab />
              </TabPanel>
            </>
          </ContentLoading>
        </TabContentContainer>
      </FormProvider>
    </Page>
  );
};
export default OverviewDetails;

interface IForm {
  deliveryNoteGroupId?: number | null;
}

type THandleActiveTab = (evt: SyntheticEvent<Element, Event>, tab: OverviewTabs) => void;
