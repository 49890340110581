import { gql } from 'graphql-request';

export const getArticlesList = gql`
  query listWA_Articles($filter: WA_ArticleFilter) {
    wawiAssist {
      listWA_Articles(filter: $filter) {
        id
        description
        articleNo
        isFractionArticle
        isDough
        isFermentationInterrupt
      }
    }
  }
`;
export const getArticleProductionTabData = gql`
  query getArticleProductionTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        daysToProduce
        productionRecipeId
        isDough
        isFermentationInterrupt
        doughWeightBeforeBaking
        doughArticleId
        countPerSheetOfCuttingMachine
        countOfSheetsPerCuttingMachine
        isShowOnProductionPrintLists
        isDoughSplitting
        doughSplittingDough1Amount
        weightOfLiterOfDough
        roundLitersTo
        isFractionArticle
        fractionArticleId
        usedPartOfFractionArticle
        numberOfPartsOfFractionArticle
        isRoundFractionArticleAmount
        articleDoughDataFromRA {
          id
          intermediateWeight
        }
        articleProductionCalculation {
          id
          isFermentationInterrupt
          addedArticleDescription
          addedArticleNo
          addedArticleId
          multiplier
        }
        willReplacedWithProductionCalc
        isProductionQuantityRounding
        productionQuantityRoundTo
        articleProductionCalculationDependencies {
          whichArticlesThisUses {
            articleNo
            description
            addedArticleNo
            addedDescription
            multiplier
            level
          }
          whichArticlesUseThis {
            articleNo
            description
            addedArticleNo
            addedDescription
            multiplier
            level
          }
          circularChain {
            articleId
            articleNo
            description
          }
          articlesLeadingToCircularDependency {
            articleId
            articleNo
            description
          }
        }
        articleCloudPrintersCheckedState {
          id
          label: name
          checked
        }
      }
    }
  }
`;

export const getArticleProductionCalculationDependencies = gql`
  query getArticleProductionCalculationDependencies($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        articleProductionCalculationDependencies {
          whichArticlesThisUses {
            articleNo
            description
            addedArticleNo
            addedDescription
            multiplier
            level
          }
          whichArticlesUseThis {
            articleNo
            description
            addedArticleNo
            addedDescription
            multiplier
            level
          }
          circularChain {
            articleId
            articleNo
            description
          }
        }
      }
    }
  }
`;

export const getArticleCashAssitTabData = gql`
  query getArticleCashAssitTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        caVisibilityStatusId
        caProductGroupId
        caTemplateId
      }
    }
  }
`;

export const getArticleLabelDataFromRA = gql`
  query getArticleLabelDataFromRA($recipeId: ID!) {
    wawiAssist {
      getWA_ArticleLabelDataFromRA(recipeId: $recipeId) {
        id
        autoDeclaration
        declaration
        isAutoDeclaration
        consumersInformation
        consumersStorageInfo
        daysToSell
        daysToConsume
        daysShelfLife
        isStoreInCoolPlace
        productOutputWeight
        sellingPoints
        sellingPointsFreezer
        sellingWeight
      }
    }
  }
`;

export const getArticlePostTabData = gql`
  query getArticlePostTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        shippingWeight
      }
    }
  }
`;

export const getArticleLabelTabData = gql`
  query getArticleLabelTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        customLabelDescription
        origin
        eanCode
        isEanCodeManagedByCA
        declaration
        isStoreInCoolPlace
        storagePlace
        consumersStorageInfo
        sellingWeight
        consumersInformation
        daysToSell
        daysToConsume
        daysShelfLife
        articleLabelDataFromRA {
          id
          autoDeclaration
          declaration
          isAutoDeclaration
          isStoreInCoolPlace
          daysToSell
          daysToConsume
          productOutputWeight
          consumersInformation
          consumersStorageInfo
          sellingPoints
          sellingWeight
          daysShelfLife
          sellingPointsFreezer
        }
      }
    }
  }
`;

export const getArticleGeneralTabData = gql`
  query getArticleGeneralTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        description
        note
        isNotifyOnOrderStage
        articleNo
        isActive
        discountGroupId
        productionGroupId
        marketingGroupId
        saleUnitId
        quantityPerLot
        vatCode
        isNoDiscount
        shippingPointId
        price1
        price2
        price3
        price4
        price5
        price6
        price7
        price8
        price9
        price10
        articleSpecialPrices {
          id
          fromDate
          toDate
          isActual
          price1
          price2
          price3
          price5
          price4
          price6
          price7
          price8
          price9
          price10
          description
        }
        articleResellerPrices {
          id
          price
          customerId
          customerNo
          customerName
          comment
        }
        articlePricesByCustomers {
          id
          specialPrice
          specialPriceType
          specialDiscount
          customerId
          customerName
          customerNo
          fromDate
          toDate
          fromQuantity
          specialIsNoDiscount
          comment
        }
      }
    }
  }
`;

export const getArticleListsTabData = gql`
  query getArticleListsTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        articleListsCheckedState {
          id
          label: name
          checked
        }
      }
    }
  }
`;

export const getArticleAvailabilityTabData = gql`
  query getArticleAvailabilityTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        availabilityInProcurementKindId
        isDeliveredMon
        isDeliveredTue
        isDeliveredWed
        isDeliveredThu
        isDeliveredFri
        isDeliveredSat
        isDeliveredSun
        articleAssortimentGroupsCheckedState {
          id
          label: name
          checked
        }
        articleDeliveryPeriodsSettings {
          articleRegularDeliveryPeriods {
            id
            fromDate
            toDate
            description
            isRepeatYearly
          }
          articleCustomWeekDaysAndDeliveryPeriods {
            id
            fromDate
            toDate
            description
            isDeliveredMon
            isDeliveredTue
            isDeliveredWed
            isDeliveredThu
            isDeliveredFri
            isDeliveredSat
            isDeliveredSun
            isRepeatYearly
          }
          articleVacations {
            id
            fromDate
            toDate
            description
          }
        }
      }
    }
  }
`;

export const weeklyDefaultOrderByArticleFragment = gql`
  fragment WeeklyDefaultOrderByArticle on WA_DefaultOrderPositionByArticle {
    internalOrFullName
    customerNr
    canChangeInOrders
    customerId
    itemSortPos
    deliverySplittingPartsCount
    isActive
    isDeliverySplitting
    isMatchAssortmentGroup
    mondaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
    tuesdaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
    wednesdaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
    thursdaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
    fridaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
    saturdaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
    sundaySettings {
      date
      isArticleAvailable
      quantity
      quantityMin
      quantityMax
      splittingPartsPreset
    }
  }
`;

export const getDefaultOrderTabData = gql`
  query getDefaultOrderTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        weeklyDefaultOrderByArticle {
          ...WeeklyDefaultOrderByArticle
        }
        #todo IF THE QUERY WILL EXPAND
      }
    }
  }
  ${weeklyDefaultOrderByArticleFragment}
`;

export const getWeeklyDefaultOrderByArticleFromDate = gql`
  query getWeeklyDefaultOrderByArticleFromDate($id: ID!, $date: Date) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        weeklyDefaultOrderByArticle(date: $date) {
          ...WeeklyDefaultOrderByArticle
        }
      }
    }
  }
  ${weeklyDefaultOrderByArticleFragment}
`;

export const getDataForNewDefaultOrderPositionByArticle = gql`
  query getDataForNewDefaultOrderPositionByArticle(
    $articleId: ID!
    $customerId: ID!
    $date: Date!
  ) {
    wawiAssist {
      getWA_DataForNewDefaultOrderPositionByArticle(
        articleId: $articleId
        customerId: $customerId
        date: $date
      ) {
        ...WeeklyDefaultOrderByArticle
      }
    }
  }
  ${weeklyDefaultOrderByArticleFragment}
`;

export const createArticle = gql`
  mutation createArticle($data: WA_CreateArticleInput!) {
    wawiAssist {
      createWA_Article(data: $data) {
        id
        description
        articleNo
        isDough
        isFractionArticle
        isFermentationInterrupt
      }
    }
  }
`;

export const copyArticle = gql`
  mutation copyArticle($originalId: ID!, $data: WA_CreateArticleInput!) {
    wawiAssist {
      copyWA_Article(originalId: $originalId, data: $data) {
        id
        description
        articleNo
        isDough
        isFractionArticle
        isFermentationInterrupt
      }
    }
  }
`;

export const saveArticleData = gql`
  mutation SaveArticleData($dataToSave: WA_SaveArticleInput!) {
    wawiAssist {
      saveArticleData(dataToSave: $dataToSave) {
        status
        updatedGridItem {
          id
          description
          articleNo
          isFractionArticle
          isDough
          isFermentationInterrupt
        }
      }
    }
  }
`;

export const saveArticleProductionCalculation = gql`
  mutation SaveArticleProductionCalculation($dataToSave: WA_SaveArticleInput!) {
    wawiAssist {
      saveArticleData(dataToSave: $dataToSave) {
        status
        updatedArticleProductionCalculationDependencies {
          whichArticlesThisUses {
            articleNo
            description
            addedArticleNo
            addedDescription
            multiplier
            level
          }
          whichArticlesUseThis {
            articleNo
            description
            addedArticleNo
            addedDescription
            multiplier
            level
          }
          circularChain {
            articleId
            articleNo
            description
          }
          articlesLeadingToCircularDependency {
            articleId
            articleNo
            description
          }
        }
      }
    }
  }
`;

export const validateArticleFieldsOnServer = gql`
  query validateWA_ArticleFields($fieldForCheck: WA_ArticlesFieldForCheck!, $id: ID) {
    wawiAssist {
      validateWA_ArticleFields(fieldsForCheck: $fieldForCheck, id: $id) {
        articleNo
        productionRecipeId
      }
    }
  }
`;

export const getArticleDoughDataFromRA = gql`
  query getWA_ArticleDoughDataFromRA($recipeId: ID!) {
    wawiAssist {
      getWA_ArticleDoughDataFromRA(recipeId: $recipeId) {
        id
        rMsTotalWeight
        nonTSLosses
        roundToInteger
        linkWeightMU
        intermediateWeight
      }
    }
  }
`;

export const getArticleSpecialPrices = gql`
  query getArticleSpecialPrices($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        articleSpecialPrices {
          id
          fromDate
          toDate
          isActual
          price1
          price2
          price3
          price5
          price4
          price6
          price7
          price8
          price9
          price10
          description
        }
      }
    }
  }
`;

export const getArticlePricesByCustomer = gql`
  query getArticlePricesByCustomer($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        articlePricesByCustomers {
          id
          specialPrice
          specialPriceType
          specialDiscount
          customerId
          customerName
          customerNo
          fromDate
          toDate
          fromQuantity
          specialIsNoDiscount
          comment
        }
      }
    }
  }
`;

export const getArticleResellerPrice = gql`
  query getArticleResellerPrice($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        articleResellerPrices {
          id
          price
          customerId
          customerNo
          customerName
          comment
        }
      }
    }
  }
`;

export const listArticlesWithSpecialOrRegularPrices = gql`
  query ListArticlesWithSpecialOrRegularPrices($articleIds: [ID]!, $onDate: Date) {
    wawiAssist {
      listWA_ArticlesWithSpecialOrRegularPrices(articleIds: $articleIds, onDate: $onDate) {
        id
        articleNo
        description
        price1
        price2
        price3
        price4
        price5
        price6
        price7
        price8
        price9
        price10
      }
    }
  }
`;

export const createArticleSpecialPrices = gql`
  mutation CreateArticleSpecialPrices($data: WA_CreateArticleSpecialPricesInput!) {
    wawiAssist {
      createWA_ArticleSpecialPrices(data: $data)
    }
  }
`;

export const customersList = gql`
  query customersList($filter: WA_CustomerFilter) {
    wawiAssist {
      listWA_Customers(filter: $filter) {
        id
        internalOrFullName
        customerNo
      }
    }
  }
`;
