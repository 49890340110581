import { FC, useState, SyntheticEvent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShortTextIcon from '@mui/icons-material/ShortText';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import NumbersIcon from '@mui/icons-material/Numbers';
import PictureInPictureOutlinedIcon from '@mui/icons-material/PictureInPictureOutlined';
import PictureInPictureAltOutlinedIcon from '@mui/icons-material/PictureInPictureAltOutlined';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined';
import { Box, styled, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { PageMarginsTuner } from './tuners/pageMarginsTuner';
import {
  ReportObjectVisibilityTuner,
  ReportObjectWithFormattedTextTuner,
  ReportObjectWithCustomFontTuner,
  ReportFooterObjectTuner,
  ReportFooterObjectWithFormattedTextTuner,
} from './tuners/reportObjectTuner';
import {
  PageBgImageTuner,
  ReportImageTuner,
  ReportFooterImageTuner,
} from './tuners/reportImageTuner';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../../../../shared/styles/theme/theme';
import { IReportConfigurationValuesPaths } from '../reportsConfiguration.tab';
import { ResetButton } from './resetButton/resetButton.component';

export const DeliveryNoteA4Config: FC<IReportConfigurationValuesPaths> = ({
  valuesPath,
  defaultValuesPath,
}) => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);

  const { t } = useTranslation();

  const handleAccordionChange = (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  return (
    <>
      <SAccordion
        expanded={expandedAccordion === 'pageMargins'}
        onChange={handleAccordionChange('pageMargins')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={ShortTextIcon}
            text={t('settings.page_margins')}
            color={Theme.palette.warning.light}
          />
          {expandedAccordion === 'pageMargins' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'pageMargins'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <PageMarginsTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'pageMargins'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'pageBgImage'}
        onChange={handleAccordionChange('pageBgImage')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={WallpaperOutlinedIcon}
            text={t('settings.page_background_image')}
            color={Theme.palette.warning.light}
          />
          {expandedAccordion === 'pageBgImage' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'pageBgImage'}
              ignoredFields={['image']}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <PageBgImageTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'pageBgImage'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'letterhead'}
        onChange={handleAccordionChange('letterhead')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={EditNoteOutlinedIcon}
            text={t('settings.letterhead')}
            color={Theme.palette.primary.main}
          />
          {expandedAccordion === 'letterhead' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'letterhead'}
              ignoredFields={['formattedText']}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportObjectWithFormattedTextTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'letterhead'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion expanded={expandedAccordion === 'logo'} onChange={handleAccordionChange('logo')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={PictureInPictureOutlinedIcon}
            text={t('settings.logo')}
            color={Theme.palette.success.light}
          />
          {expandedAccordion === 'logo' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'logo'}
              ignoredFields={['image']}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportImageTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'logo'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'deliveryAddress'}
        onChange={handleAccordionChange('deliveryAddress')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={FontDownloadOutlinedIcon}
            text={t('settings.delivery_address')}
            color={Theme.palette.primary.main}
          />
          {expandedAccordion === 'deliveryAddress' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'deliveryAddress'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportObjectWithCustomFontTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'deliveryAddress'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'invoiceAddress'}
        onChange={handleAccordionChange('invoiceAddress')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={FontDownloadOutlinedIcon}
            text={t('settings.invoice_address')}
            color={Theme.palette.primary.main}
          />
          {expandedAccordion === 'invoiceAddress' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'invoiceAddress'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportObjectWithCustomFontTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'invoiceAddress'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'phoneNumber'}
        onChange={handleAccordionChange('phoneNumber')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={FontDownloadOutlinedIcon}
            text={t('settings.phone_number')}
            color={Theme.palette.primary.main}
          />
          {expandedAccordion === 'phoneNumber' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'phoneNumber'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportObjectWithCustomFontTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'phoneNumber'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'companyVatNo'}
        onChange={handleAccordionChange('companyVatNo')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={CheckCircleOutlineIcon}
            text={t('settings.company_vat_no')}
            color={Theme.palette.primary.main}
          />
          {expandedAccordion === 'companyVatNo' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'companyVatNo'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportObjectVisibilityTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'companyVatNo'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'footerImage'}
        onChange={handleAccordionChange('footerImage')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={PictureInPictureAltOutlinedIcon}
            text={t('settings.image_in_footer')}
            color={Theme.palette.purple.main}
          />
          {expandedAccordion === 'footerImage' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'footerImage'}
              ignoredFields={['image']}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportFooterImageTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'footerImage'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'footerText'}
        onChange={handleAccordionChange('footerText')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={EditNoteOutlinedIcon}
            text={t('settings.text_in_footer')}
            color={Theme.palette.purple.main}
          />
          {expandedAccordion === 'footerText' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'footerText'}
              ignoredFields={['formattedText']}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportFooterObjectWithFormattedTextTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'footerText'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'printedAt'}
        onChange={handleAccordionChange('printedAt')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={WatchLaterOutlinedIcon}
            text={t('settings.printed_at_in_footer')}
            color={Theme.palette.purple.main}
          />
          {expandedAccordion === 'printedAt' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'printedAt'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportFooterObjectTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'printedAt'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'pageNumbering'}
        onChange={handleAccordionChange('pageNumbering')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={NumbersIcon}
            text={t('settings.page_numbering_in_footer')}
            color={Theme.palette.purple.main}
          />
          {expandedAccordion === 'pageNumbering' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'pageNumbering'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportFooterObjectTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'pageNumbering'}
          />
        </AccordionDetails>
      </SAccordion>

      <SAccordion
        expanded={expandedAccordion === 'createdBy'}
        onChange={handleAccordionChange('createdBy')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionHeader
            icon={PersonOutlinedIcon}
            text={t('settings.created_by_in_footer')}
            color={Theme.palette.purple.main}
          />
          {expandedAccordion === 'createdBy' && (
            <ResetButton
              reportActualValuesPath={valuesPath}
              reportDefaultValuesPath={defaultValuesPath}
              reportObjectName={'createdBy'}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReportFooterObjectTuner
            reportActualValuesPath={valuesPath}
            reportDefaultValuesPath={defaultValuesPath}
            reportObjectName={'createdBy'}
          />
        </AccordionDetails>
      </SAccordion>
    </>
  );
};

const SAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  '.MuiAccordion-root:first-of-type': {
    borderTopLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important',
  },

  '&.Mui-expanded': {
    backgroundColor: theme.palette.lightGrey.light,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const AccordionHeader: FC<IAccordionHeader> = ({ icon, text, color }) => {
  const Icon = styled(icon)(({ theme }) => ({
    width: 24,
    height: 24,
    display: 'inline-block',
    marginRight: theme.spacing(1),
    fill: color,
  }));
  return (
    <Box display='flex' alignItems='center'>
      <Icon />
      <Typography variant='body1' color={color}>
        {text}
      </Typography>
    </Box>
  );
};

interface IAccordionHeader {
  icon: any;
  text: string;
  color: string;
}
