import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText } from '@mui/material';
import { ChangeEvent, EventHandler, FC, ReactNode } from 'react';
import { take } from 'rxjs';
import { IUploadFileCommon, uploadFilesToS3 } from '../fileUpload.component';

export const UploadListItemButton: FC<IListItemButton> = ({
  accept = '*',
  multiple = false,
  place,
  color = 'primary',
  text = '',
  icon,
  component = 'label',
  onLoading,
  onUploaded,
  ...rest
}) => {
  const handleFileUpload: EventHandler<ChangeEvent<HTMLInputElement>> = (evt) => {
    evt.stopPropagation();

    const files = evt.currentTarget.files;
    if (typeof onLoading === 'function' && files?.length !== 0) {
      onLoading();
    }
    uploadFilesToS3({ files, place })
      .pipe(take(1))
      .subscribe((result) => {
        if (typeof onUploaded === 'function') {
          onUploaded(result);
        }
      });
  };
  return (
    <ListItemButton color={color} {...rest} component={component}>
      {icon && <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>}
      <ListItemText primary={text} />
      <input type='file' hidden accept={accept} multiple={multiple} onChange={handleFileUpload} />
    </ListItemButton>
  );
};
interface IListItemButton extends IUploadFileCommon, ListItemButtonProps {
  text: string;
  icon?: ReactNode;
}
