/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TableSelect } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { IOrderGrid } from '../../../../create/components/orderDetails/orderGrid/orderGrid.component.tsx';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component.tsx';
import {
  IOrderDetailsState as IOrderDetailsStateCreate,
  orderDetailsState as orderDetailsStateCreate,
} from '../../../../create/states/orderDetails/orderDetails.state.ts';
import {
  IOrderDetailsState as IOrderDetailsStateEdit,
  orderDetailsState as orderDetailsStateEdit,
} from '../../../../edit/states/orderDetails/orderDetails.state.ts';
import { ProductConfirmationPopup } from '../../../popups/productConfirmation.popup.tsx';
import { clearRowFromVirtualKeys } from '../splitting/additionalFunctions.tsx';
import { ProductMessagePopup } from '../../../popups/productMessage.popup.tsx';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { isSafari } from 'react-device-detect';
import { blockArrowKeysInMiddle } from '../../../../../../shared/components/table/functions/sharedFunc.ts';
import { EnterKeyNavigation } from '../../supportingFunctions/enterKeyNavigation.ts';
import { isEnterKey } from '../../../../../../shared/helpers/utils/utils.helper.ts';

export const ProductEditCell: FC<IProductEditCell> = ({
  params,
  options,
  type,
  enterKeyNavigation,
}) => {
  const { row, api, id, field, formattedValue } = params;
  const cellStream$ = useMemo(
    () => new BehaviorSubject<{ type?: 'pressKey'; changed?: boolean }>({}),
    [],
  );

  const productMessagePopup = useMemo(() => new ProductMessagePopup(), []);
  const productConfirmationPopup = useMemo(() => new ProductConfirmationPopup(), []);
  const { setEditCellValue, stopCellEditMode, getSortedRows, startCellEditMode } = api;
  const [inputValue, setInputValue] = useState(
    (options.find((el) => el?.articleNo === row.articleNo) && row.description) || '',
  );
  const [open, setOpen] = useState(true);
  const defaultValue = useMemo(() => {
    return options.find((el) => el?.id === row.articleId);
  }, []);
  const updateCell = useCallback(
    (value: IProductEditCell['options'][number], isFromPopup: boolean) => {
      if (value) {
        setEditCellValue({ id, field, value: value?.label });
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          {
            ...clearedRow,
            articleId: value?.id,
            articleNo: value?.articleNo,
            description: value?.label,
            vatCode: value?.vatCode!,
            quantityPerLot: value?.quantityPerLot,
            saleUnit: value?.saleUnit,
            weight: value?.weight,
            __reorder__: `(${value?.articleNo}) ${value?.label}`,
          },
          'descriptionCell',
        );
      }

      setOpen(false);
      if (isFromPopup) {
        stopCellEditMode({ id, field });
        const timer = setTimeout(
          () => {
            cellStream$.value.type === 'pressKey' &&
              enterKeyNavigation.handleEnterKey(field, id, api);
            clearTimeout(timer);
          },
          isSafari ? 100 : 0, // fix for safari
        );
      } else if (cellStream$.value.type === 'pressKey') {
        enterKeyNavigation.handleEnterKey(field, id, api);
      } else stopCellEditMode({ id, field });
    },
    [row],
  );

  const checkProductListed = useCallback(
    (
      productListed: ProductListedType<typeof type>,
      value: IProductEditCell['options'][number],
      isFromPopup: boolean,
    ) => {
      if (productListed) {
        productConfirmationPopup.stream.emit('open', {
          values: { productListed, value },
        });
      } else {
        updateCell(value, isFromPopup);
      }
    },
    [updateCell],
  );

  useEffect(() => {
    setEditCellValue({ id, field, value: formattedValue });
    row.description = formattedValue;
    productConfirmationPopup.stream
      .state()
      .subscribe(({ action, values: { value, productListed } }) => {
        if (action !== 'close') {
          setOpen(false);
          if (action === 'editAmount') {
            const timer = setTimeout(() => {
              startCellEditMode({ id: productListed?.id!, field: 'quantity' });
              (type === 'create'
                ? orderDetailsStateCreate
                : orderDetailsStateEdit
              ).pub.selectPosition(productListed);
              clearTimeout(timer);
            }, 0);
          } else if (action === 'addArticle') {
            updateCell(value, true);
          } else if (action === 'cancel') {
            cellStream$.value.type === 'pressKey' &&
              enterKeyNavigation.handleEnterKey(field, id, api);
            stopCellEditMode({ id, field });
          }
        }
      });
    productMessagePopup.stream.state().subscribe(({ action, values: { value, productListed } }) => {
      setOpen(false);
      if (action === 'ok') {
        checkProductListed(productListed, value, true);
      }
    });
    const cellStreamSub = cellStream$
      .pipe(
        debounceTime(20), // waiting to the answer of handleChange
      )
      .subscribe(({ type, changed }) => {
        if (type === 'pressKey' && !changed) {
          enterKeyNavigation.handleEnterKey(field, id, api);
        }
      });
    return () => {
      productConfirmationPopup.stream.unsubscribe();
      productMessagePopup.stream.unsubscribe();
      cellStreamSub.unsubscribe();
    };
  }, []);

  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    const value = v as IProductEditCell['options'][number];
    cellStream$.next({ ...cellStream$.getValue(), changed: true });
    const productListed = getSortedRows().find((el) => el.articleId === v?.id) as ProductListedType<
      typeof type
    >;
    if (value?.warningMsgOnSelect) {
      productMessagePopup.stream.emit('open', { values: { productListed, value } });
    } else {
      checkProductListed(productListed, value, false);
    }
  };
  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        (option.articleNo?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          option.label?.toLowerCase().includes(inputValue?.toLowerCase())) as boolean,
    );
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowLeft' || e.code === 'ArrowRight') {
      blockArrowKeysInMiddle(e);
      return;
    }
    e.stopPropagation();
    if (e.code === 'Escape') {
      stopCellEditMode({ id, field });
    } else if (e.target instanceof HTMLInputElement && isEnterKey(e)) {
      cellStream$.next({ type: 'pressKey' });
    }
  };

  return (
    <>
      <TableSelect
        autoFocus
        datasetattribute='productEditSelect'
        openOnFocus
        onKeyDown={handleKeyDown}
        size='small'
        width='100%'
        fontSize='14px'
        autoHighlight={true}
        filterOptions={filterOptions}
        inputValue={inputValue}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') setInputValue(newInputValue);
        }}
        defaultValue={defaultValue as ISelectOption}
        options={options as []}
        disableClearable
        onChange={handleChange}
        {...{ open }}
      />
      <productConfirmationPopup.Component />
      <productMessagePopup.Component />
    </>
  );
};

type ProductListedType<T extends 'create' | 'edit'> = T extends 'create'
  ? IOrderDetailsStateCreate['selectedPos']
  : IOrderDetailsStateEdit['selectedPos'];

export interface IProductEditCell {
  params: GridRenderEditCellParams;
  options: IOrderGrid['orderGridOptions']['productList'];
  enterKeyNavigation: EnterKeyNavigation;
  type: 'create' | 'edit';
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
