import * as yup from 'yup';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object().shape({
    articlePricelist: yup.object().shape({
      pageMargins: pageMarginsValidation(),
      letterhead: reportObjectWithFormattedTextValidation(),
      logo1Page: reportImageValidation(),
      logoFrom2ndPage: reportImageValidation(),
      address: reportObjectWithCustomFontValidation(),
      footerText: reportFooterObjectWithFormattedTextValidation(),
      printedAt: reportFooterObjectValidation(),
      pageNumbering: reportFooterObjectValidation(),
    }),

    deliveryNoteA4: yup.object().shape({
      pageMargins: pageMarginsValidation(),
      pageBgImage: pageBgImageValidation(),
      letterhead: reportObjectWithFormattedTextValidation(),
      logo: reportImageValidation(),
      deliveryAddress: reportObjectWithCustomFontValidation(),
      invoiceAddress: reportObjectWithCustomFontValidation(),
      phoneNumber: reportObjectWithCustomFontValidation(),
      companyVatNo: reportObjectVisibilityValidation(),
      sellerPerson: reportObjectValidation(),
      receiversSignature: reportObjectValidation(),
      footerImage: reportFooterImageValidation(),
      footerText: reportFooterObjectWithFormattedTextValidation(),
      printedAt: reportFooterObjectValidation(),
      pageNumbering: reportFooterObjectValidation(),
      createdBy: reportFooterObjectValidation(),
    }),
  });
};

const pageMarginsValidation = () =>
  yup.object().shape({
    left: yup.number().required('common.not_empty'),
    top: yup.number().required('common.not_empty'),
    bottom: yup.number().required('common.not_empty'),
  });

const pageBgImageValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
  });

const reportImageValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    top: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
  });

const reportFooterImageValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
  });

const reportObjectVisibilityValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
  });

const reportObjectValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    top: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
  });

const reportFooterObjectValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
  });

const reportObjectWithFormattedTextValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    top: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
  });

const reportFooterObjectWithFormattedTextValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
  });

const reportObjectWithCustomFontValidation = () =>
  yup.object().shape({
    isVisible: yup.boolean().required('common.not_empty'),
    left: yup.number().required('common.not_empty'),
    top: yup.number().required('common.not_empty'),
    height: yup.number().required('common.not_empty'),
    width: yup.number().required('common.not_empty'),
    fontOverride: fontOverrideValidation(),
  });

const fontOverrideValidation = () =>
  yup.object().shape({
    family: yup.string().required('common.not_empty'),
    size: yup.number().required('common.not_empty'),
  });
