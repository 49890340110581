import { useCommands } from '@remirror/react';
import { FC } from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { useCurrentAlignment } from './hooks/useCurrentAlignment';

export const AlignmentButtons: FC = () => {
  const { leftAlign, centerAlign, rightAlign } = useCommands();
  const currentAlignment = useCurrentAlignment();

  const handleAlignmentChange = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: AlignValue | null,
  ) => {
    if (newAlignment === AlignValue.left) {
      leftAlign();
    } else if (newAlignment === AlignValue.center) {
      centerAlign();
    } else if (newAlignment === AlignValue.right) {
      rightAlign();
    }
  };

  return (
    <SWrapper value={currentAlignment} exclusive onChange={handleAlignmentChange}>
      <SToggleButton value={AlignValue.left}>
        <FormatAlignLeftIcon />
      </SToggleButton>
      <SToggleButton value={AlignValue.center}>
        <FormatAlignCenterIcon />
      </SToggleButton>
      <SToggleButton value={AlignValue.right}>
        <FormatAlignRightIcon />
      </SToggleButton>
    </SWrapper>
  );
};

export enum AlignValue {
  left = 'left',
  center = 'center',
  right = 'right',
}

const SToggleButton = styled(ToggleButton)(() => ({
  width: '40px',
  height: '28px',
}));

const SWrapper = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#ffffff',
  '&.MuiToggleButtonGroup-root .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
