import React, { FC, useCallback, Dispatch, SetStateAction, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TableNumericField } from '../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { ICustomerImportData } from '../../../import.tab.tsx';
import { isEnterKey } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

export const AnyNumberFieldEditCell: FC<IAnyNumberFieldEditCell> = ({
  params,
  data,
  setData,
  max,
  min,
}) => {
  const { value: initVal, field, id, api } = params;
  const { setEditCellValue, stopCellEditMode } = api;
  const [value, setValue] = useState<string>(initVal !== null ? initVal : 0);
  const numberValue = Number.parseFloat(value) || 0;

  const updateCell = useCallback(
    (v: number | null) => {
      setEditCellValue({ id, field, value: v });
      let newData = structuredClone(data);
      newData = newData.map((item) => {
        if (item.id == id) {
          (item as any)[field] = Number.parseFloat(value) || 0;
        }
        return item;
      });
      setData(newData);
      stopCellEditMode({ id, field });
    },
    [params, numberValue],
  );

  const handleChange = (e: number | null) => {
    const currValue = e;
    setValue(currValue?.toString() || '');
  };

  const handleBlur = (e: number | null) => {
    const newValue = e;
    updateCell(newValue);
  };

  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && isEnterKey(v)) {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(Number(value));
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableNumericField
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      max={max || undefined}
      min={min || undefined}
      autoFocus
      noTrailingZeros
      value={numberValue}
    />
  );
};

interface IAnyNumberFieldEditCell {
  params: GridRenderEditCellParams;
  data: ICustomerImportData[];
  setData: Dispatch<SetStateAction<ICustomerImportData[]>>;
  min?: number | undefined;
  max?: number | undefined;
}
