import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IDictionaryPopup } from './dictionary.popup.tsx';
import { configsData } from '../../services/configsData/configsData.service.ts';
import { Observable, Subscription } from 'rxjs';
import { Table } from '../table/table.component.tsx';
import { Column, Footer, SFooter } from '../table/components/common.components.tsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';
import { viewCells } from './cells/views/viewCells.ts';
import { dictionaryState } from './states/dictionary.state.ts';
import { DeletionConfirmationPopup } from './deletionConfirmation/deletionConfirmation.popup.tsx';
import { viewRows } from './cells/views/viewRows.ts';

export const deletionConfirmationPopup = new DeletionConfirmationPopup();
export const ContentPopup: FC<IContentPopup> = ({
  dictionaryType,
  limit,
  columns,
  emptyRow,
  editNewCell,
  validateFields,
  nameAlias,
  popupTitle,
  recordDeletetionDisabled,
}) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<GridRowModel[]>();
  const [selectedRow, setSelectedRow] = useState<GridRowParams['row']>();
  const [disabledDelete, setDisabledDelete] = useState<boolean>(false);
  const [disableEditing, setDisableEditing] = useState<string[]>([]);

  useEffect(() => {
    let dictionarySub: Subscription;
    if (dictionaryType) {
      dictionarySub = (configsData.sub[dictionaryType]() as Observable<any>).subscribe((data) => {
        const updatedData = data.map((el: { [x: string]: any; label: string }) => {
          const { label, ...rest } = el;
          return { [nameAlias || 'name']: label, ...rest };
        });
        dictionaryState.pub.initData({
          data: updatedData,
          limit: !!limit,
          validateFields,
          dictionaryType,
        });
      });
      configsData.pub.common([dictionaryType!], true);
    }
    const dictionaryStateSub = dictionaryState.sub.state().subscribe(({ data, selectedRow }) => {
      setTableData(data);
      setSelectedRow(selectedRow);
    });
    const disabledDeleteSub = dictionaryState.sub.disableDelete().subscribe(setDisabledDelete);
    const disableEditingSub = dictionaryState.sub.disableEditing().subscribe(setDisableEditing);
    return () => {
      dictionaryState.pub.clearState();
      if (dictionarySub) dictionarySub.unsubscribe();
      dictionaryStateSub.unsubscribe();
      disabledDeleteSub.unsubscribe();
      disableEditingSub.unsubscribe();
    };
  }, []);

  const addRowHandler = useCallback(() => {
    const newRow: GridRowParams['row'] =
      emptyRow ||
      columns.reduce((acc: GridRowParams['row'], item) => {
        acc[item.field] = null;
        return acc;
      }, {});
    dictionaryState.pub.addRow(newRow);
  }, [columns, emptyRow]);

  const disableDelCustomly = useMemo<boolean>(() => {
    if (recordDeletetionDisabled && selectedRow) {
      return recordDeletetionDisabled(selectedRow);
    }
    return false;
  }, [recordDeletetionDisabled, selectedRow]);

  return (
    <>
      <Stack direction='row' spacing={2} mb={1}>
        <IconButton
          disabled={!tableData || tableData.length === limit}
          color='primary'
          size='large'
          title={t('common.add')}
          onClick={addRowHandler}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          disabled={
            !tableData ||
            tableData.length === 0 ||
            !selectedRow ||
            disableDelCustomly ||
            (disabledDelete as boolean)
          }
          color='primary'
          size='large'
          title={t('common.remove')}
          onClick={() => dictionaryState.pub.deleteRow()}
        >
          <RemoveIcon />
        </IconButton>
      </Stack>
      <Table
        heightOffset={300}
        loading={!tableData}
        data={(tableData || []) as []}
        onRowClick={(e) => dictionaryState.pub.selectRow(e.row)}
        rowSelectionModel={selectedRow?.id}
        onRowsCountChange={(rows, prevRows, apiRef) => {
          if (rows.length > prevRows.length) {
            const newRow = rows?.[rows.length - 1];
            if (newRow && !newRow[nameAlias || 'name']) {
              // // fixes opening of cell after closing previous cell
              const timer = setTimeout(() => {
                apiRef?.current.startCellEditMode({
                  id: newRow.id as string,
                  field: editNewCell || nameAlias || 'name',
                });
                clearTimeout(timer);
              }, 0);
            }
          }
        }}
        isCellEditable={(e) => !disableEditing.includes(String(e.id))}
        {...{ viewCells, viewRows }}
      >
        {
          columns.map((params, i) => (
            <Column key={i} sortable={false} resizable={false} flex={1} {...params} />
          )) as any
        }
        {limit ? (
          <Footer>
            <SCustomFooter>
              {t('common.max')}: {limit}
            </SCustomFooter>
          </Footer>
        ) : (
          <Fragment />
        )}
      </Table>
      <deletionConfirmationPopup.Component {...{ popupTitle }} />
    </>
  );
};

interface IContentPopup
  extends Pick<
    IDictionaryPopup['params'],
    | 'columns'
    | 'dictionaryType'
    | 'limit'
    | 'emptyRow'
    | 'editNewCell'
    | 'validateFields'
    | 'nameAlias'
    | 'popupTitle'
    | 'recordDeletetionDisabled'
  > {}

const SCustomFooter = styled(SFooter)({
  justifyContent: 'end',
});
