/* eslint-disable react-hooks/rules-of-hooks */

import { GridRowParams } from '@mui/x-data-grid-premium';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { SpecialPricesForm } from './specialPrices.form.tsx';
import { createValidationSchema } from './validation/individualPrices.schema.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { ITabState } from '../../../../../../../../states/tabState.model.ts';
import { localeFormatterHelper } from '../../../../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { TPopupDefaultActions } from '../../../../../../../../../../shared/components/popup/services/popup.service.ts';

const validationSchema = createValidationSchema();
export class SpecialPricesPopup extends Popup<ISpecialPricesPopup> {
  Component: FC<TPopupComponent>;
  createSpecialPriceCategoriesInputs: ITabState['createSpecialPriceCategoriesInputs'];
  constructor(params: {
    createSpecialPriceCategoriesInputs: ITabState['createSpecialPriceCategoriesInputs'];
  }) {
    super();
    const stream = this.innerStream;
    this.createSpecialPriceCategoriesInputs = params.createSpecialPriceCategoriesInputs;
    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 700 },
        }));
      }, []);
      const [field, setField] = useState('');
      const [disabledFromDate, setDisabledFromDate] = useState<Date[]>([]);
      const formMethods = useForm<TDefaultValues>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {},
      });
      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values?.row) {
            const { fromDate, toDate, ...rest } = values.row as TDefaultValues;
            formMethods.reset({
              ...rest,
              fromDate: fromDate && localeFormatterHelper.localizedDate(fromDate),
              toDate: toDate && localeFormatterHelper.localizedDate(toDate),
            });
          }
          if (values?.allPricesData) {
            const initDisabledFromDate = values?.allPricesData
              .filter(({ id }) => id !== values?.row?.id)
              .map(({ fromDate }) => fromDate);
            setDisabledFromDate(initDisabledFromDate || []);
          }
          if (values?.field) setField(values.field);
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('article.special_price')}>
          <FormProvider {...formMethods}>
            <SpecialPricesForm
              createSpecialPriceCategoriesInputs={this.createSpecialPriceCategoriesInputs}
              disabledFromDate={disabledFromDate}
              {...{ field, formMethods, stream }}
            />
          </FormProvider>
        </SWrapper>
      );
    };
  }
}

export interface ISpecialPricesPopup {
  action: 'save' | TPopupDefaultActions;
  values?: {
    row: GridRowParams['row'];
    field?: string;
    allPricesData: ITabState['articleSpecialPrices'];
  };
}

export type TDefaultValues = ITabState['articleSpecialPrices'][number];
